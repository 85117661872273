<template>
  <nav
    class="panel file-browser"
    :class="{ 'has-title': title, 'is-displayed-inline': inline, 'is-sidebar-expanded': isSidebarExpanded }"
  >
    <p class="panel-heading" v-if="title">
      {{ title }}
    </p>
    <div class="is-flex file-browser-content">
      <file-browser-sidebar
        :browser-ctrl="internalBrowserCtrl"
        v-if="isSidebarExpanded"
        @shrink-sidebar="isSidebarExpanded = false"
        :sidebar="isSidebarExpanded"
      />
      <file-browser-main
        :browser-ctrl="internalBrowserCtrl"
        @create="onCreate"
        @item-action="onItemAction"
        @expand-sidebar="isSidebarExpanded = true"
        :fileActions="fileActions"
        :directoryActions="directoryActions"
        :sidebar="isSidebarExpanded"
        :itemToInsertPreview="itemToInsertPreview"
      />
      <file-browser-details
        :browser-ctrl="internalBrowserCtrl"
        v-if="$screen.tablet && internalBrowserCtrl.selectedItems"
        :file-actions="fileActions"
        :directory-actions="directoryActions"
      />
    </div>
  </nav>
</template>

<script>
import FileBrowserSidebar from '@/components/folders/FileBrowserSidebar.vue'
import FileBrowserDetails from '@/components/folders/FileBrowserDetails.vue'
import FileBrowserMain from '@/components/folders/FileBrowserMain.vue'
import FileBrowserController from '@/lib/folders/FileBrowserController'
import { pick } from 'lodash'
import { ResolvableImage } from '@custom-media/signdigital-web-shared/src/lib/resolvable-image'
import { CustomSignImageReference, DocumentReference } from '@custom-media/signdigital-lib/src/image-reference'
import { ModalProgrammatic as Modal } from 'buefy'

export default {
  name: 'file-browser',
  components: {
    FileBrowserSidebar,
    FileBrowserDetails,
    FileBrowserMain
  },
  props: {
    title: String,
    browserCtrl: {
      type: Object,
      required: false,
      default: null
    },
    sources: {
      type: Array
    },
    nodeType: {
      type: String
    },
    fileActions: {
      type: Array,
      default () {
        return ['open', 'create', 'delete', 'rename', 'duplicate', 'move']
      }
    },
    directoryActions: {
      type: Array,
      default () {
        return ['open', 'create', 'delete', 'rename', 'move']
      }
    },
    inline: {
      type: Boolean,
      default: false
    },
    itemToInsertPreview: Object
  },
  data () {
    return {
      internalBrowserCtrl: null,
      isSidebarExpanded: this.$screen.tablet,
      detailsModal: null
    }
  },
  created () {
    this.$store?.dispatch('fileNodeTree/ensureInitialized')
    if (this.browserCtrl == null) {
      this.internalBrowserCtrl = new FileBrowserController({
        rootItems: this.$store.state.fileNodeTree.rootItems
      })
    } else {
      this.internalBrowserCtrl = this.browserCtrl
    }
  },
  watch: {
    'internalBrowserCtrl.currentItem' (n) {
      this.$store?.dispatch('fileNodeTree/maybeFetchChildren', n)
    },
    'internalBrowserCtrl.selectedItems' (n) {
      if (n && this.$screen.tablet === false) {
        if (this.detailsModal) {
          this.detailsModal.close()
        }
        this.detailsModal = Modal.open({
          parent: this,
          component: FileBrowserDetails,
          props: {
            browserCtrl: this.internalBrowserCtrl,
            fileActions: this.fileActions,
            directoryActions: this.directoryActions,
            mobile: true
          },
          close: () => {
            this.internalBrowserCtrl.clearSelectedItems()
            this.detailsModal = null
          }
        })
      }
    }
  },
  methods: {
    openNodeFile (node) {
      const route = pick(this.$route, ['name', 'query', 'params'])
      this.$store.commit('session/setExitRoute', { editor: node.fileType, route })
      switch (node.fileType) {
        case 'documents':
          this.$router.push({ name: 'document-editor', params: { id: node.file } })
          break
        case 'custom-signs':
          this.$router.push({ name: 'custom-sign-editor', params: { id: node.file } })
          break
        case 'lists':
          this.$router.push({ name: 'list-editor', params: { id: node.file } })
          break
      }
    },
    async onCreate ({ nodeType, fileType = null, documentType = 'grid', customSignType = 'signOnly' }) {
      const res = await this.$fileDialog.create({
        nodeType,
        fileType,
        location: this.internalBrowserCtrl.currentItem,
        documentType,
        customSignType,
        parent: this,
        focus: ['filename', 'documentType', 'customSignType']
      })
      if (res == null) {
        return
      }

      const { node } = res
      this.openNodeFile(node)
    },

    async downloadCustomSign (item) {
      const image = new ResolvableImage({
        $store: this.$store,
        $resolver: this.$resolver,
        reference: new CustomSignImageReference({ objectId: item.fileNode.file }),
        variant: 'original'
      })
      const w = window.open('', '_blank')
      w.focus()
      const url = await image.resolve()
      w.location.href = url + '&dl=1'
    },
    async openDocumentPDF (item) {
      const pdf = new ResolvableImage({
        $store: this.$store,
        $resolver: this.$resolver,
        reference: new DocumentReference({
          objectId: item.fileNode.file,
          service: 'documents'
        }),
        variant: 'original'
      })
      const w = window.open('', '_blank')
      w.focus()
      const url = await pdf.resolve()
      w.location.href = url + '&dl=1'
    },
    async onItemAction ({ action, item }) {
      // const nodeType = fileNodeType(item.fileNode)
      switch (action) {
        case 'play':
          this.$store.commit('session/setExitRoute', {
            editor: item.fileNode.fileType,
            route: pick(this.$route, ['name', 'query', 'params'])
          })
          this.$router.push({ name: 'list-player-setup', params: { id: item.fileNode.file } })
          return
        case 'edit':
        case 'open':
          // Switch item type
          if (item.fileNode.nodeType === 'file') {
            this.openNodeFile(item.fileNode)
          } else {
            this.browserCtrl.goTo(item)
          }
          break
        case 'rename':
          this.$fileDialog.rename({ item, parent: this })
          break
        case 'duplicate':
          this.$fileDialog.duplicate({ item, parent: this })
          break
        case 'move':
          this.$fileDialog.move({ item, parent: this })
          break

        case 'download':
          this.downloadCustomSign(item)
          break
        case 'pdf':
          this.openDocumentPDF(item)
          break
        case 'delete':
          this.$fileDialog.delete({ item, parent: this }).then((deleted) => {
            if (deleted && this.internalBrowserCtrl.currentItem._id === item._id) {
              this.internalBrowserCtrl.goToParent()
            }
          })
          break
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/bulma-variables.scss';

.file-browser.is-sidebar-expanded {
  @media screen and (max-width: $tablet) {
    .file-browser-sidebar {
      max-width: none;
      flex-grow: 1;

      &::v-deep .sidebar-content {
        margin-top: 0;
      }
    }
    .file-browser-main {
      display: none;
    }
  }
}

.file-browser::v-deep nav.file-browser-nav {
  height: 3rem;
  border-bottom: 1px solid $grey-lighter;
  // display: flex;
}

.file-browser-sidebar {
  max-width: 300px;
  min-width: 200px;
  border-right: 1px solid $grey-lighter;
  background: $grey-lightest;
}
.file-browser-details {
  max-width: 240px;
  min-width: 150px;
  border-left: 1px solid $grey-lighter;
  background: $grey-lightest;
}
.file-browser-main {
  flex-grow: 1;
}
.panel-heading {
  background: $grey-lighter;
  padding: 0.5em 0.75em;
  font-size: 1rem;

  border-bottom: 1px solid $grey-lightest;
}
.panel {
  border: 1px solid $grey-lighter;

  :not(.has-title) {
    .file-browser-sidebar {
      border-top-left-radius: $lg-radius;
      border-bottom-left-radius: $lg-radius;
      overflow: hidden;
    }
  }

  &.is-displayed-inline {
    box-shadow: none;
    border: 0;

    .file-browser-sidebar {
      border-radius: $lg-radius;
      background: transparent;
      border-right: 0;
      ::v-deep .sidebar-content {
        border-radius: $lg-radius;
        background: $grey-lightest;
        overflow: hidden;
      }
    }
  }
}
</style>
