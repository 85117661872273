<template>
  <section class="settings-topics">
    <div class="container p-5">
      <div class="cards columns is-multiline">
        <!-- User account -->
        <div class="column is-half is-one-third-widescreen">
          <div class="card">
            <div class="card-icon">
              <b-icon icon="user" />
              <h4>Benutzerkonto</h4>
            </div>
            <div class="card-content p-5">
              <div class="content">
                <ul>
                  <li><router-link :to="{ name: 'user-billing' }">Rechnungsadresse</router-link></li>
                  <li><router-link :to="{ name: 'user-password' }">Passwort ändern</router-link></li>
                  <li><router-link :to="{ name: 'user-subscription' }">Lizenz verwalten</router-link></li>
                  <li><router-link :to="{ name: 'user-invoices' }">Rechnungen</router-link></li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <!-- Team -->
        <div class="column is-half is-one-third-widescreen" v-if="showTeamSettings">
          <div class="card">
            <div class="card-icon">
              <b-icon icon="users" />
              <h4>Team</h4>
            </div>
            <div class="card-content p-5">
              <div class="content">
                <b-button
                  type="is-primary"
                  v-if="showCreateTeamButton"
                  expanded
                  tag="router-link"
                  :to="{ name: 'team-create' }"
                  >Team erstellen</b-button
                >
                <ul>
                  <li v-if="checkAuthorization('team-overview')">
                    <router-link :to="{ name: 'team-overview' }">Dein Team</router-link>
                  </li>
                  <li v-if="checkAuthorization('team-members')">
                    <router-link :to="{ name: 'team-members' }">Mitglieder</router-link>
                  </li>
                  <li v-if="checkAuthorization('team-invitations')">
                    <router-link :to="{ name: 'team-invitations' }">Einladungen</router-link>
                  </li>
                  <li v-if="checkAuthorization('team-invite')">
                    <router-link :to="{ name: 'team-invite' }">Personen einladen</router-link>
                  </li>
                  <li v-if="checkAuthorization('team-billing')">
                    <router-link :to="{ name: 'team-billing' }">Abrechnung</router-link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <!-- Documentation -->
        <div class="column is-half is-one-third-widescreen">
          <div class="card">
            <div class="card-icon">
              <b-icon icon="book-open" />
              <h4>Anleitungen</h4>
            </div>
            <div class="card-content p-5">
              <div class="content">
                <ul>
                  <!-- <li><a href="https://sign-digital.de" target="_blank">Einführungsvideo anschauen</a></li> -->
                  <li>
                    <a href="https://sign-digital.de/pdf/SIGNdigital-Handbuch.pdf" target="_blank"
                      >SIGNdigital-Handbuch</a
                    >
                  </li>
                  <li>
                    <a href="https://sign-digital.de/pdf/SIGNdigital-Teams.pdf" target="_blank"
                      >Handbuch zur Team-Funktion</a
                    >
                  </li>
                  <li>
                    <a href="https://sign-digital.de/pdf/SIGNdigital-Anleitung_Organisieren.pdf" target="_blank"
                      >Anleitung zur Organisieren-Funktion</a
                    >
                  </li>
                  <li><a href="https://sign-digital.de/pdf/SIGNdigital-Wortliste.pdf" target="_blank">Wortliste</a></li>
                  <li>
                    <a href="https://sign-digital.de/pdf/SIGNdigital-Referenten.pdf" target="_blank"
                      >Infos für Referenten</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <!-- Documentation -->
        <div class="column is-half is-one-third-widescreen">
          <div class="card">
            <div class="card-icon">
              <b-icon icon="question" />
              <h4>Support</h4>
            </div>
            <div class="card-content p-5">
              <div class="content">
                <ul>
                  <li><a href="mailto:support@sign-digital.de">Nimm Kontakt mit uns auf</a></li>
                  <li>
                    <a href="https://sign-digital.de/#signdigital-faqs" target="_blank">Häufig gestellte Fragen</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  computed: {
    showTeamSettings () {
      return this.$store.state.session.team != null || this.$store.getters['session/hasTeamLicense']
    },
    showCreateTeamButton () {
      return this.$store.getters['session/hasTeamLicense'] && this.$store.state.session.team == null
    }
  },
  methods: {
    // TODO: Move to mixin or other generally available location
    checkAuthorization (route) {
      const restricted = {
        team: [{ billingType: ['paying'], teamRole: ['owner'] }, { billingType: ['member'] }],
        'team-overview': [{ teamRole: ['member', 'manager', 'owner'] }],
        'team-members': [{ teamRole: ['manager', 'owner'] }],
        'team-invitations': [{ teamRole: ['manager', 'owner'] }],
        'team-invite': [{ teamRole: ['manager', 'owner'] }],
        'team-billing': [{ teamRole: ['manager', 'owner'] }]
      }

      const restrictions = restricted[route]
      if (!restrictions) {
        return true
      }

      for (const restriction of restrictions) {
        if (restriction.role != null) {
          const role = this.$store.state.session.user?.role
          if (restriction.role.includes(role) === false) {
            continue
          }
        }

        if (restriction.billingType != null) {
          const billingType = this.$store.state.session.user?.billingType
          if (restriction.billingType.includes(billingType) === false) {
            continue
          }
        }

        if (restriction.teamRole != null) {
          const teamRole = this.$store.state.session.teamRole
          if (restriction.teamRole.includes(teamRole) === false) {
            continue
          }
        }

        // Matched authorization rule
        return true
      }

      return false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/bulma-variables.scss';

.card {
  height: 100%;
  border: 1px solid $light;

  .card-content {
    .content {
      ul {
        margin-left: 1em;
      }
    }
  }
}
.card-icon {
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: white;
  border-bottom: 1px solid $light;

  .icon {
    width: 4rem;
    height: 4rem;
    border-radius: 2rem;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    font-size: 2rem;
  }

  h4 {
    font-weight: bold;
    margin-top: 1rem;
    font-size: 1.25rem;
  }
}
</style>
