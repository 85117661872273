<template>
  <div id="team-settings-view">
    <div class="columns">
      <div class="column is-narrow">
        <aside class="menu">
          <p class="menu-label pl-4">Team</p>
          <ul class="menu-list">
            <li><router-link :to="{ name: 'team-overview' }">Dein Team</router-link></li>
            <li>
              <router-link
                :to="{ name: 'team-members' }"
                :class="{ 'is-disabled': ['owner', 'manager'].includes(teamRole) === false }"
                >Mitglieder</router-link
              >
            </li>
            <li>
              <router-link
                :to="{ name: 'team-invitations' }"
                :class="{ 'is-disabled': ['owner', 'manager'].includes(teamRole) === false }"
                >Einladungen</router-link
              >
            </li>
            <li>
              <router-link
                :to="{ name: 'team-invite' }"
                :class="{ 'is-disabled': ['owner', 'manager'].includes(teamRole) === false }"
                >Personen einladen</router-link
              >
            </li>
            <li>
              <router-link :to="{ name: 'team-billing' }" :class="{ 'is-disabled': teamRole !== 'owner' }"
                >Abrechnung</router-link
              >
            </li>
          </ul>
        </aside>
      </div>

      <div class="column">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store'
import { mapState } from 'vuex'
export default {
  data () {
    return {}
  },
  computed: {
    ...mapState('teamSettings', ['isFetchPending', 'isFetchComplete']),
    ...mapState('session', ['teamRole'])
  },
  async beforeRouteEnter (to, from, next) {
    await store.dispatch('teamSettings/fetch', null)
    next()
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
#team-settings-view {
  display: flex;
  height: 100%;
  .columns {
    height: 100%;
    width: 100%;
  }
  .column {
    height: 100%;
  }

  .menu {
    padding-top: 3.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}
</style>
