export const stripeStyle = {
  base: {
    color: '#363636',
    fontSize: '20px',
    fontFamily: "'Mulish', sans-serif",
    backgroundColor: '#ffffff',
    '::placeholder': {
      color: '#dbdbdb'
    },
    ':-webkit-autofill': {
      color: '#32325d'
    }
  },
  invalid: {
    color: '#fa755a',
    iconColor: '#fa755a',
    ':-webkit-autofill': {
      color: '#fa755a'
    }
  }
}
