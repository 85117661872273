<template>
  <div class="page-copyright-note" :style="frameStyle">
    <span>{{ text }}</span>
    <img :src="logo" width="20" height="20" />
  </div>
</template>

<script>
import { frameStyleMixin } from '@/mixins/document-element-mixin'
import logo from '@/assets/img/sign-digital-logo_square_grey.svg'
export default {
  mixins: [frameStyleMixin],
  data () {
    return {
      logo
    }
  },
  props: {
    frame: Object,
    text: {
      type: String,
      default: 'Erstellt mit der SIGNdigital Web-App auf www.sign-digital.de'
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/bulma-variables.scss';
.page-copyright-note {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  font-size: 10px;
  font-weight: 700;
  color: $grey;
  text-align: right;
  span {
    position: relative;
    top: 3px;
  }
  img {
    margin-left: 8px;
  }
}
</style>
