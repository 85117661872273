<template>
  <div class="actions-dropdown" :class="classes">
    <b-dropdown
      :type="type"
      :triggers="['click']"
      :can-close="['outside', 'escape']"
      close-on-click
      :position="dropdownPosition"
      :disabled="disabled"
      size="is-small"
      ref="dropdown"
      @active-change="onDropdownChanged"
      @click.native.stop
    >
      <b-dropdown-item
        aria-role="listitem"
        v-for="action in actions"
        :key="action.value"
        :disabled="action.disabled"
        @click="onActionClicked($event, action)"
        :class="[type]"
      >
        <b-icon v-if="action.icon" :icon="action.icon" />
        <span>{{ action.text }}</span>
      </b-dropdown-item>
      <!-- <b-menu :activable="false">
      <b-menu-list>
        <b-menu-item
          v-for="action in actions"
          :key="action.value"
          :icon="action.icon"
          :label="action.text"
          :disabled="action.disabled"
          @click="onActionClicked($event, action)"
        ></b-menu-item>
      </b-menu-list>
    </b-menu> -->
      <template v-slot:trigger>
        <b-button
          :size="size"
          :type="type"
          :icon-left="icon"
          ref="actionButton"
          @click="onTriggerClicked"
          :class="triggerClass"
          ><span v-if="text">{{ text }}</span></b-button
        >
      </template>
    </b-dropdown>
  </div>
</template>
<script>
import { globalEventBus, globalEvents } from '@/lib/events/global-event-bus'

export default {
  props: {
    icon: {
      type: String,
      default: 'ellipsis-h'
    },
    type: {
      type: String,
      default: 'is-light'
    },
    text: String,
    actions: Array,
    position: {
      type: String,
      default: null
    },
    size: {
      type: String,
      default: 'is-default'
    },
    triggerClass: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    classes () {
      return [this.size]
    },
    dropdownPosition () {
      return this.position ?? this.suggestedDropdownPosition
    }
  },
  data () {
    return {
      suggestedDropdownPosition: 'is-bottom-left'
    }
  },
  created () {
    globalEventBus.on(globalEvents.actionsDropdown, this.onGlobalDropdownEvent)
  },
  destroyed () {
    globalEventBus.off(globalEventBus.actionsDropdown, this.onGlobalDropdownEvent)
  },
  methods: {
    onDropdownChanged (isActive) {
      if (isActive) {
        globalEventBus.emit(globalEvents.actionsDropdown, this._uid)
      }
    },
    onGlobalDropdownEvent (uid) {
      if (this._uid !== uid && this.$refs.dropdown?.isActive) {
        this.$refs.dropdown.toggle()
      }
    },
    onActionClicked (event, action) {
      this.$emit('action', action.value)
    },
    onTriggerClicked (event) {
      const viewportHeight = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
      const viewportX = document.getElementById('main-content')?.getBoundingClientRect().x

      const showLeft = event.clientX - viewportX > 300
      const showBottom = viewportHeight - event.clientY > 350
      console.log(event)
      this.suggestedDropdownPosition = `is-${showBottom ? 'bottom' : 'top'}-${showLeft ? 'left' : 'right'}`

      event.preventDefault()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/bulma-variables.scss';
.actions-dropdown {
  &.is-tiny {
    .dropdown-trigger .button {
      height: 1.75em;
      border-radius: 3px;
      font-size: 0.75rem;
    }
  }
}

.tooltip-trigger .button {
  color: darken($primary, 40%);
  &:hover {
    background: lighten($primary, 30%);
  }
  &:focus,
  &:active {
    background: lighten($primary, 10%);
  }
  &.is-active {
    background: lighten($primary, 30%);
  }
}
.dropdown-content {
  .dropdown-item {
    line-height: 1rem;
    font-size: 0.8rem;
    padding: 0.75em 0.75em;
    flex-wrap: nowrap;
    .icon {
      font-size: 1rem;
      height: 1rem;
      width: 1rem;
      margin-right: 0.5rem;
    }
  }
}
.b-tooltip::v-deep .tooltip-content {
  padding: 0.5em 0.25em;
  .menu-list {
    li a {
      line-height: 1rem;
      font-size: 0.8rem;
      padding: 0.75em 0.75em;
      flex-wrap: nowrap;
      .icon {
        font-size: 1rem;
        height: 1rem;
        width: 1rem;
        margin-right: 0.5rem;
      }
    }
  }
}
</style>
