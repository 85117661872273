<template>
  <div class="team-seats-card">
    <div class="card mb-4">
      <div class="card-header">
        <div class="card-header-title">{{ licenseTitle }}</div>
        <div class="buttons are-small mr-4">
          <b-button size="is-small" @click="reloadTeam" :loading="isGetPending" icon-left="sync"></b-button>
        </div>
      </div>
      <div class="card-content" v-if="license">
        <div class="content">
          <team-seats-chart class="mb-4" />
          <p v-if="freeSeats > 0">Es können noch {{ freeSeats }} Personen eingeladen werden.</p>
          <p v-else>Es sind alle Plätze belegt.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TeamSeatsChart from '@/components/team/TeamSeatsChart'
import { mapGetters, mapState } from 'vuex'
export default {
  components: {
    TeamSeatsChart
  },
  computed: {
    ...mapState('session', ['team', 'license']),
    ...mapGetters('teamSettings', ['seats', 'freeSeats']),
    ...mapState('teams', ['isGetPending']),

    licenseTitle () {
      return this.license?.title
    }
  },
  created () {},
  methods: {
    async reloadTeam () {
      await this.$store.dispatch('teams/get', [this.team._id])
      await this.$store.dispatch('teamSettings/fetch')
    }
  }
}
</script>
