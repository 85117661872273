<template>
  <div id="app" ref="app">
    <router-view />
  </div>
</template>
<script>
import axios from 'axios'
export default {
  created () {
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response.status === 401 && this.$route.name !== 'login') {
          this.$buefy.toast.open({
            type: 'is-danger',
            message: 'Sitzung abgelaufen'
          })
          let message
          if (error?.response?.data?.message === 'Token not allowed') {
            message = 'token-not-allowed'
          } else {
            message = 'session-expired'
          }
          this.$router.push({ name: 'login', query: { message } })
        }

        return Promise.reject(error)
      }
    )
  }
}
</script>
<style lang="scss">
@import '@/assets/scss/bulma-variables.scss';
@import '@/assets/scss/general.scss';
</style>
