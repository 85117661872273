<template>
  <div class="target-vocabulary-element" :style="sizeStyle">
    <actions-dropdown :actions="elementActions" size="is-tiny" @action="onAction" />

    <!-- CHECKBOXES -->
    <!-- Passive checkboxe -->
    <b-checkbox :style="passiveCheckboxFrameStyle" :value="element.hasPassive" @input="onHasPassiveChanged"
      >Passiv</b-checkbox
    >
    <!-- Active checkbox -->
    <b-checkbox :style="activeCheckboxFrameStyle" :value="element.hasActive" @input="onHasActiveChanged"
      >Aktiv</b-checkbox
    >

    <!-- Sign image -->
    <document-image
      :responsive="false"
      :frame="imageFrame"
      :image-reference="element.image"
      @update:image-reference="onImageChanged"
      :owner="owner"
      :ownerType="ownerType"
    />

    <!--  -->
    <document-text-area
      class="description"
      :frame="descriptionFieldFrame"
      :value="element.description"
      :font-style="descriptionStyle"
      placeholder="Beschreibung.."
      @update:value="onDescriptionChanged"
    />
  </div>
</template>

<script>
import DocumentImage from '@/components/document/DocumentImage'
import DocumentTextArea from '@/components/document/DocumentTextArea'
import { frameStyleMixin } from '@/mixins/document-element-mixin'
import { FontStyle } from '@custom-media/signdigital-lib/src/documents/font-style'
import ActionsDropdown from '../ActionsDropdown.vue'
import { mapState } from 'vuex'

export default {
  mixins: [frameStyleMixin],
  components: {
    DocumentImage,
    DocumentTextArea,
    ActionsDropdown
  },
  props: {
    elementId: String,
    size: Object
  },
  data () {
    return {
      passiveCheckboxFrame: { x: 190, y: 16, width: 30, height: 30 },
      activeCheckboxFrame: { x: 190, y: 50, width: 30, height: 30 },
      descriptionFieldFrame: { x: 190, y: 84, width: 153.5, height: 90 },
      descriptionStyle: new FontStyle(16, '500', 'left'),
      imageFrame: { x: 16, y: 16, width: 158, height: 158 },
      elementActions: [{ value: 'delete', icon: 'trash', text: 'Löschen' }]
    }
  },
  computed: {
    ...mapState('documentEditor', ['owner', 'ownerType']),
    element () {
      return this.$store.getters['documentEditor/getElement'](this.elementId)
    },
    passiveCheckboxFrameStyle () {
      return this.getFrameStyleFor(this.passiveCheckboxFrame)
    },
    activeCheckboxFrameStyle () {
      return this.getFrameStyleFor(this.activeCheckboxFrame)
    }
  },
  methods: {
    onHasActiveChanged (newHasActive) {
      this.$store.commit('documentEditor/patchElement', { id: this.element.id, data: { hasActive: newHasActive } })
    },
    onHasPassiveChanged (newHasPassive) {
      this.$store.commit('documentEditor/patchElement', { id: this.element.id, data: { hasPassive: newHasPassive } })
    },
    onDescriptionChanged (newDescription) {
      this.$store.commit('documentEditor/patchElement', { id: this.element.id, data: { description: newDescription } })
    },
    onImageChanged (newImage) {
      const data = { image: newImage }
      this.$store.commit('documentEditor/patchElement', { id: this.element.id, data })
    },
    onAction (action) {
      if (action === 'delete') {
        this.$emit('delete', this.element)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/bulma-variables.scss';
.target-vocabulary-element {
  display: flex;
  position: relative;
  padding: 16px 8px;

  .actions-dropdown {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    opacity: 0;
    transition: opacity linear 0.1s;
  }

  &:hover .actions-dropdown {
    opacity: 1;
  }

  .b-checkbox.checkbox {
    font-size: 18px;
  }

  .b-checkbox.checkbox::v-deep input[type='checkbox'] + .check {
    width: 100%;
    height: 100%;
    border: 2px solid $primary;
  }
}
</style>
