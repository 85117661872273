export function scrollToId (id) {
  if (id) {
    const el = document.getElementById(id)
    el.scrollIntoView({ behavior: 'smooth' })
  } else {
    window.scrollTo(0, 0)
  }
}

export default {
  methods: {
    scrollToId
  }
}
