<template>
  <div class="subscription-change">
    <!-- Select license step -->
    <card-step
      step-title="1."
      card-title="Lizenz auswählen"
      :active="currentStep === 'license'"
      :disabled="isChangingSubscription"
      :completed="isLicenseSelectionCompleted"
      @edit="currentStep = 'license'"
      class="mb-5"
    >
      <template #default>
        <!-- Slider to select license group size -->
        <license-seats-slider
          v-if="licenseIndex != null"
          :license-groups="licenseGroups"
          v-model="licenseIndex"
          @input="onLicenseIndexChanged"
        />

        <div class="licenses" v-if="selectedGroup != null">
          <!-- License variants -->
          <license-card
            v-for="license in selectedGroup.licenses"
            selectable
            :selected="selectedLicense === license"
            @selected="onLicenseSelected(license)"
            :key="license.id"
            :license="license"
            :vat="vat"
            :country="customer.countryCode"
          />
        </div>
      </template>
      <template #footer>
        <div class="card-footer-item">
          <b-button
            expanded
            type="is-primary"
            @click="onCompleteLicenseSelection"
            :disabled="!canCompleteLicenseSelection"
            >Weiter</b-button
          >
        </div>
      </template>
    </card-step>

    <!-- Select payment method step -->
    <validation-observer slim v-slot="{ invalid }">
      <card-step
        step-title="2."
        card-title="Zahlungsmethode auswählen"
        :active="currentStep === 'payment'"
        :disabled="isChangingSubscription"
        :completed="isPaymentMethodSelectionCompleted"
        @edit="currentStep = 'payment'"
        class="mb-5"
      >
        <template #default>
          <payment-method-select-form
            :has-submit-button="false"
            :selected-payment-method="selectedPaymentMethod"
            :payment-methods="paymentMethods"
            :required="createPaymentMethod !== 'true'"
            @update:selected-payment-method="onExistingPaymentMethodSelected"
            @remove="onRemovePaymentMethod"
          />

          <b-field>
            <b-radio
              v-model="createPaymentMethod"
              native-value="true"
              @input="onCreateNewPaymentMethodSelected"
              expanded
            >
              <span>Neue Zahlungsmethode anlegen</span>
            </b-radio>
          </b-field>
          <create-payment-method-form
            ref="createPaymentForm"
            v-if="createPaymentMethod === 'true'"
            :required="true"
            :customer="customer"
            :stripe="stripe"
            :stripe-elements="stripeElements"
            :enabled-payment-methods="enabledPaymentMethods"
            :has-submit-button="false"
          />
        </template>
        <template #footer>
          <div class="card-footer-item">
            <b-button expanded type="is-primary" @click="onCompletePaymentMethodSelection" :disabled="invalid"
              >Weiter</b-button
            >
          </div>
        </template>
      </card-step>
    </validation-observer>

    <validation-observer v-slot="{ invalid }">
      <card-step
        step-title="3."
        card-title="Bestellung bestätigen"
        :active="currentStep === 'invoice-preview'"
        :loading="isLoadingInvoicePreview"
        :completed="isInvoicePreviewCompleted"
        :disabled="isChangingSubscription"
        @edit="currentStep = 'invoice-preview'"
        class="mb-5"
      >
        <template #default>
          <feathers-error class="mt-4" :error="invoicePreviewError" title="Fehler beim Verarbeiten der Bestellung" />

          <!-- Preview -->
          <stripe-invoice-preview-table v-if="invoicePreview" :invoice-preview="invoicePreview" />

          <!-- Privacy -->
          <validation-provider
            name="Allgemeine Geschäftsbedingungen"
            :bails="false"
            :rules="{ required: { allowFalse: false } }"
            v-slot="{ errors, valid }"
          >
            <b-field :type="{ 'is-danger': errors.length > 0, 'is-primary': valid }" class="mt-4">
              <b-checkbox v-model="acceptedTerms"
                >Ich akzeptiere die
                <a :href="landingPageUrl + '/agb'" target="_blank">Allgemeinen Geschäftsbedingungen</a></b-checkbox
              >
              <template v-slot:message>
                <p>
                  <span v-if="errors.length > 0"
                    >Zur Nutzung von SIGNdigital müssen die Allgemeinen Geschäftsbedingungen akzeptiert werden. Bei
                    Fragen kannst du einfach
                    <a href="mailto:support@sign-digital.de">Kontakt mit uns aufnehmen.</a></span
                  >
                </p>
              </template>
            </b-field>
          </validation-provider>

          <validation-provider
            name="Datenschutzerklärung"
            :bails="false"
            :rules="{ required: { allowFalse: false } }"
            v-slot="{ errors, valid }"
          >
            <b-field :type="{ 'is-danger': errors.length > 0, 'is-primary': valid }" class="mt-2">
              <b-checkbox v-model="acceptedPrivacy"
                >Ich bin mit der Verarbeitung meiner Daten im Rahmen der
                <a :href="landingPageUrl + '/datenschutz'" target="_blank">Datenschutzerklärung</a>
                einverstanden</b-checkbox
              >
              <template v-slot:message>
                <p>
                  <span v-if="errors.length > 0"
                    >Zur Nutzung von SIGNdigital muss die Datenschutzerklärung akzeptiert werden. Bei Fragen kannst du
                    einfach <a href="mailto:support@sign-digital.de">Kontakt mit uns aufnehmen.</a></span
                  >
                </p>
              </template>
            </b-field>
          </validation-provider>
          <!-- Return policy -->
          <validation-provider
            name="Verzicht Widerrufsrecht"
            :bails="false"
            :rules="{ required: { allowFalse: false } }"
            v-slot="{ errors, valid }"
          >
            <b-field :type="{ 'is-danger': errors.length > 0, 'is-primary': valid }" class="mt-2 mb-4">
              <b-checkbox v-model="acceptedReturnPolicy"
                >Ich verzichte bei der Bestellung dieses digitalen Produktes auf mein 14-tägiges
                Widerrufsrecht</b-checkbox
              >
              <template v-slot:message>
                <p>
                  <span v-if="errors.length > 0"
                    >Da auf die digitalen Inhalte von SIGNdigital nach der Bestellung umgehend und vollständig
                    zugegriffen werden kann, benötigen wir den Verzicht auf das Widerrufsrecht. Bei Fragen kannst du
                    einfach <a href="mailto:support@sign-digital.de">Kontakt mit uns aufnehmen.</a></span
                  >
                </p>
              </template>
            </b-field>
          </validation-provider>
        </template>
        <template #footer>
          <div class="card-footer-item">
            <b-button
              expanded
              type="is-primary"
              @click="onCompleteSubscriptionChange"
              :disabled="!invoicePreview || invalid"
              >Jetzt kostenpflichtig bestellen</b-button
            >
          </div>
        </template>
      </card-step>
    </validation-observer>

    <card-step
      step-title="4."
      v-if="currentStep === 'submit'"
      :active="currentStep === 'invoice-preview'"
      card-title="Lizenz wird geändert"
      :editable="false"
      :completed="isSubmitCompleted"
      :loading="isChangingSubscription"
      class="mb-5"
    >
      <template #default>
        <feathers-error class="mt-4" :error="changeSubscriptionError" title="Fehler beim Ändern der Lizenz" />
      </template>
    </card-step>
  </div>
</template>

<script>
import * as licenseGroups from '@custom-media/signdigital-lib/src/constants/license-definitions'
import { License } from '@custom-media/signdigital-lib/src/license'
import * as countryDefinitions from '@custom-media/signdigital-lib/src/constants/country-definitions'
import LicenseSeatsSlider from '@custom-media/signdigital-web-shared/src/components/LicenseSeatsSlider'
import PaymentMethodSelectForm from '@/components/registration/steps/payment/PaymentMethodSelectForm'
import CreatePaymentMethodForm from '@/components/registration/steps/payment/CreatePaymentMethodForm.vue'
import LicenseCard from '@custom-media/signdigital-web-shared/src/components/LicenseCard'
import CardStep from '@/components/CardStep'
import { ValidationObserver } from 'vee-validate/dist/vee-validate.full'
import feathersClient from '@/feathers-rest-client'
import StripeInvoicePreviewTable from './registration/steps/summary/StripeInvoicePreviewTable.vue'
import * as Sentry from '@sentry/vue'

export default {
  components: {
    PaymentMethodSelectForm,
    CreatePaymentMethodForm,
    LicenseSeatsSlider,
    LicenseCard,
    CardStep,
    ValidationObserver,
    StripeInvoicePreviewTable
  },
  props: {
    customer: Object,
    subscription: Object,
    stripeSubscription: Object,
    paymentMethods: Array,
    stripe: Object,
    stripeElements: Object
  },
  data () {
    return {
      licenseGroups,
      licenseIndex: null,
      selectedLicense: null,

      currentStep: 'license',
      selectedPaymentMethod: null,
      isLicenseSelectionCompleted: false,

      isPaymentMethodSelectionCompleted: false,
      createPaymentMethod: null,

      isInvoicePreviewCompleted: false,
      isLoadingInvoicePreview: false,
      invoicePreview: null,
      invoicePreviewError: null,

      isChangingSubscription: false,
      isSubmitCompleted: false,
      changeSubscriptionError: null,

      acceptedPrivacy: false,
      acceptedTerms: false,
      acceptedReturnPolicy: false,
      landingPageUrl: process.env.VUE_APP_LANDING_HOST
    }
  },
  computed: {
    vat () {
      return countryDefinitions[this.customer.countryCode].vat
    },
    isCustomLicense () {
      return this.licenseIndex === licenseGroups.length
    },
    selectedGroupTitle () {
      if (this.isCustomLicense) {
        return 'Größere Volumenlizenz auf Anfrage'
      } else {
        return this.selectedGroup.title
      }
    },
    selectedGroup () {
      if (this.isCustomLicense) {
        return null
      }
      return licenseGroups[this.licenseIndex]
    },
    canCompleteLicenseSelection () {
      return this.selectedLicense != null
    },
    canCompeletePaymentMethodSelection () {
      return this.selectedPaymentMethod != null
    },
    enabledPaymentMethods () {
      let methods = []
      if (this.selectedLicense?.period <= 12) {
        methods = [...methods, 'card', 'sepa_debit']
      }
      if (
        this.selectedLicense.period >= 12 &&
        this.selectedLicense.size >= 5 &&
        this.customer.customerType === 'company'
      ) {
        methods = [...methods, 'invoice']
      }
      return methods
    }
  },
  mounted () {
    // Find initially selected license
    this.selectedLicense = this.getInitiallySelectedLicense()
    this.licenseIndex = this.licenseGroups.findIndex((g) => g.licenses.some((l) => l === this.selectedLicense)) ?? 0
  },
  methods: {
    getInitiallySelectedLicense () {
      for (const g of this.licenseGroups) {
        for (const l of g.licenses) {
          if (l.id === this.subscription.license) {
            return new License(l.id)
          }
        }
      }
      return null
    },
    onLicenseIndexChanged () {
      if (this.selectedGroup.licenses.some((l) => l === this.selectedLicense)) {
        return
      }
      this.selectedLicense =
        this.selectedGroup.licenses.find((l) => {
          const lo = new License(l.id)
          return lo.isPurchaseEnabledInCountry(this.customer.countryCode) && l.default
        }) ?? null
    },
    onLicenseSelected (license) {
      Sentry.addBreadcrumb({
        category: 'checkout',
        message: 'Selected license ' + license.id,
        level: 'info'
      })
      this.selectedLicense = license
    },
    async onCompleteLicenseSelection () {
      this.isLicenseSelectionCompleted = true
      this.currentStep = 'payment'
      this.fetchInvoicePreview()
    },
    async onCompletePaymentMethodSelection () {
      if (this.createPaymentMethod === 'true') {
        const paymentMethod = await this.$refs.createPaymentForm.onSubmit()
        if (!paymentMethod) {
          return
        }
        // eslint-disable-next-line vue/no-mutating-props
        this.paymentMethods.push(paymentMethod)
      }
      this.isPaymentMethodSelectionCompleted = true
      this.currentStep = 'invoice-preview'
    },
    onCreateNewPaymentMethodSelected () {
      this.createPaymentMethod = 'true'
      this.selectedPaymentMethod = null
    },
    async onRemovePaymentMethod (paymentMethod) {
      // TODO: Handle error
      this.isRemovePaymentMethodPending = true
      await feathersClient.service('checkout').remove(paymentMethod.id, { query: { object: 'payment_method' } })
      // eslint-disable-next-line vue/no-mutating-props
      this.paymentMethods = this.paymentMethods.filter((pm) => pm.id !== paymentMethod.id)
      this.isRemovePaymentMethodPending = false
    },
    async onExistingPaymentMethodSelected (paymentMethod) {
      this.createPaymentMethod = null
      this.selectedPaymentMethod = paymentMethod
    },
    async onCompleteSubscriptionChange () {
      this.isInvoicePreviewCompleted = true
      this.currentStep = 'submit'
      this.changeSubscription()
    },
    async fetchInvoicePreview () {
      this.isLoadingInvoicePreview = true
      this.invoicePreviewError = null
      try {
        this.invoicePreview = await feathersClient.service('checkout').patch(
          null,
          {
            license: this.selectedLicense.id
          },
          { query: { preview: true } }
        )
        console.log('did fetch invoice preview', this.invoicePreview)
      } catch (error) {
        console.error(error)
        this.invoicePreviewError = error
      } finally {
        this.isLoadingInvoicePreview = false
      }
    },
    async changeSubscription () {
      this.isChangingSubscription = true
      this.changeSubscriptionError = null
      try {
        const { subscription, stripeSubscription } = await feathersClient.service('checkout').patch(null, {
          license: this.selectedLicense.id,
          prorationTimestamp: this.invoicePreview.subscription_proration_date,
          paymentMethod: this.selectedPaymentMethod.id
        })
        this.$emit('change', { subscription, stripeSubscription })
        this.$buefy.toast.open({ type: 'is-success', message: 'Lizenz erfolgreich geändert' })
        this.isSubmitCompleted = true
      } catch (error) {
        console.error(error)
        this.changeSubscriptionError = error
        this.$buefy.toast.open({ type: 'is-danger', message: 'Ein Fehler ist aufgetreten' })
      } finally {
        this.isChangingSubscription = false
        // TODO: [SIGN-524] Redirect after subscription change
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.license {
  width: 100%;
  padding-left: 2em;
}
</style>
