<template>
  <div class="document-text-area" :style="frameStyle">
    <textarea class="textarea" :value="value" @input="onInput" :placeholder="placeholder" :style="textAreaStyle" />
  </div>
</template>
<script>
// TODO: Maybe check if textarea has scroll and resize text

import { frameStyleMixin } from '@/mixins/document-element-mixin'
export default {
  mixins: [frameStyleMixin],
  props: {
    frame: Object,
    value: String,
    placeholder: String,
    fontStyle: Object
  },
  computed: {
    textAreaStyle () {
      const style = this.fontStyle ? this.fontStyle.css : {}
      return {
        ...style,
        height: this.frame.height + 'px'
      }
    }
  },
  methods: {
    onInput ($event) {
      this.$emit('update:value', $event.target.value)
      // TODO: Maybe fit text?
    }
  }
}
</script>

<style lang="scss" scoped>
.document-text-area {
  overflow: hidden;
  textarea.textarea {
    min-height: 2em;
    resize: none;
  }
}
</style>
