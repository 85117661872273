<template>
  <div class="sign-editor-text-control">
    <!-- Input value -->
    <b-input v-model="text" size="is-small"></b-input>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  props: {
    layoutKey: String
  },
  computed: {
    ...mapState('signEditor', ['fixedObjectIdsByLayoutKey', 'objectsKeyedById']),
    fixedObjectId () {
      return this.fixedObjectIdsByLayoutKey[this.layoutKey]
    },
    fixedObject () {
      return this.objectsKeyedById[this.fixedObjectId]
    },
    text: {
      get () {
        const t = this.fixedObject?.text

        console.log('Text control recompute', t)
        return t
      },
      set (value) {
        const id = this.fixedObjectId
        this.$store.commit('signEditor/updateObjectProperty', {
          id,
          key: 'text',
          value,
          event: true
        })
      }
    }
  }
}
</script>
