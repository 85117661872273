<template>
  <div class="team-billing">
    <div class="container p-5">
      <h1 class="title">Abrechnung</h1>

      <div v-if="isOwner">
        <b-loading :active="isGetSubscriptionPending" />
        <p class="mb-4">
          Du bist Besitzer dieses Teams und teilst deine SIGNdigital Lizenz mit dem Team. Du kannst die Lizenz in deinem
          Benutzerkonto verwalten
        </p>
        <b-button tag="router-link" :to="{ name: 'user-subscription' }">Lizenz verwalten</b-button>
      </div>
      <p v-else>
        Die Abrechnungseinstellungen können nur vom Besitzer des Teams {{ team.owner.name }} ({{ team.owner.email }})
        eingesehen werden.
      </p>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  components: {},
  props: {},
  data () {
    return {
      subscription: null
    }
  },
  computed: {
    ...mapState('subscriptions', { isGetSubscriptionPending: 'isGetPending' }),
    ...mapState('session', ['team', 'teamRole']),
    isOwner () {
      return this.$store.getters['auth/user']._id === this.team.owner._id
    }
  },
  created () {
    this.init()
  },
  methods: {
    ...mapActions('subscriptions', { getSubscription: 'get' }),
    async init () {
      if (!this.isOwner) {
        return
      }
      this.subscription = await this.getSubscription(this.team.subscription)
    }
  }
}
</script>
