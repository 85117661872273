import { ImageReference } from '@custom-media/signdigital-lib/src/image-reference'

export async function ensureImageReferenceOwnership (ownershipDetails, imageReference, component) {
  const { Image } = component.$FeathersVuex.api
  const image = await Image.get(imageReference.objectId)

  if (
    image.acl === 'public' ||
    ((image.ownerType ?? 'users') === ownershipDetails.ownerType && image.owner === ownershipDetails.owner)
  ) {
    return imageReference
  }
  console.warn('Image needs to duplicated to match document ownership')

  if (image.ownerType === 'users') {
    return new Promise((resolve) => {
      component.$buefy.dialog.confirm({
        title: 'Mit deinem Team teilen?',
        message:
          'Du bist dabei ein hochgeladenes Bild in ein mit deinem Team geteilten Inhalt einzufügen.<br />Dadurch erhält dein gesamtes Team Zugriff auf das Bild. Bitte beachte dabei den Datenschutz und das Urheberrecht.<br/><br>Möchtest du das Bild mit deinem Team teilen?',
        confirmText: 'Mit Team teilen',
        cancelText: 'Abbrechen',
        onCancel: () => resolve(null),
        onConfirm: async () => {
          const i = await component.$store.dispatch('images/create', {
            clone: image._id,
            ...ownershipDetails
          })
          resolve(new ImageReference({ objectId: i._id }))
        }
      })
    })
  }

  // TODO: Other way round (copy image to local access)
  return imageReference
}
