import FileNodeTree from '@/lib/folders/FileNodeTree'
import FileNodeTreeItem from '@/lib/folders/FileNodeTreeItem'
import Vue from 'vue'

// TODO: Watch file-nodes and update tree on create or move

export default {
  namespaced: true,
  state: () => {
    return {
      isInitialized: false,
      rootItems: [],
      itemsById: {}
    }
  },
  getters: {
    ids (state, getters, rootState) {
      return rootState['file-nodes'].ids
    },
    userItem (state, getters, rootState) {
      const id = `user_${rootState.auth.user._id}`
      return state.itemsById[id]
    },
    teamItem (state, getters, rootState) {
      const teamId = rootState.auth.user.team ?? null
      if (teamId == null) {
        return null
      }
      const id = `team_${teamId}`
      return state.itemsById[id]
    }
  },
  mutations: {
    addItem (state, { parent, item }) {
      parent.addChild(item)
      Vue.set(state.itemsById, item._id, item)
    },

    removeItem (state, { parent, item }) {
      if (parent == null) {
        parent = state.itemsById[item.parentId]
      }
      const removedIds = parent.removeChild(item)
      removedIds.forEach((id) => {
        Vue.delete(state.itemsById, id)
      })
    },
    purgeInvalidBranches (state) {
      function purgeInvalidBranchesRecursively (parent, item, targetPath) {
        if (item.fileNode.path !== targetPath) {
          console.log(
            `Found invalid branch path at ${item.fileNode.path}: ${item._id}. This node has been moved ${targetPath}`
          )
          // TODO: this item needs to move
          if (parent) {
            // Remove this branch, add it at the correct position later
            const removedIdsInBranch = parent.removeChild(item)
            console.log('Removed invalid branch containing these ids:', removedIdsInBranch)
            removedIdsInBranch.forEach((id) => Vue.delete(state.itemsById, id))
          }
        } else {
          const childTargetPath = targetPath == null ? item.childrenPath : `${targetPath}${item._id},`
          item.children.forEach((child) => {
            purgeInvalidBranchesRecursively(item, child, childTargetPath)
          })
        }
      }

      state.rootItems.forEach((i) => purgeInvalidBranchesRecursively(null, i, null))
    },
    removeByIds (state, ids) {
      ids.forEach((id) => {
        const item = state.itemsById[id]
        if (item == null) {
          return
        }
        const parentId = item.parentId
        const parent = parentId ? state.itemsById[parentId] : null
        const removedIds = parent.removeChild(item)
        removedIds.forEach((id) => {
          Vue.delete(state.itemsById, id)
        })
      })
    },
    addNodes (state, nodes) {
      const sortedByLevel = nodes.sort((a, b) => a.path.split(',').length - b.path.split(',').length)
      for (const n of sortedByLevel) {
        const item = new FileNodeTreeItem(n)
        const parent = state.itemsById[item.parentId]
        if (parent === undefined) {
          console.info(`Won't add dangling node without parent ${item._id}, parent (${item.parentId})`)
          continue
        }
        parent.addChild(item)
        Vue.set(state.itemsById, item._id, item)
      }
    }
  },
  actions: {
    ensureInitialized ({ state, rootState }) {
      if (state.isInitialized) {
        return
      }
      const items = []

      const userId = rootState.auth.user._id
      const userIdPath = 'user_' + userId
      const userNode = {
        _id: userIdPath,
        path: null,
        name: 'Dein Ordner',
        nodeType: 'directory'
      }
      const usersTree = new FileNodeTree([userNode])
      state.itemsById[usersTree._id] = usersTree
      items.push(usersTree)

      const teamId = rootState.auth.user.team
      if (teamId) {
        const teamIdPath = 'team_' + teamId
        const teamNode = {
          _id: teamIdPath,
          path: null,
          name: 'Team-Ordner',
          nodeType: 'directory'
        }

        const teamTree = new FileNodeTree([teamNode])
        state.itemsById[teamTree._id] = teamTree
        items.push(teamTree)
      }

      state.rootItems = items
      state.isInitialized = true
    },
    async maybeFetchInPath ({ state, rootGetters, dispatch }, itemId) {
      // if (state.itemsById[itemId] && state.itemsById[itemId]) {
      //   return
      // }
      dispatch('ensureInitialized')

      const node =
        state.itemsById[itemId]?.fileNode ??
        rootGetters['file-nodes/get'](itemId) ??
        (await dispatch('file-nodes/get', itemId, { root: true }))

      const pathIds = node.path?.split(',').slice(1, -1) ?? []
      if (node.nodeType === 'directory') {
        pathIds.push(itemId)
      }
      for (const id of pathIds) {
        const i = state.itemsById[id]
        await dispatch('maybeFetchChildren', i)
      }
      return state.itemsById[itemId]
    },
    async maybeFetchChildren ({ rootState, dispatch }, item) {
      if (item == null) {
        return
      }
      if (item.didFetchChildren) {
        return
      }
      item.isFindChildrenLoading = true

      await dispatch(
        'file-nodes/find',
        { query: { path: item.childrenPath }, qid: `node_${item._id}`, paginate: false },
        { root: true }
      )
      await dispatch('rebuild')
      item.didFetchChildren = true
      item.isFindChildrenLoading = false
    },
    rebuild ({ rootGetters, commit, dispatch }) {
      // Rebuild complete tree except for rootItems?
      console.log('rebuild')
      commit('purgeInvalidBranches')
      dispatch('syncNodes', rootGetters['file-nodes/list'])
    },
    syncNodes ({ commit, dispatch, state }, nodes) {
      console.log('sync nodes')
      const ids = nodes.map((n) => n._id)
      const rootItemIds = state.rootItems.map((i) => i._id)
      const itemIdsInTree = Object.keys(state.itemsById).filter((id) => !rootItemIds.includes(id))
      const idsToRemove = itemIdsInTree.filter((id) => ids.includes(id) === false)
      const nodesToAdd = nodes.filter((node) => itemIdsInTree.includes(node._id) === false)
      console.log('will remove', idsToRemove)
      dispatch('removeAssociatedFilesFromStore', idsToRemove)
      commit('removeByIds', idsToRemove)

      console.log('will add nodes', nodesToAdd)
      commit('addNodes', nodesToAdd)
    },
    removeAssociatedFilesFromStore ({ state, commit }, idsToRemove) {
      // Collect by file type
      const removedFilesByType = {}
      idsToRemove.forEach((id) => {
        const i = state.itemsById[id]
        if (i?.fileNode?.nodeType !== 'file') {
          return
        }

        const fileType = i.fileNode.fileType
        if (removedFilesByType[fileType] == null) {
          removedFilesByType[fileType] = [i.fileNode.file]
        } else {
          removedFilesByType[fileType].push(i.fileNode.file)
        }
      })

      console.log('Will remove files from store', removedFilesByType)

      // Remove from stores
      Object.entries(removedFilesByType).forEach(([fileType, ids]) => {
        commit(`${fileType}/removeItems`, ids, { root: true })
      })
    }
  }
}
