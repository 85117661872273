import { Rectangle } from '@custom-media/geometry'
const OUTLINE_STROKE_WIDTH = 16

export const SIGN_BOX_STYLES = {
  adjective: {
    key: 'adjective',
    title: 'Adjektiv',
    properties: {
      rectangleOutline: {
        stroke: '#40c0f0',
        strokeWidth: 16,
        cornerRadius: 30
      },
      lineHorizontal: {
        stroke: '#40c0f0',
        strokeWidth: 16,
        cornerRadius: 30
      },
      lineVertical: {
        stroke: '#40c0f0',
        strokeWidth: 16,
        cornerRadius: 30
      },
      roundedBackgroundTop: {
        fill: 'white'
      },
      straightBackgroundTop: {
        fill: 'white'
      }
    }
  },
  adverb: {
    key: 'adverb',
    title: 'Adverb',
    properties: {
      rectangleOutline: {
        stroke: '#fecb05',
        strokeWidth: 16,
        cornerRadius: 30
      },
      lineHorizontal: {
        stroke: '#fecb05',
        strokeWidth: 16,
        cornerRadius: 30
      },
      lineVertical: {
        stroke: '#fecb05',
        strokeWidth: 16,
        cornerRadius: 30
      },
      roundedBackgroundTop: {
        fill: 'white'
      },
      straightBackgroundTop: {
        fill: 'white'
      }
    }
  },
  article: {
    key: 'article',
    title: 'Artikel',
    properties: {
      rectangleOutline: {
        stroke: '#a9dae6',
        strokeWidth: 16,
        cornerRadius: 30
      },
      lineHorizontal: {
        stroke: '#a9dae6',
        strokeWidth: 16,
        cornerRadius: 30
      },
      lineVertical: {
        stroke: '#a9dae6',
        strokeWidth: 16,
        cornerRadius: 30
      },
      roundedBackgroundTop: {
        fill: 'white'
      },
      straightBackgroundTop: {
        fill: 'white'
      }
    }
  },
  phrase: {
    key: 'phrase',
    title: 'Floskel',
    properties: {
      rectangleOutline: {
        stroke: '#05171d',
        strokeWidth: 3,
        cornerRadius: 30
      },
      lineHorizontal: {
        stroke: '#05171d',
        strokeWidth: 3,
        cornerRadius: 30
      },
      lineVertical: {
        stroke: '#05171d',
        strokeWidth: 3,
        cornerRadius: 30
      },
      roundedBackgroundTop: {
        fill: '#939292'
      },
      straightBackgroundTop: {
        fill: '#939292'
      }
    }
  },
  conjunction: {
    key: 'conjunction',
    title: 'Konjunktion',
    properties: {
      rectangleOutline: {
        stroke: '#eecde4',
        strokeWidth: 16,
        cornerRadius: 30
      },
      lineHorizontal: {
        stroke: '#eecde4',
        strokeWidth: 16,
        cornerRadius: 30
      },
      lineVertical: {
        stroke: '#eecde4',
        strokeWidth: 16,
        cornerRadius: 30
      },
      roundedBackgroundTop: {
        fill: 'white'
      },
      straightBackgroundTop: {
        fill: 'white'
      }
    }
  },
  noun: {
    key: 'noun',
    title: 'Nomen',
    properties: {
      rectangleOutline: {
        stroke: '#05171d',
        strokeWidth: 16,
        cornerRadius: 30
      },
      lineHorizontal: {
        stroke: '#05171d',
        strokeWidth: 16,
        cornerRadius: 30
      },
      lineVertical: {
        stroke: '#05171d',
        strokeWidth: 16,
        cornerRadius: 30
      },
      roundedBackgroundTop: {
        fill: 'white'
      },
      straightBackgroundTop: {
        fill: 'white'
      }
    }
  },

  numeral: {
    key: 'numeral',
    title: 'Numeral',
    properties: {
      rectangleOutline: {
        stroke: '#b2bcc2',
        strokeWidth: 16,
        cornerRadius: 30
      },
      lineHorizontal: {
        stroke: '#b2bcc2',
        strokeWidth: 16,
        cornerRadius: 30
      },
      lineVertical: {
        stroke: '#b2bcc2',
        strokeWidth: 16,
        cornerRadius: 30
      },
      roundedBackgroundTop: {
        fill: 'white'
      },
      straightBackgroundTop: {
        fill: 'white'
      }
    }
  },
  preposition: {
    key: 'preposition',
    title: 'Präposition',
    properties: {
      rectangleOutline: {
        stroke: '#72b73a',
        strokeWidth: 16,
        cornerRadius: 30
      },
      lineHorizontal: {
        stroke: '#72b73a',
        strokeWidth: 16,
        cornerRadius: 30
      },
      lineVertical: {
        stroke: '#72b73a',
        strokeWidth: 16,
        cornerRadius: 30
      },
      roundedBackgroundTop: {
        fill: 'white'
      },
      straightBackgroundTop: {
        fill: 'white'
      }
    }
  },
  pronoun: {
    key: 'pronoun',
    title: 'Pronomen',
    properties: {
      rectangleOutline: {
        stroke: '#b399c9',
        strokeWidth: 16,
        cornerRadius: 30
      },
      lineHorizontal: {
        stroke: '#b399c9',
        strokeWidth: 16,
        cornerRadius: 30
      },
      lineVertical: {
        stroke: '#b399c9',
        strokeWidth: 16,
        cornerRadius: 30
      },
      roundedBackgroundTop: {
        fill: 'white'
      },
      straightBackgroundTop: {
        fill: 'white'
      }
    }
  },
  verb: {
    key: 'verb',
    title: 'Verb',
    properties: {
      rectangleOutline: {
        stroke: '#ec6669',
        strokeWidth: 16,
        cornerRadius: 30
      },
      lineHorizontal: {
        stroke: '#ec6669',
        strokeWidth: 16,
        cornerRadius: 30
      },
      lineVertical: {
        stroke: '#ec6669',
        strokeWidth: 16,
        cornerRadius: 30
      },
      roundedBackgroundTop: {
        fill: 'white'
      },
      straightBackgroundTop: {
        fill: 'white'
      }
    }
  },
  other: {
    key: 'other',
    title: 'Andere',
    properties: {
      rectangleOutline: {
        stroke: '#312F2F',
        strokeWidth: 3,
        cornerRadius: 30
      },
      lineHorizontal: {
        stroke: '#312F2F',
        strokeWidth: 3,
        cornerRadius: 30
      },
      lineVertical: {
        stroke: '#312F2F',
        strokeWidth: 3,
        cornerRadius: 30
      },
      roundedBackgroundTop: {
        fill: 'white'
      },
      straightBackgroundTop: {
        fill: 'white'
      }
    }
  }
}

export const SIGN_BOX_FIXED_ELEMENTS = {
  imageSingleTop: {
    objectType: 'image',
    contentKey: 'imageComposedLeft', // Use same image as imageComposedLeft
    frame: Rectangle.fromProperties(36, 36, 720, 708),
    allowedContentTypes: ['signs', 'custom-signs', 'images'],
    allowedSignImageTypes: ['signOnly'],
    allowedCustomSignTypes: ['signOnly'],
    verticalAlignment: 'bottom',
    horizontalAlignment: 'center',
    layer: 0
  },
  imageComposedLeft: {
    objectType: 'image',
    frame: Rectangle.fromProperties(36, 195, 360, 550),
    allowedContentTypes: ['signs', 'custom-signs', 'images'],
    allowedSignImageTypes: ['signOnly'],
    allowedCustomSignTypes: ['signOnly'],
    verticalAlignment: 'bottom',
    horizontalAlignment: 'center',
    layer: 0
  },
  imageComposedRight: {
    objectType: 'image',
    frame: Rectangle.fromProperties(396, 195, 360, 550),
    allowedContentTypes: ['signs', 'custom-signs', 'images'],
    allowedSignImageTypes: ['signOnly'],
    allowedCustomSignTypes: ['signOnly'],
    verticalAlignment: 'bottom',
    horizontalAlignment: 'center',
    layer: 0
  },
  textComposedTopLeft: {
    objectType: 'textbox',
    text: 'Text links',
    fontSize: 54,
    frame: Rectangle.fromProperties(40, 123, 352, 72),
    layer: 0
  },
  textComposedTopRight: {
    objectType: 'textbox',
    text: 'Text rechts',
    fontSize: 54,
    frame: Rectangle.fromProperties(400, 123, 352, 72),
    layer: 0
  },
  imageBottom: {
    objectType: 'image',
    frame: Rectangle.fromProperties(46, 818, 332, 332),
    allowedContentTypes: ['images', 'custom-signs'],
    verticalAlignment: 'center',
    horizontalAlignment: 'center',
    layer: 0
  },
  rectangleOutline: {
    objectType: 'rectangle',
    frame: Rectangle.fromProperties(30, 30, 732, 1166),
    strokeWidth: OUTLINE_STROKE_WIDTH,
    fill: 'transparent',
    stroke: '#201E1A',
    cornerRadius: 30,
    layer: 1
  },
  roundedBackgroundTop: {
    objectType: 'rectangle',
    frame: Rectangle.fromProperties(30, 30, 732, 100),
    strokeWidth: 0,
    stroke: 'none',
    fill: 'white',
    cornerRadius: 30,
    layer: -1
  },
  straightBackgroundTop: {
    objectType: 'rectangle',
    frame: Rectangle.fromProperties(30, 100, 732, 644),
    strokeWidth: 0,
    stroke: 'none',
    fill: 'white',
    cornerRadius: 0,
    layer: -1
  },
  straightBackgroundBottom: {
    objectType: 'rectangle',
    frame: Rectangle.fromProperties(30, 744, 732, 382),
    strokeWidth: 0,
    stroke: 'none',
    fill: 'white',
    cornerRadius: 0,
    layer: -1
  },
  roundedBackgroundBottom: {
    objectType: 'rectangle',
    frame: Rectangle.fromProperties(30, 1096, 732, 100),
    strokeWidth: 0,
    stroke: 'none',
    fill: 'white',
    cornerRadius: 30,
    layer: -1
  },
  cardBackground: {
    objectType: 'rectangle',
    frame: Rectangle.fromProperties(30, 30, 732, 1166),
    strokeWidth: 0,
    stroke: 'none',
    fill: 'white',
    cornerRadius: 30,
    layer: -1
  },
  // TODO: Define lines as rectangles
  lineHorizontal: {
    objectType: 'line',
    frame: Rectangle.fromProperties(30, 744, 732, 0),
    strokeWidth: OUTLINE_STROKE_WIDTH,
    layer: 1
  },
  lineVertical: {
    objectType: 'line',
    frame: Rectangle.fromProperties(396, 815, 0, 336),
    strokeWidth: OUTLINE_STROKE_WIDTH,
    layer: 1
  },
  // TODO: Build V-Stack out of these texts
  textBottomAltTop: {
    objectType: 'textbox',
    layer: 0
  },
  textBottomRight: {
    objectType: 'textbox',
    text: 'Text unten',
    fontSize: 75,
    frame: Rectangle.fromProperties(417, 841, 328, 278),
    layer: 0,
    layoutFrame: Rectangle.fromProperties(417, 841, 328, 278),
    verticalAlignmentInLayoutFrame: 'center'
  },
  textBottomAltBottom: {
    objectType: 'textbox',
    layer: 0
  }
}
