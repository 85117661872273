<template>
  <div class="team-invitation-card">
    <div class="box box-shadow mb-4" v-if="invitation">
      <p class="has-text-grey is-size-7 mb-2">An {{ invitation.email }}:</p>
      <strong>Team-Einladung:</strong>
      <h2 class="subtitle mb-1 mt-2">{{ invitation.team.name }}</h2>
      <p>{{ invitation.team.description }}</p>
      <b-button type="is-danger" class="mt-4" size="is-small" expanded icon-left="times" @click="onCancel"
        >Einladung vorerst ignorieren</b-button
      >
      <slot />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  props: {},
  computed: {
    ...mapState('teamInvitation', ['invitation'])
  },
  methods: {
    onCancel () {
      this.$buefy.dialog.confirm({
        title: 'Einladung verwerfen',
        message: `Du warst gerade dabei, eine Einladung zum SIGNdigital-Team „${this.invitation.team.name}” wahrzunehmen. Wenn du auf „Ohne Einladung fortfahren” klickst, kannst du dich ganz normal mit einem anderen Konto anmelden, oder ein neues Konto erstellen. Du kannst den Einladungs-Prozess neu starten, indem du erneut auf den Einladungs-Link der E-Mail klickst.`,
        confirmText: 'Einladung verwerfen',
        cancelText: 'Abbrechen',
        type: 'is-danger',
        onConfirm: () => {
          this.$store.dispatch('teamInvitation/cancel')
          this.$emit('cancel')
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/bulma-variables.scss';
.team-invitation-card > .box .subtitle {
  color: $dark;
}
</style>
