import { shuffle } from 'lodash'
import { UNLICENSED_VISIBLE_SIGN_COUNT } from '@/lib/constants/unlicensed-constants'

export default {
  namespaced: true,
  state: () => {
    return {
      revealable: [],
      revealed: [],
      shuffle: false,
      isSharedList: false,
      list: null,
      shuffledSigns: [],
      index: 0,
      isLoading: false
    }
  },
  getters: {
    isUnlicensed (_state, _getters, _rootState, rootGetter) {
      return !rootGetter['auth/user']?.hasActiveSubscription
    },
    isRevealed (state) {
      return (field) => state.revealed?.includes(field) ?? false
    },
    isRevealable (state) {
      return (field) => state.revealable?.includes(field) ?? false
    },
    unlearnedSigns (state) {
      if (state.list == null || state.list.signs == null || state.list.learnedSigns == null) {
        return []
      }

      const signs = state.shuffle ? state.shuffledSigns : state.list.signs

      const unlearnedSigns = signs.filter((s) => !state.list.learnedSigns.includes(s._id))
      return unlearnedSigns
    },
    unlicensedSigns (_state, getters) {
      return getters.unlearnedSigns.slice(0, UNLICENSED_VISIBLE_SIGN_COUNT)
    },
    visibleSigns (_state, getters) {
      return getters.isUnlicensed ? getters.unlicensedSigns : getters.unlearnedSigns
    },
    currentSign (state, getters) {
      return getters.unlearnedSigns?.[state.index] ?? null
    },
    hasPrevious (state) {
      return state.index > 0
    },
    hasNext (state, getters) {
      if (getters.visibleSigns == null) {
        return false
      }
      return state.index < getters.visibleSigns.length - 1
    },
    isLast (state, getters) {
      if (getters.visibleSigns == null) {
        return false
      }
      return state.index === getters.visibleSigns.length - 1
    }
  },
  mutations: {
    setRevealable (state, value) {
      state.revealable = value
    },
    setRevealed (state, value) {
      state.revealed = value
    },
    setShuffle (state, value) {
      state.shuffle = value
    },
    setList (state, list) {
      state.list = list
      state.shuffledSigns = shuffle(state.list.signs)
    },
    shuffleSigns (state) {
      state.shuffledSigns = shuffle(state.list.signs)
    },
    resetRevealProgress (state) {
      state.revealed = []
    },
    revealAll (state) {
      state.revealed = state.revealable
    },
    goToNext (state) {
      state.index += 1
    },
    goToPrev (state) {
      state.index -= 1
    },
    goTo (state, index) {
      state.index = index
    }
  },
  actions: {
    async ensureLoaded ({ state, commit, dispatch }, { id = null, shareKey = null }) {
      try {
        state.isLoading = true
        state.isSharedList = false

        if (id && state.list?._id !== id) {
          const list = await dispatch('lists/get', id, { root: true })
          state.isSharedList = true
          commit('setList', list)
        } else if (shareKey && state.list?.shareKey !== shareKey) {
          const res = await dispatch('lists/find', { query: { shareKey } }, { root: true })
          const list = res.data[0]
          list.learnedSigns = []
          commit('setList', list)
          state.isSharedList = true
          state.resetProgressOnStart = false
          state.shuffle = false
        }
      } catch (error) {
        console.error('Error loading list:', error)
      } finally {
        state.isLoading = false
      }
    },
    async resetProgress ({ state, getters }) {
      // state.list.learnedSigns = []
      state.index = 0
      if (!getters.isUnlicensed && !state.isSharedList) {
        await state.list.patch({ data: { learnedSigns: [] } })
      }
    },
    async markAsLearned ({ state, getters }, signId) {
      state.list.learnedSigns.push(signId)
      state.index = Math.min(state.index, getters.unlearnedSigns.length - 1)
      state.list.save()
    }
  }
}
