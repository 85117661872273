<template>
  <div>
    <div class="field is-grouped is-grouped-multiline">
      <div class="control">
        <div class="tags has-addons">
          <span class="tag is-primary">Abrechnungszeitraum</span>
          <span class="tag is-grey">{{ summary.periodStart | date }} - {{ summary.periodEnd | date }}</span>
        </div>
      </div>
      <div class="control">
        <div class="tags has-addons">
          <span class="tag is-primary">Fällig am</span>
          <span class="tag is-grey">{{ summary.dueDate | dateTimeAt }} </span>
        </div>
      </div>
    </div>

    <table class="table is-light" width="100%">
      <thead>
        <tr>
          <th>Position</th>
          <td></td>
          <th>MwSt.</th>
          <th>Preis</th>
        </tr>
      </thead>
      <tbody>
        <!-- License -->
        <tr :key="i" v-for="(item, i) in summary.lineItems">
          <td>
            <span>{{ item.name }}</span>
            <!-- <div class="tags mt-1">
                <span class="tag is-grey">Laufzeit: {{ license.period }} Monat{{ license.period > 1 ? 'e' : ''}}</span>
              </div> -->
          </td>
          <td></td>
          <td class="vat-col">{{ item.vat | price }} ({{ item.vatPercent }}%)</td>
          <td class="price-col">{{ item.total | price }}</td>
        </tr>
      </tbody>
      <!-- Totals -->
      <tfoot>
        <!-- Net total -->
        <tr>
          <td colspan="3">Netto</td>
          <td class="price-col">
            <!-- License -->
            <!-- License -->
            {{ summary.netSum | price }}
          </td>
        </tr>
        <!-- Vat amount -->
        <tr>
          <td colspan="3">MwSt.</td>
          <td class="price-col">
            {{ summary.vatSum | price }}
          </td>
        </tr>
        <!-- Total -->
        <tr class="total">
          <td colspan="3">Gesamt</td>
          <td class="price-col">
            {{ summary.total | price }}
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    invoicePreview: Object
  },
  data () {
    return {
      netSum: null,
      vatSum: null,
      total: null,
      lineItems: null
    }
  },
  // TODO: [SIGN-361] Übersetzung Stripe Proration Table
  computed: {
    summary () {
      return {
        lineItems: this.invoicePreview.lines.data.map((i) => {
          const net = i.amount / 100
          const vat = i.tax_amounts?.[0]?.amount / 100
          return {
            name: i.description,
            net,
            vatPercent: i.tax_rates?.[0]?.percentage,
            vat,
            total: vat + net
          }
        }),
        netSum: this.invoicePreview.subtotal / 100,
        vatSum: this.invoicePreview.tax / 100,
        total: this.invoicePreview.total / 100,
        periodStart: new Date(this.invoicePreview.period_start * 1000),
        periodEnd: new Date(this.invoicePreview.period_end * 1000),
        dueDate: new Date(this.invoicePreview.next_payment_attempt * 1000)
      }
    }
  },
  methods: {}
}

// "lines": {
//     "object": "list",
//     "data": [
//       {
//         "id": "il_tmp_1J3FBQCFxtTvZWVuNOJXKJbg",
//         "object": "line_item",
//         "amount": -38,
//         "currency": "eur",
//         "description": "Unused time on SIGNdigital Einzellizenz after 17 Jun 2021",
//         "discount_amounts": [],
//         "discountable": false,
//         "discounts": [],
//         "invoice_item": "ii_1J3FBQCFxtTvZWVuNOJXKJbg",
//         "livemode": false,
//         "metadata": {},
//         "period": {
//           "end": 1624130792,
//           "start": 1623913116
//         },
//         "plan": {
//           "id": "price_1IVu2aCFxtTvZWVuCZyRUhks",
//           "object": "plan",
//           "active": true,
//           "aggregate_usage": null,
//           "amount": 463,
//           "amount_decimal": "463",
//           "billing_scheme": "per_unit",
//           "created": 1615967020,
//           "currency": "eur",
//           "interval": "month",
//           "interval_count": 1,
//           "livemode": false,
//           "metadata": {},
//           "nickname": null,
//           "product": "prod_Ia87qPpo8ueslL",
//           "tiers": null,
//           "tiers_mode": null,
//           "transform_usage": null,
//           "trial_period_days": null,
//           "usage_type": "licensed"
//         },
//         "price": {
//           "id": "price_1IVu2aCFxtTvZWVuCZyRUhks",
//           "object": "price",
//           "active": true,
//           "billing_scheme": "per_unit",
//           "created": 1615967020,
//           "currency": "eur",
//           "livemode": false,
//           "lookup_key": null,
//           "metadata": {},
//           "nickname": null,
//           "product": "prod_Ia87qPpo8ueslL",
//           "recurring": {
//             "aggregate_usage": null,
//             "interval": "month",
//             "interval_count": 1,
//             "trial_period_days": null,
//             "usage_type": "licensed"
//           },
//           "tiers_mode": null,
//           "transform_quantity": null,
//           "type": "recurring",
//           "unit_amount": 463,
//           "unit_amount_decimal": "463"
//         },
//         "proration": true,
//         "quantity": 1,
//         "subscription": "sub_JKiAG3EiFFM5WG",
//         "subscription_item": "si_JKiAy0zaNrH7qd",
//         "tax_amounts": [
//           {
//             "amount": -3,
//             "inclusive": false,
//             "tax_rate": "txr_1IVu4eCFxtTvZWVuOg6pxxuz"
//           }
//         ],
//         "tax_rates": [
//           {
//             "id": "txr_1IVu4eCFxtTvZWVuOg6pxxuz",
//             "object": "tax_rate",
//             "active": true,
//             "country": "DE",
//             "created": 1615967148,
//             "description": "MwSt. 7% exkl.",
//             "display_name": "Umsatzsteuer",
//             "inclusive": false,
//             "jurisdiction": null,
//             "livemode": false,
//             "metadata": {},
//             "percentage": 7,
//             "state": null
//           }
//         ],
//         "type": "invoiceitem"
//       },
//       {
//         "id": "il_tmp_1J3FBQCFxtTvZWVu9NDQXWk9",
//         "object": "line_item",
//         "amount": 76,
//         "currency": "eur",
//         "description": "Remaining time on SIGNdigital Familienlizenz (3 Plätze) after 17 Jun 2021",
//         "discount_amounts": [],
//         "discountable": false,
//         "discounts": [],
//         "invoice_item": "ii_1J3FBQCFxtTvZWVu9NDQXWk9",
//         "livemode": false,
//         "metadata": {},
//         "period": {
//           "end": 1624130792,
//           "start": 1623913116
//         },
//         "plan": {
//           "id": "price_1IYreTCFxtTvZWVuwpEg0EIa",
//           "object": "plan",
//           "active": true,
//           "aggregate_usage": null,
//           "amount": 929,
//           "amount_decimal": "929",
//           "billing_scheme": "per_unit",
//           "created": 1616672821,
//           "currency": "eur",
//           "interval": "month",
//           "interval_count": 1,
//           "livemode": false,
//           "metadata": {},
//           "nickname": null,
//           "product": "prod_JBE6K02DwN56qM",
//           "tiers": null,
//           "tiers_mode": null,
//           "transform_usage": null,
//           "trial_period_days": null,
//           "usage_type": "licensed"
//         },
//         "price": {
//           "id": "price_1IYreTCFxtTvZWVuwpEg0EIa",
//           "object": "price",
//           "active": true,
//           "billing_scheme": "per_unit",
//           "created": 1616672821,
//           "currency": "eur",
//           "livemode": false,
//           "lookup_key": null,
//           "metadata": {},
//           "nickname": null,
//           "product": "prod_JBE6K02DwN56qM",
//           "recurring": {
//             "aggregate_usage": null,
//             "interval": "month",
//             "interval_count": 1,
//             "trial_period_days": null,
//             "usage_type": "licensed"
//           },
//           "tiers_mode": null,
//           "transform_quantity": null,
//           "type": "recurring",
//           "unit_amount": 929,
//           "unit_amount_decimal": "929"
//         },
//         "proration": true,
//         "quantity": 1,
//         "subscription": "sub_JKiAG3EiFFM5WG",
//         "subscription_item": "si_JKiAy0zaNrH7qd",
//         "tax_amounts": [
//           {
//             "amount": 5,
//             "inclusive": false,
//             "tax_rate": "txr_1IVu4eCFxtTvZWVuOg6pxxuz"
//           }
//         ],
//         "tax_rates": [
//           {
//             "id": "txr_1IVu4eCFxtTvZWVuOg6pxxuz",
//             "object": "tax_rate",
//             "active": true,
//             "country": "DE",
//             "created": 1615967148,
//             "description": "MwSt. 7% exkl.",
//             "display_name": "Umsatzsteuer",
//             "inclusive": false,
//             "jurisdiction": null,
//             "livemode": false,
//             "metadata": {},
//             "percentage": 7,
//             "state": null
//           }
//         ],
//         "type": "invoiceitem"
//       }
//     ],
//     "has_more": false,
//     "total_count": 2,
//     "url": "/v1/invoices/upcoming/lines?customer=cus_JKi7N8HEk8oMuk&subscription=sub_JKiAG3EiFFM5WG&subscription_proration_date=1623913116"
//   },
</script>

<style lang="scss" scoped>
@import '@/assets/scss/bulma-variables.scss';

.button {
  color: #7a7a7a;
}
.table {
  background: transparent;
  th,
  td {
    vertical-align: middle;
  }
  td:last-child {
    text-align: right;
  }

  td.price-col,
  td.vat-col {
    white-space: nowrap;
  }

  tfoot td {
    color: $grey;
  }
  tfoot tr.total td {
    color: $text;
    font-weight: bold;
  }
}
</style>
