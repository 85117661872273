<template>
  <div class="sign-details-view" :class="{ unlicensed: isUnlicensed }">
    <!-- NAVBAR for unlicensed users -->
    <b-navbar class="app-nav is-mobile pl-2" :mobile-burger="false">
      <template slot="brand">
        <b-navbar-item tag="div">
          <nav class="breadcrumb" aria-label="breadcrumbs">
            <ul>
              <li><div>Gebärden</div></li>
              <li class="is-active">
                <a @click="scrollToId()" v-if="sign">{{ sign.name }}</a>
              </li>
            </ul>
          </nav>
        </b-navbar-item>
      </template>
      <template slot="start">
        <b-navbar-item @click="scrollToId('what-is-sign-digital')" v-if="isUnlicensed">
          Was ist SIGNdigital?
        </b-navbar-item>
        <b-navbar-item @click="scrollToId('how-much-does-it-cost')" v-if="isUnlicensed"> Preise </b-navbar-item>
      </template>
      <template slot="end">
        <LoginButtonTopRight />
        <template v-if="!isUnlicensed && sign">
          <b-navbar-item tag="div">
            <favorite-button
              :sign="sign"
              @add="$emit('favorite-added', sign)"
              @remove="$emit('favorite-removed', sign)"
              button-type="is-secondary"
              outlined
              :size="buttonSize"
            />
          </b-navbar-item>
          <b-navbar-item tag="div">
            <b-button
              type="is-secondary"
              outlined
              icon-left="plus"
              @click.stop="onAddToButtonClicked"
              :size="buttonSize"
              >Lernliste</b-button
            >
          </b-navbar-item>
          <b-navbar-item tag="div">
            <b-dropdown aria-role="list" custom position="is-bottom-left">
              <b-button
                type="is-secondary"
                outlined
                icon-left="share-alt"
                slot="trigger"
                slot-scope="{ active }"
                :size="buttonSize"
              >
                <span>Teilen</span>
                <b-icon :icon="active ? 'caret-up' : 'caret-down'"></b-icon>
              </b-button>
              <share-sign-link-menu :slug="this.$route.params.slug"></share-sign-link-menu>
            </b-dropdown>
          </b-navbar-item>
        </template>
      </template>
    </b-navbar>

    <!-- SIGN DETAILS -->
    <sign-details
      v-if="sign"
      :sign="sign"
      :unlicensedContent="isUnlicensed"
      :fullscreen="!isUnlicensed"
      :is-below-navbar="true"
      :show-title="false"
      :newTabButton="false"
      :shareButton="false"
      :video-controls="isUnlicensed ? ['playPause'] : true"
    ></sign-details>

    <!-- LOADING INDICATOR -->
    <b-loading :active="!sign" />

    <div class="content container px-5" v-if="sign && isUnlicensed">
      <b-notification type="is-secondary" icon="info-circle" has-icon :closable="false">
        <span>Hier siehst du die Gebärde für „{{ sign.name }}”. <br /></span>
        <span
          >Du {{ !isLoggedIn ? 'bist nicht angemeldet' : 'hast keine aktive Lizenz' }} und kannst daher nur ausgewählte
          Inhalte mit Wasserzeichen sehen.</span
        >
      </b-notification>
    </div>

    <!-- INFORMATION for unauthenticated users -->
    <SignDigitalInformation v-if="isUnlicensed" no-list-information />
  </div>
</template>

<script>
import SignDetails from '@/components/SignDetails.vue'
import LoginButtonTopRight from '@/components/LoginButtonTopRight.vue'
import SignDigitalInformation from '@/components/SignDigitalInformation.vue'
import AddToListModal from '@/components/AddToListModal'
import FavoriteButton from '@custom-media/signdigital-web-shared/src/components/FavoriteButton'
import scrollUtilsMixin from '@/mixins/scroll-utils-mixin'
import ShareSignLinkMenu from '@/components/ShareSignLinkMenu.vue'

export default {
  mixins: [scrollUtilsMixin],
  components: {
    SignDetails,
    LoginButtonTopRight,
    SignDigitalInformation,
    FavoriteButton,
    ShareSignLinkMenu
  },
  mounted () {
    this.fetchSign()
  },
  data () {
    return {
      sign: null
    }
  },
  computed: {
    isLoggedIn () {
      return !!this.$store.getters['auth/user']
    },
    hasActiveSubscription () {
      return this.$store.getters['auth/user']?.hasActiveSubscription
    },
    isUnlicensed () {
      return !this.isLoggedIn || !this.hasActiveSubscription
    },
    buttonSize () {
      return !this.$screen.tablet ? 'is-small' : 'is-default'
    }
  },
  methods: {
    async fetchSign () {
      const { Sign } = this.$FeathersVuex.api
      const { id = null, slug = null } = this.$route.params

      if (!id && !slug) {
        this.recoverFromInvalidParams()
        return
      }

      try {
        if (id) {
          // Get by id
          this.sign = await Sign.get(id)
        } else {
          // Find by slug
          const res = await Sign.find({ query: { slug } })
          if (res.data.length === 0) {
            this.$buefy.toast.open({
              message: 'Keine passende Gebärde gefunden',
              duration: 5000,
              type: 'is-warning'
            })
            this.recoverFromInvalidParams()
            return
          }
          this.sign = res.data[0]
        }
      } catch (error) {
        this.$buefy.dialog({
          message: 'Fehler beim Abrufen der Gebärde',
          duration: 5000,
          type: 'is-danger'
        })
        this.recoverFromInvalidParams()
      }
    },
    recoverFromInvalidParams () {
      if (this.isLoggedIn) {
        this.$router.replace({ name: 'search' })
      } else {
        this.$router.replace({ name: 'login' })
      }
    },
    onAddToButtonClicked () {
      this.$buefy.modal.open({
        component: AddToListModal,
        props: {
          signId: this.sign._id
        },
        parent: this,
        width: 450
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/bulma-variables.scss';
.list-editor {
  overflow: hidden;
  width: 100%;
}

.navbar {
  &::v-deep {
    .navbar-menu {
      flex-wrap: wrap;
    }

    .navbar-start {
      flex-wrap: wrap;
    }
  }

  .navbar-item {
    display: flex;
    align-items: center;
  }
}

.sign-details-view {
  background: #312f2f;

  .navbar {
    .button-group {
      padding-right: 1rem;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }

  &.unlicensed {
    background: none;

    .sign-details::v-deep {
      background-color: $grey-lighter;
      margin-bottom: 2rem;

      .sign-details-content {
        margin-bottom: 50px;
        @include from($desktop) {
          padding-left: 1.5em;
          padding-right: 1.5em;
        }
      }

      .video-controls .navbar.is-dark {
        background-color: transparent;
        .navbar-menu {
          box-shadow: none;
        }
      }
    }
  }
}
</style>
