import SearchDropdown from '@custom-media/signdigital-web-shared/src/components/SearchDropdown'

export default {
  install (Vue, options) {
    Vue.prototype.$pickImage = function (options) {
      return new Promise((resolve) => {
        this.isActionsTooltipActive = false
        const SearchDropdownCtrl = Vue.extend(SearchDropdown)
        const el = new SearchDropdownCtrl({
          propsData: {
            target: options.target ?? document.body,
            minX: options.minX ?? 260,
            minY: options.minY ?? 70,
            ...options.propsData
          },
          parent: options.parent ?? this
        })
        el.$once('selected', (sign) => {
          resolve(sign)
        })
        el.$once('closed', () => {
          el.$destroy()
          el.$el.parentNode.removeChild(el.$el)
          resolve(null)
        })
        el.$mount()
      })
    }
  }
}
