<template>
  <div class="arrow-list">
    <ul>
      <li class="p-2" v-for="arrow in arrows" :key="arrow.image" @click="$emit('selected', arrow)">
        <img :src="arrow.src" />
        <span>{{ arrow.name }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
import customSignArrows from '@custom-media/signdigital-lib/src/custom-sign-arrows'
import { publicPath } from '../../../vue.config.js'

export default {
  data () {
    return {
      arrows: customSignArrows
    }
  },
  mounted () {
    // TODO: Find correct way to automatically copy assets in public folder
    // const arrowContext = require.context('@custom-media/signdigital-lib/assets/arrows', true, /^\.svg$/)
    // console.log(arrowContext('03-kurze-bewegung-mit-abrupten-halt.svg'))
    this.arrows = this.arrows.map((a) => ({
      ...a,
      src: `${publicPath}img/custom-signs/arrows/${a.image}.svg`
    }))
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/bulma-variables.scss';
.arrow-list {
  background: $light;
  padding: 0.5em;
  border-radius: $radius-small;
  $border: 1px solid $grey-light;

  ul {
    display: grid;
    grid-template-columns: 50% 50%;
    column-gap: 1em;
    row-gap: 1em;

    li {
      cursor: pointer;
      border-radius: $radius-large;
      img {
        pointer-events: none;
      }

      &:hover {
        background: $primary;
      }
      span {
        display: block;
        cursor: pointer;
        font-size: 0.75em;
        line-height: 1.5em;
      }
    }
  }
}
</style>
