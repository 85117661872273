<template>
  <b-breadcrumb :size="size">
    <b-breadcrumb-item
      v-for="item of crumbs"
      :key="item._id"
      @click="onItemSelected(item)"
      :active="browserCtrl.currentItem._id === item._id"
      :tag="interactive ? 'a' : 'span'"
      >{{ item.fileNode.name }}</b-breadcrumb-item
    >
  </b-breadcrumb>
</template>

<script>
export default {
  props: {
    browserCtrl: Object,
    includeLast: {
      type: Boolean,
      default: false
    },
    interactive: {
      type: Boolean,
      default: true
    },
    size: {
      type: String,
      default: 'is-small'
    }
  },
  computed: {
    crumbs () {
      if (!this.browserCtrl.currentItem) {
        return []
      }
      const items = this.browserCtrl.currentRoot?.getItemsInPath?.(this.browserCtrl.currentItem.fileNode.path) ?? []
      if (this.includeLast && items.includes(this.browserCtrl.currentItem) === false) {
        items.push(this.browserCtrl.currentItem)
      }
      return items
    }
  },
  methods: {
    onItemSelected (node) {
      this.$emit('item-selected', node)
    }
  }
}
</script>

<style lang="scss" scoped>
nav.breadcrumb::v-deep {
  margin-bottom: 0;
  ul > li > span {
    padding-left: 10px;
    padding-right: 10px;
  }
}
</style>
