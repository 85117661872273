<template>
  <div class="license-selection">
    <p class="has-text-weight-bold">Wähle einfach aus, wie viele Lizenzen du benötigst:</p>
    <LicenseSeatsSlider v-model="licenseIndex" :license-groups="licenseGroups" />

    <div v-if="selectedGroup" class="licenses">
      <!-- License variants -->
      <div class="columns">
        <div v-for="license in selectedGroup.licenses" :key="license.id" class="column is-half">
          <LicenseCard
            :license="license"
            :vat="vat"
            :type="license.default ? 'is-primary' : 'is-secondary'"
            :submitable="false"
          />
        </div>
      </div>

      <b-field
        label="Falls du nicht in Deutschland wohnst: Wähle bitte dein Land aus, um den Preis mit deinem korrekten Mehrwertsteuersatz anzuzeigen"
      >
        <country-select v-model="country" />
      </b-field>

      <p>
        * Bezahlung auf Rechnung ist nicht für alle Pakete verfügbar und Einrichtungen und Organisationen vorbehalten,
        die nicht auf Lastschrift oder per Kreditkarte zahlen können.
      </p>
    </div>
    <b-message v-else-if="isCustomLicense" type="is-primary">
      Individuelle Lizenzen (größere Pakete, längere Laufzeiten) können auch ausgehandelt werden. Dazu gibt es im
      Bestellvorgang ein Formular.
    </b-message>
  </div>
</template>

<script>
import * as licenseGroups from '@custom-media/signdigital-lib/src/constants/license-definitions'
import * as countryDefinitions from '@custom-media/signdigital-lib/src/constants/country-definitions'
import LicenseSeatsSlider from '@custom-media/signdigital-web-shared/src/components/LicenseSeatsSlider.vue'
import LicenseCard from '@custom-media/signdigital-web-shared/src/components/LicenseCard.vue'
import CountrySelect from '@custom-media/signdigital-web-shared/src/components/CountrySelect.vue'
export default {
  components: {
    LicenseSeatsSlider,
    LicenseCard,
    CountrySelect
  },
  data () {
    return {
      country: 'DE',
      licenseGroups,
      licenseIndex: null
    }
  },
  computed: {
    vat () {
      return countryDefinitions[this.country].vat
    },
    isCustomLicense () {
      return this.licenseIndex === licenseGroups.length
    },
    selectedGroup () {
      if (this.isCustomLicense) {
        return null
      }
      return licenseGroups[this.licenseIndex]
    }
  }
}
</script>
