<template>
  <div class="add-to-list-modal">
    <nav class="panel is-primary">
      <p class="panel-heading">Zu Lernliste hinzufügen:</p>

      <a
        v-for="list in lists"
        :key="list._id"
        class="panel-block"
        :class="{ 'is-disabled': isListDisabled(list) }"
        @click="onAddToList(list)"
      >
        <span>{{ list.name }}</span>
        <span class="tag" v-if="isListDisabled(list)">Bereits in Lernliste vorhanden</span>
      </a>

      <div class="panel-block" v-if="isFindListsPending">
        <b-loading active :is-full-page="false"></b-loading>
      </div>
      <div class="panel-block">
        <b-pagination
          :total="total"
          :current="currentPage"
          @change="onPageChange"
          size="is-small"
          :per-page="limit"
          aria-next-label="Nächste Seite"
          aria-previous-label="Vorherige Seite"
          aria-page-label="Seite"
          aria-current-label="Aktuelle Seite"
        >
        </b-pagination>
      </div>
    </nav>
  </div>
  <!-- TODO: Mini lists browser -->
</template>

<script>
import { makeFindMixin } from 'feathers-vuex'
export default {
  mixins: [makeFindMixin({ service: 'lists', qid: 'listLists', watch: true })],
  props: {
    signId: String
  },
  data () {
    return {
      limit: 10,
      skip: 0,
      sortField: 'updatedAt',
      sortOrder: 'desc'
    }
  },

  computed: {
    listsParams () {
      return {
        query: {
          $sort: { [this.sortField]: this.sortOrder === 'asc' ? 1 : -1 },
          owner: this.$store.getters['auth/user']._id,
          $limit: this.limit,
          $skip: this.skip
        },
        debounce: 50
      }
    },
    listTemplatesParams () {
      return { query: { isPublic: true, $sort: { createdAt: -1 } } }
    },
    total () {
      return this.listsPaginationData?.listLists?.mostRecent?.total ?? 0
    },
    currentPage: {
      get () {
        return Math.floor(this.skip / this.limit) + 1
      },
      set (newPage) {
        this.skip = (newPage - 1) * this.limit
      }
    }
  },
  methods: {
    onPageChange (page) {
      this.skip = (page - 1) * this.limit
    },
    isListDisabled (list) {
      return list.signs.some((sign) => sign._id === this.signId)
    },
    async onAddToList (list) {
      if (!this.isListDisabled(list)) {
        list.signs.push(this.signId)
        list.save()
        this.$buefy.toast.open({
          type: 'is-success',
          message: `Zu „${list.name}” hinzugefügt`
        })
      }
      this.$parent.close()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/bulma-variables.scss';
.panel-block {
  background: $light;
}
a.panel-block {
  display: flex;
  justify-content: space-between;
  .tag {
    background: $grey;
    color: white;
    font-size: 0.6rem;
  }
  &:hover {
    background: $primary-dark;
    color: white;
  }
  &.is-disabled,
  &.is-disabled {
    background: $grey-lightest;
    color: $grey;
    cursor: not-allowed;

    &:hover {
      background: $grey-lighter;
      color: $grey;
    }
  }
}
</style>
