import feathersClient, { makeAuthPlugin } from '../feathers-rest-client'
import * as Sentry from '@sentry/vue'

feathersClient.service('authentication').hooks({
  after: {
    create: [
      (context) => {
        const user = context?.result?.user
        if (user != null) {
          Sentry.setUser({
            id: user._id
          })
        } else {
          Sentry.setUser(null)
        }
      }
    ]
  }
})

export default makeAuthPlugin({ userService: 'users' })
