<template>
  <div class="team-invitations-view">
    <div class="container p-5">
      <h1 class="title">Einladungen</h1>

      <div class="card">
        <div class="card-header">
          <div class="card-header-title">Offene Einladungen</div>
        </div>
        <div class="card-content">
          <b-table :data="openInvitations">
            <b-table-column label="E-Mail" v-slot="{ row: invitation }">{{ invitation.email }}</b-table-column>
            <b-table-column label="Aktionen" v-slot="{ row: invitation }">
              <b-button size="is-small" type="is-danger" icon-left="times" @click="onCancelButtonClicked(invitation)"
                >Stornieren</b-button
              >
            </b-table-column>
            <template #empty>
              <div class="has-text-centered">
                <p>Momentan keine offenen Einladungen</p>
                <b-button class="mt-4" tag="router-link" :to="{ name: 'team-invite' }"
                  >Jetzt Teammitglieder einladen</b-button
                >
              </div>
            </template>
          </b-table>
        </div>
      </div>

      <div class="card mt-4">
        <div class="card-header">
          <div class="card-header-title">Alle Einladungen</div>
        </div>
        <div class="card-content">
          <b-table :data="invitations">
            <b-table-column label="E-Mail" v-slot="{ row: invitation }">{{ invitation.email }}</b-table-column>
            <b-table-column label="Status" v-slot="{ row: invitation }">
              <!-- Consumed -->
              <div class="tags has-addons" v-if="invitation.isConsumed">
                <span class="tag is-primary">Eingelöst</span>
                <span class="tag is-light">{{ invitation.consumedAt | dateTimeAt }}</span>
              </div>
              <!-- Invalidated -->
              <div class="tags has-addons" v-else-if="invitation.isInvalidated">
                <span class="tag is-danger">Storniert</span>
                <span class="tag is-light">{{ invitation.invalidatedAt | dateTimeAt }}</span>
              </div>
              <!-- Expired -->
              <div class="tags has-addons" v-else-if="invitation.isExpired">
                <span class="tag is-dark">Abgelaufen</span>
                <span class="tag is-light">{{ invitation.expiresAt | dateTimeAt }}</span>
              </div>
              <!-- Open -->
              <div class="tags has-addons" v-else>
                <span class="tag is-success">Offen</span>
                <span class="tag is-light">bis zum {{ invitation.expiresAt | dateTimeAt }}</span>
              </div>
            </b-table-column>
            <template #empty>
              <div class="has-text-centered">
                <p>Noch keine Einladungen erstellt</p>
              </div>
            </template>
          </b-table>
          <b-pagination
            class="mt-2"
            :total="totalInvitations"
            v-model="currentInvitationsPage"
            :per-page="invitationsLimit"
            aria-next-label="Nächste Seite"
            aria-previous-label="Vorherige Seite"
            aria-page-label="Seite"
            aria-current-label="Aktuelle Seite"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { makeFindMixin } from 'feathers-vuex'
import { mapState } from 'vuex'
export default {
  mixins: [
    makeFindMixin({
      service: 'invitations',
      name: 'invitations',
      items: 'invitations',
      params: 'invitationsParams',
      qid: 'listInvitations',
      watch: true
    }),
    makeFindMixin({
      service: 'invitations',
      name: 'openInvitations',
      items: 'openInvitations',
      params: 'openInvitationsParams',
      qid: 'listOpenInvitations',
      watch: true
    })
  ],
  data () {
    return {
      skippedInvitations: 0,
      invitationsLimit: 20
    }
  },
  computed: {
    ...mapState('session', ['team']),
    invitationsParams () {
      return {
        query: {
          team: this.team._id,
          $limit: this.invitationsLimit,
          $skip: this.skippedInvitations
        },
        $sort: {
          createdAt: 1
        }
      }
    },
    totalInvitations () {
      return this.invitationsPaginationData?.listInvitations?.mostRecent?.total ?? 0
    },
    currentInvitationsPage: {
      get () {
        return Math.floor(this.skippedInvitations / this.invitationsLimit) + 1
      },
      set (newPage) {
        this.skippedInvitations = (newPage - 1) * this.invitationsLimit
      }
    },
    openInvitationsParams () {
      return {
        query: {
          team: this.team._id,
          invalidatedAt: null,
          consumedAt: null,
          expiresAt: {
            $gt: new Date()
          }
        }
      }
    }
  },
  methods: {
    async onCancelButtonClicked (invitation) {
      invitation.invalidatedAt = new Date()
      await invitation.save()
      await this.$store.dispatch('teamSettings/fetch')
    }
  }
}
</script>
