<template>
  <b-dropdown expanded v-model="style" aria-role="list" :triggers="['click']">
    <template #trigger>
      <button class="button is-small is-fullwidth">
        <img width="30" height="30" :src="getStyleThumbnail(style)" />
        <span class="ml-4">{{ selectedStyle.title }}</span>
      </button>
    </template>

    <b-dropdown-item :value="style.key" aria-role="listitem" v-for="style of styles" :key="style.key">
      <div class="media">
        <img class="media-left" width="30" height="30" :src="getStyleThumbnail(style.key)" />
        <div class="media-content">
          <span>{{ style.title }}</span>
        </div>
      </div>
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { SIGN_BOX_STYLES } from '@/lib/constants/sign-editor-constants'
export default {
  data () {
    return {
      styles: Object.values(SIGN_BOX_STYLES).sort((a, b) => a.title.localeCompare(b.title))
    }
  },
  computed: {
    ...mapState('signEditor', ['signTypeProperties', 'signType']),
    ...mapGetters('signEditor', ['signTypeProperties']),
    selectedStyle () {
      return SIGN_BOX_STYLES[this.style]
    },
    style: {
      get () {
        return this.signTypeProperties?.style
      },
      set (value) {
        this.$store.commit('signEditor/setSignTypeProperty', { key: 'style', value })
        this.$store.dispatch('signEditor/applySignBoxStyle')
      }
    }
  },
  methods: {
    getStyleThumbnail (key) {
      const images = require.context('@/assets/img/custom-signs/sign-box-line-styles/')
      return images('./' + key + '.svg')
    }
  }
}
</script>
