<template>
  <div
    class="grid-element"
    :class="{ 'is-placeholder': isPlaceholder, 'is-dragged-over': isDraggedOver }"
    :style="frameStyle"
    draggable="true"
    @dragstart="onDragStart"
    @dragend="onDragEnd"
    @dragover="onDragOver"
    @drop="onDrop"
    @dragenter="onDragEnter"
    @dragleave="onDragLeave"
  >
    <document-image
      :responsive="false"
      :image-reference="element != null ? element.image : null"
      @update:image-reference="onImageChanged"
      :owner="owner"
      :ownerType="ownerType"
    />

    <!-- Drag handle -->
    <b-button
      v-if="!isPlaceholder"
      @click="onDragHandleClicked"
      type="is-light"
      class="drag-handle"
      size="is-small"
      icon-left="arrows"
    />
  </div>
</template>

<script>
import DocumentImage from '@/components/document/DocumentImage'
import { frameStyleMixin } from '@/mixins/document-element-mixin'
import { GridElement } from '@custom-media/signdigital-lib/src/documents/grid-document'
import { mapState } from 'vuex'

export default {
  components: {
    DocumentImage
  },
  mixins: [frameStyleMixin],
  props: {
    isPlaceholder: Boolean,
    frame: Object,
    elementId: {
      type: String,
      default: null
    },
    gridIndex: Object
  },
  data () {
    return {
      isDraggedOver: false,
      isDragging: false
    }
  },
  computed: {
    ...mapState('documentEditor', ['owner', 'ownerType']),
    element () {
      if (this.elementId == null) {
        return null
      }
      return this.$store.getters['documentEditor/getElement'](this.elementId)
    }
  },
  methods: {
    onImageChanged (newImage) {
      if (newImage == null) {
        // Did remove image, remove element too
        this.$emit('delete', this.element)
      } else {
        if (this.elementId == null) {
          // Selected new element
          this.$emit('create', new GridElement({ gridIndex: this.gridIndex, image: newImage }))
        } else {
          // Updated image
          this.$emit('update', this.element, { image: newImage })
        }
      }
    },
    onDragStart (event) {
      event.dataTransfer.effectAllowed = 'move'
      event.dataTransfer.setData('application/signdigital.grid-element', this.elementId)
      this.isDragging = true
    },
    onDragEnter (event) {
      if (this.isDragging) {
        return
      }
      const isGridElement = event.dataTransfer.types.includes('application/signdigital.grid-element')
      if (isGridElement) {
        event.preventDefault()
        event.dataTransfer.dropEffect = 'move'
      }
    },
    onDragOver (event) {
      if (this.isDragging) {
        return
      }
      const isGridElement = event.dataTransfer.types.includes('application/signdigital.grid-element')
      if (isGridElement) {
        event.preventDefault()
        event.dataTransfer.dropEffect = 'move'
        this.isDraggedOver = true
      } else {
        this.isDraggedOver = false
      }
    },
    onDragLeave (event) {
      this.isDraggedOver = false
    },
    onDrop (event) {
      this.$emit('replace', event.dataTransfer.getData('application/signdigital.grid-element'))
      this.isDraggedOver = false
    },
    onDragEnd (event) {
      this.isDragging = false
    },
    onDragHandleClicked () {
      this.$buefy.toast.open({
        type: 'is-info',
        message:
          'Durch Klicken & Ziehen am <i class="far fa-arrows"></i>-Anfasser können die Elemente verschoben werden',
        duration: 3500
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/bulma-variables.scss';

.grid-element {
  .drag-handle {
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
    opacity: 0;
    transition: opacity 0.15s linear;
    cursor: move;
    border: 0;
    color: #133234;
    // background: transparent;
    height: 1.75em;
  }

  &:hover .drag-handle {
    opacity: 1;
  }

  .document-image {
    width: 100%;
    height: 100%;
  }
  .document-image::v-deep .button.add-button {
    border: 0;
  }
  &.is-placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    box-sizing: border-box;
    border: 0.5px solid $grey-lightest;
    color: white;
    cursor: pointer;
    .icon {
      text-align: center;
      display: none;
    }
    &:hover {
      background-color: $primary;
      .icon {
        display: initial;
      }
    }
  }

  &.is-dragged-over {
    background: $primary;
  }
}
</style>
