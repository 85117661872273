<template>
  <div class="general-actions p-3">
    <b-field label="Abgeschnittene Bereiche">
      <b-radio-button v-model="localIsClippingMaskVisible" size="is-small" native-value="true" type="is-primary">
        <b-icon icon="eye-slash"></b-icon>
        <span>Ausblenden</span>
      </b-radio-button>

      <b-radio-button v-model="localIsClippingMaskVisible" size="is-small" native-value="false" type="is-primary">
        <b-icon icon="eye"></b-icon>
        <span>Anzeigen</span>
      </b-radio-button>
    </b-field>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState('signEditor', ['isClippingMaskVisible']),
    localIsClippingMaskVisible: {
      get () {
        return this.$store.state.signEditor.isClippingMaskVisible ? 'true' : 'false'
      },
      set (newValue) {
        this.$store.commit('signEditor/setClippingMaskVisibility', newValue === 'true')
      }
    }
  },
  methods: {
    onToggleClippingButtonClicked () {}
  }
}
</script>

<style lang="scss" scoped>
::v-deep .label {
  font-size: 0.8rem;
}
</style>
