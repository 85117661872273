<template>
  <div class="search-settings-dropdown">
    <b-dropdown-item aria-role="menu-item" :focusable="false" custom>
      <!-- <image-size-switch :value="search.imageType" @input="onImageTypeChanged" /> -->

      <!-- Sign only -->

      <b-field label="Größe Vorschaubild"> </b-field>
      <b-field>
        <b-radio
          native-value="small"
          title="Nur Gebärde"
          :value="listEditorPreferences.imageSize"
          @input="onImageSizeChanged"
        >
          <div style="display: flex; align-items: center">
            <span style="display: flex; justify-content: center; align-items: center; width: 3rem">
              <img :src="signOnlyIcon" style="padding-right: 0.75rem; max-width: 2rem; max-height: 2rem" />
            </span>
            Klein
          </div>
        </b-radio>
      </b-field>

      <b-field>
        <b-radio
          native-value="medium"
          title="Nur Gebärde"
          :value="listEditorPreferences.imageSize"
          @input="onImageSizeChanged"
        >
          <div style="display: flex; align-items: center">
            <span style="display: flex; justify-content: center; align-items: center; width: 3rem">
              <img :src="signOnlyIcon" style="padding-right: 0.75rem; max-width: 3rem; max-height: 3rem" />
            </span>
            Mittel
          </div>
        </b-radio>
      </b-field>

      <b-field>
        <b-radio
          native-value="large"
          title="Nur Gebärde"
          :value="listEditorPreferences.imageSize"
          @input="onImageSizeChanged"
        >
          <div style="display: flex; align-items: center">
            <span style="display: flex; justify-content: center; align-items: center; width: 3rem">
              <img :src="signOnlyIcon" style="padding-right: 0.75rem; max-width: 4rem; max-height: 4rem" />
            </span>
            Groß
          </div>
        </b-radio>
      </b-field>
    </b-dropdown-item>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import signOnlyIcon from '@custom-media/signdigital-web-shared/src/assets/img/image-types/sign-only.svg'
export default {
  data () {
    return {
      signOnlyIcon
    }
  },
  components: {},
  computed: {
    ...mapState('preferences', { listEditorPreferences: 'listEditor' }),
    columnMessage () {
      const limit = this.columnLimit
      if (this.search.columns <= limit) {
        return null
      }
      return `Aufgrund von Platzmangel wurde die Anzahl der Spalten auf ${limit} beschränkt`
    },
    columnLimit () {
      if (this.$screen.widescreen) {
        return 10
      } else if (this.$screen.desktop) {
        return 7
      } else if (this.$screen.tablet) {
        return 5
      } else if (this.$screen.mobile) {
        return 2
      }
      return 10
    }
  },
  mounted () {},
  methods: {
    ...mapActions('preferences', ['updateListEditorPreferences']),

    onImageSizeChanged (imageSize) {
      if (this.listEditorPreferences.imageSize === imageSize) {
        return
      }
      this.updateListEditorPreferences({ imageSize })
    }
  }
}
</script>

<style lang="scss" scoped></style>
