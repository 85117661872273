<template>
  <div class="templates-view">
    <!-- NAVBAR -->
    <b-navbar class="app-nav is-mobile" :mobile-burger="false" :active="true">
      <template slot="brand">
        <b-navbar-item tag="div">
          <nav class="breadcrumb" aria-label="breadcrumbs">
            <ul>
              <li class="is-active"><a href="#">Druckvorlagen</a></li>
            </ul>
          </nav>
        </b-navbar-item>
      </template>
      <template slot="start"> </template>
      <template slot="end">
        <!-- <b-navbar-item tag="div">
          <b-button @click="onAddButtonClicked" icon-left="plus" type="is-primary">Neue Druckvorlage</b-button>
        </b-navbar-item> -->
      </template>
    </b-navbar>

    <!-- Create new document prompt -->
    <section class="create-new-document">
      <div class="container px-6 pt-3 pb-6">
        <br />
        <h2 class="subtitle">Neue Druckvorlage erstellen</h2>
        <document-type-picker @selected="onCreateDocument($event)"></document-type-picker>
      </div>
    </section>

    <!-- Recently used documents -->
    <section class="recent-documents">
      <div class="container px-6 py-6">
        <div class="level">
          <div class="level-left"><h2 class="subtitle">Zuletzt verwendet</h2></div>
          <div class="level-right">
            <b-button
              tag="router-link"
              :to="{ name: 'file-browser', query: { t: 'documents' } }"
              size=""
              type="is-ghost"
              icon-left="folder-tree"
              >Alle Druckvorlagen anzeigen</b-button
            >
          </div>
        </div>

        <hint hint-key="file-browser-view-documents">
          <p>
            Deine Druckvorlagen sind ab jetzt im Bereich „Organisieren” gespeichert. Hier siehst du nur deine zuletzt
            verwendeten Druckvorlagen.
          </p>
          <p>
            Klicke auf
            <router-link
              :to="{ name: 'file-browser', query: { t: 'documents' } }"
              size=""
              type="is-text"
              icon-left="folder-tree"
              >„Alle Druckvorlagen anzeigen”</router-link
            >
            um die neue Organisieren-Funktion und die Verwendung von Ordnern auszuprobieren.
          </p>
        </hint>

        <div class="document-list columns is-multiline" v-if="recentlyUsedDocuments.length > 0">
          <div
            class="column is-one-quarter-fullhd is-one-quarter-desktop is-half-tablet"
            v-for="document in recentlyUsedDocuments"
            :key="document._id"
          >
            <document-card
              :document="document"
              @click="onDocumentClicked(document)"
              @action="onDocumentAction(document, $event)"
            />
          </div>
        </div>
        <div v-else class="content has-text-centered">
          <p>Du hast noch keine Druckvorlagen angelegt.</p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import DocumentCard from '@/components/DocumentCard'
import DocumentTypePicker from '@/components/document/DocumentTypePicker'
import { mapState } from 'vuex'
import { makeFindMixin } from 'feathers-vuex/dist'
import Hint from '@/components/Hint'

export default {
  components: { DocumentCard, DocumentTypePicker, Hint },
  mixins: [
    makeFindMixin({
      service: 'documents'
    })
  ],
  data () {
    return {}
  },
  computed: {
    ...mapState('auth', ['user']),
    documentsParams () {
      return this.$store.getters['preferences/recentlyUsedQuery']('documents', 8)
    },
    recentlyUsedDocuments () {
      if (!this.$store.getters['preferences/isActualRecentlyUsedQuery']('documents')) {
        return this.documents
      }
      const ids = this.$store.state.preferences.recentlyUsed.documents
      if (ids.length === 1) {
        return this.documents
      }

      const sorted = ids
        .map((i) => this.documents.find((d) => d?._id === i))
        .reverse()
        .filter((f) => !!f)
      return sorted
    }
  },
  mounted () {},
  methods: {
    onDocumentClicked (document) {
      this.openDocument(document)
    },
    openDocument (document) {
      this.$store.commit('session/clearExitRoute')
      this.$router.push({ name: 'document-editor', params: { id: document._id } })
    },
    async onCreateDocument (type) {
      const { file } = await this.$fileDialog.create({
        nodeType: 'file',
        fileType: 'documents',
        documentType: type,
        pick: ['filename', 'location'],
        parent: this
      })

      this.$store.commit('session/clearExitRoute')
      this.$router.push({ name: 'document-editor', params: { id: file._id } })
      // this.$router.push({ name: 'document-editor' })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/bulma-variables.scss';
.create-cards {
  display: flex;
  .column {
    display: flex;
  }
}

section.intro .container.p-6 {
  padding-bottom: 0;
}

section.create-new-document {
  background: $grey-lightest;
}

.document-list .column {
  display: flex;
  .document-card {
    width: 100%;
  }
}
</style>
