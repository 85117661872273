<template>
  <div class="own-sign-element" :style="sizeStyle">
    <div class="text-wrapper">
      <document-text-field
        class="name"
        :frame="nameFieldFrame"
        :value="element.name"
        :font-style="nameFontStyle"
        placeholder="Name der Gebärde"
        @update:value="onNameChanged"
      />
      <document-text-area
        class="description"
        :frame="descriptionFieldFrame"
        :value="element.description"
        :font-style="descriptionFontStyle"
        placeholder="Beschreibung der Gebärde"
        @update:value="onDescriptionChanged"
      />
    </div>
    <div class="own-sign-wrapper">
      <document-image
        :responsive="false"
        :frame="imageFrame"
        :image-reference="element.firstColumnImage"
        @update:image-reference="onFirstColumnImageChanged"
        :owner="owner"
        :ownerType="ownerType"
      />
    </div>
    <div class="sign-wrapper">
      <document-image
        :responsive="false"
        :frame="imageFrame"
        :image-reference="element.secondColumnImage"
        @update:image-reference="onSecondColumnImageChanged"
        :owner="owner"
        :ownerType="ownerType"
      />
    </div>
    <b-button class="drag-handle" size="is-small" icon-left="arrows-v" />
  </div>
</template>

<script>
import DocumentImage from '@/components/document/DocumentImage'
import DocumentTextArea from '@/components/document/DocumentTextArea'
import DocumentTextField from '@/components/document/DocumentTextField'
import { frameStyleMixin } from '@/mixins/document-element-mixin'
import { FontStyle } from '@custom-media/signdigital-lib/src/documents/font-style'
import { mapState } from 'vuex'
export default {
  mixins: [frameStyleMixin],

  props: {
    elementId: String,
    frame: Object
  },
  data () {
    return {
      nameFieldFrame: { x: 0, y: 0, height: 50, width: 245 },
      descriptionFieldFrame: { x: 0, y: 58, height: 216, width: 245 },
      imageFrame: { x: 8, y: 0, width: 205, height: 274 },
      descriptionFontStyle: new FontStyle(20, '500'),
      nameFontStyle: new FontStyle(20, 'bold')
    }
  },
  computed: {
    ...mapState('documentEditor', ['owner', 'ownerType']),
    element () {
      return this.$store.getters['documentEditor/getElement'](this.elementId)
    }
  },
  components: {
    DocumentImage,
    DocumentTextArea,
    DocumentTextField
  },
  methods: {
    onNameChanged (newName) {
      this.$store.commit('documentEditor/patchElement', { id: this.element.id, data: { name: newName } })
    },
    onDescriptionChanged (newDescription) {
      this.$store.commit('documentEditor/patchElement', { id: this.element.id, data: { description: newDescription } })
    },
    onFirstColumnImageChanged (newFirstColumnImage) {
      const data = { firstColumnImage: newFirstColumnImage }
      this.$store.commit('documentEditor/patchElement', { id: this.element.id, data })
    },
    onSecondColumnImageChanged (newSecondColumnImage) {
      const data = { secondColumnImage: newSecondColumnImage }
      this.$store.commit('documentEditor/patchElement', { id: this.element.id, data })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/bulma-variables.scss';
.own-sign-element {
  position: relative;
  clear: both;
  padding: 16px 8px;

  .drag-handle {
    position: absolute;
    right: -30px;
    top: 50%;
    opacity: 0;
    transition: opacity 0.15s linear;
    cursor: ns-resize;
    border: 0;
    color: $primary;
    background: transparent;

    &:hover,
    &:active {
      background: $primary;
      color: white;
    }
  }

  &:hover .drag-handle {
    opacity: 1;
  }

  .text-wrapper,
  .own-sign-wrapper,
  .sign-wrapper {
    position: relative;
    float: left;
    height: 306px;
  }
  .text-wrapper {
    width: 261px;
    padding: 0 8px;
  }
  .own-sign-wrapper,
  .sign-wrapper {
    width: 221px;
    padding: 0 8px;
  }

  .document-image {
    width: 205px;
    height: 274px;
    flex-shrink: 0;
  }

  #debug {
    position: absolute;
    bottom: 0;
  }
}
</style>
