// src/store/index.js
import Vue from 'vue'
import Vuex from 'vuex'
import auth from './store.auth'
import teamSettingsModule from './store.team-settings'
import teamInvitationModule from './store.team-invitation'
import sessionModule from './store.session'
import listPlayerModule from './store.list-player'
import checkoutModule from './checkout/checkout'
import signEditorModule from './sign-editor/sign-editor'
import fileNodeTreeModule from './store.file-node-tree'
import documentEditorModule from './document-editor/document-editor'
import makePreferencesModule from '@custom-media/signdigital-web-shared/src/store/preferences/preferences'
import cargo from '@custom-media/signdigital-web-shared/src/store/cargo/cargo'
import feathersClient, { makeServicePlugin, BaseModel, FeathersVuex } from '../feathers-rest-client'

Vue.use(Vuex)
Vue.use(FeathersVuex)

const plugins = [
  'categories',
  'custom-signs',
  'custom-subscription-requests',
  'customers',
  'documents',
  'files',
  'file-nodes',
  'images',
  'invitations',
  'lists',
  'list-templates',
  'sign-requests',
  'signs',
  'subscriptions',
  'teams',
  'users'
]

const feathersVuexPlugins = plugins
  .map((name) => require(`@custom-media/signdigital-web-shared/src/store/services/${name}.js`).default)
  .map((initServicePlugin) => initServicePlugin(makeServicePlugin, feathersClient, BaseModel))

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    cargo,
    checkout: checkoutModule,
    signEditor: signEditorModule,
    documentEditor: documentEditorModule,
    teamSettings: teamSettingsModule,
    teamInvitation: teamInvitationModule,
    session: sessionModule,
    listPlayer: listPlayerModule,
    fileNodeTree: fileNodeTreeModule,
    preferences: makePreferencesModule(feathersClient)
  },
  plugins: [...feathersVuexPlugins, auth]
})
