<template>
  <div class="divider" :class="type">
    <span>{{ label }}</span>
    <b-button
      v-if="actionVisible && (actionIcon || actionText)"
      :icon-left="actionIcon"
      size="is-small"
      type="is-light"
      :title="actionTitle"
      @click="$emit('action')"
    >
      <span v-if="actionText">{{ actionText }}</span>
    </b-button>
  </div>
</template>
<script>
export default {
  props: {
    label: String,
    type: {
      type: String,
      default: 'is-center'
    },
    actionIcon: String,
    actionTitle: String,
    actionText: String,
    actionVisible: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="scss" scoped>
.divider {
  position: relative;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #7a7a7a;
  font-size: 0.75rem;
  font-weight: 600;
  letter-spacing: 0.5px;
  margin: 25px 0;

  &:not(.is-left):before {
    margin-right: 10px;
    content: '';
    display: block;
    flex: 1;
    height: 1px;
    background-color: #dbdbdb;
  }

  &:not(.is-right):after {
    margin-left: 10px;
    content: '';
    display: block;
    flex: 1;
    height: 1px;
    background-color: #dbdbdb;
  }

  .button {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    color: #7a7a7a;
  }
}
</style>
