<template>
  <div class="document-editor" :style="windowHeightCSS">
    <!-- Toolbar -->
    <b-navbar class="app-nav is-mobile" :active="true" :mobile-burger="false">
      <template slot="brand">
        <b-navbar-item tag="div">
          <nav class="breadcrumb" aria-label="breadcrumbs">
            <ul>
              <li><router-link :to="{ name: 'documents' }">Druckvorlagen</router-link></li>
              <li>
                <editable-button :value="name" @input="onDocumentNameChanged" icon-only></editable-button>
              </li>
            </ul>
          </nav>
        </b-navbar-item>
      </template>
      <template slot="end">
        <b-navbar-item tag="div">
          <b-button type="is-primary" @click="onPrintButtonClicked">PDF / Drucken</b-button>
        </b-navbar-item>
        <b-navbar-item tag="router-link" :to="exitRoute">
          <b-button type="is-light">Schließen</b-button>
        </b-navbar-item>
      </template>
    </b-navbar>
    <!-- Subnav -->
    <b-navbar class="is-autoheight is-small is-mobile" :active="true" :mobile-burger="false">
      <template slot="end">
        <b-navbar-item tag="div">
          <zoom-control
            v-if="documentHasPages"
            :zoom="zoom"
            @update:zoom="onZoomChanged"
            @fit="onZoomToFit"
          ></zoom-control>
        </b-navbar-item>
        <b-navbar-item tag="div">
          <saving-indicator :saving="isSavePending" :saved-at="updatedAt" :has-changes="hasUnsavedChanges" />
        </b-navbar-item>
      </template>
    </b-navbar>

    <!-- Leave delay indicator -->
    <div class="px-5 py-4 has-background-success animate__animated animate__flipInX" v-if="isSavingBeforeLeave">
      <b-field label="Dokument wird noch gespeichert..">
        <b-progress size="is-small" type="is-dark" />
      </b-field>
    </div>

    <b-message
      v-if="isDuplicateHintShown"
      type="is-primary"
      class="mx-6 my-3"
      title="Datei aus Team-Ordner"
      @close="closedDuplicateHint = true"
    >
      <p>
        Du betrachtest gerade eine Druckvorlage, die von einem anderen Team-Mitglied erstellt wurde. Du kannst sie zwar
        bearbeiten, veränderst dadurch aber die Originaldatei im Team-Ordner.
      </p>
      <p>Wir empfehlen, eine Kopie in deinem persönliche Ordner zu speichern und die Kopie zu bearbeiten.</p>
      <b-button @click="onCreateCopy" expanded class="mt-5" type="is-primary">Kopie erstellen</b-button>
    </b-message>

    <!-- Document Content -->
    <div class="document-content-container" ref="contentContainer">
      <document-content-navigator :zoom="zoom" :size="navigatorSize" ref="navigator"></document-content-navigator>
    </div>
  </div>
</template>

<script>
import { globalEventBus, globalEvents } from '@/lib/events/global-event-bus'
import { mapState, mapGetters } from 'vuex'
import DocumentContentNavigator from '@/components/document/DocumentContentNavigator'
import ZoomControl from '@/components/document/ZoomControl'
import EditableButton from '@/components/EditableButton'
import SavingIndicator from '@/components/SavingIndicator.vue'
import { debounce } from 'lodash'
import { ResolvableImage } from '@custom-media/signdigital-web-shared/src/lib/resolvable-image'
import { DocumentReference } from '@custom-media/signdigital-lib/src/image-reference'
import windowHeightMixin from '@/mixins/window-height-mixin'
// import { OwnSignElement, OwnSignPage, OwnSignDocument } from '@custom-media/signdigital-lib/src/documents/own-sign-document'
export default {
  mixins: [windowHeightMixin],
  components: {
    DocumentContentNavigator,
    ZoomControl,
    EditableButton,
    SavingIndicator
  },
  data () {
    return {
      zoom: 0.75,
      contentSize: null,
      navigatorSize: {},
      unsubscribe: null,
      isSavingBeforeLeave: false,
      debouncedSave: debounce(this.save, 10000, { maxWait: 30000 }),
      windowHeightMinWidth: 0,
      closedDuplicateHint: false
    }
  },
  computed: {
    ...mapState('documentEditor', [
      '_id',
      'node',
      'ownerType',
      'author',
      'name',
      'isSavePending',
      'hasUnsavedChanges',
      'updatedAt'
    ]),
    ...mapGetters('documentEditor', {
      documentHasPages: 'hasPages'
    }),
    isOwnedByTeam () {
      return this.ownerType === 'teams'
    },
    isUserAuthor () {
      return this.author === this.$store.state.auth.user._id
    },
    isDuplicateHintShown () {
      return this.isOwnedByTeam && !this.isUserAuthor && !this.closedDuplicateHint
    },
    exitRoute () {
      return this.$store.state.session.exitRoute?.documents ?? { name: 'documents' }
    }
  },
  watch: {
    _id (value) {
      if (this.$route.params.id == null && !this.isSavingBeforeLeave) {
        console.log('Replacing url with for final id ' + value)
        this.$router.replace({ name: 'document-editor', params: { id: value } })
      }
    }
  },
  mounted () {
    this.updateNavigatorSize()
    this.initView()
    window.addEventListener('resize', this.updateNavigatorSize)
    globalEventBus.on(globalEvents.sidebarChanged, this.updateNavigatorSizeNextTick)
    this.unsubscribe = this.$store.subscribe(this.onDocumentEditorMutation)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.updateNavigatorSize)
    globalEventBus.off(globalEvents.sidebarChanged, this.updateNavigatorSizeNextTick)
    if (this.unsubscribe) {
      this.unsubscribe()
    }
  },
  async beforeRouteLeave (to, from, next) {
    console.log('Route leave', to, from)

    if (this.$store.state.documentEditor.hasUnsavedChanges) {
      this.isSavingBeforeLeave = true
      this.debouncedSave.cancel()
      await this.save()
    }
    // this.$store.dispatch('documentEditor/reset')
    next()
    this.isSavingBeforeLeave = false

    // Close search dropdowns
    document.querySelectorAll('.search-dropdown').forEach((modal) => {
      modal.__vue__?.$vnode?.context?.close()
    })
  },
  methods: {
    onDocumentEditorMutation (mutation) {
      if (!mutation.type.startsWith('documentEditor/')) {
        return
      }
      const editorMutation = mutation.type.split('/')[1]
      const triggerMutations = [
        'setName',
        'updateProperty',
        'addPage',
        'removePage',
        'patchPage',
        'addElements',
        'addElement',
        'addElementToPageById',
        'addElementsToPageId',
        'moveElement',
        'patchElement',
        'removeElementById'
      ]

      if (triggerMutations.includes(editorMutation)) {
        console.log('Save triggered by ' + editorMutation)
        if (this._id == null) {
          console.log('Is initial change. Saving immediately')
          this.save()
        } else {
          this.debouncedSave()
        }
      }
    },
    async maybeLoadDocument () {
      if (this.$route.params && this.$route.params.id) {
        await this.$store.dispatch('documentEditor/loadDocumentWithId', this.$route.params.id)
        this.$store.dispatch('preferences/trackRecentlyUsed', { fileType: 'documents', id: this.$route.params.id })
      }
    },
    async initView () {
      await this.maybeLoadDocument()
      await this.$nextTick()
      await this.$nextTick() // Why 2 next ticks?
      this.onZoomToFit()
    },

    /**
     * Update the explicit size of the navigator
     */
    updateNavigatorSize () {
      const width = this.$refs.contentContainer.clientWidth
      const height = this.$refs.contentContainer.clientHeight
      if (width !== this.navigatorSize.width || height !== this.navigatorSize.height) {
        this.navigatorSize = { width, height }
      }
    },
    async updateNavigatorSizeNextTick () {
      await this.$nextTick()
      this.updateNavigatorSize()
    },
    onZoomChanged (zoom) {
      this.zoom = zoom
    },
    onZoomToFit () {
      const { content, editorPadding } = this.$refs.navigator.dimensions
      this.zoom = (this.navigatorSize.width - 2 * editorPadding) / content.width
    },
    onContentSizeChanged (contentSize) {
      this.contentSize = contentSize
    },
    onDocumentUpdated (newDocument) {
      console.log('Document updated. Needs to save')
      // TODO: Save document to cookie
      this.document = newDocument
    },
    async onDocumentNameChanged (newName) {
      try {
        await this.$store.dispatch('documentEditor/rename', newName)
      } catch (error) {
        this.$buefy.dialog.alert({
          title: 'Fehler beim Umbenennen',
          type: 'is-danger',
          icon: 'triangle-exclamation',
          message:
            error?.code === 409
              ? `Am Speicherort dieser Druckvorlage existiert bereits eine Datei mit dem Namen „${newName}”.<br/><br/> Bitte wähle einen anderen Namen.`
              : error
        })
      }
    },

    async onPrintButtonClicked () {
      this.debouncedSave.cancel()
      await this.save()
      const pdf = new ResolvableImage({
        $store: this.$store,
        $resolver: this.$resolver,
        reference: new DocumentReference({
          objectId: this._id,
          service: 'documents'
        }),
        variant: 'original'
      })
      const url = await pdf.resolve()
      window.open(url, '_blank')
    },
    async save () {
      console.log('Saving document ' + this._id)
      await this.$store.dispatch('documentEditor/save')
      // TODO: Handle & Display Save error
    },
    async onCreateCopy () {
      await this.$store.dispatch('fileNodeTree/maybeFetchInPath', this.node)
      const originalItem = this.$store.state.fileNodeTree.itemsById[this.node]
      const node = await this.$fileDialog.duplicate({
        item: originalItem,
        currentItem: this.$store.getters['fileNodeTree/userItem'],
        parent: this
      })

      const documentId = node.file
      this.$router.replace({ name: 'document-editor', params: { id: documentId } })
      await this.$store.dispatch('documentEditor/loadDocumentWithId', documentId)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/bulma-variables.scss';
.document-editor {
  // Limit height, force child to scroll
  display: flex;
  flex-direction: column;

  @include until($desktop) {
    padding-top: 70px;
    position: relative;
    margin-top: -70px;

    .navbar.app-nav {
      .button {
        font-size: 0.8rem;
      }
    }
  }

  .navbar {
    border-bottom: 1px solid #eaeaea;

    .navbar-brand {
      .navbar-item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        label {
          font-size: 0.8rem;
          font-style: italic;
        }
      }
    }
  }

  .document-content-container {
    position: relative;
    display: flex;
    flex-grow: 1;
  }
}
.search-panel {
  position: absolute;
}
</style>
