export const frameStyleMixin = {
  computed: {
    frameStyle () {
      return this.getFrameStyleFor(this.frame)
    },
    sizeStyle () {
      if (this.size == null && this.frame == null) {
        return {}
      }
      const size = this.size || { width: this.frame.width, height: this.frame.height }
      return this.getSizeStyleFor(size)
    }
  },
  methods: {
    getFrameStyleFor (frame) {
      if (frame == null) {
        return {}
      }
      return {
        position: 'absolute',
        top: frame.y + 'px',
        left: frame.x + 'px',
        width: frame.width + 'px',
        height: frame.height + 'px'
      }
    },
    getSizeStyleFor (size) {
      if (size == null) {
        return {}
      }
      return {
        width: size.width + 'px',
        height: size.height + 'px'
      }
    }
  }
}
