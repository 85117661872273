// import FileNodeTree from './FileNodeTree'
import mitt from 'mitt'
import FileNodeTreeItem from './FileNodeTreeItem'

// const { userNodes } = require('../../stories/folders/node-fixtures')

// Get parent
// TODO: Fetch nodes from api
// TODO: Build node tree, update node tree as further nodes are fetched

export default class FileBrowserController {
  constructor (options = {}) {
    // this.$store = options.$store ?? null
    this.originalFilename = options.originalFilename ?? null
    this.originalItem = options.originalItem ?? null
    this.allowOriginalFilename = options.allowOriginalFilename ?? false

    // The available filename in the current directory
    this.proposedFilename = null

    this.selectedItems = options.selectedItems ?? null
    this.currentItem = options.currentItem ?? null
    this.currentRoot = options.currentRoot ?? null
    this.rootItems = options.rootItems ?? []
    this.fileTypeFilter = options.fileTypeFilter ?? null

    this.events = mitt()

    if (!this.currentItem) {
      if (this.rootItems.length > 0) {
        this.goTo(this.rootItems[0], true)
      }
    } else {
      this.goTo(this.currentItem, true)
    }
  }

  isFilenameAvailable (filename) {
    const childNodes = this.currentItem.children
    const conflictingItem = childNodes.find((i) => i.fileNode.name === filename)
    if (!conflictingItem) {
      return true
    }

    return this.originalItem && conflictingItem === this.originalItem && this.allowOriginalFilename
  }

  setOriginalFilename (filename) {
    this.originalFilename = filename
    const proposedFilename = this.getProposedFilename(filename)
    console.log('proposed filename is now ', proposedFilename)
    this.updateProposedFilename(proposedFilename)
  }

  updateProposedFilename (filename) {
    this.proposedFilename = filename
    this.events.emit('proposed-filename-changed', filename)
  }

  getProposedFilename (originalFilename) {
    if (!originalFilename) {
      return null
    }

    let filename = originalFilename
    const getNextProposal = (filename) => {
      const regex = / Kopie \d+$/
      const matches = regex.exec(filename)
      if (matches != null) {
        const matchStart = matches.index
        const basenamePart = filename.slice(0, matchStart)
        const numberPart = filename.slice(matchStart + ' Kopie '.length)
        const number = Number.parseInt(numberPart)
        return `${basenamePart} Kopie ${number + 1}`
      } else {
        return `${filename} Kopie 1`
      }
    }
    const maxIterations = 200
    let i = 0
    while (!this.isFilenameAvailable(filename) && i++ < maxIterations) {
      filename = getNextProposal(filename)
    }
    return filename
  }

  clearFileTypeFilter () {
    this.fileTypeFilter = null
  }

  getRootItemOf (item) {
    const rootId = item.getRootId()
    return this.rootItems.find((item) => item._id === rootId)
  }

  getParentOf (item) {
    const root = this.getRootItemOf(item)
    return root.getParentOf(item)
  }

  goTo (item, skipClearSelection = false) {
    this.currentItem = item
    this.currentRoot = this.getRootItemOf(item)
    if (!skipClearSelection) {
      this.clearSelectedItems()
    }

    if (this.originalFilename) {
      const proposedFilename = this.getProposedFilename(this.originalFilename)
      this.updateProposedFilename(proposedFilename)
    }
  }

  select (items) {
    this.selectedItems = items
  }

  deselect (items) {
    this.selectedItems = this.selectedItems.filter((i) => items.some((i1) => i1._id === i._id))
  }

  clearSelectedItems () {
    this.selectedItems = null
  }

  isItemSelected (item) {
    return this.selectedItems?.some((i) => i._id === item._id) ?? false
  }

  goToParent () {
    const parent = this.currentRoot?.getParentOf(this.currentItem)
    if (parent) {
      this.goTo(parent)
    }
  }

  async createDirectoryAt (location, name) {
    const path = (location?.fileNode.path ?? ',') + location._id + ','
    // const pathComponents = [...this.getRootItemOf(this.currentItem).getItemsInPath(this.currentItem.fileNode.path), this.currentItem._id]
    // const path = ',' + pathComponents.join(',') + ','
    const node = new this.api.FileNode({
      name,
      path,
      nodeType: 'directory'
    })
    await node.save()
    location.addChild(new FileNodeTreeItem(node))
  }

  async createDirectoryHere (name) {
    return this.createDirectoryAt(this.currentItem, name)
  }
}
