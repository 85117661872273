<template>
  <image-component v-if="image != null" :image="image" />
</template>

<script>
import ImageComponent from '@custom-media/signdigital-web-shared/src/components/ImageComponent'
import { CustomSignImageReference } from '@custom-media/signdigital-lib/src/image-reference'
import { ResolvableImage } from '@custom-media/signdigital-web-shared/src/lib/resolvable-image'

export default {
  components: {
    ImageComponent
  },
  props: {
    customSign: Object,
    variant: {
      type: String,
      default: 'thumbnail'
    }
  },
  data () {
    return {
      image: null
    }
  },
  watch: {
    customSign (newValue) {
      if (newValue == null) {
        this.image = null
        return
      }
      if (newValue != null && this.image != null && newValue.id === this.image.reference.objectId) {
        // Noo need to reload
        return
      }
      this.updateImage()
    }
  },
  mounted () {
    this.updateImage()
  },
  methods: {
    updateImage () {
      this.image = new ResolvableImage({
        $store: this.$store,
        $resolver: this.$resolver,
        reference: new CustomSignImageReference({ objectId: this.customSign._id }),
        variant: this.variant
      })
    }
  }
}
</script>
