<template>
  <div class="sign-editor-image-control">
    <div style="display: flex; flex-direction: row; justify-content: space-between">
      <document-image
        :image-reference.sync="imageReference"
        :allowedSignImageTypes="definition.allowedSignImageTypes"
        :allowedContentTypes="definition.allowedContentTypes"
        :allowedCustomSignTypes="definition.allowedCustomSignTypes"
        :dropdown="false"
        style="width: 100px; height: 100px"
        ref="documentImage"
        :owner="owner"
        :ownerType="ownerType"
      ></document-image>
      <div class="buttons" style="flex-direction: column; justify-content: center" v-if="imageReference != null">
        <b-button expanded size="is-small" icon-left="pencil" @click="$refs.documentImage.onAddButtonClicked($event)"
          >Ändern</b-button
        >
        <b-button expanded size="is-small" icon-left="times" @click="$refs.documentImage.onDeleteButtonClicked()"
          >Entfernen</b-button
        >
      </div>
    </div>
    <b-field class="mt-2">
      <b-checkbox size="is-small" v-model="shouldOverrideScale">Größe anpassen</b-checkbox>
    </b-field>
    <b-field v-if="shouldOverrideScale">
      <b-slider
        :custom-formatter="tooltipLabel"
        size="is-small"
        :min="50"
        :max="150"
        v-model="scaleOverride"
      ></b-slider>
    </b-field>
  </div>
</template>

<script>
import DocumentImage from '@/components/document/DocumentImage.vue'
import { SIGN_BOX_FIXED_ELEMENTS } from '@/lib/constants/sign-editor-constants'
import { mapGetters, mapState } from 'vuex'

export default {
  components: {
    DocumentImage
  },
  props: {
    layoutKey: String
  },
  computed: {
    ...mapGetters('signEditor', ['signTypeProperties']),
    ...mapState('signEditor', ['owner', 'ownerType']),
    definition () {
      return SIGN_BOX_FIXED_ELEMENTS[this.layoutKey]
    },
    fixedObject () {
      const id = this.$store.state.signEditor.fixedObjectIdsByLayoutKey[this.layoutKey]
      if (id == null) {
        return null
      }
      return this.$store.state.signEditor.objectsKeyedById[id]
    },
    scaleOverride: {
      get () {
        const scale = this.signTypeProperties.imageScaleOverrides?.[this.layoutKey] ?? 1
        return Math.round(scale * 100)
      },
      set (value) {
        this.setScaleOverride(value / 100)
        // TODO: Notify of change
      }
    },
    shouldOverrideScale: {
      get () {
        return this.signTypeProperties.imageScaleOverrides?.[this.layoutKey] != null
      },
      set (value) {
        if (value === false) {
          this.setScaleOverride(null)
        } else {
          this.setScaleOverride(1.0)
        }
      }
    },
    imageReference: {
      get () {
        return this.fixedObject?.imageReference
      },
      set (value) {
        this.$store.dispatch('signEditor/updateFixedImage', {
          layoutKey: this.layoutKey,
          imageReference: value,
          resolver: this.$resolver
        })
      }
    }
  },

  methods: {
    tooltipLabel (scale) {
      return `${scale} %`
    },
    setScaleOverride (scale) {
      this.$store.commit('signEditor/setSignTypeProperty', {
        key: 'imageScaleOverrides',
        value: {
          ...this.signTypeProperties.imageScaleOverrides,
          [this.layoutKey]: scale
        }
      })

      this.$store.dispatch('signEditor/updateFixedImagePosition', {
        layoutKey: this.layoutKey,
        resolver: this.$resolver
      })
    }
  }
}
</script>

<style></style>
