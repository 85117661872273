<template>
  <div>
    <div class="info-about-product content container px-5">
      <h1 class="subtitle has-text-dark mt-6 mb-6" id="what-is-sign-digital">Herzlich Willkommen auf SIGNdigital!</h1>

      <p>
        SIGNdigital ist eine Web-Anwendung. Präge dir die Gebärden mithilfe deiner Lernlisten ein, schlage wichtige
        Gebärden nach oder erstelle Druckvorlagen mit den Gebärdenzeichnungen in drei Formaten.
      </p>

      <p>
        Das SIGNbox-Format vereint Gebärdenzeichnung, METACOM-Symbol und Schrift. SIGNmap eignet sich beispielsweise für
        unsere SIGNmap-Kommunikationstafel. Du kannst dir auch nur die Gebärde anzeigen lassen und so deine Materialien
        erstellen.
      </p>

      <div class="images-row-centered">
        <div>
          <img alt="SIGNbox" src="@/assets/img/image-types/sign-box.svg" />
          <h6>SIGNbox</h6>
        </div>
        <div>
          <img alt="SIGNmap" src="@/assets/img/image-types/sign-map.svg" />
          <h6>SIGNmap</h6>
        </div>
        <div>
          <img alt="Gebaerde" src="@/assets/img/image-types/sign-only.svg" />
          <h6>Gebärde</h6>
        </div>
      </div>

      <p>Alle Gebärden gibt es auch als Video. Langsam und schnell. Mit und ohne Ton. So wie du es gerade benötigst.</p>
      <p>
        Du möchtest eigene Gebärdenbilder erstellen? Zum Beispiel für die ganz individuelle Kommunikation oder um
        Namensgebärden zu dokumentieren? Auch das ist mit SIGNdigital leicht möglich.
      </p>
      <p>
        Bitte beachte, dass unsere frei verfügbaren Inhalte mit einem Wasserzeichen versehen sind, um Qualität und
        Sicherheit zu gewährleisten.
      </p>

      <b-button
        size=""
        type="is-primary"
        class="mt-5"
        expanded
        tag="a"
        icon-left="external-link"
        href="https://sign-digital.de/"
        target="_blank"
      >
        Mehr über SIGNdigital
      </b-button>
    </div>

    <div v-if="!noListInformation" class="content container p-5">
      <div anchor="lernlisten-informationen">
        <div class="">
          <h1 class="mt-6 mb-6" id="about-lists">Über Lernlisten</h1>
          <div class="content">
            <p>
              Lernlisten auf SIGNdigital bieten dir die Möglichkeit, individuell angepasste Listen von Gebärden zu
              erstellen, die du lernen oder nachschlagen möchtest. Diese Funktion hilft dir, systematisch und effizient
              neue Gebärden zu erlernen und zu festigen.
            </p>
            <p>
              Du kannst eigene Lernlisten erstellen oder aus einer Vielzahl von vorgefertigten Listen auswählen. Dies
              ermöglicht es dir, gezielt wichtige Gebärden für verschiedene Situationen und Bedürfnisse zu üben. Die
              Lernlisten sind besonders nützlich für den alltäglichen Gebrauch, das Erlernen neuer Gebärden oder die
              Vorbereitung auf bestimmte Kommunikationsanforderungen.
            </p>
            <p>
              Jede Lernliste verfolgt deinen Fortschritt, sodass du jederzeit sehen kannst, welche Gebärden du bereits
              gemeistert hast und welche du noch üben musst. Dies fördert einen strukturierten und motivierenden
              Lernprozess.
            </p>
            <p>
              Zusätzlich kannst du die Gebärden als Videos in unterschiedlichen Geschwindigkeiten und mit oder ohne Ton
              ansehen. Dies erleichtert das Verstehen und Nachmachen der Gebärden erheblich.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="costs content container p-5">
      <div anchor="signdigital-preise">
        <div class="">
          <h1 class="mt-6 mb-6" id="how-much-does-it-cost">Preisauskunft</h1>
          <div class="content">
            <p>
              SIGNdigital ist eine Web-Anwendung (auch „Web-App”), also eine Webseite, die ihr auf dem PC, Tablet oder
              Smartphone aufrufen könnt.<br />
              Ihr meldet euch über den Browser an und jede Person hat einen eigenen Zugang (Lizenz).<br /><br />
              Es gibt verschiedene Möglichkeiten, SIGNdigital zu nutzen:
            </p>
            <ul>
              <li>als Einzelperson</li>
              <li>als Familie oder kleine Praxis (3 - 5 Lizenzen)</li>
              <li>als große Einrichtung (10 und mehr Lizenzen)</li>
            </ul>
            <p>
              Ihr könnt SIGNdigital monatlich abonnieren oder einen Zugang für ein ganzes Jahr erwerben und dabei zwei
              Monate sparen. Einrichtungen (z.B. Schulen, Kitas, Werkstätten) können den Zugang zu SIGNdigital für ein
              Jahr oder gleich für zwei Jahre erwerben.
            </p>

            <LicenseSelection />
            <b-button v-if="!isLoggedIn" size="" type="is-primary" class="mt-5 mb-6" expanded tag="a" href="/login">
              Jetzt anmelden!
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LicenseSelection from '@/components/LicenseSelection.vue'

export default {
  components: {
    LicenseSelection
  },
  props: {
    noListInformation: {
      type: Boolean
    }
  },
  computed: {
    hasActiveSubscription () {
      return this.$store.getters['auth/user']?.hasActiveSubscription
    },
    isLoggedIn () {
      return !!this.$store.getters['auth/user']
    }
  }
}
</script>

<style lang="scss" scoped>
.info-about-product {
  padding-left: 1.5rem;
  padding-bottom: 2.5rem;

  .images-row-centered {
    padding: 0 0 1rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    & > div {
      margin-left: 3rem;
      margin-right: 3rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      height: 100%;

      & > img {
        margin-bottom: 1rem;
      }
    }
  }
}
</style>
