var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"billing-form"},[_c('validation-observer',{ref:"validationObserver",attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('validation-observer',{ref:"billing",attrs:{"slim":""}},[_c('b-field',{attrs:{"label":_vm.customerTypeTitle}},[_c('b-radio-button',{attrs:{"native-value":"person","type":"is-primary "},model:{value:(_vm.customerClone.customerType),callback:function ($$v) {_vm.$set(_vm.customerClone, "customerType", $$v)},expression:"customerClone.customerType"}},[_c('span',[_vm._v("Privatkunde")])]),_c('b-radio-button',{attrs:{"native-value":"company","type":"is-primary "},model:{value:(_vm.customerClone.customerType),callback:function ($$v) {_vm.$set(_vm.customerClone, "customerType", $$v)},expression:"customerClone.customerType"}},[_c('span',[_vm._v("Geschäftskunde")])])],1),(_vm.customerClone.customerType === 'company')?_c('validation-provider',{attrs:{"name":"Name der Firma","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"label":"Firma, Organisation oder Einrichtung","type":{ 'is-danger': errors.length > 0, 'is-primary': valid },"message":errors[0]}},[_c('b-input',{key:"company-input",attrs:{"type":"text"},model:{value:(_vm.customerClone.company),callback:function ($$v) {_vm.$set(_vm.customerClone, "company", $$v)},expression:"customerClone.company"}})],1)]}}],null,true)}):_vm._e(),(_vm.isVatIdRequired)?_c('validation-provider',{staticClass:"is-expanded",attrs:{"name":"USt.-Id.","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"label":"Umsatzsteuer-Identifikationsnummer","type":{ 'is-danger': errors.length > 0, 'is-primary': valid },"message":errors[0],"expanded":""}},[_c('b-input',{key:"vat-id",attrs:{"type":"text"},model:{value:(_vm.customerClone.vatId),callback:function ($$v) {_vm.$set(_vm.customerClone, "vatId", $$v)},expression:"customerClone.vatId"}})],1)]}}],null,true)}):_vm._e(),_c('validation-provider',{attrs:{"name":"Dein Name","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"label":"Vor- und Nachname","type":{ 'is-danger': errors.length > 0, 'is-primary': valid },"message":errors[0]}},[_c('b-input',{key:"name-input",attrs:{"type":"text"},model:{value:(_vm.customerClone.name),callback:function ($$v) {_vm.$set(_vm.customerClone, "name", $$v)},expression:"customerClone.name"}})],1)]}}],null,true)}),_c('b-field',{attrs:{"label":"Abteilung, Apartment, Gebäude (optional)"}},[_c('b-input',{attrs:{"type":"text"},model:{value:(_vm.customerClone.additional),callback:function ($$v) {_vm.$set(_vm.customerClone, "additional", $$v)},expression:"customerClone.additional"}})],1),_c('b-field',{attrs:{"grouped":""}},[_c('validation-provider',{attrs:{"name":"Straße","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"label":"Straße","type":{ 'is-danger': errors.length > 0, 'is-primary': valid },"message":errors[0],"expanded":""}},[_c('b-input',{key:"street-input",attrs:{"type":"text"},model:{value:(_vm.customerClone.street),callback:function ($$v) {_vm.$set(_vm.customerClone, "street", $$v)},expression:"customerClone.street"}})],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Hausnummer","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"label":"Hausnummer","type":{ 'is-danger': errors.length > 0, 'is-primary': valid },"message":errors[0],"expanded":""}},[_c('b-input',{key:"number-input",attrs:{"type":"text"},model:{value:(_vm.customerClone.number),callback:function ($$v) {_vm.$set(_vm.customerClone, "number", $$v)},expression:"customerClone.number"}})],1)]}}],null,true)})],1),_c('b-field',{attrs:{"grouped":""}},[_c('validation-provider',{attrs:{"name":"Postleitzahl","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"label":"Postleitzahl","type":{ 'is-danger': errors.length > 0, 'is-primary': valid },"message":errors[0],"expanded":""}},[_c('b-input',{key:"postcode-input",attrs:{"type":"text"},model:{value:(_vm.customerClone.postcode),callback:function ($$v) {_vm.$set(_vm.customerClone, "postcode", $$v)},expression:"customerClone.postcode"}})],1)]}}],null,true)}),_c('validation-provider',{staticClass:"is-expanded",attrs:{"name":"Ort","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"label":"Ort","type":{ 'is-danger': errors.length > 0, 'is-primary': valid },"message":errors[0],"expanded":""}},[_c('b-input',{key:"city-input",attrs:{"type":"text"},model:{value:(_vm.customerClone.city),callback:function ($$v) {_vm.$set(_vm.customerClone, "city", $$v)},expression:"customerClone.city"}})],1)]}}],null,true)})],1),_c('validation-provider',{staticClass:"is-expanded",attrs:{"name":"Land","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"label":"Land","type":{ 'is-danger': errors.length > 0, 'is-primary': valid },"message":errors[0],"expanded":""}},[_c('country-select',{model:{value:(_vm.customerClone.countryCode),callback:function ($$v) {_vm.$set(_vm.customerClone, "countryCode", $$v)},expression:"customerClone.countryCode"}})],1)]}}],null,true)}),(_vm.customerClone.customerType === 'company')?_c('b-field',{attrs:{"label":"Interne Referenz, Vorgangsnummer oder Kostenstelle","message":"Wird auf die Rechnung gedruckt"}},[_c('b-input',{attrs:{"type":"text"},model:{value:(_vm.customerClone.reference),callback:function ($$v) {_vm.$set(_vm.customerClone, "reference", $$v)},expression:"customerClone.reference"}})],1):_vm._e()],1),(_vm.submit)?_c('b-button',{attrs:{"type":"is-primary","disabled":invalid,"expanded":""},on:{"click":_vm.onSubmit}},[_vm._v(_vm._s(_vm.submitTitle))]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }