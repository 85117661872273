var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"user-invoices-view"}},[_c('div',{staticClass:"container p-5"},[_c('h1',{staticClass:"title"},[_vm._v("Deine Rechnungen")]),(_vm.userBillingType === 'paying' && !_vm.hasManualSubscription)?_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-content"},[_c('div',{staticClass:"content"},[_c('b-loading',{attrs:{"active":_vm.isFindInvoicesPending,"is-full-page":false}}),(_vm.invoices)?_c('b-table',{attrs:{"data":_vm.invoices},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('div',{staticClass:"content has-text-centered"},[_c('p',[_vm._v("Es sind noch keine Rechnungen hinterlegt.")])])]},proxy:true}],null,false,1779057628)},[_c('b-table-column',{attrs:{"field":"created","label":"Datum"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v("Rechnung vom "+_vm._s(_vm._f("date")(new Date(row.created * 1000))))]),_c('div',{staticClass:"tags"},[_c('span',{staticClass:"tag"},[_vm._v(_vm._s(row.id))])])]}}],null,false,440599710)}),_c('b-table-column',{attrs:{"field":"total","label":"Summe"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("price")((row.total / 100)))+" ")]}}],null,false,1888328470)}),_c('b-table-column',{attrs:{"field":"status","label":"Status"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.status)+" ")]}}],null,false,1772283595)}),_c('b-table-column',{attrs:{"label":"Aktionen"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"buttons"},[_c('b-button',{attrs:{"tag":"a","href":row.invoice_pdf,"target":"_blank","size":"is-small"}},[_vm._v("Download")])],1)]}}],null,false,707981023)})],1):_vm._e()],1)])]):(_vm.userBillingType === 'paying' && _vm.hasManualSubscription)?_c('div',{staticClass:"card"},[_vm._m(0)]):(_vm.userBillingType === 'member')?_c('div',{staticClass:"card"},[_vm._m(1)]):(_vm.userBillingType === 'free')?_c('div',{staticClass:"card"},[_vm._m(2)]):_vm._e()])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-content"},[_c('div',{staticClass:"content"},[_vm._v(" Die Bereitstellung von Rechnungen im Benutzerkonto ist nur für Lizenzen verfügbar, die per Kreditkarte oder Lastschrift bezahlt werden. ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-content"},[_c('div',{staticClass:"content"},[_vm._v(" Du nutzt die Lizenz deines Teams - die Rechnungen werden vom Team-Besitzer verwaltet. ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-content"},[_c('div',{staticClass:"content"},[_vm._v("Du nutzt einen kostenlosen Zugang. Es gibt daher keine Rechnungen abzurufen.")])])}]

export { render, staticRenderFns }