<template>
  <div class="lists-view">
    <!-- NAVBAR -->
    <b-navbar class="app-nav is-mobile" :mobile-burger="false" :active="true">
      <template slot="brand">
        <b-navbar-item tag="div">
          <nav class="breadcrumb" aria-label="breadcrumbs">
            <ul>
              <li class="is-active"><a href="#">Lernlisten</a></li>
            </ul>
          </nav>
        </b-navbar-item>
      </template>
      <template slot="start"> </template>
    </b-navbar>

    <!-- Create new list prompt -->
    <section class="create-new-list">
      <div class="container px-6 pt-3 pb-6">
        <br />
        <h2 class="subtitle">Neue Lernliste erstellen</h2>
        <b-field>
          <b-radio-button v-model="newListType" native-value="empty" type="is-primary">
            <b-icon icon="plus"></b-icon>
            <span>Leer</span>
          </b-radio-button>

          <b-radio-button v-model="newListType" native-value="template" type="is-primary">
            <b-icon icon="books"></b-icon>
            <span>Aus Vorlage</span>
          </b-radio-button>
        </b-field>
        <div class="list-cards columns is-multiline">
          <div
            class="column is-one-quarter-widescreen is-one-quarter-desktop is-half-tablet"
            v-if="newListType === 'empty'"
          >
            <empty-list-card @click="onAddButtonClicked" />
          </div>
          <template v-if="newListType === 'template'">
            <div
              class="column is-one-quarter-widescreen is-one-quarter-desktop is-half-tablet"
              v-for="listTemplate in listTemplates"
              :key="listTemplate._id"
            >
              <list-template-card :list-template="listTemplate" @click="onListTemplateClicked(listTemplate)" />
            </div>
          </template>
        </div>
      </div>
    </section>

    <section class="all-lists">
      <div class="container p-6">
        <!-- Heading -->
        <div class="level">
          <div class="level-left">
            <h2 class="subtitle">Zuletzt verwendet</h2>
          </div>
          <div class="level-right">
            <b-button
              tag="router-link"
              :to="{ name: 'file-browser', query: { t: 'lists' } }"
              size=""
              type="is-ghost"
              icon-left="folder-tree"
              >Alle Lernlisten anzeigen</b-button
            >
          </div>
        </div>

        <hint hint-key="file-browser-view-lists">
          <p>
            Deine Lernlisten sind ab jetzt im Bereich „Organisieren” gespeichert. Hier siehst du nur deine zuletzt
            verwendeten Lernlisten.
          </p>
          <p>
            Klicke auf
            <router-link
              :to="{ name: 'file-browser', query: { t: 'lists' } }"
              size=""
              type="is-text"
              icon-left="folder-tree"
              >„Alle Lernlisten anzeigen”</router-link
            >
            um die neue Organisieren-Funktion und die Verwendung von Ordnern auszuprobieren.
          </p>
        </hint>

        <!-- List of custom signs -->
        <section class="lists-results">
          <b-table
            striped
            hoverable
            :data="recentlyUsedLists"
            default-sort="updatedAt"
            default-sort-direction="desc"
            @click="onListClicked"
            :loading="isFindListsPending"
          >
            <b-table-column field="name" label="Name" v-slot="props">
              <strong>{{ props.row.name }}</strong>
            </b-table-column>
            <b-table-column field="progress" label="Lernfortschritt" v-slot="props">
              <b-progress
                v-if="props.row.signs.length > 0"
                :value="props.row.progress"
                show-value
                format="percent"
                type="is-primary"
              ></b-progress>
              <span v-else> - </span>
            </b-table-column>
            <b-table-column label="Datum" field="updatedAt" v-slot="props">
              <b-tag>{{ props.row.updatedAt | dateTime }}</b-tag>
            </b-table-column>
            <b-table-column label="Aktionen" v-slot="props">
              <div class="buttons">
                <b-button
                  type="is-primary"
                  size="is-small"
                  icon-left="play"
                  @click.stop="onOpenButtonClicked(props.row)"
                  :disabled="props.row.signs.length === 0"
                  >Öffnen</b-button
                >
                <b-button size="is-small" @click.stop="onEditButtonClicked(props.row)">Bearbeiten</b-button>
                <!-- <b-button type="is-danger" size="is-small" @click.stop="onDeleteButtonClicked(props.row)"
                  >Löschen</b-button
                > -->
                <actions-dropdown
                  :actions="listActions"
                  type="is-default"
                  size="is-small"
                  icon="ellipsis-h"
                  @action="onListAction($event, props.row)"
                ></actions-dropdown>
              </div>
            </b-table-column>

            <template #empty>
              <div class="content has-text-centered">
                <p>Du hast noch keine Lernlisten angelegt</p>
                <p>Leg doch einfach eine neue an oder schau dir unsere fertigen Lernlisten an.</p>
              </div>
            </template>
          </b-table>
        </section>
      </div>
    </section>
  </div>
</template>

<script>
import ListTemplateCard from '@/components/ListTemplateCard'
import { makeFindMixin } from 'feathers-vuex'
import EmptyListCard from '@/components/EmptyListCard.vue'
import ActionsDropdown from '@/components/ActionsDropdown'
import Hint from '@/components/Hint'

export default {
  mixins: [
    makeFindMixin({ service: 'lists', qid: 'listLists', watch: true }),
    makeFindMixin({ service: 'list-templates', qid: 'listListTemplates' })
  ],
  components: { ListTemplateCard, EmptyListCard, ActionsDropdown, Hint },
  data () {
    return {
      listActions: [
        { value: 'reveal', icon: 'folder-tree', text: 'In „Organisieren” anzeigen' },
        { value: 'rename', icon: 'comment-edit', text: 'Umbenennen' },
        { value: 'move', icon: 'level-down', text: 'Verschieben' },
        { value: 'duplicate', icon: 'clone', text: 'Duplizieren' },
        { value: 'delete', icon: 'trash', text: 'Löschen' }
      ],

      limit: 10,
      skip: 0,
      sortField: 'updatedAt',
      sortOrder: 'desc',

      newListType: 'empty'
    }
  },

  computed: {
    listsParams () {
      return this.$store.getters['preferences/recentlyUsedQuery']('lists', 10)
    },
    listTemplatesParams () {
      return { query: { isPublic: true, $sort: { createdAt: -1 } } }
    },
    recentlyUsedLists () {
      if (!this.$store.getters['preferences/isActualRecentlyUsedQuery']('lists')) {
        return this.lists
      }
      const ids = this.$store.state.preferences.recentlyUsed.lists
      if (ids.length === 1) {
        return this.lists
      }

      const sorted = ids
        .map((i) => this.lists.find((d) => d?._id === i))
        .reverse()
        .filter((f) => !!f)
      return sorted
    }
  },
  methods: {
    async onAddButtonClicked () {
      // Create
      const { file } = await this.$fileDialog.create({
        nodeType: 'file',
        fileType: 'lists',
        parent: this
      })
      this.$store.commit('session/clearExitRoute')
      this.$router.push({ name: 'list-editor', params: { id: file._id } })
    },
    onOpenButtonClicked (list) {
      this.$store.commit('session/clearExitRoute')
      this.$router.push({ name: 'list-player-setup', params: { id: list._id } })
    },
    onEditButtonClicked (list) {
      this.$store.commit('session/clearExitRoute')
      this.$router.push({ name: 'list-editor', params: { id: list._id } })
      event.preventDefault()
      event.stopPropagation()
    },
    onListClicked (list) {
      this.onOpenButtonClicked(list)
    },
    onListTemplateClicked (licenseTemplate) {
      this.$store.commit('session/clearExitRoute')
      this.$router.push({ name: 'list-editor', query: { template: licenseTemplate._id } })
    },
    async onDeleteButtonClicked (list) {
      await list.remove()
    },
    async onListAction (action, list) {
      const item = await this.$store.dispatch('fileNodeTree/maybeFetchInPath', list.node)
      switch (action) {
        case 'reveal':
          this.$router.push({
            name: 'file-browser',
            query: {
              c: item.parentId,
              s: item._id
            }
          })
          return
        case 'rename':
          await this.$fileDialog.rename({ item, parent: this })
          await this.$store.dispatch('lists/get', list._id)
          return
        case 'move':
          await this.$fileDialog.move({ item, parent: this })
          await this.$store.dispatch('lists/get', list._id)
          return
        case 'duplicate': {
          const duplicate = await this.$fileDialog.duplicate({ item, parent: this })
          await this.$store.dispatch('lists/get', duplicate.file)
          return
        }
        case 'delete':
          return this.$fileDialog.delete({ item, parent: this })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/bulma-variables.scss';
.featured-lists {
  .list-template-card {
    flex-grow: 1;
  }
  .list-cards {
    .column {
      display: flex;
    }
  }
}

section.create-new-list {
  background: $grey-lightest;
}

.b-table::v-deep {
  td {
    vertical-align: middle;
    cursor: pointer;
  }
}
</style>
