<template>
  <div class="document-card card" @click.self="$emit('click', $event)" @mouseleave="onMouseLeave">
    <div class="card-image">
      <document-thumbnail :document="document" />
    </div>
    <div class="card-content p-4">
      <h1 class="title mb-3">{{ document.name }}</h1>
      <h2 class="subtitle mb-2">{{ document.properties.title }}</h2>

      <div class="level">
        <time>{{ document.updatedAt | dateTime }}</time>
      </div>
    </div>

    <div class="card-footer">
      <div class="card-footer-item is-justify-content-end">
        <document-actions ref="documentActions" type="is-ghost" :edit="false" :document="document" />
      </div>
    </div>
  </div>
</template>

<script>
import DocumentActions from '@/components/document/DocumentActions'
import DocumentThumbnail from '@/components/document/DocumentThumbnail'

export default {
  components: {
    DocumentThumbnail,
    DocumentActions
  },
  props: {
    document: Object
  },
  methods: {
    onMouseLeave () {
      const dropdown = this.$refs.documentActions?.$refs?.actionsDropdown?.$refs?.dropdown
      if (dropdown?.isActive) {
        dropdown.toggle()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/bulma-variables.scss';
.document-card {
  display: inline-block;
  transition:
    transform ease-out 0.25s,
    box-shadow ease-out 0.25s,
    background-color ease-out 0.25s;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 10;

  .hover-title {
    display: none;
  }

  .image-component {
    transition: transform ease-out 0.25s;
    transform-origin: center bottom;

    ::v-deep img {
      border: 1px solid $grey;
      border-bottom: 0;
    }
  }

  &:hover {
    transform: scaleX(1.02) scaleY(1.02);
    box-shadow:
      0 0em 1.5em -0.05em rgba(10, 10, 10, 0.1),
      0 0px 0 0px rgba(10, 10, 10, 0.02);
    cursor: pointer;
    z-index: 20;

    .card-image {
      background-color: $primary;
      .image-component {
        transform: scale(1.02) rotate3d(1, 0, 0, -6deg);
      }
    }

    .card-footer .button.primary {
      background-color: $primary;
      color: $white;
    }
  }

  .card-image {
    background-color: $grey-lighter;
    pointer-events: none;
    padding-left: 20px;
    padding-top: 20px;
    padding-right: 20px;
    perspective: 800px;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;

    &::v-deep:first-child img {
      border-radius: 0;
    }
  }

  .card-content {
    pointer-events: none;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    z-index: 5;
    border-top: 1px solid $grey-lighter;

    .title {
      font-size: 0.85em;
    }
    .subtitle {
      font-size: 0.75em;
      color: $grey-dark;
    }
    .title:not(.is-spaced) + .subtitle {
      margin-top: -0.75em;
    }
    time {
      font-size: 0.8rem;
      color: $grey-light;
    }
  }

  .card-footer {
    pointer-events: none;
    .button,
    .actions-dropdown {
      pointer-events: initial;
    }
    .button.is-ghost:hover,
    ::v-deep .dropdown-trigger .button.is-ghost:hover,
    ::v-deep .dropdown.is-active .dropdown-trigger .button.is-ghost {
      text-decoration: none;
      background-color: $primary;
      color: white;
    }
    .footer-item {
      flex-wrap: nowrap;
    }
  }
}
</style>
