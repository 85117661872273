<template>
  <div class="team-invite-view">
    <div class="container p-5">
      <h1 class="title">Personen einladen</h1>

      <!-- Overview -->
      <team-seats-card ref="seatsCard" :team="team" />

      <!-- Quick invite -->
      <b-collapse class="card mb-4" :open.sync="isParserExpanded" animation="slide">
        <template #trigger="props">
          <div class="card-header" role="button">
            <p class="card-header-title">Schnelles Hinzufügen von Personen</p>
            <a class="card-header-icon">
              <b-icon :icon="props.open ? 'chevron-down' : 'chevron-up'"> </b-icon>
            </a>
          </div>
        </template>
        <div class="card-content">
          <email-address-parser @submit="onEmailAddressParserSubmit" />
        </div>
      </b-collapse>

      <!-- Email Table -->
      <div class="card mb-4">
        <div class="card-header">
          <div class="card-header-title">Personen zum Einladen eingeben</div>
          <div class="buttons"></div>
        </div>
        <div class="card-content">
          <div class="content">
            <p>
              Die hier eingegebenen Personen werden eine E-Mail mit einem Einladungs-Link zu SIGNdigital erhalten.
              Darüber können Sie sich selbstständig mit der angegebenen E-Mail Adresse bei SIGNdigital anmelden und
              werden diesem Team hinzugefügt.
            </p>

            <!-- List of persons -->
            <b-table :data="persons">
              <b-table-column field="name" label="Name">
                <template #header>
                  Name
                  <b-tooltip
                    multilined
                    label="Es besteht die Möglichkeit, als Name auch Pseudonyme zu verwenden. Dabei besteht jedoch eine höhere Chance, dass die Einladungs-E-Mail im Spam-Ordner landet oder abgelehnt wird."
                    position="is-bottom"
                    type="is-light"
                  >
                    <b-icon icon="info-circle" />
                  </b-tooltip>
                </template>
                <template #default="{ row }">
                  <b-input
                    :id="'cm-invite-name-field-' + indexOfRow(row)"
                    size="is-small"
                    type="text"
                    v-model="row.name"
                    @keydown.native.enter="onNameEnter(row)"
                  />
                </template>
              </b-table-column>
              <b-table-column field="email" label="E-Mail Adresse" v-slot="{ row }">
                <b-input
                  :id="'cm-invite-email-field-' + indexOfRow(row)"
                  size="is-small"
                  type="email"
                  v-model="row.email"
                  @keydown.native.enter="onEmailEnter(row)"
                />
              </b-table-column>
              <b-table-column label="Aktionen" v-slot="{ row }">
                <b-button expanded size="is-small" type="is-danger" @click="onRemoveRow(row)">Entfernen</b-button>
              </b-table-column>
            </b-table>

            <div class="p-3">
              <b-button expanded class="" size="is-small" @click="addRow">Weitere Person hinzufügen</b-button>
            </div>

            <b-field label="Nachricht für die Einladung" class="mt-5">
              <b-input type="textarea" :placeholder="messagePlaceholder" v-model="message" />
            </b-field>
          </div>
        </div>
      </div>

      <!-- Submit -->
      <b-message v-if="personCount - freeSeats > 0" type="is-warning"
        >Es sind nicht genügend freie Plätze in Ihrer Lizenz vorhanden. Entfernen Sie {{ personCount - freeSeats }}
        {{ 'Person' | pluralize(personCount - freeSeats, 'en') }} aus der Liste oder stornieren Sie
        {{ personCount - freeSeats }} offene {{ 'Einladung' | pluralize(personCount - freeSeats, 'en') }}.</b-message
      >
      <b-button
        type="is-primary"
        :disabled="personCount === 0 || personCount > freeSeats"
        expanded
        @click="onSubmitButtonClicked"
        >Einladungs E-Mails an {{ personCount }} Personen senden</b-button
      >
    </div>
  </div>
</template>

<script>
import EmailAddressParser from '@/components/team/EmailAddressParser'
import TeamSeatsCard from '@/components/team/TeamSeatsCard.vue'
import { mapGetters, mapState } from 'vuex'
export default {
  components: {
    EmailAddressParser,
    TeamSeatsCard
  },
  data () {
    return {
      isParserExpanded: false,
      persons: [{ name: null, email: null }],
      message: null
    }
  },
  computed: {
    ...mapGetters('teamSettings', ['freeSeats']),
    ...mapState('session', ['team']),
    validPersons () {
      const emailRegex =
        /(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/
      return this.persons.filter((p) => p.email && p.email.length && emailRegex.test(p.email))
    },
    personCount () {
      return this.validPersons.length
    },
    messagePlaceholder () {
      return `Tritt unserem SIGNdigital Team „${this.team?.name}” kostenlos bei.`
    }
  },
  methods: {
    onNameEnter (row) {
      const index = this.indexOfRow(row)
      const e = document.getElementById(`cm-invite-email-field-${index}`)
      if (e != null) {
        e.focus()
      }
    },
    async onEmailEnter (row) {
      const index = this.indexOfRow(row)
      this.addRow()
      await this.$nextTick()
      const e = document.getElementById(`cm-invite-name-field-${index + 1}`)
      if (e != null) {
        e.focus()
      }
    },
    addRow () {
      this.persons.push({ name: null, email: null })
    },
    indexOfRow (row) {
      return this.persons.indexOf(row)
    },
    onRemoveRow (row) {
      this.persons.splice(this.indexOfRow(row), 1)
    },
    onEmailAddressParserSubmit (persons) {
      const currentPersonsByEmail = this.persons.reduce((acc, p) => {
        acc[p.email] = p
        return acc
      }, {})

      persons.forEach((p) => {
        const personAlreadyExists = currentPersonsByEmail[p.email] != null
        if (personAlreadyExists) {
          // Replace existing person
          const i = this.persons.findIndex((cp) => cp.email?.toLowerCase() === p.email?.toLowerCase())
          this.persons[i] = p
        } else {
          this.persons.push(p)
        }
      })

      this.isParserExpanded = false
    },
    async onSubmitButtonClicked () {
      const invitationData = this.validPersons.map((p) => ({
        ...p,
        team: this.team._id,
        message: this.message || this.messagePlaceholder
      }))
      await this.$store.dispatch('invitations/create', [invitationData])
      await this.$store.dispatch('teamSettings/fetch')
      this.$buefy.toast.open({
        type: 'is-success',
        message: `Einladungs-E-Mail an ${invitationData.length} Personen verschickt`
      })

      this.$router.push({ name: 'team-invitations' })
    }
  }
}
</script>
