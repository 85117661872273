import axios from 'axios'

export const apiMixin = {
  methods: {
    /**
     * Complete a pending verification
     * @param {*} verificationId
     * @param {*} token
     * @param {*} data
     * @returns
     */
    async completeVerification (verificationId, token, additionalData = {}) {
      const apiHost = process.env.VUE_APP_API_HOST ? process.env.VUE_APP_API_HOST : 'http://localhost:3030'
      const verificationsEndpoint = `${apiHost}/verifications/${verificationId}`
      const patchData = {
        token,
        ...additionalData
      }
      return axios.patch(verificationsEndpoint, patchData)
    },

    /**
     * Creates a new verification object to verify the signup email
     * @param {String} accessToken current authentication token
     * @returns
     */
    async createNewSignupEmailVerification (accessToken) {
      const apiHost = process.env.VUE_APP_API_HOST ? process.env.VUE_APP_API_HOST : 'http://localhost:3030'
      const verificationsEndpoint = `${apiHost}/verifications/`
      const data = {
        action: 'signupEmail',
        user: this.$store.getters['auth/user']._id
      }
      return axios.post(verificationsEndpoint, data, {
        headers: {
          Authorization: accessToken
        }
      })
    },

    /**
     * Create a new verification object to confirm password reset via email
     * @param {String} email user email
     * @returns
     */
    async createNewPasswordResetVerification (email) {
      const apiHost = process.env.VUE_APP_API_HOST ? process.env.VUE_APP_API_HOST : 'http://localhost:3030'
      const verificationsEndpoint = `${apiHost}/verifications/`
      const data = {
        action: 'passwordReset',
        email
      }
      return axios.post(verificationsEndpoint, data)
    }
  }
}
