<template>
  <div class="listPlayer-setup">
    <!-- Navbar -->
    <b-navbar class="is-white is-mobile" :mobile-burger="false" :active="true">
      <template slot="start">
        <b-navbar-item tag="div" v-if="list"> Lernliste öffnen: {{ list.name }} </b-navbar-item>
      </template>
      <template slot="end">
        <b-navbar-item tag="div">
          <b-button tag="router-link" :to="exitRoute" type="is-light"> Zurück zur Übersicht </b-button>
        </b-navbar-item>
      </template>
    </b-navbar>

    <b-loading :active="isLoading" :is-full-page="false" />
    <section class="content" v-if="list">
      <div class="container p-5">
        <div class="card">
          <div class="card-content">
            <b-field label="Name">
              {{ list.name }}
            </b-field>

            <b-field
              v-if="list.learnedSigns && list.signs"
              label="Lernfortschritt"
              :message="
                'Du hast ' +
                list.learnedSigns.length +
                ' von ' +
                list.signs.length +
                ' Gebärden als „gelernt” markiert.'
              "
            >
              <b-progress
                v-if="list.progress != null"
                :value="list.progress"
                show-value
                format="percent"
                type="is-primary"
              ></b-progress>
            </b-field>

            <b-field
              class="mt-5"
              label="Quiz-Modus"
              message="Im Quiz-Modus sind bestimmte Inhalte zunächst ausgeblendet und können nacheinander aufgedeckt werden. Der Ton der Gebärdenvideos ist außerdem zunächst deaktiviert."
            >
            </b-field>

            <b-field>
              <b-checkbox native-value="title" :value="revealable" @input="setRevealable">Name der Gebärde</b-checkbox>
            </b-field>
            <b-field>
              <b-checkbox native-value="video" :value="revealable" @input="setRevealable">Video</b-checkbox>
            </b-field>
            <b-checkbox native-value="image" :value="revealable" @input="setRevealable">Gebärdenzeichnung</b-checkbox>

            <b-field label="Vor dem Abspielen" class="mt-5"></b-field>
            <b-field>
              <b-checkbox :disabled="isSharedList" v-model="resetProgressOnStart"
                >Lernfortschritt zurücksetzen</b-checkbox
              >
            </b-field>
            <b-field>
              <b-checkbox :value="shuffle" @input="setShuffle">Gebärden mischen (zufällige Reihenfolge)</b-checkbox>
            </b-field>
            <div class="buttons mt-6">
              <b-button icon-left="play" type="is-primary" @click="onPlayButtonClicked">Lernliste öffnen</b-button>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import windowHeightMixin from '@/mixins/window-height-mixin'
export default {
  mixins: [windowHeightMixin],
  components: {},
  data () {
    return {
      isLoading: true,
      resetProgressOnStart: false
    }
  },
  mounted () {
    this.init()
    window.addEventListener('keydown', this.onKeydown)
  },
  beforeDestroy () {
    window.removeEventListener('keydown', this.onKeydown)
  },
  computed: {
    ...mapState('listPlayer', ['list', 'isSharedList', 'unlearnedSigns', 'revealable', 'revealed', 'shuffle']),
    exitRoute () {
      return this.$store.state.session.exitRoute?.lists ?? { name: 'lists' }
    }
  },
  methods: {
    ...mapActions('lists', { getList: 'get' }),
    ...mapActions('listPlayer', ['resetProgress', 'ensureLoaded']),
    ...mapMutations('listPlayer', ['setList', 'setRevealable', 'setShuffle', 'resetRevealProgress']),
    async init () {
      this.isLoading = true
      this.ensureLoaded(this.$route.params)
      this.isLoading = false
    },
    onShuffleChanged () {
      this.unlearnedSigns = this.getUnlearnedSigns()
    },
    async onPlayButtonClicked () {
      if (this.resetProgressOnStart) {
        await this.resetProgress()
      }
      this.resetRevealProgress()
      this.$store.dispatch('preferences/trackRecentlyUsed', { fileType: 'lists', id: this.$route.params.id })
      if (this.isSharedList) {
        this.$router.push({ name: 'list-player-shared', params: { shareKey: this.$route.params.shareKey } })
      } else {
        this.$router.push({ name: 'list-player', params: { id: this.$route.params.id } })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/bulma-variables.scss';
section.completed {
  .hero {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .icon {
    font-size: 3em;
  }
}

.progress-wrapper:not(:last-child) {
  margin-bottom: 0;
}
</style>
