<template>
  <div class="team-overview">
    <div class="container p-5">
      <h1 class="title">Dein Team</h1>

      <b-loading :active="isPatchTeamPending" />

      <b-message v-if="$route.query && $route.query.message === 'welcome'" type="is-primary">
        <h2 class="subtitle">Willkommen bei SIGNdigital!</h2>
        <p>Du nutzt jetzt die Lizenz deines Teams und kannst direkt loslegen</p>
        <b-button type="is-primary" tag="router-link" :to="{ name: 'search' }" class="mt-4"
          >Jetzt zur Gebärdensuche</b-button
        >
      </b-message>

      <div class="card" v-if="team">
        <div class="card-header">
          <div class="card-header-title">{{ team.name }}</div>
          <!-- Edit button for managers -->
          <div class="buttons are-small mr-4" v-if="['owner', 'manager'].includes(teamRole)">
            <b-button v-if="!isEditing" @click="onEditButtonClicked">Bearbeiten</b-button>
            <b-button v-if="isEditing" @click="onResetButtonClicked">Abbrechen</b-button>
          </div>
          <div class="buttons are-small mr-4" v-else>
            <b-button v-if="!isEditing" type="is-danger" @click="onLeaveButtonClicked">Team verlassen</b-button>
          </div>
        </div>
        <div class="card-content">
          <b-field label="Besitzer" v-if="['owner', 'manager'].includes(teamRole)">
            {{ team.owner.name }}
            ({{ team.owner.email }})

            <b-tag v-if="team.ownerHasAccess" type="is-primary">
              <b-icon icon="check-circle" />
              <span>SIGNdigital-Zugang</span>
            </b-tag>
          </b-field>
          <div class="content" v-if="!isEditing">
            <b-field label="Beschreibung">
              {{ team.description }}
            </b-field>
          </div>
          <team-details-form
            v-if="isEditing"
            ref="teamForm"
            submit-text="Team aktualisieren"
            :team="team"
            @submit="onTeamFormSubmit"
          />
        </div>
      </div>

      <team-seats-card class="mt-4" v-if="['owner', 'manager'].includes(teamRole)" :team="team" />
    </div>
  </div>
</template>

<script>
import TeamDetailsForm from '@/components/team/TeamDetailsForm.vue'
import { mapActions, mapState } from 'vuex'
import TeamSeatsCard from '@/components/team/TeamSeatsCard.vue'
export default {
  components: { TeamDetailsForm, TeamSeatsCard },
  data () {
    return {
      isEditing: false
    }
  },
  computed: {
    ...mapState('teams', { isPatchTeamPending: 'isPatchPending' }),
    ...mapState('session', ['team', 'teamRole']),
    ...mapState('auth', ['user'])
  },
  methods: {
    ...mapActions('teams', { getTeam: 'get' }),
    onEditButtonClicked () {
      this.isEditing = true
    },
    onResetButtonClicked () {
      this.isEditing = false
    },
    onLeaveButtonClicked () {
      this.$buefy.dialog.confirm({
        title: 'Team verlassen',
        message:
          'Willst du das Team wirklich verlassen? Damit verlierst du auch Zugriff auf die SIGNdigital-Lizenz deines Teams und musst deine eigene Lizenz erwerben.',
        type: 'is-danger',
        cancelText: 'Abbrechen',
        confirmText: 'Verlassen',
        onConfirm: async () => {
          //  Perform team leave, update user billingType
          await this.$store.dispatch('users/patch', [this.user._id, { team: null }])
          window.location.reload()
        }
      })
    },
    async onTeamFormSubmit (localTeam) {
      localTeam.commit()
      await this.team.save()
      this.isEditing = false
    }
  }
}
</script>
