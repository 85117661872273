<template>
  <div class="email-address-parser">
    <b-field>
      <template #label>
        <div class="level">
          <div class="level-left">
            <span>Liste von E-Mail Adressen</span>
          </div>
          <div class="level-right">
            <b-dropdown position="is-bottom-left">
              <template #trigger>
                <b-icon size="is-small" icon="question-circle"></b-icon>
              </template>

              <b-dropdown-item custom paddingless :focusable="false">
                <div class="address-format-info content p-4">
                  <p>
                    Mit dieser Funktion kann schnell eine Liste von E-Mail Adressen (z.B. im Format eines kopierten
                    E-Mail-Verteilers) eingeladen werden.
                  </p>
                  <p>
                    Zum Trennen der E-Mail Adressen bitte einen Zeilenumbruch, ein Komma, oder ein Semikolon verwenden.
                    Es können außerdem direkt die Namen der Personen direkt mit übernommen werden.
                  </p>
                  <strong>Mögliche Formate:</strong>
                  <pre>
Maria Musterfrau &lt;maria-m@organisation.de&gt;,
  Max Mustermann &lt;max-m@organisation.de&gt;
  Britta Beispiel britta.beispiel@organisation.de
  benutzer1@schule.de; benutzer2@schule.de
  benutzer3@schule.de</pre
                  >
                </div>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </template>
      <b-input
        v-model="input"
        type="textarea"
        value=""
        placeholder="Maria Musterfrau <maria.musterfrau@organisation.de>, max.mustermann@organisation.de, ..."
      ></b-input>
    </b-field>

    <b-field label="Gefundene Personen" v-if="parsedPersons.length" grouped group-multiline>
      <div class="control" v-for="person in parsedPersons" :key="person.email">
        <b-taglist attached>
          <b-tag type="is-info">{{ person.name }}</b-tag>
          <b-tag type="is-dark">{{ person.email }}</b-tag>
        </b-taglist>
      </div>
    </b-field>

    <p class="control">
      <b-button
        @click="onSubmit"
        expanded
        :disabled="parsedPersons.length === 0"
        size="is-small"
        type="is-primary"
        class="mb-4"
        label="Gefundene E-Mail Adressen übernehmen"
        icon-left="user-plus"
      />
    </p>
  </div>
</template>

<script>
import { capitalize } from 'lodash'
export default {
  data () {
    return {
      input: null
    }
  },
  computed: {
    parsedPersons () {
      if (!this.input?.length) {
        return []
      }

      const emailRegex =
        /(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/

      const parsedPersons = this.input
        .split(/[,;\n\r]+/g)
        .filter((v) => v.length !== 0)
        .filter((v) => emailRegex.test(v))
        .map((v) => {
          const email = v.match(emailRegex)[0]
          let name = v
            .replace(email, '')
            .replace(/[^A-Za-z0-9\s]/gi, '')
            .trim()
          if (name.length === 0) {
            name = email
              .substring(0, email.indexOf('@'))
              .replace(/[.]/gi, ' ')
              .split(' ')
              .map((v) => capitalize(v))
              .join(' ')
          }
          return {
            email,
            name
          }
        })
      return parsedPersons
    }
  },
  methods: {
    reset () {
      this.input = null
    },
    onSubmit () {
      this.$emit('submit', this.parsedPersons)
      this.reset()
    }
  }
}
</script>

<style lang="scss" scoped>
.address-format-info {
  width: 600px;
}
</style>
