<template>
  <div class="learned-button">
    <b-button ref="button" type="is-primary" icon-left="check" @click="onClick">Gelernt</b-button>
  </div>
</template>

<script>
export default {
  data () {
    return {
      isAnimating: false
    }
  },
  methods: {
    onClick () {
      if (this.isAnimating) {
        return
      }

      this.isAnimating = true
      this.$refs.button.$el.classList.add('is-animating')
      this.$refs.button.$el.classList.add('active')
      setTimeout(() => {
        this.$refs.button.$el.classList.remove('active')
        this.$refs.button.$el.classList.add('reset')

        setTimeout(() => {
          this.$refs.button.$el.classList.remove('reset')
          this.$refs.button.$el.classList.remove('is-animating')
          this.isAnimating = false
          this.$emit('click')
        }, 150)
      }, 350)
    }
  }
}
</script>

<style lang="scss" scoped>
$speed: '0.25s';
$transition: all #{$speed} cubic-bezier(0.31, -0.105, 0.43, 1.4);
.button::v-deep {
  overflow: hidden;
  span {
    position: relative;
    transition: $transition;
  }

  &.active {
    .icon {
      transform: translateX(43px) scale(1.9);
    }
    span:last-child {
      transform: translateX(120px);
    }
  }

  &.active {
    transition: $transition;
    overflow: visible;
    background: transparent !important;
    .icon {
      transition-delay: 0.1s;
      transform: translateX(100px) translateY(-40px) scale(5) rotate(15deg);
      opacity: 0;
    }
    span:last-child {
      opacity: 0;
      transition: none;
    }
  }

  &.reset {
    opacity: 0;
    .icon {
      transform: none;
    }
    span:last-child {
      transform: none;
    }
  }
}
</style>
