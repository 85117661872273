<template>
  <actions-dropdown
    :actions="documentActions"
    :type="type"
    :size="size"
    ref="actionsDropdown"
    @action="onDocumentAction($event, document)"
    :text="text"
    icon="ellipsis-h"
  />
</template>

<script>
import ActionsDropdown from '@/components/ActionsDropdown'
import { ResolvableImage } from '@custom-media/signdigital-web-shared/src/lib/resolvable-image'
import { DocumentReference } from '@custom-media/signdigital-lib/src/image-reference'

export default {
  components: {
    ActionsDropdown
  },
  props: {
    document: {
      type: Object
    },
    keepOverview: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'is-light'
    },
    size: {
      type: String,
      default: 'is-small'
    },
    edit: {
      type: Boolean,
      default: true
    },
    text: {
      type: String,
      default: null
    }
  },
  computed: {
    documentActions () {
      const actions = []
      if (this.edit !== false) {
        actions.push({ value: 'edit', icon: 'edit', text: 'Öffnen' })
      }
      actions.push({ value: 'reveal', icon: 'folder-tree', text: 'In „Organisieren” anzeigen' })
      actions.push({ value: 'rename', icon: 'comment-edit', text: 'Umbenennen' })
      actions.push({ value: 'move', icon: 'level-down', text: 'Verschieben' })
      actions.push({ value: 'duplicate', icon: 'clone', text: 'Duplizieren' })
      actions.push({ value: 'pdf', icon: 'file-pdf', text: 'PDF anzeigen' })
      actions.push({ value: 'delete', icon: 'trash', text: 'Löschen' })
      return actions
    }
  },
  methods: {
    async onDocumentAction (action, document) {
      if (action === 'pdf') {
        return this.openPDF(document)
      } else if (action === 'edit') {
        return this.openDocument(document)
      }
      const item = await this.$store.dispatch('fileNodeTree/maybeFetchInPath', this.document.node)
      switch (action) {
        case 'reveal':
          this.$router.push({
            name: 'file-browser',
            query: {
              c: item.parentId,
              s: item._id
            }
          })
          return
        case 'rename':
          await this.$fileDialog.rename({ item, parent: this })
          await this.$store.dispatch('documents/get', this.document._id)
          return
        case 'move':
          await this.$fileDialog.move({ item, parent: this })
          await this.$store.dispatch('documents/get', this.document._id)
          return
        case 'duplicate': {
          const duplicate = await this.$fileDialog.duplicate({ item, parent: this })
          await this.$store.dispatch('documents/get', duplicate.file)
          return
        }
        case 'delete':
          return this.$fileDialog.delete({ item, parent: this })
      }
    },
    async openPDF (document) {
      const pdf = new ResolvableImage({
        $store: this.$store,
        $resolver: this.$resolver,
        reference: new DocumentReference({
          objectId: document._id,
          service: 'documents'
        }),
        variant: 'original'
      })
      const w = window.open('', '_blank')
      w.focus()
      const url = await pdf.resolve()
      w.location.href = url
    },
    async openDocument (document) {
      this.$router.push({ name: 'document-editor', params: { id: document._id } })
    }
  }
}
</script>
