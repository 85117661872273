<template>
  <div class="file-node-actions">
    <b-dropdown aria-role="list" position="is-bottom-left" append-to-body>
      <template #trigger="{}">
        <b-button icon-left="ellipsis-h" size="is-small" :type="type" :outlined="outlined" @click.prevent />
      </template>

      <b-dropdown-item aria-role="listitem" @click="onAction('play')" v-if="can('play')"
        ><b-icon icon="play" />Öffnen</b-dropdown-item
      >
      <b-dropdown-item aria-role="listitem" @click="onAction('edit')" v-if="can('edit')"
        ><b-icon icon="edit" />Bearbeiten</b-dropdown-item
      >
      <b-dropdown-item aria-role="listitem" @click="onAction('open')" v-if="can('open')"
        ><b-icon :icon="item.fileNode.nodeType === 'file' ? 'edit' : 'folder-open'" />Öffnen</b-dropdown-item
      >
      <b-dropdown-item aria-role="listitem" @click="onAction('rename')" v-if="can('rename')"
        ><b-icon icon="comment-edit" />Umbenennen</b-dropdown-item
      >
      <b-dropdown-item aria-role="listitem" @click="onAction('duplicate')" v-if="can('duplicate')"
        ><b-icon icon="clone" />Duplizieren</b-dropdown-item
      >
      <b-dropdown-item aria-role="listitem" @click="onAction('move')" v-if="can('move')"
        ><b-icon icon="level-down" />Verschieben</b-dropdown-item
      >
      <b-dropdown-item aria-role="listitem" @click="onAction('print')" v-if="can('print')"
        ><b-icon icon="print" />Drucken</b-dropdown-item
      >
      <b-dropdown-item aria-role="listitem" @click="onAction('pdf')" v-if="can('pdf')"
        ><b-icon icon="file-pdf" />PDF anzeigen</b-dropdown-item
      >
      <b-dropdown-item aria-role="listitem" @click="onAction('download')" v-if="can('download')"
        ><b-icon icon="cloud-download" />Herunterladen</b-dropdown-item
      >
      <b-dropdown-item aria-role="listitem" @click="onAction('delete')" v-if="can('delete')"
        ><b-icon icon="trash" />Löschen</b-dropdown-item
      >
    </b-dropdown>
  </div>
</template>

<script>
export default {
  props: {
    item: Object,
    outlined: {
      type: Boolean,
      default: true
    },
    type: {
      type: String,
      default: 'is-dark'
    },
    actions: {
      type: Array,
      default () {
        return ['open', 'rename', 'duplicate', 'move', 'delete']
      }
    },
    hiddenActions: {
      type: Array,
      default: null
    }
  },
  methods: {
    can (action) {
      if (!this.actions.includes(action)) {
        return false
      }

      if (this.hiddenActions && this.hiddenActions.includes(action)) {
        return false
      }

      return true
    },
    onAction (action) {
      this.$emit('action', { action })
    }
  }
}
</script>
