<template>
  <div class="saving-indicator">
    <transition name="fade" mode="out-in">
      <!-- Is saving -->
      <span class="tag is-light is-loading" v-if="saving">
        <span class="text">Speichern..</span>
        <span class="loader is-loading"></span>
      </span>

      <!-- Saved at -->
      <b-tooltip
        :label="'Am ' + $options.filters.dateTimeAt(savedAt) + ' gespeichert'"
        type="is-light"
        position="is-left"
        v-else-if="!saving && savedAt && !hasChanges"
      >
        <span class="tag is-light">
          <span class="text">Gespeichert</span>
          <b-icon icon="check" class="animate__animated animate__fadeInDown" />
        </span>
      </b-tooltip>

      <!-- Unsaved changes -->
      <span class="tag is-light" v-else-if="!saving && hasChanges">
        <span class="text">Bearbeitet</span>
        <b-icon icon="pencil" class="animate__animated animate__pulse" />
      </span>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    saving: Boolean,
    hasChanges: Boolean,
    savedAt: String
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/bulma-variables.scss';
@include until($widescreen) {
  .text {
    display: none;
  }
  .tag > .icon {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.loader.is-loading {
  position: relative;
  pointer-events: none;
  margin-left: 1em;
  &:after {
    @include loader;
    position: absolute;
    top: calc(50% - 0.5em);
    left: calc(50% - 0.5em);
    width: 1em;
    height: 1em;
    border-width: 0.25em;
  }
}
</style>
