var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"password-reset-form"},[_c('validation-observer',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('validation-provider',{attrs:{"name":"Neues Passwort","bails":false,"rules":"required|min:8|passwordNumber"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"label":"Neues Passwort","type":{ 'is-danger': errors.length > 0, 'is-primary': valid }}},[_c('template',{slot:"message"},[_c('ul',_vm._l((errors),function(error,index){return _c('li',{key:index},[_vm._v(_vm._s(error))])}),0)]),_c('b-input',{attrs:{"id":"new-password","autocomplete":"new-password","name":"new-password","type":"password","placeholder":"Neues Passwort","password-reveal":""},model:{value:(_vm.newPassword),callback:function ($$v) {_vm.newPassword=$$v},expression:"newPassword"}})],2)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Passwort-Wiederholung","rules":"required|match:@Neues Passwort"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{staticClass:"mt-2",attrs:{"label":"Neues Passwort wiederholen","type":{ 'is-danger': errors.length > 0 },"message":errors[0]}},[_c('b-input',{attrs:{"name":"new-password-confirmation","type":"password","placeholder":"Passwort wiederholen","password-reveal":""},model:{value:(_vm.newPasswordConfirmation),callback:function ($$v) {_vm.newPasswordConfirmation=$$v},expression:"newPasswordConfirmation"}})],1)]}}],null,true)}),(_vm.oldPassword)?_c('div',{staticClass:"pt-3"},[_c('hr'),_c('validation-provider',{attrs:{"name":"Bisheriges Passwort","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{staticClass:"mt-2",attrs:{"label":"Bisheriges Passwort","type":{ 'is-danger': errors.length > 0 },"message":errors[0]}},[_c('b-input',{attrs:{"name":"old-password","type":"password","placeholder":"Bisheriges Passwort","password-reveal":""},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1)]}}],null,true)})],1):_vm._e(),(_vm.submit)?_c('b-button',{staticClass:"mt-5",attrs:{"native-type":"submit","type":"is-primary","disabled":invalid,"expanded":""}},[_vm._v("Passwort ändern")]):_vm._e()],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }