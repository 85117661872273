<template>
  <div class="sign-details" :style="windowStyle">
    <!-- Navbar -->
    <b-navbar class="top-bar" type="is-dark" :mobile-burger="false" v-if="hasTopBar">
      <template slot="brand">
        <b-navbar-item tag="div" v-if="showTitle">
          <h1 class="subtitle has-text-white">
            <revealable-text
              :title="!$screen.tablet ? 'Name' : 'Namen der Gebärde'"
              v-if="isTitleRevealable"
              :value="isTitleRevealed"
              @input="reveal('title')"
            >
              {{ sign.name }}
            </revealable-text>
            <span v-else>{{ sign.name }}</span>
          </h1>
        </b-navbar-item>
      </template>
      <template slot="start">
        <b-navbar-item tag="div">
          <!-- DGS tooltip -->
          <dgs-tooltip :sign="sign" :has-icon="true" />
        </b-navbar-item>
      </template>
      <template slot="end">
        <b-navbar-item tag="div">
          <router-link
            v-if="newTabButton !== false && sign.slug"
            :to="{ name: 'sign-details-public', params: { slug: sign.slug } }"
            tag="a"
            target="_blank"
            rel="noopener noreferrer"
          >
            <b-button type="is-white" outlined icon-left="external-link"></b-button>
          </router-link>
        </b-navbar-item>
        <b-navbar-item tag="div">
          <b-dropdown aria-role="list" custom position="is-bottom-left" v-if="shareButton !== false">
            <b-button
              v-if="sign.slug"
              type="is-white"
              outlined
              icon-left="share-alt"
              slot="trigger"
              slot-scope="{ active }"
            >
              <span>Teilen</span>
              <b-icon :icon="active ? 'caret-up' : 'caret-down'"></b-icon>
            </b-button>
            <share-sign-link-menu :slug="sign.slug"></share-sign-link-menu>
          </b-dropdown>
        </b-navbar-item>
        <b-navbar-item tag="div">
          <b-button
            v-if="$parent.close"
            @click="$parent.close()"
            class="close-button"
            icon-left="times"
            type="is-white"
            outlined
            >Schließen</b-button
          >
        </b-navbar-item>
      </template>
    </b-navbar>
    <div class="sign-details-content" :class="{ 'is-collapsed': !showSidebar }">
      <div class="dynamic-container">
        <!-- Content -->
        <div class="columns is-gapless">
          <!-- Video column -->
          <div class="column video">
            <div v-show="!isVideoRevealable || isVideoRevealed">
              <!-- Video player -->
              <video-player :src="signVideoUrl" ref="videoPlayer" />
              <!-- Video controls -->
              <video-controls
                v-if="videoControls !== false && $refs.videoPlayer"
                :controls="videoControls"
                :video-player="$refs.videoPlayer"
              />
            </div>
            <div v-if="isVideoRevealable && !isVideoRevealed" class="video-reveal">
              <b-image ratio="16by9"></b-image>
              <div class="button-container">
                <b-button @click="reveal('video')" icon-right="sparkles" type="is-primary"
                  >Gebärdenvideo aufdecken</b-button
                >
              </div>
            </div>
          </div>

          <!-- Spacer column -->
          <div class="column spacer">
            <!-- Sidebar toggle -->
            <b-button
              :icon-left="sidebarIcon"
              type="is-dark"
              size="is-small"
              @click="showSidebar = !showSidebar"
            ></b-button>
          </div>

          <!-- Sign sidebar -->
          <div class="column sidebar">
            <div class="sidebar-content" :class="{ extended: showSidebar }">
              <div v-if="!isImageRevealable || isImageRevealed">
                <!-- Sign images -->
                <div class="image-wrapper relative">
                  <image-component v-if="signBoxImage" v-show="previewFormat === 'signBox'" :image="signBoxImage" />
                  <image-component v-if="signMapImage" v-show="previewFormat === 'signMap'" :image="signMapImage" />
                  <image-component v-if="signOnlyImage" v-show="previewFormat === 'signOnly'" :image="signOnlyImage" />
                  <b-icon
                    v-if="unlicensedContent && (signBoxImage || signMapImage)"
                    v-show="previewFormat === 'signBox' || previewFormat === 'signMap'"
                    icon="lock"
                    class="locked"
                    type="is-primary"
                    custom-size="fa-5x"
                  />
                </div>
                <!-- Switch image format -->
                <image-format-switch
                  v-model="previewFormat"
                  size="is-small"
                  :label="false"
                  expanded
                  :text="false"
                  on-dark
                />
              </div>
              <div v-if="isImageRevealable && !isImageRevealed" class="image-reveal">
                <b-button @click="reveal('image')" icon-right="sparkles" type="is-primary"
                  >Zeichnung aufdecken</b-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImageFormatSwitch from '@custom-media/signdigital-web-shared/src/components/ImageFormatSwitch'
import DgsTooltip from '@custom-media/signdigital-web-shared/src/components/DgsTooltip.vue'
import { ResolvableImage } from '@custom-media/signdigital-web-shared/src/lib/resolvable-image'
import { SignImageReference } from '@custom-media/signdigital-lib/src/image-reference'
import ImageComponent from '@custom-media/signdigital-web-shared/src/components/ImageComponent.vue'
import RevealableText from '@/components/RevealableText.vue'
import VideoPlayer from '@custom-media/signdigital-web-shared/src/components/VideoPlayer'
import VideoControls from '@custom-media/signdigital-web-shared/src/components/VideoControls'
import windowHeightMixin from '@/mixins/window-height-mixin'
import ShareSignLinkMenu from '@/components/ShareSignLinkMenu.vue'

// TODO: Measure time until video loaded and maybe switch to lowquality mode
// TODO: Add loading indicator / skeleton for video & images

export default {
  mixins: [windowHeightMixin],
  components: {
    VideoPlayer,
    VideoControls,
    ImageFormatSwitch,
    ImageComponent,
    DgsTooltip,
    RevealableText,
    ShareSignLinkMenu
  },
  props: {
    sign: Object,
    revealable: {
      type: Array,
      required: false,
      default: () => []
    },
    revealed: {
      type: Array,
      required: false,
      default: () => []
    },
    showTitle: {
      type: Boolean,
      default: true
    },
    topBar: {
      type: Boolean,
      default: true
    },
    fullscreen: {
      type: Boolean,
      default: true
    },
    unlicensedContent: {
      type: Boolean,
      default: false
    },
    videoControls: {
      type: [Boolean, Array],
      default: true
    },
    shareButton: {
      type: Boolean,
      default: true
    },
    newTabButton: {
      type: Boolean,
      default: true
    },
    isBelowNavbar: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      src: null,
      showSidebar: true,
      previewFormat: this.unlicensedContent ? 'signOnly' : 'signBox',
      signBoxImage: null,
      signMapImage: null,
      signOnlyImage: null,
      signVideoUrl: null
    }
  },
  computed: {
    isModal () {
      return this.$parent.close
    },
    supportsRevealables () {
      return this.unlicensedContent === false
    },
    isTitleRevealable () {
      return this.supportsRevealables && this.revealable.includes('title')
    },
    isVideoRevealable () {
      return this.supportsRevealables && this.revealable.includes('video')
    },
    isImageRevealable () {
      return this.supportsRevealables && this.revealable.includes('image')
    },
    isTitleRevealed () {
      return !this.supportsRevealables || this.revealed.includes('title')
    },
    isVideoRevealed () {
      return !this.supportsRevealables || this.revealed.includes('video')
    },
    isImageRevealed () {
      return !this.supportsRevealables || this.revealed.includes('image')
    },
    hasTopBar () {
      return this.topBar !== false && (this.isModal || this.showTitle || this.shareButton || this.newTabButton)
    },
    sidebarIcon () {
      return this.showSidebar ? 'arrow-from-left' : 'arrow-from-right'
    },
    isDGS () {
      return this.sign?.name?.includes('DGS')
    },
    windowStyle () {
      if (!this.fullscreen) {
        return null
      }

      return this.isBelowNavbar ? this.windowHeightCSSWithoutNavbar : this.windowHeightCSS
    }
  },
  watch: {
    sign (newValue, oldValue) {
      if (this.sign == null) {
        return
      }
      if (newValue?._id === oldValue?._id) {
        return
      }
      this.getResolvableImages()
      if (this.isVideoRevealable && !this.isVideoRevealed) {
        this.$refs.videoPlayer.isPlaying = false
      }
      if (this.revealable.length > 0) {
        this.$refs.videoPlayer.isMuted = true
      }
    },
    revealed (newValue, oldValue) {
      if (!oldValue?.includes('video') && newValue.includes('video')) {
        this.$refs.videoPlayer.isPlaying = true
      }
    }
  },
  created () {
    this.getResolvableImages()
  },
  mounted () {
    if (this.isVideoRevealable && !this.isVideoRevealed) {
      this.$refs.videoPlayer.isPlaying = false
    }
    if (this.revealable.length > 0) {
      this.$refs.videoPlayer.isMuted = true
    }
  },
  methods: {
    async getResolvableImages () {
      this.signBoxImage = new ResolvableImage({
        $store: this.$store,
        $resolver: this.$resolver,
        reference: new SignImageReference({ objectId: this.sign._id, imageType: 'signBox' }),
        instance: this.sign,
        variant: this.unlicensedContent ? 'tiny' : 'default'
      })
      this.signMapImage = new ResolvableImage({
        $store: this.$store,
        $resolver: this.$resolver,
        reference: new SignImageReference({ objectId: this.sign._id, imageType: 'signMap' }),
        instance: this.sign,
        variant: this.unlicensedContent ? 'tiny' : 'default'
      })
      this.signOnlyImage = new ResolvableImage({
        $store: this.$store,
        $resolver: this.$resolver,
        reference: new SignImageReference({ objectId: this.sign._id, imageType: 'signOnly' }),
        instance: this.sign,
        variant: this.unlicensedContent ? 'watermarked' : 'default'
      })
      this.signVideoUrl = await new ResolvableImage({
        $store: this.$store,
        $resolver: this.$resolver,
        reference: new SignImageReference({
          objectId: this.sign._id,
          imageType: 'signVideo'
        }),
        variant: this.unlicensedContent ? 'watermarked' : 'default'
      }).resolve()
    },
    reveal (property) {
      this.$emit('update:revealed', [...this.revealed, property])
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/bulma-variables.scss';

.video-reveal {
  display: flex;
  height: 100%;
  background-color: $light;
  justify-content: center;
  align-items: center;
  position: relative;
  .button-container {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.image-reveal {
  display: flex;
  height: 100%;
  background: $light;
  justify-content: center;
  align-items: center;
}
.sign-details {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;

  .subtitle {
    color: white;
    margin-bottom: 0;
  }

  .sign-details-content {
    width: 100%;
    overflow: hidden;

    .dynamic-container {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  .column.spacer {
    display: none;
    justify-content: center;
    align-items: center;
  }
  .column.sidebar {
    .sidebar-content {
      background: white;
      border-radius: $sm-radius;
      padding: 0.5em;
      display: block;
      height: 100%;
      width: 100%;
      overflow: hidden;

      .image-wrapper {
        margin: 0 auto;
        text-align: center;
      }
    }
  }

  // Desktop layout
  @include from($tablet) {
    overflow: hidden;
    .is-collapsed .column.sidebar {
      display: none;
    }

    .sign-details-content {
      padding: 1em;
      display: flex;
      align-items: center;
      height: 100%;
      margin-bottom: 70px;

      .columns {
        max-height: 100%;
        width: 100%;

        .column.video {
          flex-basis: 160px;
          flex-grow: 16;
          flex-shrink: 16;
          display: block;
          height: auto;
          width: 100%;

          & > div {
            overflow: hidden;
            width: 100%;
            height: 100%;
          }
        }
        .column.spacer {
          display: flex;
          flex-basis: 10px;
          flex-grow: 1;
          flex-shrink: 1;
        }
        .column.sidebar {
          flex-basis: 50px;
          flex-grow: 5;
          flex-shrink: 5;

          .sidebar-content {
            height: 100%;
          }
        }
      }

      .video-controls {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }
  }

  .navbar.top-bar {
    .navbar-brand .navbar-item {
      .title,
      .subtitle {
        white-space: nowrap;
      }
    }
    @include until($desktop) {
      display: flex;
      flex-wrap: wrap;

      &::v-deep {
        .navbar-menu {
          flex-grow: 1;
          display: flex;
          background: transparent;
          align-items: stretch;
          .navbar-brand,
          .navbar-brand .navbar-item {
            flex-shrink: 1;
          }
          .navbar-start {
            display: none;
          }
          .navbar-end {
            justify-content: flex-end;
            margin-left: auto;
          }
        }
      }
    }
  }
}

.button-group {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.costs {
  padding: 0 1.5rem;
}

.relative {
  position: relative;
}

.locked {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: auto;

  filter: drop-shadow(0 4px 3px rgb(0 0 0 / 0.07)) drop-shadow(0 2px 2px rgb(0 0 0 / 0.06));
}
</style>
