<template>
  <div class="list-editor" v-if="list">
    <b-navbar class="app-nav is-mobile" :mobile-burger="false" :active="true">
      <template slot="brand">
        <b-navbar-item tag="div">
          <nav class="breadcrumb" aria-label="breadcrumbs">
            <ul>
              <li><router-link :to="{ name: 'lists' }">Lernlisten</router-link></li>
              <li>
                <editable-button
                  :disabled="isTemplate || isSharedList"
                  :value="list.name"
                  @input="onTitleChanged"
                  submit-label="Umbenennen"
                />
              </li>
            </ul>
          </nav>
        </b-navbar-item>
      </template>
      <template slot="start"> </template>
      <template slot="end">
        <b-navbar-item tag="div" v-if="!isTemplate && !isSharedList">
          <b-dropdown aria-role="list" custom position="is-bottom-left">
            <b-button
              v-if="!isSharedList"
              type="is-white"
              size="is-small"
              icon-left="share"
              slot="trigger"
              slot-scope="{ active }"
            >
              <span>Teilen</span>
              <b-icon :icon="active ? 'caret-up' : 'caret-down'"></b-icon>
            </b-button>
            <share-link-menu :list="list" />
          </b-dropdown>
        </b-navbar-item>
        <b-navbar-item tag="div">
          <!-- APPEARANCE -->
          <b-dropdown aria-role="list" custom position="is-bottom-left">
            <b-button type="is-white" size="is-small" icon-left="cog" slot="trigger" slot-scope="{ active }">
              <span>Ansicht</span>
              <b-icon :icon="active ? 'caret-up' : 'caret-down'"></b-icon>
            </b-button>

            <list-editor-appearance-settings />
          </b-dropdown>
        </b-navbar-item>

        <b-navbar-item tag="div" v-if="isTemplate || isSharedList">
          <b-button @click="onSaveTemplate" :loading="isCreatePending" type="is-primary">Abspeichern</b-button>
        </b-navbar-item>

        <b-navbar-item tag="div" v-if="list && list.signs && !isTemplate">
          <router-link
            :disabled="list.signs.length === 0"
            :to="linkToPlayer"
            tag="a"
            :class="['button', isSharedList ? 'is-light' : 'is-primary']"
          >
            <b-icon icon="play" class="mr-1"></b-icon>Öffnen</router-link
          >
        </b-navbar-item>

        <b-navbar-item tag="div">
          <router-link :to="exitRoute" tag="a" class="button is-light"> Schließen </router-link>
        </b-navbar-item>
      </template>
    </b-navbar>

    <b-message v-if="isTemplate" type="is-primary" class="mx-6 my-3">
      <p>
        Du betrachtest gerade eine vorgefertigte Lernliste. Du kannst eine Kopie als deine Lernliste abspeichern und
        anschließend bearbeiten.
      </p>
      <b-button @click="onSaveTemplate" :loading="isCreatePending" expanded class="mt-5" type="is-primary"
        >Als meine Lernliste abspeichern</b-button
      >
    </b-message>
    <b-message v-if="isSharedList && sharedList" type="is-primary" class="mx-6 my-3">
      <p>
        <span v-if="sharedList.authorFullname">
          „{{ sharedList.authorFullname }}” hat dir diese Lernliste auf SIGNdigital freigegeben.
        </span>
        <span v-else>Du betrachtest eine Lernliste die auf SIGNdigital freigegeben wurde. </span>

        <span>Du kannst eine Kopie als deine Lernliste abspeichern und anschließend bearbeiten.</span>
      </p>
      <b-button @click="onSaveTemplate" :loading="isCreatePending" expanded class="mt-5" type="is-primary"
        >Als meine Lernliste abspeichern</b-button
      >
    </b-message>

    <b-message v-if="isDuplicateHintShown" type="is-primary" class="mx-6 my-3" title="Datei aus Team-Ordner">
      <p>
        Du betrachtest gerade eine Lernliste, die von einem anderen Team-Mitglied erstellt wurde. Du kannst sie zwar
        bearbeiten, veränderst dadurch aber die Originaldatei im Team-Ordner.
      </p>
      <p>Wir empfehlen, eine Kopie in deinem persönliche Ordner zu speichern und die Kopie zu bearbeiten.</p>
      <b-button @click="onCreateCopy" expanded class="mt-5" type="is-primary">Kopie erstellen</b-button>
    </b-message>

    <section class="editor">
      <div class="container p-5">
        <div class="level">
          <div class="level-left">
            <h2 class="subtitle">Gebärden in dieser Lernliste</h2>
          </div>
          <div class="level-right">
            <b-button
              class="is-hidden-print"
              type="is-primary"
              size="is-small"
              :disabled="isTemplate || isSharedList"
              icon-left="plus"
              @click="onAddButtonClicked"
              >Gebärde hinzufügen</b-button
            >
          </div>
        </div>
        <b-table
          :data="list.signs"
          :draggable="!isTemplate && !isSharedList"
          @dragstart="dragstart"
          @drop="drop"
          @dragover="dragover"
          @dragleave="dragleave"
        >
          <b-table-column label="Gebärde" v-slot="{ index }">
            <image-component :image="images[index]" :style="imageSizeStyle" />
          </b-table-column>

          <b-table-column label="Name" v-slot="{ row }" field="name" sortable>{{ row.name }}</b-table-column>

          <b-table-column label="Erlernt" v-slot="{ row }">
            <b-checkbox
              :value="isLearned(row)"
              @input="onIsLearnedToggled(row)"
              :disabled="isTemplate || isSharedList"
            />
          </b-table-column>

          <b-table-column cell-class="is-hidden-print" header-class="is-hidden-print" label="Aktionen" v-slot="{ row }">
            <b-button
              type="is-danger"
              size="is-small"
              icon-left="trash"
              @click="onRemoveButtonClicked(row)"
              :disabled="isTemplate || isSharedList"
              >Entfernen</b-button
            >
          </b-table-column>

          <template #empty>
            <div class="content has-text-centered">
              <b-icon icon="ellipsis-h" size="is-large" type="is-light" />
              <p>Diese Lernliste ist noch leer</p>
            </div>
          </template>
        </b-table>
      </div>
    </section>
  </div>
</template>

<script>
import EditableButton from '@/components/EditableButton.vue'
import ListEditorAppearanceSettings from '@/components/ListEditorAppearanceSettings.vue'
import ImageComponent from '@custom-media/signdigital-web-shared/src/components/ImageComponent.vue'
import ShareLinkMenu from '@/components/ShareLinkMenu.vue'
import { mapActions, mapState } from 'vuex'
import { SignImageReference } from '@custom-media/signdigital-lib/src/image-reference'
import { ResolvableImage } from '@custom-media/signdigital-web-shared/src/lib/resolvable-image'

export default {
  components: {
    EditableButton,
    ImageComponent,
    ListEditorAppearanceSettings,
    ShareLinkMenu
  },
  data () {
    return {
      list: {},
      template: null,
      sharedList: null,
      draggedRowIndex: null,
      closedDuplicateHint: false
    }
  },
  mounted () {
    this.init()
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('lists', ['isPatchPending', 'isCreatePending']),
    ...mapState('preferences', { listEditorPreferences: 'listEditor' }),
    hasActiveSubscription () {
      return this.$store.getters['auth/user']?.hasActiveSubscription
    },
    isLoggedIn () {
      return !!this.$store.getters['auth/user']
    },
    exitRoute () {
      return this.$store.state.session.exitRoute?.lists ?? { name: 'lists' }
    },
    isTemplate () {
      return !!this.$route.query?.template
    },
    isSharedList () {
      return !!this.$route.params?.shareKey
    },
    isOwnedByTeam () {
      return this.list.ownerType === 'teams'
    },
    isUserAuthor () {
      return this.list.author === this.$store.state.auth.user._id
    },
    isDuplicateHintShown () {
      return this.isOwnedByTeam && !this.isUserAuthor && !this.closedDuplicateHint
    },
    images () {
      return this.list.signs.map((s) => {
        return new ResolvableImage({
          $store: this.$store,
          $resolver: this.$resolver,
          reference: new SignImageReference({
            objectId: s._id ?? s.__id,
            imageType: 'signOnly'
          }),
          variant: 'default'
        })
      })
    },
    imageSizeStyle () {
      const s = this.listEditorPreferences?.imageSize ?? 'large'
      const em = { large: 16, medium: 8, small: 4 }[s]
      return {
        'max-width': `${em}rem`,
        'max-height': `${em}rem`
      }
    },
    linkToPlayer () {
      const list = this.sharedList ?? this.list
      if (list.signs.length === 0) {
        return null
      }
      if (this.isSharedList) {
        return { name: 'list-player-setup-shared', params: { shareKey: this.$route.params.shareKey } }
      } else {
        return { name: 'list-player-setup', params: { id: list._id } }
      }
    }
  },
  beforeRouteLeave (to, from, next) {
    // Close search dropdowns
    document.querySelectorAll('.search-dropdown').forEach((modal) => {
      modal.__vue__?.$vnode?.context?.close()
    })
    next()
  },
  methods: {
    ...mapActions('lists', {
      getList: 'get'
    }),
    async init () {
      this.template = null
      this.list = null
      this.sharedList = null

      if (this.$route.params.id == null) {
        if (this.$route.query.template != null) {
          this.template = await this.$store.dispatch('list-templates/get', this.$route.query.template)
        } else if (this.$route.params.shareKey != null) {
          const { List } = this.$FeathersVuex.api
          const res = await List.find({ query: { shareKey: this.$route.params.shareKey } })
          this.sharedList = res.data[0]

          if (this.$store.getters['auth/user']?.hasWriteAccess(this.sharedList)) {
            // Is owned document, redirect to editor
            this.$router.replace({
              name: 'list-editor',
              params: {
                id: this.sharedList._id
              }
            })
          }
        } else {
          this.$router.replace({ name: 'lists' })
          return
        }
        // Create placeholder list
        const { List } = this.$FeathersVuex.api
        this.list = new List({
          name: this.template?.name ?? this.sharedList?.name ?? 'Unbenannte Lernliste',
          owner: this.user._id,
          signs: this.template?.signs ?? this.sharedList?.signs ?? []
        })
      } else {
        // Load list
        this.list = await this.getList(this.$route.params.id)
        this.$store.dispatch('preferences/trackRecentlyUsed', { fileType: 'lists', id: this.$route.params.id })
      }
    },
    async onSaveTemplate () {
      const { file } = await this.$fileDialog.create({
        nodeType: 'file',
        fileType: 'lists',
        filename: this.list.name,
        parent: this,
        fileData: {
          signs: this.template?.signs ?? this.sharedList?.signs ?? []
        }
      })
      this.$router.replace({ name: 'list-editor', params: { id: file._id } })
      this.init()
    },
    async save () {
      await this.list.save()
      if (this.$route.params.id !== this.list._id) {
        this.$router.replace({ name: 'list-editor', params: { id: this.list._id || this.list.__id } })
      }
    },
    //
    // USER INPUT
    //
    async onTitleChanged (newTitle) {
      try {
        await this.$store.dispatch('lists/patch', [this.list._id, { name: newTitle }])
        this.list.name = newTitle
      } catch (error) {
        this.$buefy.dialog.alert({
          title: 'Fehler beim Umbenennen',
          type: 'is-danger',
          icon: 'triangle-exclamation',
          message:
            error?.code === 409
              ? `Am Speicherort dieser Lernliste existiert bereits eine Datei mit dem Namen „${newTitle}”.<br/><br/> Bitte wähle einen anderen Namen.`
              : error
        })
      }
    },

    async rename ({ state, dispatch, commit }, name) {
      const res = await dispatch('lists/patch', [state._id, { name }], { root: true })
      state.name = name
      await dispatch('file-nodes/get', res.node, { root: true })
      await dispatch('fileNodeTree/rebuild', null, { root: true })
    },
    async onAddButtonClicked (event) {
      const image = await this.$pickImage({
        target: event.target,
        propsData: {
          allowedContentTypes: ['signs'],
          allowedSignImageTypes: ['signOnly']
        }
      })
      if (image == null) {
        return
      }
      const { Sign } = this.$FeathersVuex.api
      const sign = await Sign.get(image.objectId)
      const alreadyAdded = this.list.signs.some((s) => s._id === sign._id)
      if (alreadyAdded) {
        this.$buefy.toast.open({
          message: 'Bereits in Lernliste enthalten',
          type: 'is-warning'
        })
        return
      }
      this.list.signs.push(sign)
      this.save()
    },
    async onRemoveButtonClicked (sign) {
      const i = this.list.signs.findIndex((s) => s._id === sign._id)
      if (i !== -1) {
        this.list.signs.splice(i, 1)
      }
      const si = this.list.learnedSigns.findIndex((s) => s._id === sign._id)
      if (si !== -1) {
        this.list.learnedSigns.splice(si, -1)
      }
      this.save()
    },
    isLearned (sign) {
      return this.list.learnedSigns.includes(sign._id)
    },
    onIsLearnedToggled (sign) {
      const i = this.list.learnedSigns.indexOf(sign._id)
      if (i !== -1) {
        this.list.learnedSigns.splice(i, 1)
      } else {
        this.list.learnedSigns.push(sign._id)
      }
      this.save()
    },
    // Drag & Drop rows
    dragstart (payload) {
      this.draggedRowIndex = payload.index
      payload.event.dataTransfer.effectAllowed = 'copy'
    },
    dragover (payload) {
      payload.event.dataTransfer.dropEffect = 'copy'
      payload.event.target.closest('tr').classList.add('is-selected')
      payload.event.preventDefault()
    },
    dragleave (payload) {
      payload.event.target.closest('tr').classList.remove('is-selected')
      payload.event.preventDefault()
    },
    drop (payload) {
      payload.event.target.closest('tr').classList.remove('is-selected')
      const sourceIndex = this.draggedRowIndex
      const targetIndex = payload.index
      // this.$buefy.toast.open(`Moved ${this.draggingRow.first_name} from row ${this.draggingRowIndex + 1} to ${droppedOnRowIndex + 1}`)
      this.list.signs.splice(targetIndex, 0, this.list.signs.splice(sourceIndex, 1)[0])
      this.save()
    },
    async onCreateCopy () {
      await this.$store.dispatch('fileNodeTree/maybeFetchInPath', this.list.node)
      const originalItem = this.$store.state.fileNodeTree.itemsById[this.list.node]
      const node = await this.$fileDialog.duplicate({
        item: originalItem,
        currentItem: this.$store.getters['fileNodeTree/userItem'],
        parent: this
      })

      const listId = node.file
      this.$router.replace({ name: 'list-editor', params: { id: listId } })
      this.init()
    }
  }
}
</script>

<style lang="scss" scoped>
figure.sign-image {
  max-width: 120px;
}

.b-table::v-deep {
  table.table {
    td {
      vertical-align: middle;
    }
  }
}
</style>
