<template>
  <div class="card" v-if="license">
    <header class="card-header">
      <p class="card-header-title">
        <span>{{ license.title }}</span>
        <b-tag class="ml-2" :type="$options.filters.subscriptionStatusClass(subscription.status)">
          {{ subscription.status | subscriptionStatus }}</b-tag
        >
      </p>
    </header>
    <div class="card-content">
      <div class="content">
        <!-- Subscription is pending approval -->
        <b-message v-if="subscription.status === 'pending-approval'" type="is-warning">
          <p>
            Diese Bestellung auf Rechnung muss erst noch überprüft werden. Bis dahin ist der SIGNdigital-Zugang noch
            nicht freigeschaltet.
          </p>
          <p>Nach erfolgreicher Überprüfung erhältst du eine Benachrichtigung und die Rechnung per E-Mail.</p>
          <p v-if="hasTeamLicense">
            Im Anschluss kann auch ein Team erstellt werden und Teammitglieder zur Mitbenutzung der Lizenz eingeladen
            werden.
          </p>
        </b-message>

        <!-- Auto reneval deactivated -->
        <b-message v-if="subscription.cancelAtPeriodEnd && !isCanceled && !renewal" type="is-warning">
          <p>
            Die automatische Verlängerung dieser Lizenz ist ausgeschaltet. Dein SIGNdigital Zugang wird Am
            {{ subscription.periodEnd | dateTimeAt }} beendet.<br />
          </p>
          <p v-if="subscription.subscriptionType === 'stripe'">
            Wenn du dich danach wieder einloggen möchtest, kannst du sofort eine neue Lizenz deiner Wahl erwerben. Deine
            Nutzerdaten, z.B. Druckvorlagen oder Lernlisten, bleiben dir vollständig erhalten
          </p>
          <p v-else>
            Lizenzen mit Rechnungszahlung <strong>verlängern sich nicht automatisch</strong>.<br />
            Wir erinnern dich 30 Tage bevor die Lizenz abläuft per E-Mail.<br />Schreib uns rechtzeitig an
            <a
              :href="
                'mailto:info@sign-digital.de?subject=Verlängerung SIGNdigital Lizenz ' +
                $store.getters['auth/user'].email
              "
              >info@sign-digital.de</a
            >
            ob die Lizenz verlängert werden soll und wir stellen eine Rechnung aus. Vergiss nicht uns zu schreiben
            welche Lizenz (Größe und Dauer, siehe
            <a href="https://sign-digital.de/#signdigital-preise" target="_blank">sign-digital.de</a>) für die
            Verlängerung genutzt werden soll. Bitte kontrolliere vorab, ob die in deinem Konto hinterlegte
            <router-link :to="{ name: 'user-billing' }">Rechnungsadresse</router-link> korrekt ist.
          </p>

          <div class="buttons are-small" v-if="subscription.subscriptionType === 'manual'">
            <router-link tag="b-button" :to="{ name: 'user-billing' }">Rechnungsadresse anpassen</router-link>
            <b-button tag="a" href="https://sign-digital.de/#signdigital-preise" target="_blank"
              >Alle Lizenzen ansehen</b-button
            >
            <b-button
              tag="a"
              :href="
                'mailto:info@sign-digital.de?subject=Verlängerung SIGNdigital Lizenz ' +
                $store.getters['auth/user'].email
              "
              >SIGNdigital Lizenz verlängern</b-button
            >
          </div>
        </b-message>

        <b-message v-if="renewal" type="is-info">
          <p>
            Es wurde eine Verlängerung ({{ renewal.licenseObject.title }}) für diese Lizenz geplant. Die Verlängerung
            wird am {{ renewal.periodStart | dateTimeAt }} automatisch aktiv und läuft bis zum
            {{ renewal.periodEnd | dateTimeAt }} .<br />
          </p>
          <p class="is-size-7">
            <strong>Hinweis:</strong> Falls die geplante Verlängerung eine kleinere Lizenz für weniger Nutzer ist,
            werden am Stichtag die überschüssigen Team-Mitglieder automatisch vom System entfernt. Reduziere vor dem
            Ablauf der Lizenz die Team-Mitglieder, um die Kontrolle darüber zu behalten welche Mitglieder entfernt
            werden.
          </p>
        </b-message>

        <b-message v-else-if="renewsSoon" type="is-primary">
          <p>
            Deine SIGNdigital Lizenz wird am {{ subscription.periodEnd | date }} automatisch um
            {{ subscriptionPeriod }} verlängert.
          </p>
        </b-message>

        <!-- Expired subscription -->
        <b-message v-if="isCanceled" type="is-danger">
          <p>Deine SIGNdigital Lizenz ist am {{ subscription.periodEnd | dateTimeAt }} abgelaufen.</p>
          <p>
            Alle deine Einstellungen, Gebärden, Lernlisten und Druckvorlagen sind weiterhin vorhanden, du brauchst
            jedoch eine aktive Lizenz um darauf zugreifen zu können.
          </p>
          <p v-if="subscription.subscriptionType === 'stripe'">
            Du kannst eine neue Lizenz erwerben, indem du den Button
            <router-link :to="{ name: 'checkout' }">Lizenz jetzt verlängern</router-link> klickst.
          </p>
          <p v-else>
            Lizenzen mit Rechnungszahlung <strong>verlängern sich nicht automatisch</strong>.<br />
            Wir haben dich 30 Tage bevor die Lizenz abgelaufen ist per E-Mail benachrichtigt.<br />Schreib uns einfach
            an
            <a
              :href="
                'mailto:info@sign-digital.de?subject=Verlängerung SIGNdigital Lizenz ' +
                $store.getters['auth/user'].email
              "
              >info@sign-digital.de</a
            >
            um die Lizenz zu verlängern und wir stellen eine Rechnung aus. Vergiss nicht uns zu schreiben welche Lizenz
            (Größe und Dauer, siehe
            <a href="https://sign-digital.de/#signdigital-preise" target="_blank">sign-digital.de</a>) für die
            Verlängerung genutzt werden soll. Bitte kontrolliere vorab, ob die in deinem Konto hinterlegte
            <router-link :to="{ name: 'user-billing' }">Rechnungsadresse</router-link> korrekt ist.
          </p>

          <div class="buttons are-small" v-if="subscription.subscriptionType === 'manual'">
            <router-link tag="b-button" :to="{ name: 'user-billing' }">Rechnungsadresse anpassen</router-link>
            <b-button tag="a" href="https://sign-digital.de/#signdigital-preise" target="_blank"
              >Alle Lizenzen ansehen</b-button
            >
            <b-button
              tag="a"
              :href="
                'mailto:info@sign-digital.de?subject=Verlängerung SIGNdigital Lizenz ' +
                $store.getters['auth/user'].email
              "
              >SIGNdigital Lizenz verlängern</b-button
            >
          </div>
        </b-message>

        <div class="columns">
          <div class="column">
            <divider label="Lizenz-Status" type="is-left" />
            <!-- status -->
            <b-field label="Status">
              <span>{{ subscription.status | subscriptionStatus }}</span>
            </b-field>
            <!-- periodEnd -->
            <b-field label="Läuft bis">
              <span v-if="subscription.periodEnd">{{ subscription.periodEnd | dateTimeAt }}</span>
              <span v-else>-</span>
            </b-field>
            <!-- cancelAtPeriodend -->
            <b-field label="Automatische Verlängerung">
              <span>{{ automaticRenewalText }}</span>
            </b-field>
          </div>
          <div class="column">
            <divider label="Lizenz" type="is-left" />
            <!-- license.title -->
            <b-field label="Lizenz">
              <p>
                {{ license.title }} (<code>{{ license.id }}</code
                >)
              </p>
            </b-field>
            <!-- license.title -->
            <b-field label="Plätze">
              <span>{{ license.size }}</span>
            </b-field>
            <!-- license.title -->
            <b-field label="Abrechnungsperiode">
              <span>{{ subscriptionPeriod }}</span>
            </b-field>
          </div>
        </div>

        <divider label="Zahlungsmethode" type="is-left" />
        <payment-method :payment-method="paymentMethod" :actions="false" />

        <!-- Actions -->
        <divider label="Aktionen" type="is-left" />
        <div class="buttons" v-if="!hasManualSubscription">
          <!-- Automatic renewal -->
          <template v-if="subscription.licenseObject.canAutoRenew && !isCanceled">
            <b-button
              type="is-danger"
              v-if="!subscription.cancelAtPeriodEnd"
              @click="$emit('cancel')"
              :disabled="subscription.cancelAtPeriodEnd"
              >Automatische Verlängerung deaktivieren</b-button
            >
            <b-button type="is-primary" v-else @click="$emit('renew')">Automatische Verlängerung aktivieren</b-button>
          </template>

          <!-- Subscription change -->
          <b-button @click="$emit('change')" v-if="!isCanceled">Lizenz ändern</b-button>

          <!-- Purchase new subscription -->
          <b-button v-if="isCanceled" tag="router-link" :to="{ name: 'checkout' }" type="is-primary"
            >Lizenz jetzt verlängern</b-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PaymentMethod from '@/components/registration/steps/summary/PaymentMethod'
import Divider from '@/components/Divider'
import { mapGetters } from 'vuex'
export default {
  components: {
    Divider,
    PaymentMethod
  },
  props: {
    subscription: Object,
    stripeSubscription: Object,
    paymentMethods: Array,
    renewal: Object
  },
  computed: {
    ...mapGetters('session', ['hasManualSubscription', 'hasTeamLicense']),
    paymentMethod () {
      if (this.hasManualSubscription) {
        return { type: 'invoice' }
      } else {
        return this.paymentMethods.find((pm) => pm.id === this.stripeSubscription.default_payment_method)
      }
    },
    license () {
      return this.subscription?.licenseObject
    },
    isCanceled () {
      return this.subscription?.status === 'canceled'
    },
    subscriptionPeriod () {
      if (this.license.period % 12 === 0) {
        const years = this.license.period / 12
        return years + ' Jahr' + (years > 1 ? 'e' : '')
      }
      return this.license.period + ' Monat' + (this.license.period > 1 ? 'e' : '')
    },
    automaticRenewalText () {
      if (this.hasManualSubscription) {
        return 'Bei Rechnungszahlung nicht möglich'
      } else if (this.subscription.licenseObject?.canAutoRenew === false) {
        return `Für ${this.subscription.licenseObject.title} nicht möglich`
      } else {
        return this.subscription.cancelAtPeriodEnd ? 'Deaktiviert' : 'Aktiviert'
      }
    },
    renewsSoon () {
      if (!this.subscription || this.subscription.status !== 'active' || this.subscription.cancelAtPeriodEnd) {
        return false
      }
      const soonTime = 21 * 24 * 60 * 60 * 1000 // 21 days
      return new Date(this.subscription.periodEnd).getTime() - new Date().getTime() < soonTime
    }
  }
}
</script>

<style lang="scss" scoped></style>
