<template>
  <div class="share-link-menu">
    <b-dropdown-item custom>
      <p>Du kannst diesen Link kopieren, um diese Gebärde mit anderen zu teilen:</p>
      <b-field>
        <b-input
          ref="keyInput"
          size="is-small"
          :value="shareLink"
          @click.native="onCopyToClipboard()"
          readonly="readonly"
        ></b-input>
        <p class="control">
          <b-button size="is-small" icon-left="copy" class="button is-primary" @click="onCopyToClipboard()"></b-button>
        </p>
      </b-field>
    </b-dropdown-item>
  </div>
</template>

<script>
import shareLinkMixin from '@/mixins/share-link-mixin'

export default {
  mixins: [shareLinkMixin],
  props: {
    slug: String
  },
  computed: {
    shareLink () {
      const route = this.$router.resolve({
        name: 'sign-details-public',
        params: { slug: this.slug }
      })
      return `${window.location.origin}${route.href}`
    }
  }
}
</script>
