<template>
  <div class="file-browser-sidebar">
    <b-navbar class="is-mobile" :mobile-burger="false" transparent>
      <template #end>
        <b-navbar-item tag="div" v-if="sidebar">
          <b-button size="is-small" icon-left="arrow-from-right" type="is-light" @click="$emit('shrink-sidebar')" />
        </b-navbar-item>
      </template>
    </b-navbar>

    <div class="control" v-if="browserCtrl.fileTypeFilter">
      <b-taglist attached>
        <b-tag type="is-light" closable @close="onClearFileTypeFilterButtonClicked"
          ><strong>Filter: </strong>{{ browserCtrl.fileTypeFilter | fileTypeName(true) }}</b-tag
        >
      </b-taglist>
    </div>

    <div class="sidebar-content">
      <file-node-explorer-item
        v-for="item of browserCtrl.rootItems"
        :key="item._id"
        :browserCtrl="browserCtrl"
        :item="item"
        @item-selected="onItemSelected"
      />
    </div>
  </div>
</template>

<script>
import FileNodeExplorerItem from './FileNodeExplorerItem.vue'
import { fileNodeMixin } from '@custom-media/signdigital-web-shared/src/mixins/file-node-mixins'

export default {
  mixins: [fileNodeMixin],
  components: {
    FileNodeExplorerItem
  },
  props: {
    browserCtrl: Object,
    sidebar: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onItemSelected (item) {
      console.log('Node tree item selected')
      this.browserCtrl.goTo(item)
    },
    onClearFileTypeFilterButtonClicked () {
      this.browserCtrl.clearFileTypeFilter()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/bulma-variables.scss';
.file-browser-sidebar {
  position: relative;
}
.sidebar-content {
  position: relative;
  padding: 0.3rem;
  margin-top: 2.6rem;
}

.control {
  position: absolute;
  left: 0.3rem;
  top: calc(80px + 0.5rem);

  // .tag:first-child {
  //   font-weight: bold;
  //   background-color: $light;
  // }
  // // .b-tag::fir
  // .tag:last-child {
  //   background-color: $light;
  // }
}

.navbar::v-deep {
  background: transparent;
  height: 80px;
}
</style>
