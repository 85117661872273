import { render, staticRenderFns } from "./EmailAddressParser.vue?vue&type=template&id=21526ffd&scoped=true"
import script from "./EmailAddressParser.vue?vue&type=script&lang=js"
export * from "./EmailAddressParser.vue?vue&type=script&lang=js"
import style0 from "./EmailAddressParser.vue?vue&type=style&index=0&id=21526ffd&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21526ffd",
  null
  
)

export default component.exports