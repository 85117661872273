<template>
  <section class="password-reset-request hero is-primary is-fullheight is-bold">
    <div class="hero-body">
      <div class="container">
        <div class="column is-6 is-offset-3">
          <div class="logo-wrapper">
            <img class="logo" src="@/assets/img/sign-digital-logo_white.svg" />
          </div>
          <b-button icon-left="chevron-left" expanded class="mb-4" tag="router-link" :to="{ name: 'login' }"
            >Zurück zur Anmeldung</b-button
          >

          <!-- Reset request -->
          <div class="box box-shadow" v-if="!didSendRequest">
            <h1 class="title">Passwort zurücksetzen</h1>
            <validation-observer v-slot="{ invalid, handleSubmit }">
              <form @submit.prevent="handleSubmit(onResetFormSubmit)">
                <!-- E-MAIL -->
                <validation-provider name="E-Mail Adresse" rules="required|email" v-slot="{ errors, valid }">
                  <b-field
                    label="E-Mail Adresse"
                    :type="{ 'is-danger': errors.length > 0, 'is-primary': valid }"
                    :message="errors[0]"
                  >
                    <b-input
                      icon="envelope"
                      type="email"
                      name="email"
                      placeholder="Deine E-Mail Adresse"
                      v-model="email"
                    />
                  </b-field>
                </validation-provider>

                <feathers-error class="mt-4" action="Senden der Wiederherstellungs-Mail" :error="error" />

                <!-- Submit button -->
                <b-button
                  class="mt-4"
                  type="is-primary"
                  native-type="submit"
                  value="Wiederherstellen"
                  expanded
                  :disabled="invalid"
                  :loading="isRequestPending"
                  >Wiederherstellungs-Mail schicken</b-button
                >
              </form>
            </validation-observer>
          </div>

          <!-- Instructions when done -->
          <div class="box box-shadow" v-else>
            <h1 class="title">Anfrage gesendet</h1>
            <p>
              Wir haben eine E-Mail zum Zurücksetzen deines Passworts an <strong>{{ email }}</strong> geschickt.
            </p>
            <p>Kontrolliere auch deinen Spam-Ordner.</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { apiMixin } from '@/mixins/api-mixin'
export default {
  mixins: [apiMixin],
  data () {
    return {
      email: null,
      error: null,
      isRequestPending: false,
      didSendRequest: false
    }
  },
  methods: {
    async onResetFormSubmit () {
      this.isRequestPending = true
      // Request reset verification
      try {
        await this.createNewPasswordResetVerification(this.email)
        this.didSendRequest = true
      } catch (error) {
        this.error = error
      } finally {
        this.isRequestPending = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/bulma-variables.scss';
.logo-wrapper {
  text-align: center;
  margin-bottom: 1em;
  .logo {
    max-width: 250px;
  }
}
.hero .box {
  .title {
    color: $dark;
  }
}
</style>
