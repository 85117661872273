<template>
  <div class="iban-input">
    <b-field label="IBAN" :type="{ 'is-danger': hasError }" :message="errorMessage">
      <div id="iban-element"><!-- A Stripe Element will be inserted here. --></div>
    </b-field>
  </div>
</template>

<script>
import { stripeStyle } from '@/lib/constants/stripe-style'
export default {
  props: {
    stripeElements: Object,
    value: Boolean
  },
  data () {
    return {
      element: null,
      elementFlags: {
        error: null,
        empty: true,
        complete: false
      }
    }
  },
  computed: {
    valid () {
      return this.elementFlags.empty === false && this.elementFlags.error == null && this.elementFlags.complete
    },
    invalid () {
      return this.hasError
    },
    hasError () {
      return this.elementFlags.error != null
    },
    errorMessage () {
      if (!this.hasError) {
        return null
      }
      return this.elementFlags.error.message
    }
  },
  mounted () {
    this.initStripe()
  },
  beforeDestroy () {
    this.element.destroy()
    this.element = null
  },
  watch: {
    valid (newValue, oldValue) {
      this.$emit('input', newValue)
    }
  },
  methods: {
    async initStripe () {
      if (this.element != null) {
        return
      } // Already created
      // Custom styling can be passed to options when creating an Element.
      const options = {
        style: stripeStyle,
        supportedCountries: ['SEPA'],
        placeholderCountry: 'DE'
      }

      this.element = this.stripeElements.create('iban', options)
      this.element.mount('#iban-element')
      this.element.on('change', this.onChange)
    },
    onChange (event) {
      this.elementFlags.error = event.error
      this.elementFlags.empty = event.empty
      this.elementFlags.complete = event.complete
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/bulma-variables.scss';
.StripeElement {
  background: white;
  display: block;
  align-items: center;
  height: 2.5em;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: $sm-radius;
  border: 1px solid #dbdbdb;
  padding-bottom: calc(0.5em - 1px);
  padding-left: calc(0.75em - 1px);
  padding-right: calc(0.75em - 1px);
  padding-top: calc(0.5em - 1px);
  margin-bottom: 0.75rem;
}
</style>
