<template>
  <div class="custom-sign-sidebar p-2">
    <section class="add-image">
      <div class="group">
        <div class="field">
          <label class="label group-label">Rahmen & Farbe</label>
        </div>
        <b-field>
          <sign-box-style-dropdown></sign-box-style-dropdown>
        </b-field>
      </div>

      <!-- Layout switch -->
      <div class="group">
        <div class="field">
          <label class="label group-label">Layout</label>
        </div>
        <b-field>
          <b-radio-button v-model="signBoxLayout" expanded size="is-small" native-value="single">
            Ein Wort
          </b-radio-button>

          <b-radio-button expanded v-model="signBoxLayout" size="is-small" native-value="composed">
            Zusammengesetzt
          </b-radio-button>
        </b-field>
      </div>

      <div v-if="signBoxLayout === 'composed'">
        <!-- Left word (single or composed layout)-->
        <div class="group">
          <div class="field">
            <label class="label group-label">Linker Begriff</label>
          </div>

          <div class="field">
            <label class="label is-small">Text</label>
            <sign-editor-text-control layout-key="textComposedTopLeft" />
          </div>

          <div class="field">
            <label class="label is-small">Bild</label>
            <sign-editor-image-control layout-key="imageComposedLeft"></sign-editor-image-control>
          </div>
        </div>

        <!-- Right word (composed layout) -->

        <div class="group">
          <div class="field">
            <label class="label group-label">Rechter Begriff</label>
          </div>

          <div class="field">
            <label class="label is-small">Text</label>
            <sign-editor-text-control layout-key="textComposedTopRight" />
          </div>

          <div class="field">
            <label class="label is-small">Bild</label>
            <sign-editor-image-control layout-key="imageComposedRight"></sign-editor-image-control>
          </div>
        </div>
      </div>
      <div class="group" v-else>
        <div class="field">
          <label class="label group-label">Oben</label>
        </div>

        <div class="field">
          <label class="label is-small">Bild</label>
          <sign-editor-image-control layout-key="imageSingleTop"></sign-editor-image-control>
        </div>
      </div>

      <!-- Bottom -->
      <div class="group">
        <div class="field">
          <label class="label group-label">Unten</label>
        </div>
        <div class="field">
          <label class="label is-small">Text</label>
          <sign-editor-text-control layout-key="textBottomRight" />
        </div>

        <div class="field">
          <label class="label is-small">Bild</label>
          <sign-editor-image-control layout-key="imageBottom"></sign-editor-image-control>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import SearchDropdown from '@custom-media/signdigital-web-shared/src/components/SearchDropdown'
import SignBoxStyleDropdown from '@/components/custom-signs/SignBoxStyleDropdown.vue'
import SignEditorTextControl from '@/components/custom-signs/SignEditorTextControl.vue'
import SignEditorImageControl from '@/components/custom-signs/SignEditorImageControl.vue'
import Vue from 'vue'
import { ensureImageReferenceOwnership } from '@/lib/util/image-ownership'
// import { mapGetters, mapMutations, mapState } from 'vuex'

export default {
  components: {
    SignBoxStyleDropdown,
    SignEditorTextControl,
    SignEditorImageControl
  },
  data () {
    return {
      text: null
    }
  },
  computed: {
    // ...mapState('signEditor', [])
    signBoxLayout: {
      get () {
        return this.$store.getters['signEditor/signTypeProperties']?.layout
      },
      set (value) {
        this.$store.commit('signEditor/setSignTypeProperty', { key: 'layout', value })
        this.$store.dispatch('signEditor/ensureFixedObjects')
        // TODO: Update controls here
      }
    }
  },
  methods: {
    async onArrowSelected (arrow) {
      const o = await this.$store.dispatch('signEditor/addArrow', arrow)
      this.$store.commit('signEditor/setSelectedObjectIds', [o.id])
    },
    async onAddText () {
      if (!this.text) {
        return
      }
      const o = await this.$store.dispatch('signEditor/addText', { text: this.text, color: '#000000' })
      this.text = null
      this.$store.commit('signEditor/setSelectedObjectIds', [o.id])
    },
    onAddImageButtonClicked (event) {
      const SearchDropdownCtrl = Vue.extend(SearchDropdown)
      const el = new SearchDropdownCtrl({
        propsData: {
          target: event.target,
          minX: 260,
          minY: 70,
          allowedContentTypes: ['images']
        },
        parent: this
      })
      el.$once('selected', async (reference) => {
        if (reference.service === 'images') {
          const { ownerType, owner } = this.$store.state.signEditor
          reference = await ensureImageReferenceOwnership({ ownerType, owner }, reference, this)
          if (reference === null) {
            return
          }
        }
        await this.$store.dispatch('signEditor/addImage', reference)
        this.$store.dispatch('signEditor/ensureFixedObjectOrder', reference)
      })
      el.$once('closed', () => {
        el.$destroy()
        el.$el.parentNode.removeChild(el.$el)
      })
      el.$mount()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/bulma-variables.scss';
.custom-sign-sidebar {
  background: white;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-left: 1px solid $grey-light;
  overflow: auto;

  @include until($tablet) {
    border-top: 1px solid $grey-light;
  }

  section {
    margin-bottom: 1em;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .add-arrows {
    min-height: 100px;
    flex-shrink: 1;
    display: flex;
    flex-direction: column;
    label {
      font-weight: bold;
    }
  }

  .arrow-list {
    padding-right: 1em;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .group {
    padding: 0.5rem;
    border: 1px solid $primary-dark;
    border-radius: $sm-radius;
    margin-bottom: 1em;
    background: #f5f5f5;

    .group-label {
      background: $primary-dark;
      color: $white;
      margin-left: -0.5rem;
      margin-right: -0.5rem;
      margin-top: -0.5rem;
      padding: 0.15rem 0.5rem;
      font-size: 0.7rem;
      font-weight: bold;
    }
  }
}
</style>
