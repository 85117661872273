<template>
  <div class="file-browser-details" :class="{ 'is-mobile': mobile }">
    <div class="details p-2" v-if="node">
      <!-- <custom-sign-thumbnail :custom-sign="file"> -->
      <b-loading :active="isGetPending" :is-full-page="false" />
      <small class="has-text-grey">Details der Datei</small>
      <h4 class="mb-0">{{ node.name }}</h4>
      <div class="file-type has-text-weight-bold">
        <small>{{ node | fileNodeType }}</small>
        <small v-if="node.fileType !== 'lists'"> ({{ node | fileNodeTypeDetailsOnly }})</small>
      </div>
      <div v-if="file">
        <div v-if="node.fileType === 'documents'" class="">
          <document-thumbnail :document="file" :square="false" />
          <div class="tags mt-2">
            <b-tag icon="info-square">{{ file.type | documentType }}</b-tag>
          </div>
        </div>
        <div v-else-if="node.fileType === 'custom-signs'">
          <custom-sign-thumbnail :custom-sign="file" />
          <div class="tags mt-2">
            <b-tag icon="info-square">{{ file.signType | customSignType }}</b-tag>
          </div>
        </div>
        <div v-else-if="node.fileType === 'lists'" class="">
          <span class="is-size-7">{{ file.signs.length }} Gebärde{{ file.signs.length === 1 ? '' : 'n' }}</span>
          <b-progress
            v-if="file.signs.length > 0"
            :value="file.progress"
            show-value
            format="percent"
            type="is-primary"
          ></b-progress>
        </div>

        <div class="meta mt-2" v-if="file">
          <div class="tags">
            <b-tag icon="pen" size="is-small"
              ><time>{{ file.updatedAt | dateTime }}</time></b-tag
            >
            <b-tag icon="plus" size="is-small"
              ><time>{{ file.createdAt | dateTime }}</time></b-tag
            >
            <b-tag icon="user" size="is-small">{{ isMine ? 'Von dir erstellt' : 'Andere Person' }} </b-tag>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomSignThumbnail from '../custom-signs/CustomSignThumbnail.vue'
import DocumentThumbnail from '../document/DocumentThumbnail.vue'
import { fileNodeMixin } from '@custom-media/signdigital-web-shared/src/mixins/file-node-mixins'
export default {
  components: {
    DocumentThumbnail,
    CustomSignThumbnail
  },
  mixins: [fileNodeMixin],
  props: {
    browserCtrl: Object,
    mobile: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    node () {
      return this.browserCtrl.selectedItems?.[0].fileNode ?? null
    },
    isMine () {
      return this.file?.author === this.$store.state.auth.user._id
    }
  },
  watch: {
    node (n, o) {
      console.log('Watch changed', n, o)
      this.fetch()
    }
  },
  data () {
    return {
      isGetPending: false,
      file: null
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    async fetch () {
      this.file = null
      if (this.node?.file == null) {
        return
      }
      const service = this.node.fileType
      const localCopy = this.$store.getters[`${service}/get`](this.node.file)
      if (localCopy) {
        this.file = localCopy
        return
      }
      this.isGetPending = true
      this.file = await this.$store.dispatch(`${service}/get`, this.node.file)
      this.isGetPending = false
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/bulma-variables.scss';
.file-browser-details {
  &.is-mobile {
    background: white;
    border-radius: $lg-radius;
    margin: 0.25rem;
    padding: 1rem;

    &::v-deep figure {
      border: 1px solid black;
    }
  }
}
small {
  font-size: 0.6rem;
  line-height: 0.6rem;
}
h4 {
  font-weight: bold;
  font-size: 0.9rem;
  line-height: 0.9rem;
}
.file-type {
  line-height: 0.6rem;
  margin-bottom: 1rem;
}
.details {
  position: sticky;
  top: 0;
}
</style>
