<template>
  <div class="team-details-form">
    <validation-observer slim v-slot="{ invalid, handleSubmit }" ref="validationObserver">
      <form ref="teamDetailsForm" @submit.prevent="handleSubmit(onSubmit)" v-if="localTeam">
        <!-- Team name -->
        <validation-provider name="Name deines Teams" rules="required" v-slot="{ errors, valid }" slim>
          <b-field
            label="Name deines Teams"
            :type="{ 'is-danger': errors.length > 0, 'is-primary': valid }"
            :message="errors[0]"
          >
            <b-input
              type="text"
              placeholder="z.B. Musterschule, Praxis am Musterweg, Familie Mustermann, ..."
              v-model="localTeam.name"
              key="team"
            />
          </b-field>
        </validation-provider>

        <!-- Team description -->
        <validation-provider name="Beschreibung deines Teams" rules="required" v-slot="{ errors, valid }" slim>
          <b-field
            label="Beschreibung deines Teams"
            :type="{ 'is-danger': errors.length > 0, 'is-primary': valid }"
            :message="errors[0]"
          >
            <b-input
              type="textarea"
              placeholder="Diese Beschreibung sehen neue Mitglieder zum Beispiel in der Einladungs-E-Mail"
              v-model="localTeam.description"
              key="team-description"
            />
          </b-field>
        </validation-provider>

        <b-field
          label="Rolle des Team-Besitzers"
          message="Wenn angewählt, kann der Team-Besitzer SIGNdigital ebenfalls benutzen, verbraucht aber einen Platz der Lizenz."
        >
          <b-checkbox v-model="localTeam.ownerHasAccess">Besitzer hat SIGNdigital Zugang</b-checkbox>
        </b-field>

        <!-- SAVE BUTTON -->
        <b-button v-if="submit" native-type="submit" type="is-primary" :disabled="invalid" expanded class="mt-5">{{
          submitText
        }}</b-button>
      </form>
    </validation-observer>
  </div>
</template>

<script>
export default {
  props: {
    submit: {
      type: Boolean,
      default: true
    },
    submitText: {
      type: String,
      default: 'Team erstellen'
    },
    team: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      localTeam: null
    }
  },
  mounted () {
    this.reset()
  },
  methods: {
    reset () {
      const { Team } = this.$FeathersVuex.api
      if (this.team == null) {
        this.localTeam = new Team()
      } else {
        this.localTeam = this.team.clone()
      }
    },
    onSubmit () {
      this.$emit('submit', this.localTeam)
    },
    onCancel () {
      this.$emit('cancel')
      this.reset()
    }
  }
}
</script>
