<template>
  <div class="file-browser-main">
    <b-navbar class="is-mobile" :mobile-burger="false" transparent>
      <template #start>
        <b-navbar-item tag="div" v-if="!sidebar">
          <b-button size="is-small" icon-left="arrow-from-left" type="is-light" @click="$emit('expand-sidebar')" />
        </b-navbar-item>
        <b-navbar-item tag="div" class="current-item">
          <file-node-breadcrumbs :browser-ctrl="browserCtrl" @item-selected="onItemSelected"></file-node-breadcrumbs>
          <div class="is-flex" v-if="browserCtrl.currentItem">
            <h4>
              {{ browserCtrl.currentItem.fileNode.name }}
            </h4>
            <file-node-actions
              v-if="browserCtrl.currentItem && browserCtrl.currentItem.hasParent()"
              @action="onFolderAction"
              class="ml-2"
              type="is-light"
              :outlined="false"
              :nodeType="browserCtrl.currentItem.fileNode.nodeType"
              :actions="directoryActions"
              :item="browserCtrl.currentItem"
              :hiddenActions="['open']"
            />
          </div>
        </b-navbar-item>
      </template>
      <template #end>
        <b-navbar-item tag="div" class="main">
          <b-button
            v-if="browserCtrl.currentItem && browserCtrl.currentItem.hasParent()"
            class="ml-2"
            size="is-small"
            icon-left="level-up"
            type="is-light"
            @click="onGoToParentButtonClicked"
          ></b-button>
        </b-navbar-item>
        <b-navbar-item tag="div">
          <b-dropdown aria-role="list" position="is-bottom-left" v-if="canCreateDirectory || canCreateFile">
            <template #trigger="{ active }">
              <b-button
                icon-left="plus-circle"
                size="is-small"
                type="is-primary"
                :icon-right="active ? 'chevron-up' : 'chevron-down'"
                >Neu</b-button
              >
            </template>

            <b-dropdown-item aria-role="listitem" @click="onCreate('directory')" v-if="canCreateDirectory"
              ><b-icon icon="folder" class="mr-2" />Ordner</b-dropdown-item
            >
            <b-dropdown-item aria-role="listitem" @click="onCreate('file', 'lists')" v-if="canCreateFile"
              ><b-icon icon="list" class="mr-2" />Lernliste</b-dropdown-item
            >
            <b-dropdown-item aria-role="listitem" @click="onCreate('file', 'custom-signs')" v-if="canCreateFile"
              ><b-icon icon="vector-square" class="mr-2" />Eigene Gebärde</b-dropdown-item
            >
            <b-dropdown-item aria-role="listitem" @click="onCreate('file', 'documents')" v-if="canCreateFile"
              ><b-icon icon="file" class="mr-2" />Druckvorlage</b-dropdown-item
            >
          </b-dropdown>
        </b-navbar-item>
      </template>
    </b-navbar>
    <div class="file-browser-main-content">
      <file-node-table
        :browserCtrl="browserCtrl"
        @action="onItemAction"
        :file-actions="fileActions"
        :directoryActions="directoryActions"
        :itemToInsertPreview="itemToInsertPreview"
      ></file-node-table>
    </div>
  </div>
</template>

<script>
import FileNodeActions from './FileNodeActions.vue'
import FileNodeBreadcrumbs from './FileNodeBreadcrumbs.vue'
import FileNodeTable from './FileNodeTable.vue'
import { fileNodeMixin } from '@custom-media/signdigital-web-shared/src/mixins/file-node-mixins'
export default {
  mixins: [fileNodeMixin],
  components: {
    FileNodeTable,
    FileNodeBreadcrumbs,
    FileNodeActions
  },
  props: {
    browserCtrl: Object,
    sidebar: {
      type: Boolean,
      default: false
    },
    fileActions: {
      type: Array
    },
    directoryActions: {
      type: Array
    },
    itemToInsertPreview: Object
  },
  computed: {
    canCreateDirectory () {
      return this.directoryActions.includes('create')
    },
    canCreateFile () {
      return this.fileActions.includes('create')
    }
  },
  methods: {
    onGoToParentButtonClicked () {
      this.browserCtrl.goToParent()
    },
    onItemSelected (item) {
      this.browserCtrl.goTo(item)
    },
    onCreate (nodeType, fileType = null) {
      this.$emit('create', { nodeType, fileType })
    },
    onItemAction ({ action, item }) {
      this.$emit('item-action', { action, item })
    },
    onFolderAction ({ action }) {
      this.$emit('item-action', { action, item: this.browserCtrl.currentItem })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/bulma-variables.scss';
.file-browser-main {
  overflow: hidden;
}
h4 {
  font-weight: 600;
  font-size: 1.15rem;
}

.file-browser-main-content {
  padding: 0.5em 0.75em;
  padding-top: 0;
}
.navbar::v-deep {
  background: transparent;
  min-height: 80px;
  .navbar-menu {
    flex-shrink: 1;
  }
  .navbar-start {
    flex-shrink: 1;
  }
  .navbar-brand {
    display: none;
  }
}

.navbar-item.current-item::v-deep {
  flex-direction: column;
  align-items: flex-start !important;
  text-align: left;
  justify-content: center;
  flex-shrink: 1;

  h4 {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .dropdown-item .icon:only-child {
    margin-left: 0;
    margin-right: 0;
  }
}

.navbar::v-deep {
  @include until($tablet) {
    .navbar-menu {
      flex-wrap: wrap;
    }
    .navbar-item {
      padding: 0.25rem 0.5rem;
    }
  }
}
</style>
