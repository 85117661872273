export default {
  namespaced: true,
  state: () => {
    return {
      invitation: null,
      invitationId: null,
      invitationToken: null,
      isGetPending: false,
      errorOnGet: null
    }
  },
  getters: {},
  mutations: {
    reset (state) {
      state.invitation = null
      state.invitationId = null
      state.invitationToken = null
      state.isGetPending = false
      state.errorOnGet = null
    },
    startInvitationProcess (state, { invitationId, invitationToken }) {
      state.invitationId = invitationId
      state.invitationToken = invitationToken
    },
    setInvitation (state, invitation) {
      state.invitation = invitation
    },
    setGetPending (state) {
      state.isGetPending = true
    },
    unsetGetPending (state) {
      state.isGetPending = false
    },
    setErrorOnGet (state, error) {
      state.errorOnGet = error
    },
    unsetErrorOnGet (state) {
      state.errorOnGet = null
    }
  },
  actions: {
    async start ({ dispatch, commit }, { invitationId, invitationToken }) {
      commit('startInvitationProcess', { invitationId, invitationToken })
      await dispatch('fetch')
    },
    cancel ({ commit }) {
      commit('reset')
    },
    async fetch ({ rootGetters, state, commit, dispatch }) {
      commit('setGetPending')
      commit('unsetErrorOnGet')
      try {
        const { invitationId, invitationToken } = state
        const invitation = await dispatch('invitations/get', [invitationId, { query: { invitationToken } }], {
          root: true
        })
        if (!invitation.isValid) {
          let reason
          if (invitation.isConsumed) {
            reason = 'Der Einladungslink wurde bereits verwendet.'
          } else if (invitation.isInvalidated) {
            reason = 'Die Einladung wurde durch einen Verwalter des Teams storniert.'
          } else {
            reason = 'Die Einladung ist bereits abgelaufen.'
          }
          commit('setErrorOnGet', new Error('Dieser Einladungslink ist ungültig. ' + reason))
          commit('unsetGetPending')
          return
        }
        commit('setInvitation', invitation)
      } catch (error) {
        console.log('Error on fetching team settings: ' + error)
        commit('setErrorOnGet', error)
      } finally {
        commit('unsetGetPending')
      }
    },

    async accept ({ rootGetters, state, dispatch }) {
      const user = rootGetters['auth/user']
      const { invitation, invitationId, invitationToken } = state
      await dispatch(
        'users/patch',
        [
          user._id,
          {
            team: invitation.team._id,
            invitationId,
            invitationToken
          }
        ],
        { root: true }
      )
    }
  }
}
