<template>
  <div class="team-create-view">
    <div class="container p-5">
      <h1 class="title">Team erstellen</h1>
      <b-loading :active="isCreatePending" />

      <div class="card">
        <div class="card-header">
          <div class="card-header-title">Deine neues Team</div>
        </div>
        <div class="card-content">
          <feathers-error class="mb-4" vuex-store="teams" vuex-action="create" title="Fehler beim Anlegen des Teams" />
          <div class="content">
            <p>
              Du hast eine Lizenz für mehrere Personen ausgewählt. Damit du andere Personen zur Nutzung von SIGNdigital
              einladen kannst, musst du zuerst ein Team erstellen.
            </p>
            <!-- Show only if family license -->
            <team-details-form @submit="onTeamSubmit" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TeamDetailsForm from '@/components/team/TeamDetailsForm'
import { mapState } from 'vuex'
export default {
  components: {
    TeamDetailsForm
  },
  computed: {
    ...mapState('teams', ['isCreatePending', 'errorOnCreate'])
  },
  methods: {
    async onTeamSubmit (team) {
      await team.save()
      this.$buefy.toast.open({ type: 'is-success', message: 'Team erfolgreich erstellt!' })
      await this.$store.dispatch('users/get', this.$store.getters['auth/user']._id)
      await this.$store.dispatch('session/fetch')
      this.$router.push({ name: 'team-overview' })
    }
  }
}
</script>
