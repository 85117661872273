<template>
  <div class="grid-page-content">
    <document-watermark
      :frame="watermarkFrame"
      :image="documentProperties.logo"
      @update:image="onDocumentLogoChanged"
      :editable="$authorization.hasMultiplicatorPermission"
    />
    <document-text-field
      :frame="titleFrame"
      placeholder="Überschrift"
      :font-style="titleStyle"
      :value="documentProperties.title"
      @update:value="onDocumentTitleChanged"
    />

    <div class="elements-wrapper" :style="elementsWrapperFrameStyle">
      <b-dropdown class="grid-settings-dropdown" position="is-bottom-left" ref="gridSettingsDropdown">
        <template #trigger>
          <b-button type="is-light" size="is-small" icon-left="cog">Raster</b-button>
        </template>
        <grid-settings
          :grid="page.grid"
          :page="page"
          @apply="onChangeGrid"
          @cancel="$refs.gridSettingsDropdown.isActive = false"
        />
      </b-dropdown>
      <div class="grid" :style="gridFrameStyle">
        <!-- Elements -->
        <grid-element
          v-for="slot in slotsArray"
          :key="slot.gridIndex.column + '-' + slot.gridIndex.row"
          :size="page.grid.elementSize"
          :element-id="slot.element == null ? null : slot.element.id"
          :is-placeholder="slot.element == null"
          :grid-index="slot.gridIndex"
          :frame="page.grid.getRectangleOfElementAt(slot.gridIndex)"
          @create="onCreateElement"
          @delete="onDeleteElement"
          @update="onUpdateElement"
          @replace="onReplaceElement(slot, $event)"
        />
      </div>
    </div>

    <document-text-field
      v-if="documentProperties.footerText || $authorization.hasMultiplicatorPermission"
      placeholder="Optionaler Vermerk für Referenten"
      :frame="footerTextFrame"
      :font-style="footerTextStyle"
      :value="documentProperties.footerText"
      @update:value="onFooterTextChanged"
      :editable="$authorization.hasMultiplicatorPermission"
      class="height-auto"
    />
    <page-copyright-note v-if="documentProperties.logo" :frame="copyrightNoteFrame" />
  </div>
</template>

<script>
// import draggable from 'vuedraggable'
import { mapState } from 'vuex'
import { chunk } from 'lodash'
import { frameStyleMixin } from '@/mixins/document-element-mixin'

import DocumentWatermark from '@/components/document/DocumentWatermark'
import DocumentTextField from '@/components/document/DocumentTextField'
import PageCopyrightNote from '@/components/document/PageCopyrightNote'

import GridElement from '@/components/document/GridElement'
import GridSettings from '@/components/document/GridSettings'
import { Grid } from '@custom-media/signdigital-lib'
import { GridPage } from '@custom-media/signdigital-lib/src/documents/grid-document'
import { FontStyle } from '@custom-media/signdigital-lib/src/documents/font-style'

export default {
  mixins: [frameStyleMixin],
  components: {
    DocumentWatermark,
    DocumentTextField,
    PageCopyrightNote,
    GridElement,
    GridSettings
    // GridElementPlaceholder
  },
  props: {
    page: Object
  },
  data () {
    return {
      titleStyle: new FontStyle(30, '700', 'left'),
      footerTextStyle: new FontStyle(10, '400', 'left'),
      watermarkFrame: { x: 0, y: 0, width: 160, height: 90 },
      titleFrame: { x: this.page.printableArea.width - 424 - 16, y: 0, width: 439, height: 60 },
      copyrightNoteFrame: {
        x: this.page.printableArea.width / 2,
        y: this.page.printableArea.height,
        width: this.page.printableArea.width / 2,
        height: 20
      },
      footerTextFrame: {
        x: 0,
        y: this.page.printableArea.height + 5,
        width: this.page.printableArea.width / 2,
        height: 15
      }
    }
  },
  computed: {
    ...mapState('documentEditor', {
      documentProperties: 'properties'
    }),
    elementsWrapperFrame () {
      const printableArea = this.page.printableArea
      return { x: 0, y: 100, width: printableArea.width, height: printableArea.height - 100 }
    },
    gridFrame () {
      const availableSpace = this.elementsWrapperFrame
      const gridSize = this.page.grid.outerDimensions
      return {
        x: (availableSpace.width - gridSize.width) / 2,
        y: (availableSpace.height - gridSize.height) / 2,
        height: gridSize.height,
        width: gridSize.width
      }
    },
    elementsWrapperFrameStyle () {
      return this.getFrameStyleFor(this.elementsWrapperFrame)
    },
    gridFrameStyle () {
      return this.getFrameStyleFor(this.gridFrame)
    },
    elementsByGridIndex () {
      const slots = Array(this.page.grid.rows)
        .fill(null)
        .map((_) => Array(this.page.grid.columns).fill(null))
      this.page.elementIds.forEach((elementId) => {
        const element = this.$store.getters['documentEditor/getElement'](elementId)
        slots[element.gridIndex.row][element.gridIndex.column] = element
      })
      return slots
    },
    slotsArray () {
      const slots = this.page.grid.mapElementIndices((gridIndex) => ({ gridIndex, element: null }))
      this.page.elementIds.forEach((elementId) => {
        const element = this.$store.getters['documentEditor/getElement'](elementId)
        const i = this.page.grid.getLinearIndex(element.gridIndex)
        slots[i].element = element
      })
      return slots
    }
  },
  methods: {
    onDocumentTitleChanged (newDocumentTitle) {
      this.$store.commit('documentEditor/updateProperty', { key: 'title', value: newDocumentTitle })
    },
    onDocumentLogoChanged (newLogo) {
      this.$store.commit('documentEditor/updateProperty', { key: 'logo', value: newLogo })
    },
    onFooterTextChanged (newFooterText) {
      this.$store.commit('documentEditor/updateProperty', { key: 'footerText', value: newFooterText })
    },
    onCreateElement (element) {
      this.$store.commit('documentEditor/addElementToPageById', { pageId: this.page.id, element })
    },
    onDeleteElement (element) {
      this.$store.commit('documentEditor/removeElementById', element.id)
    },
    onUpdateElement (element, changes) {
      this.$store.commit('documentEditor/patchElement', { id: element.id, data: changes })
    },
    onReplaceElement (slot, elementId) {
      this.$store.commit('documentEditor/moveElement', {
        elementId,
        targetPageId: this.page.id,
        targetGridIndex: slot.gridIndex,
        targetElementId: slot.element?.id
      })
    },
    /**
     * Handle changes to the grid
     * - find out if any elements need to overflow to the next page
     * - let the user confirm overflow
     * - create new pages, overflow items if necessary
     * - update grid and remove overflowed items from original page
     */
    onChangeGrid (grid) {
      // Find out if elements need to overflow
      const getElementById = this.$store.getters['documentEditor/getElement']
      const elementsOutside = this.page.elementIds
        .map(getElementById)
        .filter(({ gridIndex }) => gridIndex.column >= grid.columns || gridIndex.row >= grid.rows)

      if (elementsOutside.length > 0) {
        // Let the user confirm the overflow
        this.$buefy.dialog.confirm({
          title: 'Elemente werden verschoben',
          message: `Durch diese Änderung <strong>${elementsOutside.length > 1 ? 'passen' : 'passt'} ${
            elementsOutside.length
          } Element${
            elementsOutside.length > 1 ? 'e' : ''
          } nicht mehr auf die aktuelle Seite</strong>. Die Elemente werden auf eine oder mehrere neue Seiten verteilt.`,
          confirmText: 'Raster trotzdem ändern',
          cancelText: 'Abbrechen',
          type: 'is-warning',
          hasIcon: true,
          onConfirm: () => {
            const pageSize = grid.columns * grid.rows
            const pageIndex = this.$store.getters['documentEditor/getPageIndex'](this.page.id)
            const remaining = this.page.elementIds.filter((elementId) =>
              elementsOutside.every((o) => o.id !== elementId)
            )
            this.$refs.gridSettingsDropdown.isActive = false

            // Create new pages and overflow items
            chunk(elementsOutside, pageSize).forEach((elements, i) => {
              const indices = grid.allElementIndices
              // Assign new indices to elements
              elements.forEach((e, i) => (e.gridIndex = indices[i]))
              const page = new GridPage({ grid: Grid.fromData(grid.toData()) })
              const index = pageIndex + i + 1
              this.$store.commit('documentEditor/insertPageAt', { page, index })
              this.$store.commit('documentEditor/addElementsToPageById', { pageId: page.id, elements })
            })

            // Update grid and remove overflowed items from original page
            this.$store.commit('documentEditor/patchPage', {
              id: this.page.id,
              data: {
                grid,
                elementIds: remaining
              }
            })
          }
        })
      } else {
        // Update grid
        this.$store.commit('documentEditor/patchPage', { id: this.page.id, data: { grid } })
        this.$refs.gridSettingsDropdown.isActive = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/bulma-variables.scss';
.elements-wrapper {
  .placeholders-border-frame {
    box-sizing: border-box;
    border: 0.5px solid $grey-lightest;
  }
}

.grid-settings-dropdown {
  position: absolute;
  top: -40px;
  right: 0;

  .button {
    color: #133234;
    height: 1.75em;
  }
}
</style>
