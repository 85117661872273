<template>
  <image-component :ratio="ratio" v-if="image != null" :image="image" :draggable="false" />
</template>

<script>
import ImageComponent from '@custom-media/signdigital-web-shared/src/components/ImageComponent'
import { DocumentReference } from '@custom-media/signdigital-lib/src/image-reference'
import { ResolvableImage } from '@custom-media/signdigital-web-shared/src/lib/resolvable-image'
export default {
  components: {
    ImageComponent
  },
  props: {
    document: Object,
    square: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      image: null
    }
  },
  computed: {
    ratio () {
      if (this.square) {
        return '1by1'
      } else {
        return '210by297'
      }
    }
  },
  watch: {
    document (newValue) {
      if (newValue == null) {
        this.image = null
        return
      }
      if (newValue != null && this.image != null && newValue.id === this.image.reference.objectId) {
        // Noo need to reload
        return
      }
      this.updateImage()
    }
  },
  mounted () {
    this.updateImage()
  },
  methods: {
    updateImage () {
      this.image = new ResolvableImage({
        $store: this.$store,
        $resolver: this.$resolver,
        reference: new DocumentReference({ objectId: this.document._id }),
        variant: this.square ? 'thumbnail-square' : 'thumbnail'
      })
    }
  }
}
</script>
