<template>
  <div class="share-link-menu">
    <b-dropdown-item custom v-if="shareKey">
      <p class="is-size-7">
        Diesen Link kannst du an andere Personen weitergeben, um eine Kopie deiner Lernliste zu teilen.
      </p>
    </b-dropdown-item>
    <b-dropdown-item custom v-if="shareKey">
      <b-field>
        <b-input
          ref="keyInput"
          size="is-small"
          readonly="readonly"
          :value="shareLink"
          @click.native="onCopyToClipboard()"
        ></b-input>
        <p class="control">
          <b-button size="is-small" icon-left="copy" class="button is-primary" @click="onCopyToClipboard()"></b-button>
        </p>
      </b-field>
    </b-dropdown-item>
    <b-dropdown-item custom v-if="shareKey">
      <b-button icon-left="trash" expanded size="is-small" type="is-danger" :loading="isGenerating" @click="onDelete()"
        >Link löschen</b-button
      >
    </b-dropdown-item>
    <b-dropdown-item custom v-else>
      <p class="is-size-7">Mit einem Teilen-Link können andere Personen eine Kopie deiner Lernliste sehen.</p>
      <b-button icon-left="link" size="is-small" :loading="isGenerating" @click="onGenerate()"
        >Teilen-Link erzeugen</b-button
      >
    </b-dropdown-item>
  </div>
</template>

<script>
import shareLinkMixin from '@/mixins/share-link-mixin'

export default {
  mixins: [shareLinkMixin],
  props: {
    list: Object
  },
  computed: {
    shareKey () {
      return this.list.shareKey
    },
    shareLink () {
      return `${process.env.VUE_APP_FRONTEND_HOST}/lernliste/ueberblick/${this.shareKey}`
    }
  },
  methods: {
    async onGenerate () {
      this.isGenerating = true
      try {
        await this.list.patch({ data: { shareKey: 'generate' } })
        this.onCopyToClipboard()
      } finally {
        this.isGenerating = false
      }
    },
    async onDelete () {
      this.$buefy.dialog.confirm({
        type: 'is-danger',
        title: 'Teilen-Link löschen?',
        message: 'Du kannst zwar jederzeit einen neuen Link erzeugen, er wird jedoch vom aktuellen abweichen! ',
        confirmText: 'Link löschen',
        cancelText: 'Abbrechen',
        onConfirm: async () => {
          this.isGenerating = true
          try {
            await this.list.patch({ data: { shareKey: null } })
          } finally {
            this.isGenerating = false
          }
        }
      })
    }
  }
}
</script>
