<template>
  <nav class="panel">
    <b-loading :active="isLoading" :is-full-page="false" />
    <p class="panel-heading">{{ title }}</p>
    <div class="panel-block" v-if="canPickNodeType">
      <!-- Directory / File picker -->
      <b-field>
        <b-radio-button v-model="internal.nodeType" native-value="directory" size="is-small">
          <b-icon icon="folder"></b-icon>
          <span>Ordner</span>
        </b-radio-button>
        <b-radio-button v-model="internal.nodeType" size="is-small" native-value="file">
          <b-icon icon="file"></b-icon>
          <span>Datei</span>
        </b-radio-button>
      </b-field>
    </div>
    <div
      class="panel-block"
      v-if="internal.nodeType === 'file' && (canPickFileType || canPickDocumentType || canPickCustomSignType)"
    >
      <!-- File type picker -->
      <file-type-picker
        :file-type.sync="internal.fileType"
        :documentType.sync="internal.documentType"
        :customSignType.sync="internal.customSignType"
        :pick="pick"
        small
      />
    </div>
    <div class="panel-block main-block">
      <!-- Filename input -->
      <b-field
        label="Name"
        :type="filenameAlreadyExists && !isLoading ? 'is-danger' : ''"
        :message="
          filenameAlreadyExists && !isLoading
            ? 'Ein Objekt mit diesem Namen existiert bereits in diesem Ordner! Bitte wähle einen anderen Namen.'
            : ''
        "
        grouped
      >
        <b-input
          type="text"
          v-model="internal.filename"
          @focus="onInputFocus"
          :disabled="!canPickFilename"
          :placeholder="proposedFilename"
          @keydown.native="onKeydown"
          ref="nameInput"
          expanded
        />
        <p class="control">
          <b-button
            v-if="filenameAlreadyExists && !isLoading"
            type="is-danger"
            @click="setAvailableFilename"
            icon-left="redo"
            >Freien Namen nutzen</b-button
          >
        </p>
      </b-field>
      <!-- Location picker file browser -->
      <div class="location-picker">
        <b-field label="Speicherort">
          <div class="is-flex is-align-items-center">
            <file-node-breadcrumbs size="is-default" include-last :interactive="false" :browser-ctrl="browserCtrl" />
            <b-button
              size="is-small"
              :icon-left="isFileBrowserExpanded ? 'chevron-up' : 'chevron-down'"
              :disabled="!canPickLocation"
              @click="isFileBrowserExpanded = !isFileBrowserExpanded"
            />
          </div>
        </b-field>
        <file-browser
          v-if="browserCtrl && isFileBrowserExpanded"
          :browserCtrl="browserCtrl"
          inline
          :title="fileBrowserTitle"
          :fileActions="[]"
          :directoryActions="['open', 'create']"
          :itemToInsertPreview="itemToInsertPreview"
        />
      </div>
    </div>
    <div class="panel-block" v-if="error">
      <feathers-error
        class=""
        :error="error"
        title="Es ist ein Fehler aufgetreten"
        :closable="false"
        :messages="{
          409: 'Ein Objekt mit diesem Namen existiert bereits. Bitte wähle einen anderen Namen oder abweichenden Speicherort.'
        }"
      />
    </div>
    <div class="panel-block is-justify-content-flex-end">
      <div class="buttons">
        <b-button :size="size" @click="onCancel" :disabled="isLoading">Abbrechen</b-button>
        <b-button type="is-primary" :size="size" @click="onSubmit" :disabled="!canSubmit" :loading="isLoading">{{
          actionTitle
        }}</b-button>
      </div>
    </div>
  </nav>
</template>

<script>
import FileBrowser from '@/components/folders/FileBrowser.vue'
import FileNodeBreadcrumbs from './FileNodeBreadcrumbs.vue'
import FileTypePicker from './FileTypePicker.vue'
import { defaultNameForNodeType } from '@custom-media/signdigital-web-shared/src/mixins/file-node-mixins'
import FileNodeTreeItem from '@/lib/folders/FileNodeTreeItem'

export default {
  name: 'file-save-dialog',
  components: {
    FileBrowser,
    FileNodeBreadcrumbs,
    FileTypePicker
  },
  props: {
    browserCtrl: Object,
    title: String,
    actionTitle: {
      type: String,
      default: 'Hier speichern'
    },
    fileBrowserTitle: {
      type: String,
      default: null
    },
    size: {
      type: String,
      default: 'is-default'
    },

    // Existing item that shall be saved
    item: {
      type: Object,
      default: null
    },

    // Define which file properties can be modified
    pick: {
      type: Array,
      default () {
        return ['location', 'filename', 'fileType', 'documentType', 'customSignType']
      }
    },
    forceChange: {
      type: Array,
      default () {
        return []
      }
    },
    focus: {
      type: Array,
      default () {
        return []
      }
    },
    filename: {
      type: String,
      default: null
    },
    location: {
      type: Object,
      default: null
    },
    nodeType: {
      type: String,
      default: 'file'
    },
    fileType: {
      type: String,
      default: 'documents'
    },
    documentType: {
      type: String,
      default: 'grid'
    },
    customSignType: {
      type: String,
      default: 'signOnly'
    },
    closeOnCancel: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      isFileBrowserExpanded: false,
      internal: {
        filename: null,
        nodeType: null,
        fileType: null,
        documentType: null,
        customSignType: null
      },
      isLoading: false,
      error: null,
      proposedFilename: null
    }
  },
  created () {
    console.log('FileSaveDialog.created: ', this.proposedFilename)
  },
  mounted () {
    // Expand file browser by default for „move” action
    if (this.focus?.includes('location') || (this.canPickFilename === false && this.canPickLocation)) {
      this.isFileBrowserExpanded = true
    }

    this.internal.nodeType = this.item?.fileNode?.nodeType ?? this.nodeType
    this.internal.fileType = this.item?.fileNode?.fileType ?? this.fileType

    this.internal.documentType = this.item?.document?.type ?? this.documentType
    this.internal.customSignType = this.item?.document?.signType ?? this.customSignType

    if (this.item == null) {
      this.browserCtrl.setOriginalFilename(this.filename ?? this.defaultFilename)
    }

    this.browserCtrl.events.on('proposed-filename-changed', this.trackProposedFilename)
    this.proposedFilename = this.browserCtrl.proposedFilename
  },
  beforeDestroy () {
    this.browserCtrl.events.off('proposed-filename-changed', this.trackProposedFilename)
  },
  computed: {
    filenameAlreadyExists () {
      return this.browserCtrl.isFilenameAvailable(this.effectiveFilename) === false
      // TODO: If renaming, ensure name changed
    },
    defaultFilename () {
      return defaultNameForNodeType(this.internal.nodeType, this.internal.fileType)
    },
    hasFilenameOverride () {
      return this.internal.filename != null && this.internal.filename.length > 0
    },
    effectiveFilename () {
      return this.hasFilenameOverride ? this.internal.filename : this.proposedFilename
    },
    canSubmit () {
      // TODO: Ensure action is performed
      // const renamed = this.internal.name ===
      // if (this.renamable && !this.movable) {
      //   // Rename
      // } else if (this.renamable && thi)
      const isFilenameValid = !this.canPick('filename') || this.filenameAlreadyExists === false

      if (this.forceChange && this.forceChange.includes('filename')) {
        if (this.browserCtrl.originalFilename === this.effectiveFilename) {
          return false
        }
      }

      return isFilenameValid
    },
    canPickFilename () {
      return this.canPick('filename')
    },
    canPickLocation () {
      return this.canPick('location')
    },
    canPickNodeType () {
      return this.canPick('nodeType')
    },
    canPickFileType () {
      return this.canPick('fileType')
    },
    canPickDocumentType () {
      return this.canPick('documentType')
    },
    canPickCustomSignType () {
      return this.canPick('documentType')
    },
    itemToInsertPreview () {
      if (!this.effectiveFilename) {
        return null
      }
      if (this.browserCtrl.originalItem) {
        return new FileNodeTreeItem({
          ...this.browserCtrl.originalItem.fileNode,
          name: this.effectiveFilename
        })
      } else {
        return new FileNodeTreeItem({
          fileType: this.internal.fileType,
          nodeType: this.internal.nodeType,
          fileDetails: {
            documentType: this.internal.documentType,
            customSignType: this.internal.customSignType
          },
          name: this.effectiveFilename
        })
      }
    }
  },
  methods: {
    trackProposedFilename (v) {
      console.log('trackProposedFilename', v)
      this.proposedFilename = v
    },

    setAvailableFilename () {
      if (this.internal.filename !== this.browserCtrl.originalFilename) {
        this.browserCtrl.setOriginalFilename(this.internal.filename)
      }
      this.internal.filename = null
    },
    canPick (property) {
      return this.pick.includes(property)
    },
    onCancel () {
      if (this.closeOnCancel !== false) {
        this.$emit('close')
      }
      this.$emit('cancel', { dialog: this })
    },
    onSubmit () {
      this.isLoading = true
      this.$emit('submit', {
        item: this.item,
        location: this.browserCtrl.currentItem,
        dialog: this,
        ...this.internal,
        filename: this.effectiveFilename
      })
    },
    close () {
      this.$emit('close')
    },
    onKeydown (event) {
      if (event.keyCode === 13) {
        this.onSubmit()
      }
    },
    onInputFocus ($event) {
      if (!this.internal.filename || this.internal.filename === this.proposedFilename) {
        this.internal.filename = this.proposedFilename
        this.$nextTick().then(() => {
          $event.target.select()
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/bulma-variables.scss';
.main-block {
  display: block;
}
.panel {
  background: $white-ter;
  height: 100%;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  align-items: stretch;

  .main-block,
  .main-block .location-picker {
    overflow: hidden;
    display: flex;
    flex-shrink: 1;
    flex-direction: column;
    align-items: stretch;
  }

  ::v-deep {
    .panel.file-browser {
      overflow: hidden;
      display: flex;
      flex-shrink: 1;
      flex-direction: column;
      align-items: stretch;
      .file-browser-content {
        overflow: hidden;
        flex-shrink: 1;

        .file-browser-sidebar {
          overflow-y: scroll;
          flex-shrink: 1;
        }
        .file-browser-main {
          overflow-y: scroll;
          flex-shrink: 1;
        }
      }
    }
  }
}

.file-type-picker {
  width: 100%;
}
</style>
