<template>
  <div class="grid-settings p-3">
    <div class="level">
      <div class="level-left">
        <strong>Raster konfigurieren</strong>
      </div>
      <div class="level-right">
        <div class="buttons">
          <b-button size="is-small" @click="onCancel">Abbrechen</b-button>
          <b-button :disabled="!hasChanges" @click="onApply" type="is-primary" size="is-small">Raster ändern</b-button>
        </div>
      </div>
    </div>

    <div class="columns" v-if="localMillimeterGrid">
      <div class="column">
        <!-- Layout mode switch -->
        <b-field>
          <b-radio-button v-model="layoutMode" size="is-small" native-value="columns-rows">
            <b-icon icon="th-large"></b-icon>
            <span>Spalten & Zeilen</span>
          </b-radio-button>

          <b-radio-button v-model="layoutMode" size="is-small" native-value="width-height">
            <b-icon icon="ruler-combined"></b-icon>
            <span>Breite & Höhe</span>
          </b-radio-button>
        </b-field>
        <!-- Columns & rows -->
        <b-field grouped v-if="layoutMode === 'columns-rows'">
          <b-field label="Spalten">
            <b-numberinput
              min="1"
              max="15"
              type="is-light"
              size="is-small"
              v-model="localMillimeterGrid.columns"
              @input="onGridPropertyChanged"
              controls-position="compact"
            />
          </b-field>
          <b-field label="Reihen">
            <b-numberinput
              min="1"
              max="15"
              type="is-light"
              size="is-small"
              v-model="localMillimeterGrid.rows"
              @input="onGridPropertyChanged"
              controls-position="compact"
            />
          </b-field>
        </b-field>
        <!-- Exact dimensions -->
        <b-field grouped expanded v-if="layoutMode === 'width-height'">
          <b-field label="Breite">
            <b-numberinput
              min="0"
              min-step="0.01"
              type="is-light"
              size="is-small"
              v-model="localMillimeterGrid.elementSize.width"
              @input="onGridPropertyChanged"
              controls-position="compact"
            />
          </b-field>
          <b-field label="Höhe" expanded>
            <b-numberinput
              min="0"
              min-step="0.01"
              type="is-light"
              size="is-small"
              v-model="localMillimeterGrid.elementSize.height"
              @input="onGridPropertyChanged"
              controls-position="compact"
            />
          </b-field>
        </b-field>
        <b-field grouped>
          <b-field label="Abstand Spalten">
            <b-numberinput
              min="0"
              min-step="0.01"
              type="is-light"
              size="is-small"
              v-model="localMillimeterGrid.columnGap"
              @input="onGridPropertyChanged"
              controls-position="compact"
            />
          </b-field>
          <b-field label="Abstand Reihen">
            <b-numberinput
              min="0"
              min-step="0.01"
              type="is-light"
              size="is-small"
              v-model="localMillimeterGrid.rowGap"
              @input="onGridPropertyChanged"
              controls-position="compact"
            />
          </b-field>
        </b-field>

        <b-field expanded grouped>
          <b-field label="Vorlage laden" expanded>
            <b-select
              placeholder="Vorlage auswählen.."
              size="is-small"
              expanded
              v-model="template"
              @input="onTemplateSelected"
            >
              <option v-for="template in templates" :value="template.key" :key="template.key">
                {{ template.name }}
              </option>
            </b-select>
          </b-field>
        </b-field>

        <!-- Alignment -->
        <!-- <alignment-control :horizontal-alignment="" :vertical-alignment="" @change="onAlignmentChanged" /> -->
      </div>

      <div class="column">
        <b-field label="Vorschau">
          <grid-preview :grid="localGrid" :page="page" />
        </b-field>
      </div>
    </div>
  </div>
</template>

<script>
import GridPreview from '@/components/document/GridPreview'
import { Grid } from '@custom-media/signdigital-lib'
import { Size } from '@custom-media/geometry'
import { mmToPx } from '@custom-media/layout'

const mmToPxFactor = mmToPx(1)
const pxToMmFactor = 1 / mmToPxFactor

export default {
  components: {
    GridPreview
  },
  props: {
    grid: {
      type: Object
    },
    gridFrame: {
      type: Object
    },
    page: {
      type: Object
    }
  },
  data () {
    return {
      localGrid: null,
      localMillimeterGrid: null,
      layoutMode: 'width-height',
      hasChanges: false,
      template: null,
      templates: [
        {
          key: 'SIGNbox',
          name: 'SIGNbox Karten',
          gridProperties: {
            elementSize: new Size(80, 120),
            columnGap: 30,
            rowGap: 10,
            columns: 2,
            rows: 2
          }
        },
        {
          key: 'SIGNmap',
          name: 'SIGNmap Karten',
          gridProperties: {
            elementSize: new Size(50, 50),
            columnGap: 20,
            rowGap: 16,
            columns: 3,
            rows: 4
          }
        }
      ]
    }
  },
  watch: {
    grid (newValue) {
      this.convertLocalGrid()
    }
  },
  mounted () {
    this.convertLocalGrid()
  },
  methods: {
    reset () {
      this.convertLocalGrid()
      this.hasChanges = false
      this.template = null
    },
    convertLocalGrid () {
      this.localGrid = this.grid.clone()
      this.localMillimeterGrid = this.grid.multipliedByValue(pxToMmFactor)
    },
    onGridPropertyChanged () {
      this.hasChanges = true

      const { columns, rows, elementSize, columnGap, rowGap } = this.localMillimeterGrid
      const options = { columnGap, rowGap }
      if (this.layoutMode === 'width-height') {
        // Calculate columns / cells
        options.elementSize = elementSize
      } else {
        // Calculate elementSize
        options.columns = columns
        options.rows = rows
      }
      this.localMillimeterGrid = Grid.fittingSize(this.page.contentArea.size.multipliedByValue(pxToMmFactor), options)
      if (this.layoutMode === 'columns-rows') {
        // Floor elementSize to avoid floating point milimeter values
        this.localMillimeterGrid.elementSize.width = Math.floor(this.localMillimeterGrid.elementSize.width)
        this.localMillimeterGrid.elementSize.height = Math.floor(this.localMillimeterGrid.elementSize.height)
      }
      this.localGrid = this.localMillimeterGrid.multipliedByValue(mmToPxFactor)
    },
    onCancel () {
      this.$emit('cancel')
      this.reset()
    },
    onApply () {
      this.$emit('apply', this.localGrid)
      this.reset()
    },
    onTemplateSelected () {
      const template = this.templates.find((t) => t.key === this.template)
      this.layoutMode = 'width-height'
      this.localMillimeterGrid = new Grid(template.gridProperties)
      this.localGrid = this.localMillimeterGrid.multipliedByValue(mmToPxFactor)
      this.hasChanges = true
    }
  }
}
</script>

<style lang="scss" scoped>
.b-numberinput::v-deep input {
  width: 4rem;
}
::v-deep .label {
  font-size: 0.8rem;
}
.grid-preview {
  min-width: 140px;
}
</style>
