<template>
  <div class="card card-step">
    <div class="card-header">
      <span class="card-header-title">
        <b-icon v-if="loading" icon="spinner" type="is-success" class="fa-spin" />
        <span class="step-number" v-else-if="!completed">{{ stepTitle }}</span>
        <b-icon v-else icon="check" type="is-success" />
        <span>{{ cardTitle }}</span>
      </span>
      <b-button
        v-if="editable && completed && !active"
        class="edit-step-button"
        :disabled="disabled"
        @click="$emit('edit')"
        type="is-white"
        icon-left="chevron-down"
        >{{ editTitle }}</b-button
      >
    </div>
    <div class="card-body-wrapper">
      <div class="card-content pb-1" v-if="active">
        <b-loading :active="loading" :is-full-page="false" />
        <slot name="default" />
      </div>
      <div class="card-footer" v-if="active">
        <slot name="footer" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    stepTitle: String,
    cardTitle: String,

    editTitle: {
      type: String,
      default: 'Ändern'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    editable: {
      type: Boolean,
      default: true
    },

    active: {
      type: Boolean
    },

    loading: {
      type: Boolean,
      default: false
    },
    completed: {
      type: Boolean
    }
  }
}
</script>

<style lang="scss" scoped>
.card-body-wrapper {
  position: relative;
}
.step-number,
.icon {
  margin-right: 1em;
}
.edit-step-button {
  height: 100%;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
</style>
