import { Page } from '@custom-media/signdigital-lib/src/documents/document'
import {
  parseDocumentElement,
  parseDocumentPage,
  parseDocumentProperties
} from '@custom-media/signdigital-lib/src/documents/document-helper'
import {
  GridDocumentProperties,
  GridElement,
  GridPage
} from '@custom-media/signdigital-lib/src/documents/grid-document'
import {
  OwnSignDocumentProperties,
  OwnSignElement,
  OwnSignPage
} from '@custom-media/signdigital-lib/src/documents/own-sign-document'
import {
  TargetVocabularyDocumentProperties,
  TargetVocabularyElement,
  TargetVocabularyPage
} from '@custom-media/signdigital-lib/src/documents/target-vocabulary-document'
import Vue from 'vue'

const LOCAL_STORAGE_DOCUMENT_ID_KEY = 'de.custommedia.signdigital.currentdocument.id'
const LOCAL_STORAGE_DOCUMENT_DATA_KEY = 'de.custommedia.signdigital.currentdocument.data'

export default {
  namespaced: true,
  //
  // ─── CURRENTDOCUMENT MODULE ─────────────────────────────────────────────────────
  //
  state: () => {
    return {
      // Model properties
      _id: null,
      owner: null,
      ownerType: null,
      author: null,
      node: null,

      name: null,
      revision: 0,
      pageIds: [],
      pagesKeyedById: {},
      elementsKeyedById: {},
      properties: null,
      type: null,

      updatedAt: null,
      createdAt: null,

      // Lifecycle state
      isSavePending: false,
      savedPromise: null,
      hasUnsavedChanges: false
    }
  },
  getters: {
    pages (state) {
      return state.pageIds.map((pageId) => state.pagesKeyedById[pageId])
    },
    getPage: (state) => (id) => state.pagesKeyedById[id],
    getPageIndex: (state) => (id) => state.pageIds.indexOf(id),
    getElement: (state) => (id) => state.elementsKeyedById[id],
    hasPages (state) {
      return state.pageIds.length > 0
    },
    lastPage (state) {
      if (state.pageIds.length === 0) {
        return null
      }
      return state.pagesKeyedById[state.pageIds[state.pageIds.length - 1]]
    },
    documentData (state) {
      const pagesKeyedById = Object.entries(state.pagesKeyedById).reduce((acc, [id, page]) => {
        return { ...acc, [id]: page.toData() }
      }, {})
      const elementsKeyedById = Object.entries(state.elementsKeyedById).reduce((acc, [id, element]) => {
        return { ...acc, [id]: element.toData() }
      }, {})
      return {
        owner: state.owner,
        ownerType: state.ownerType,
        author: state.author,
        node: state.node,

        type: state.type,
        revision: state.revision,
        name: state.name,
        properties: state.properties?.toData(),
        pageIds: state.pageIds,
        pagesKeyedById,
        elementsKeyedById
      }
    }
  },
  mutations: {
    reset (state) {
      state._id = null

      state.owner = null
      state.ownerType = null
      state.author = null
      state.node = null

      state.revision = 0
      state.type = null

      state.name = null
      state.properties = null

      state.createdAt = null
      state.updatedAt = null

      state.pageIds = []
      state.pagesKeyedById = {}
      state.elementsKeyedById = {}

      state.isSavePending = false
      state.savedPromise = null
      state.hasUnsavedChanges = false
    },
    loadData (state, options) {
      state._id = options._id ?? null

      state.owner = options.owner ?? null
      state.ownerType = options.ownerType ?? 'users'
      state.author = options.author ?? null
      state.node = options.node ?? null

      state.name = options.name ?? null
      state.revision = options.revision ?? 0
      state.type = options.type ?? null

      state.properties = options.properties ?? null

      state.createdAt = options.createdAt ?? null
      state.updatedAt = options.updatedAt ?? null

      state.pageIds = options.pageIds ?? []

      const instanciatedPages = {}
      if (options.pagesKeyedById) {
        Object.entries(options.pagesKeyedById).forEach(([pageId, page]) => {
          instanciatedPages[pageId] = parseDocumentPage(page)
        })
      }
      state.pagesKeyedById = instanciatedPages

      const instanciatedElements = {}
      if (options.elementsKeyedById) {
        Object.entries(options.elementsKeyedById).forEach(([elementId, element]) => {
          instanciatedElements[elementId] = parseDocumentElement(element)
        })
      }
      state.elementsKeyedById = instanciatedElements

      state.isSavePending = false
      state.savedPromise = null
      state.hasUnsavedChanges = false
    },
    mergeData (state, document) {
      state._id = document._id

      state.owner = document.owner
      state.ownerType = document.ownerType
      state.author = document.author
      state.node = document.node

      state.revision = document.revision
      state.type = document.type

      state.createdAt = document.createdAt
      state.updatedAt = document.updatedAt

      state.name = document.name
      state.properties = parseDocumentProperties(document.properties)

      state.pageIds = document.pageIds

      // TODO: Maybe merge pages instead of replacing

      // state.pagesKeyedById = document.pagesKeyedById
      const instanciatedPages = {}
      Object.entries(document.pagesKeyedById).forEach(([pageId, page]) => {
        instanciatedPages[pageId] = parseDocumentPage(page)
      })
      state.pagesKeyedById = instanciatedPages

      // TODO: Maybe merge elements instead of replacing
      const instanciatedElements = {}
      Object.entries(document.elementsKeyedById).forEach(([elementId, element]) => {
        instanciatedElements[elementId] = parseDocumentElement(element)
      })
      state.elementsKeyedById = instanciatedElements
    },
    mergeEssentialData (state, document) {
      state._id = document._id
      state.createdAt = document.createdAt
      state.updatedAt = document.updatedAt
    },
    setName (state, newName) {
      state.name = newName
      state.hasUnsavedChanges = true
    },
    updateProperty (state, { key, value }) {
      if (state.properties[key] === undefined) {
        throw Error('Unable to update not existing property ' + key)
      }
      state.properties[key] = value
      state.hasUnsavedChanges = true
    },
    incrementRevision (state) {
      state.revision += 1
      state.hasUnsavedChanges = true
    },
    setHasUnsavedChanges (state, value) {
      state.hasUnsavedChanges = value
    },
    setIsSavePending (state, { isPending, promise }) {
      state.isSavePending = isPending
      state.savedPromise = promise
    },
    closeDocument (state, document) {
      window.localStorage.removeItem(LOCAL_STORAGE_DOCUMENT_ID_KEY)
      window.localStorage.removeItem(LOCAL_STORAGE_DOCUMENT_DATA_KEY)
    },
    //
    // ─── PAGE ───────────────────────────────────────────────────────────────────────
    //
    addPage (state, page) {
      if (state.pagesKeyedById[page.id] != null) {
        console.warn('Already has element with id ' + page.id)
        return
      }
      Vue.set(state.pagesKeyedById, page.id, page)
      state.pageIds.push(page.id)
      state.hasUnsavedChanges = true
    },
    insertPageAt (state, { index, page }) {
      if (state.pagesKeyedById[page.id] != null) {
        console.warn('Already has element with id ' + page.id)
        return
      }
      Vue.set(state.pagesKeyedById, page.id, page)
      state.pageIds.splice(index, 0, page.id)
    },
    removePage (state, page) {
      if (page instanceof Page) {
        page = page.id
      }
      Vue.delete(state.pagesKeyedById, page)
      const i = state.pageIds.indexOf(page)
      Vue.delete(state.pageIds, i)
      state.hasUnsavedChanges = true
    },
    patchPage (state, { id, data }) {
      Object.entries(data).forEach(([key, value]) => {
        Vue.set(state.pagesKeyedById[id], key, value)
      })
      state.hasUnsavedChanges = true
    },
    //
    // ─── ELEMENTS ────────────────────────────────────────────────────
    //
    // addElements (state, elements) {
    //   for (const element of elements) {
    //     if (state.elementsKeyedById[element.id] != null) {
    //       console.warn('Already has element for id ' + element.id)
    //       continue
    //     }
    //     state.elementsKeyedById[element.id] = element
    //   }
    //   state.hasUnsavedChanges = true
    // },
    addElement (state, element) {
      if (state.elementsKeyedById[element.id] != null) {
        console.warn('Already has element for id ' + element.id)
        return
      }
      Vue.set(state.elementsKeyedById, element.id, element)
      state.hasUnsavedChanges = true
    },
    addElementToPageById (state, { pageId, element }) {
      const elementId = element.id
      state.pagesKeyedById[pageId].elementIds.push(elementId)
      Vue.set(state.elementsKeyedById, elementId, element)
      state.hasUnsavedChanges = true
    },
    addElementsToPageById (state, { pageId, elements }) {
      elements.forEach((e) => {
        const elementId = e.id
        state.pagesKeyedById[pageId].elementIds.push(elementId)
        Vue.set(state.elementsKeyedById, elementId, e)
      })
      state.hasUnsavedChanges = true
    },
    // Move element between slots
    moveElement (state, { elementId, targetPageId, targetGridIndex, targetElementId }) {
      // TODO: [SIGN-243] Remove the grid-specific move element logic from the document-editor, split it into multiple mutations
      const originalPageId = Object.values(state.pagesKeyedById).find((page) => page.elementIds.includes(elementId))?.id
      const originalPage = state.pagesKeyedById[originalPageId]
      const targetPage = state.pagesKeyedById[targetPageId]
      const originalPageElementIndex = originalPage.elementIds.indexOf(elementId)

      if (originalPageId !== targetPageId) {
        console.log('Moved between pages', originalPageId, targetPageId)
      } else {
        console.log('Moved within page', targetPageId)
      }

      // Is switching places with an existing element?
      if (targetElementId != null) {
        console.log('Is replace operation')
        const originalGridIndex = state.elementsKeyedById[elementId].gridIndex
        Vue.set(state.elementsKeyedById[targetElementId], 'gridIndex', originalGridIndex)

        const targetElementIndex = targetPage.elementIds.indexOf(targetElementId)
        Vue.set(targetPage.elementIds, targetElementIndex, elementId) // Move original to target
        Vue.set(originalPage.elementIds, originalPageElementIndex, targetElementId) // Move target to original
      } else {
        targetPage.elementIds.push(elementId) // Push new
        Vue.delete(originalPage.elementIds, originalPageElementIndex) // Delete old
      }
      // Update grid index of the moved element
      Vue.set(state.elementsKeyedById[elementId], 'gridIndex', targetGridIndex)
      state.hasUnsavedChanges = true
    },
    patchElement (state, { id, data }) {
      Object.entries(data).forEach(([key, value]) => {
        Vue.set(state.elementsKeyedById[id], key, value)
      })
      state.hasUnsavedChanges = true
    },
    removeElementById (state, id) {
      Vue.delete(state.elementsKeyedById, id)
      for (const page of Object.values(state.pagesKeyedById)) {
        const i = page.elementIds.indexOf(id)
        if (i !== -1) {
          Vue.delete(page.elementIds, i)
          break
        }
      }
    }
  },
  actions: {
    //
    // ─── DOCUMENT ────────────────────────────────────────────────────
    //
    loadDocument ({ commit, dispatch }, document) {
      commit('reset')
      commit('mergeData', document)
    },

    async loadDocumentWithId ({ commit, dispatch }, documentId) {
      const document = await dispatch('documents/get', documentId, { root: true })
      commit('reset')
      // TODO: Reset pages
      commit('mergeData', document)
      // TODO: Merge pages
    },

    async rename ({ state, dispatch, commit }, name) {
      const res = await dispatch('documents/patch', [state._id, { name }], { root: true })
      state.name = name
      await dispatch('file-nodes/get', res.node, { root: true })
      await dispatch('fileNodeTree/rebuild', null, { root: true })
    },

    async createNewDocument ({ rootState, dispatch }, options) {
      switch (options.documentType) {
        case 'grid':
          return dispatch('createGridDocument', options)
        case 'target-vocabulary':
          return dispatch('createTargetVocabularyDocument', options)
        case 'own-sign':
          return dispatch('createOwnSignDocument', options)
      }
    },
    async createOwnSignDocument ({ commit, dispatch, rootState }, options = {}) {
      const author = rootState.auth.user._id
      const properties = new OwnSignDocumentProperties({})
      commit('loadData', {
        owner: options.owner,
        ownerType: options.ownerType,
        node: options.node,
        author,
        type: 'own-sign',
        name: options.name || '„Meine Gebärden”-Dokument',
        properties
      })
      await dispatch('addNewPage')
      commit('setHasUnsavedChanges', false)
    },
    async createTargetVocabularyDocument ({ commit, dispatch, rootState }, options = {}) {
      const author = rootState.auth.user._id
      const properties = new TargetVocabularyDocumentProperties()
      commit('loadData', {
        owner: options.owner,
        ownerType: options.ownerType,
        node: options.node,
        author,
        type: 'target-vocabulary',
        name: options.name ?? '„Zielwortschatz”-Dokument',
        properties
      })
      await dispatch('addNewPage')
      commit('setHasUnsavedChanges', false)
    },
    async createGridDocument ({ commit, dispatch, rootState }, options = {}) {
      const author = rootState.auth.user._id
      const properties = new GridDocumentProperties()
      commit('loadData', {
        owner: options.owner,
        ownerType: options.ownerType,
        node: options.node,
        author,
        type: 'grid',
        name: options.name ?? '„Raster”-Dokument',
        properties
      })
      await dispatch('addNewPage')
      commit('setHasUnsavedChanges', false)
    },
    async _executeSave ({ state, commit, getters, dispatch }) {
      commit('incrementRevision')
      commit('setHasUnsavedChanges', false)

      let document
      try {
        if (state._id === null) {
          // Create object
          document = await dispatch('documents/create', getters.documentData, { root: true })
        } else {
          // Patch object
          document = await dispatch('documents/patch', [state._id, getters.documentData], { root: true })
        }
      } catch (error) {
        commit('setHasUnsavedChanges', true)
        throw error
      }

      console.log(document)
      // if (document.revision === state.revision) {
      commit('mergeEssentialData', document)
      //   // TODO: Only merge _id, createdAt, updatedAt?
      // } else {
      //   console.log('Local document already changed. Skipping merge.')
      // }
      return document
    },
    async save ({ state, commit, dispatch }) {
      console.log('Save requested')
      // TODO: Store in savedPromise

      // NOTE: Fix for SIGN-564 save if document was not yet created on server
      const isNewDocument = state._id === null
      if (!isNewDocument && state.hasUnsavedChanges === false) {
        console.warn('Skipping save: No unsaved changes.')
        return state.savedPromise
      }
      if (state.isSavePending === true) {
        console.log('Save is already in progress')
        return state.savedPromise
      }
      const savedPromise = dispatch('_executeSave')
      commit('setIsSavePending', { isPending: true, promise: savedPromise })
      const res = await savedPromise
      commit('setIsSavePending', { isPending: false, promise: savedPromise })
      return res
    },
    //
    // ─── PAGES ───────────────────────────────────────────────────────
    //
    async addNewPage ({ state, commit, getters }) {
      let page, elements
      switch (state.type) {
        case 'own-sign':
          elements = [new OwnSignElement(), new OwnSignElement(), new OwnSignElement()]
          page = new OwnSignPage()
          break
        case 'target-vocabulary':
          page = new TargetVocabularyPage()
          break
        case 'grid':
          page = new GridPage({ grid: getters.lastPage?.grid?.clone() })
          break
      }

      commit('addPage', page)
      if (elements != null) {
        commit('addElementsToPageById', { pageId: page.id, elements })
      }
      return page
    },

    async removePage ({ commit }, page) {
      commit('removePage', page)
      if (page.elementIds.length > 0) {
        page.elementIds.forEach((id) => commit('removeElementById', id))
      }
    },

    async addNewElement ({ state, commit, dispatch }, pageId) {
      const type = state.type
      const element = await dispatch('createNewElement', type)
      commit('addElementToPageById', { pageId, element })
      return element
    },

    removeElementById ({ commit }, elementId) {
      commit('removeElementById', elementId)
    },

    //
    // ─── ELEMENTS ────────────────────────────────────────────────────
    //
    createNewElement ({ commit }, type) {
      let element
      switch (type) {
        case 'own-sign':
          element = new OwnSignElement()
          break
        case 'target-vocabulary':
          element = new TargetVocabularyElement()
          break
        case 'grid':
          element = new GridElement()
          break
      }
      return element
    }
  }
}
