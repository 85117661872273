<template>
  <div id="settings-view">
    <!-- NAVBAR -->
    <b-navbar class="app-nav is-mobile" :mobile-burger="false" :active="true">
      <template slot="brand">
        <b-navbar-item tag="div">
          <nav class="breadcrumb" aria-label="breadcrumbs">
            <ul>
              <li
                v-for="(route, index) in breadcrumbs"
                :key="route.meta.breadcrumbName"
                :class="{ 'is-active': index === breadcrumbs.length - 1 }"
              >
                <router-link :to="route.path">{{ route.meta.breadcrumbName }}</router-link>
              </li>
            </ul>
          </nav>
        </b-navbar-item>
      </template>
      <template slot="start"> </template>
      <template slot="end"> </template>
    </b-navbar>
    <router-view />
  </div>
</template>
<script>
export default {
  computed: {
    breadcrumbs () {
      const crumbs = this.$route.matched.filter((r) => r.meta?.breadcrumbName != null)
      return crumbs
    }
  }
}
</script>

<style lang="scss" scoped>
#settings-view {
}
</style>
