<template>
  <div class="list-template-card card" @click="$emit('click', $event)">
    <div class="card-image" :class="'hover-color-' + listTemplate.color">
      <list-template-thumbnail variant="small" :list-template="listTemplate" />
    </div>
    <div class="card-content">
      <h1 class="title">{{ listTemplate.name }}</h1>
      <p class="description">{{ listTemplate.description }}</p>
    </div>
  </div>
</template>

<script>
import ListTemplateThumbnail from '@custom-media/signdigital-web-shared/src/components/ListTemplateThumbnail.vue'
export default {
  components: {
    ListTemplateThumbnail
  },
  props: {
    loading: {
      default: false,
      type: Boolean
    },
    listTemplate: Object
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/bulma-variables.scss';
.list-template-card {
  display: inline-block;
  transition:
    transform ease-out 0.25s,
    box-shadow ease-out 0.25s;
  display: flex;
  flex-direction: column;

  .card-image {
    transition: background-color ease-out 0.25s;
    background-color: $grey-lighter;
    perspective: 800px;
    overflow: hidden;

    img {
      transform-origin: center bottom;
      transition: transform ease-out 0.25s;
    }
  }

  &:hover {
    transform: scale(1.02);
    box-shadow:
      0 0em 1.5em -0.05em rgba(10, 10, 10, 0.1),
      0 0px 0 0px rgba(10, 10, 10, 0.02);
    cursor: pointer;

    .card-image {
      img {
        transform: scale(1.02) rotate3d(1, 0, 0, -6deg);
      }
    }
  }
  .card-image.hover-color-primary:hover {
    background-color: $primary;
  }
  .card-image.hover-color-secondary:hover {
    background-color: $secondary;
  }

  .card-content {
    padding: 1rem 1rem;
    font-size: 0.8rem;

    h1.title {
      font-size: 1rem;
      margin-bottom: 0.5rem;
    }

    p.description {
      margin-bottom: 1em;
    }
  }
}
</style>
