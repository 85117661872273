<template>
  <div class="list-player">
    <!-- Navbar -->
    <b-navbar class="is-dark is-mobile" :mobile-burger="false" :active="true">
      <template slot="start">
        <b-navbar-item tag="div" v-if="list">
          {{ list.name }}
        </b-navbar-item>
        <b-navbar-item tag="div" class="progress-item" v-if="isSharedList && !isUnlicensed">
          <b-progress
            v-if="list && list.progress != null"
            :value="list.progress"
            show-value
            format="percent"
            type="is-primary"
          ></b-progress>
        </b-navbar-item>
        <b-navbar-item tag="div">
          <b-icon icon="random" v-if="shuffle" />
        </b-navbar-item>
      </template>
      <template slot="end">
        <b-navbar-item tag="div" v-if="isSharedList && !isUnlicensed">
          <b-button @click="onSaveSharedList" :loading="isCreatePending" type="is-primary" :size="buttonSize"
            >Abspeichern</b-button
          >
        </b-navbar-item>

        <b-navbar-item tag="div" v-if="!isSharedList">
          <b-button
            tag="router-link"
            :to="{ name: 'list-player-setup', params: { id: $route.params.id } }"
            type="is-light"
            :size="buttonSize"
          >
            Zurück
          </b-button>
        </b-navbar-item>

        <b-navbar-item tag="div" v-else>
          <b-button
            tag="router-link"
            :to="{ name: 'list-overview-public', params: { shareKey: $route.params.shareKey } }"
            type="is-light"
            :size="buttonSize"
          >
            Übersicht anzeigen
          </b-button>
        </b-navbar-item>
      </template>
    </b-navbar>

    <b-loading :active="isLoading" :is-full-page="false" />
    <!-- CONTENT -->
    <section class="player content" v-if="!isLoading && visibleSigns.length > 0 && !isEndOfUnlicensedScreen">
      <!-- Navigation -->
      <div class="container list-player-navigation">
        <div class="level is-mobile">
          <!-- Previous -->
          <div class="level-item previous">
            <b-button
              size="is-large"
              type="is-primary"
              icon-left="arrow-left"
              :disabled="!hasPrevious"
              @click="onPreviousButtonClicked"
            />
          </div>
          <!-- Learned -->
          <div class="level-item">
            <b-button v-if="showRevealAllButton" @click="revealAllButtonClicked" type="is-primary" icon-right="sparkles"
              >Alles aufdecken</b-button
            >
            <div v-else class="controls">
              <learned-button v-if="!isSharedList" @click="onLearnedButtonClicked" />
              <div v-else-if="isUnlicensed" :class="textNotLoggedInSize">
                <span>Für vollen Zugriff, weitere Funktionen </span><br /><span>und Gebärden ohne Wasserzeichen</span
                ><br />
                <span
                  >bitte mit Lizenz
                  <router-link :to="{ name: 'login' }" tag="a" class="no-hover">anmelden</router-link>.</span
                >
              </div>
            </div>
          </div>
          <!-- Next -->
          <div class="level-item next">
            <b-button
              size="is-large"
              type="is-primary"
              icon-left="arrow-right"
              v-if="!hasPrevious || hasNext"
              :disabled="!hasNext"
              @click="onNextButtonClicked"
            />
            <b-button
              v-if="!hasNext && hasPrevious && !isUnlicensed"
              size="is-large"
              type="is-primary"
              icon-left="undo"
              @click="goTo(0)"
            />
            <b-button
              v-else-if="!hasNext && hasPrevious && isUnlicensed"
              size="is-large"
              type="is-primary"
              icon-left="arrow-right"
              @click="showEndOfUnlicensedScreen()"
            />
          </div>
        </div>
      </div>

      <!-- Content player -->
      <div class="sign-details-wrapper">
        <sign-details
          :sign="currentSign"
          y-inset="210px"
          :show-title="true"
          :new-tab-button="false"
          :share-button="false"
          :revealable="revealable"
          :revealed="revealed"
          :unlicensedContent="isUnlicensed"
          @update:revealed="setRevealed"
          :fullscreen="false"
        ></sign-details>
      </div>
    </section>
    <!-- Completed -->
    <section class="hero is-dark completed" v-else-if="!isLoading && !isEndOfUnlicensedScreen">
      <div class="hero-body has-text-centered">
        <b-icon icon="check-circle" size="is-large" class="animate__animated animate__tada" />
        <h1 class="title mb-6 mt-6">Du hast alle Gebärden als gelernt markiert!</h1>
        <h2 class="subtitle mb-6">Was jetzt?</h2>
        <div class="buttons is-centered">
          <b-button type="is-primary" @click="onResetButtonClicked">Nochmal von vorn</b-button>
          <b-button tag="router-link" :to="{ name: 'lists' }">Zurück zur Übersicht</b-button>
        </div>
      </div>
    </section>
    <section class="hero is-dark completed end-of-unlicensed" v-if="!isLoading && isEndOfUnlicensedScreen">
      <div class="hero-body">
        <b-icon icon="lock" size="is-large" class="animate__animated animate__tada" />
        <div class="content has-text-left">
          <h1 class="title mb-6 mt-6">Ende der Lernlisten-Vorschau</h1>
          <h4 class="subtitle mb-6">
            Du benötigst eine SIGNdigital-Lizenz,<br />
            um weitere Gebärden auf dieser Lernliste anzuzeigen
          </h4>
          <p>Mit einer Lizenz kannst du außerdem...</p>
          <ul>
            <li>die Lernliste abspeichern und bearbeiten</li>
            <li>eigene Lernlisten erstellen</li>
            <li>die Quiz-Funktion der Lernlisten nutzen</li>
            <li>Über 1200 Gebärden-Videos und -zeichnungen ohne Wasserzeichen anzeigen</li>
            <li>Druckvorlagen mit Gebärdenzeichnungen erstellen</li>
            <li>und vieles mehr!</li>
          </ul>
        </div>
        <div class="buttons is-centered mt-5" v-if="!isLoggedIn">
          <b-button tag="router-link" :to="{ name: 'signup' }" type="is-secondary">Neues Konto erstellen</b-button>
          <b-button tag="router-link" :to="{ name: 'login' }" type="is-primary"
            >Mit bestehendem Konto anmelden</b-button
          >
        </div>
        <div class="buttons is-centered mt-5" v-else-if="isUnlicensed">
          <b-button tag="router-link" :to="{ name: 'user-subscription' }" type="is-primary">Lizenz erwerben</b-button>
        </div>
        <div class="buttons is-centered">
          <b-button tag="a" :href="landingPageUrl" target="_blank" type="is-light">Mehr über SIGNdigital</b-button>
          <b-button type="is-light" icon-left="redo" @click="onResetButtonClicked">Nochmal von vorn</b-button>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import SignDetails from '@/components/SignDetails.vue'
// import SignDetailsPublic from '@/views/SignDetailsPublic.vue'
import LearnedButton from '@/components/list/LearnedButton.vue'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import windowHeightMixin from '@/mixins/window-height-mixin'
export default {
  mixins: [windowHeightMixin],
  components: {
    SignDetails,
    // SignDetailsPublic,
    LearnedButton
  },
  data () {
    return {
      isEndOfUnlicensedScreen: false,
      landingPageUrl: process.env.VUE_APP_LANDING_HOST
    }
  },
  mounted () {
    this.init()
    window.addEventListener('keydown', this.onKeydown)
  },
  beforeDestroy () {
    window.removeEventListener('keydown', this.onKeydown)
  },
  computed: {
    ...mapState('listPlayer', ['list', 'revealable', 'revealed', 'shuffle', 'index', 'isLoading']),
    ...mapGetters('listPlayer', [
      'isUnlicensed',
      'visibleSigns',
      'unlearnedSigns',
      'currentSign',
      'hasPrevious',
      'hasNext',
      'isLast'
    ]),
    ...mapState('lists', ['isCreatePending']),
    isSharedList () {
      return !!this.$route.params?.shareKey
    },
    showRevealAllButton () {
      return this.revealable.length !== this.revealed.length
    },
    isLoggedIn () {
      return !!this.$store.getters['auth/user']
    },
    buttonSize () {
      return !this.$screen.tablet ? 'is-small' : 'is-default'
    },
    textNotLoggedInSize () {
      return !this.$screen.tablet ? 'is-size-7' : ''
    }
  },
  methods: {
    ...mapActions('lists', { getList: 'get' }),
    ...mapActions('listPlayer', ['resetProgress', 'markAsLearned', 'ensureLoaded']),
    ...mapMutations('listPlayer', ['setList', 'setRevealed', 'goToNext', 'goToPrev', 'goTo', 'shuffleSigns']),
    async init () {
      this.ensureLoaded(this.$route.params)
    },
    onNextButtonClicked () {
      if (!this.hasNext) {
        return
      }
      this.goToNext()
      this.resetRevealProgress()
    },
    onPreviousButtonClicked () {
      if (!this.hasPrevious) {
        return
      }
      this.goToPrev()
      this.resetRevealProgress()
    },
    revealAllButtonClicked () {
      this.setRevealed([...this.revealable])
    },
    async onLearnedButtonClicked () {
      if (this.unlearnedSigns.length === 0) {
        return
      }
      // Mark as learned
      this.resetRevealProgress()
      await this.markAsLearned(this.currentSign._id)
    },
    onResetButtonClicked () {
      this.resetProgress()
      this.shuffleSigns()
      this.resetRevealProgress()
      this.isEndOfUnlicensedScreen = false
    },
    resetRevealProgress () {
      this.setRevealed([])
    },
    showEndOfUnlicensedScreen () {
      this.isEndOfUnlicensedScreen = true
    },
    async save () {
      if (!this.isUnlicensed) {
        await this.list.save()
      }
    },
    onKeydown (event) {
      if (event.code === 'ArrowRight') {
        this.onNextButtonClicked()
      } else if (event.code === 'ArrowLeft') {
        this.onPreviousButtonClicked()
      } else if (event.code === 'Enter') {
        this.onLearnedButtonClicked()
      }
    },
    async onSaveSharedList () {
      const { file } = await this.$fileDialog.create({
        nodeType: 'file',
        fileType: 'lists',
        filename: this.list.name,
        parent: this,
        fileData: {
          signs: this.list?.signs ?? []
        }
      })
      this.$router.replace({ name: 'list-editor', params: { id: file._id } })
      console.log(file)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/bulma-variables.scss';

section.completed {
  .hero {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .icon {
    font-size: 3em;
  }
}

.list-player {
  display: flex;
  flex-direction: column;
  position: relative;
  background: $dark;
  overflow: auto;
  height: 100%;
  min-height: 100vh;

  @include from($desktop) {
    height: 100vh;
    overflow: hidden;
  }

  .player.content {
    min-height: 0;
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
    flex-direction: column;
    justify-content: space-around;
    overflow: hidden;

    @include from($desktop) {
      flex-flow: column-reverse;
    }
  }

  .navbar {
    &::v-deep {
      .navbar-menu {
        flex-wrap: wrap-reverse;
      }

      .navbar-brand {
        display: none;
      }

      .navbar-start {
        flex-wrap: wrap;
      }
    }

    .navbar-item {
      display: flex;
      color: #fff;
      align-items: center;
    }
  }

  .progress-item {
    min-width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;

    .progress-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-grow: 1;
    }
  }

  .sign-details-wrapper {
    position: relative;
    display: flex;
    flex-shrink: 1;
    overflow: hidden;

    .sign-details {
      height: auto;
      min-height: 100%;
    }
  }

  .level {
    display: flex;
  }

  .list-player-navigation {
    z-index: 25;
    flex-grow: 0;
    flex-shrink: 1;
    width: 100%;
    padding-left: 1em;
    padding-right: 1em;
    padding-bottom: 1em;

    @include until($tablet) {
      button.button.is-large {
        font-size: 1.2em;
      }

      padding-left: 0.5em;
      padding-right: 0.5em;
    }

    .level-item.next {
      justify-content: flex-end;
    }

    .level-item.previous {
      justify-content: flex-start;
    }

    .level-item {
      flex: 1;

      .controls {
        flex-direction: column;
        color: white;
        align-items: center;
        display: flex;

        label {
          font-weight: bold;
          font-size: 2rem;
          margin-bottom: 1rem;
          text-align: center;

          @include until($tablet) {
            font-size: 1.2rem;
          }
        }
      }
    }
  }
}

.no-hover:hover {
  color: #3c9ea4;
}

.end-of-unlicensed {
  .hero-body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
</style>
