<template>
  <div class="search-settings-dropdown">
    <b-dropdown-item aria-role="menu-item" :focusable="false" custom>
      <b-field label="Spalten" type="" :message="columnMessage">
        <b-slider size="is-large" :min="1" :max="10" :value="search.columns" @input="onColumnsChanged" expanded>
          <template v-for="val in [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]">
            <b-slider-tick :value="val" :key="val">{{ val }}</b-slider-tick>
          </template>
        </b-slider>
      </b-field>

      <image-format-switch :value="search.imageType" @input="onImageTypeChanged" />

      <b-field label="Weitere Optionen">
        <b-switch :value="search.showNames" @input="onShowNamesChanged"> Name der Gebärde anzeigen </b-switch>
      </b-field>
    </b-dropdown-item>
  </div>
</template>

<script>
import ImageFormatSwitch from '@custom-media/signdigital-web-shared/src/components/ImageFormatSwitch'
import { mapActions, mapState } from 'vuex'
export default {
  components: {
    ImageFormatSwitch
  },
  computed: {
    ...mapState('preferences', ['search']),
    columnMessage () {
      const limit = this.columnLimit
      if (this.search.columns <= limit) {
        return null
      }
      return `Aufgrund von Platzmangel wurde die Anzahl der Spalten auf ${limit} beschränkt`
    },
    columnLimit () {
      if (this.$screen.widescreen) {
        return 10
      } else if (this.$screen.desktop) {
        return 7
      } else if (this.$screen.tablet) {
        return 5
      } else if (this.$screen.mobile) {
        return 2
      }
      return 10
    }
  },
  mounted () {},
  methods: {
    ...mapActions('preferences', ['updateSearchPreferences']),
    onColumnsChanged (columns) {
      if (this.search.columns === columns) {
        return
      }
      this.updateSearchPreferences({ columns })
    },
    onImageTypeChanged (imageType) {
      if (this.search.imageType === imageType) {
        return
      }
      this.updateSearchPreferences({ imageType })
    },
    onShowNamesChanged (showNames) {
      if (this.search.showNames === showNames) {
        return
      }
      this.updateSearchPreferences({ showNames })
    }
  }
}
</script>

<style lang="scss" scoped>
.search-settings-dropdown {
  &::v-deep {
    .b-slider {
      margin-bottom: 2em;
      padding-left: 1em;
      padding-right: 1em;

      .b-slider-tick-label {
        padding-top: 0.6em;
      }
    }
  }
}
</style>
