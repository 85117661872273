import Vue from 'vue'
import {
  SignEditorArrowObject,
  SignEditorImageObject,
  SignEditorObject,
  SignEditorTextObject
} from '@custom-media/signdigital-lib/src/sign-editor-object'
import { Size, Point } from '@custom-media/geometry'
import { SIGN_BOX_FIXED_ELEMENTS, SIGN_BOX_STYLES } from '@/lib/constants/sign-editor-constants.js'
import { pick } from 'lodash'
import mitt from 'mitt'

export default {
  namespaced: true,
  //
  // ─── CURRENTDOCUMENT MODULE ─────────────────────────────────────────────────────
  //
  state: () => {
    return {
      // Model properties
      owner: null,
      ownerType: null,
      author: null,
      node: null,

      _id: null,
      signType: 'signOnly',
      name: null,

      objectIds: [],
      objectsKeyedById: {},

      // References to unique layout objects referenced by unique layout key
      fixedObjectIdsByLayoutKey: {},

      updatedAt: null,
      revision: 0,

      // Options specific to a certain signType
      signTypeProperties: {
        signBox: {
          // SIGNbox-Specific layout options ['single', 'composed']
          layout: null,
          style: null,
          imageScaleOverrides: {}
        }
      },

      // Interface state
      isClippingMaskVisible: true,
      selectedObjectIds: [],
      canvasScale: 1,

      // Lifecycle state
      isLoaded: false,
      isSavePending: null,
      savedPromise: null,
      hasUnsavedChanges: false,

      // Communication bus
      events: mitt()
    }
  },
  getters: {
    contentSize (state) {
      switch (state.signType) {
        case 'signOnly':
          return new Size(800, 800)
        case 'signBox':
          return new Size(800, 1228)
      }
      return null
    },
    fixedObjectForLayoutKey (state) {
      return (key) => {
        const id = state.fixedObjectIdsByLayoutKey[key]
        if (id == null) {
          return null
        }
        return state.objectsKeyedById[id]
      }
    },
    isFixedObject (state) {
      return (id) => Object.values(state.fixedObjectIdsByLayoutKey).includes(id)
    },

    visibleFixedObjectLayoutKeys (state) {
      if (state.signType === 'signOnly') {
        return []
      } else if (state.signType === 'signBox') {
        let visibleLayoutKeys = [
          'imageBottom',
          'rectangleOutline',
          // 'rectangleBackground',
          'roundedBackgroundTop',
          'straightBackgroundTop',
          'roundedBackgroundBottom',
          'straightBackgroundBottom',
          'lineHorizontal',
          'lineVertical',
          'textBottomRight'
        ]
        if (state.signTypeProperties.signBox?.layout === 'composed') {
          visibleLayoutKeys = [
            ...visibleLayoutKeys,
            'imageComposedLeft',
            'imageComposedRight',
            'textComposedTopLeft',
            'textComposedTopRight'
          ]
        } else {
          visibleLayoutKeys = [...visibleLayoutKeys, 'imageSingleTop']
        }
        return visibleLayoutKeys // pick(SIGN_BOX_FIXED_ELEMENTS, visibleLayoutKeys)
      }
    },

    visibleFixedObjectDefinitions (state, getters) {
      const layoutKeys = getters.visibleFixedObjectLayoutKeys
      return pick(SIGN_BOX_FIXED_ELEMENTS, layoutKeys)
    },

    selectedObjects (state) {
      return state.selectedObjectIds.map((id) => state.objectsKeyedById[id])
    },
    signTypeProperties (state) {
      return state.signTypeProperties[state.signType]
    },
    signData (state) {
      const objectsKeyedById = Object.entries(state.objectsKeyedById).reduce((acc, [id, object]) => {
        return { ...acc, [id]: object.toData() }
      }, {})
      const data = {
        owner: state.owner,
        ownerType: state.ownerType,
        author: state.author,
        node: state.node,

        name: state.name,
        objectIds: state.objectIds,
        objectsKeyedById,
        revision: state.revision,
        signType: state.signType,
        fixedObjectIdsByLayoutKey: state.fixedObjectIdsByLayoutKey,
        signTypeProperties: state.signTypeProperties
      }
      return data
    }
  },
  mutations: {
    reset (state) {
      state._id = null

      state.owner = null
      state.ownerType = null
      state.author = null
      state.node = null

      state.signType = null
      state.name = null
      state.objectIds = []
      state.objectsKeyedById = {}

      state.fixedObjectIdsByLayoutKey = {}
      state.signTypeProperties = {}

      state.updatedAt = null
      state.revision = 0

      state.signBoxLayout = null
      state.signBoxStyle = null

      state.isClippingMaskVisible = false
      state.selectedObjectIds = []

      state.isLoaded = false
      state.isSavePending = null
      state.savedPromise = null
      state.hasUnsavedChanges = false
    },

    loadData (state, options) {
      state._id = options._id ?? null

      state.owner = options.owner ?? null
      state.ownerType = options.ownerType ?? 'users'
      state.author = options.author ?? null
      state.node = options.node ?? null

      state.signType = options.signType ?? 'signOnly'

      state.name = options.name ?? null
      state.objectIds = options.objectIds ?? []

      // Inflate objects
      state.objectsKeyedById = options.objectsKeyedById ?? {}
      Object.keys(state.objectsKeyedById).forEach((id) => {
        if (!(state.objectsKeyedById[id] instanceof SignEditorObject)) {
          Vue.set(state.objectsKeyedById, id, SignEditorObject.fromData(state.objectsKeyedById[id]))
        }
      })

      state.fixedObjectIdsByLayoutKey = options.fixedObjectIdsByLayoutKey ?? {}
      state.updatedAt = options.updatedAt ?? null
      state.revision = options.revision ?? 0
      state.isLoaded = true

      state.signTypeProperties = options.signTypeProperties ?? {}
    },

    mergeData (state, sign) {
      if (sign._id !== state._id) {
        if (state._id === null) {
          console.log('Received final id', sign._id)
          state._id = sign._id
        } else {
          throw new Error('Unable to merge data of sign with different id: ' + sign._id)
        }
      }

      state.owner = sign.owner
      state.ownerType = sign.ownerType
      state.author = sign.author
      state.node = sign.node

      state.signType = sign.signType
      state.objectIds = sign.objectIds

      Object.keys(state.objectsKeyedById).forEach((id) => {
        if (!(state.objectsKeyedById[id] instanceof SignEditorObject)) {
          Vue.set(state.objectsKeyedById, id, SignEditorObject.fromData(state.objectsKeyedById[id]))
        }
      })

      state.fixedObjectIdsByLayoutKey = sign.fixedObjectIdsByLayoutKey
      state.signTypeProperties = sign.signTypeProperties ?? {}

      state.updatedAt = sign.updatedAt
      state.revision = sign.revision
    },
    mergeEssentialData (state, sign) {
      if (sign._id !== state._id) {
        if (state._id === null) {
          console.log('Received final id', sign._id)
          state._id = sign._id
        } else {
          throw new Error('Unable to merge data of sign with different id: ' + sign._id)
        }
      }
      state.signType = sign.signType
      state.updatedAt = sign.updatedAt
      state.createdAt = sign.createdAt
    },
    incrementRevision (state) {
      state.revision += 1
      state.hasUnsavedChanges = true
    },
    setHasUnsavedChanges (state, value) {
      state.hasUnsavedChanges = value
    },
    setIsSavePending (state, { isPending, promise }) {
      state.isSavePending = isPending
      state.savedPromise = promise
    },
    setName (state, newName) {
      state.name = newName
      state.hasUnsavedChanges = true
    },
    setSignType (state, newSignType) {
      state.signType = newSignType
    },
    toggleClippingMaskVisibility (state) {
      state.isClippingMaskVisible = !state.isClippingMaskVisible
    },
    setClippingMaskVisibility (state, value) {
      state.isClippingMaskVisible = value
    },
    moveObjectsToFrontByIds (state, ids) {
      const remaining = state.objectIds.filter((id) => !ids.includes(id))
      state.objectIds = [...remaining, ...ids]
      state.hasUnsavedChanges = true
    },
    moveObjectsToBackByIds (state, ids) {
      const remaining = state.objectIds.filter((id) => !ids.includes(id))
      state.objectIds = [...ids, ...remaining]
      state.hasUnsavedChanges = true
    },
    ensureFixedObjectOrder (state) {
      const layoutKeys = Object.keys(state.fixedObjectIdsByLayoutKey)
      const def = SIGN_BOX_FIXED_ELEMENTS

      const moveToFront = layoutKeys
        .filter((k) => def[k].layer != null && def[k].layer > 0)
        .sort((a, b) => SIGN_BOX_FIXED_ELEMENTS[a].layer - SIGN_BOX_FIXED_ELEMENTS[b].layer)
        .map((k) => state.fixedObjectIdsByLayoutKey[k])

      const sendToBack = layoutKeys
        .filter((k) => SIGN_BOX_FIXED_ELEMENTS[k].layer != null && SIGN_BOX_FIXED_ELEMENTS[k].layer < 0)
        .sort((a, b) => SIGN_BOX_FIXED_ELEMENTS[b].layer - SIGN_BOX_FIXED_ELEMENTS[a].layer)
        .map((k) => state.fixedObjectIdsByLayoutKey[k])

      const remaining = state.objectIds.filter((id) => !moveToFront.includes(id) && !sendToBack.includes(id))
      state.objectIds = [...sendToBack, ...remaining, ...moveToFront]
    },
    addObject (state, object) {
      state.objectIds.push(object.id)
      Vue.set(state.objectsKeyedById, object.id, object)
      state.hasUnsavedChanges = true
    },
    addObjects (state, objects) {
      objects.forEach((o) => {
        state.objectIds.push(o.id)
        Vue.set(state.objectsKeyedById, o.id, o)
      })
      state.hasUnsavedChanges = true
    },
    removeObjectsByIds (state, ids) {
      const newSelectedObjectIds = state.selectedObjectIds.filter((id) => !ids.includes(id))
      const newObjects = state.objectIds.filter((id) => !ids.includes(id))
      ids.forEach((id) => Vue.delete(state.objectsKeyedById, id))

      ids.forEach((id) => {
        for (const [layoutKey, objectId] of Object.entries(state.fixedObjectIdsByLayoutKey)) {
          if (objectId === id) {
            Vue.delete(state.fixedObjectIdsByLayoutKey, layoutKey)
            break
          }
        }
      })

      state.selectedObjectIds = newSelectedObjectIds
      state.objectIds = newObjects
      state.hasUnsavedChanges = true
    },
    setSelectedObjectIds (state, ids) {
      state.selectedObjectIds = ids
      state.hasUnsavedChanges = true
    },
    clearSelection (state) {
      state.selectedObjectIds = []
    },
    updateObjectProperty (state, { id, key, value, event = false }) {
      Vue.set(state.objectsKeyedById[id], key, value)
      state.hasUnsavedChanges = true

      if (event) {
        console.log('signEditor::updateObjectProperty: Sending object-model-changed event', id, key, value)
        state.events.emit('object-model-changed', id)
      }
    },
    updateObjectProperties (state, { id, data, event = false }) {
      const object = state.objectsKeyedById[id]
      Object.entries(data).forEach(([key, value]) => {
        Vue.set(object, key, value)
      })
      state.hasUnsavedChanges = true
      if (event) {
        console.log('signEditor::updateObjectProperties: Sending object-model-changed event', id, data)
        state.events.emit('object-model-changed', id)
      }
    },
    setFixedObject (state, { layoutKey, object }) {
      Vue.set(state.fixedObjectIdsByLayoutKey, layoutKey, object.id)
    },
    setFixedImage (state, { layoutKey, imageReference }) {},
    setSignTypeProperty (state, { key, value }) {
      Vue.set(state.signTypeProperties[state.signType], key, value)
    },
    setCanvasScale (state, value) {
      state.canvasScale = value
    },
    setDefaultSignTypeProperties (state) {
      if (state.signType === 'signBox') {
        state.signTypeProperties = {
          signBox: {
            layout: 'single',
            style: 'noun',
            imageScaleOverrides: {}
          }
        }
      }
    }
  },
  actions: {
    create ({ dispatch, commit, rootState }, data) {
      console.log('Create', data)
      commit('reset')
      commit('loadData', {
        name: data.signType === 'signBox' ? 'Unbenannte Karte' : 'Unbenannte Gebärde',
        owner: rootState.auth.user._id,
        ...data
      })

      commit('setDefaultSignTypeProperties')
      dispatch('ensureFixedObjects')
    },
    async load ({ dispatch, commit }, id) {
      commit('reset')
      const sign = await dispatch('custom-signs/get', id, { root: true })
      commit('loadData', sign)
    },

    async rename ({ state, dispatch, commit }, name) {
      const res = await dispatch('custom-signs/patch', [state._id, { name }], { root: true })
      state.name = name
      await dispatch('file-nodes/get', res.node, { root: true })
      await dispatch('fileNodeTree/rebuild', null, { root: true })
    },

    ensureFixedObjects ({ dispatch, commit, getters, state }) {
      const hasUnsavedChanges = state.hasUnsavedChanges
      console.log('Ensure fixed objects')
      if (state.signType === 'signBox') {
        const visibleLayoutKeys = getters.visibleFixedObjectLayoutKeys

        // Remove elements that are not visible
        const layoutKeysToRemove = Object.keys(state.fixedObjectIdsByLayoutKey).filter(
          (existingObjectLayoutKey) => visibleLayoutKeys.includes(existingObjectLayoutKey) === false
        )
        const objectIdsToRemove = layoutKeysToRemove.map((k) => state.fixedObjectIdsByLayoutKey[k])
        console.log(`Removing ${objectIdsToRemove.length} fixed objects with ids: ${objectIdsToRemove.join(', ')}`)
        commit('removeObjectsByIds', objectIdsToRemove)

        // Create elements that dont exist yet
        const createdObjects = Object.entries(getters.visibleFixedObjectDefinitions)
          .filter(([_, data]) => data.objectType !== 'image')
          .map(([layoutKey, data]) => dispatch('ensureFixedObject', { layoutKey, data }))
          .filter((o) => o != null)

        console.log(
          `Created ${createdObjects.length} fixed objects with ids: ${createdObjects.map((o) => o.id).join(', ')}`
        )
        // commit('addObjects', createdObjects)

        commit('ensureFixedObjectOrder')
        commit('setHasUnsavedChanges', hasUnsavedChanges)

        // TODO: Ensure fixed object order
      }
    },

    ensureFixedObject ({ commit, state }, { layoutKey, data }) {
      console.log('Ensure fixed object', layoutKey)
      if (state.fixedObjectIdsByLayoutKey[layoutKey] != null) {
        return null
      }

      const o = SignEditorObject.fromData(data)
      commit('addObject', o)
      Vue.set(state.fixedObjectIdsByLayoutKey, layoutKey, o.id)
      return o
    },

    applySignBoxStyle ({ state, getters, commit }) {
      const styleKey = state.signTypeProperties[state.signType].style
      const style = SIGN_BOX_STYLES[styleKey]

      Object.keys(style.properties).forEach((layoutKey) => {
        const id = state.fixedObjectIdsByLayoutKey[layoutKey]
        if (id == null) {
          return
        }
        // Apply style to object
        commit('updateObjectProperties', { id, data: style.properties[layoutKey], event: true })
        commit('ensureFixedObjectOrder')
      })
      // TODO: Re-render lines (position is off)
    },

    //
    // ─── ARROW ───────────────────────────────────────────────────────
    //
    addArrow ({ commit }, arrow) {
      const object = new SignEditorArrowObject({
        arrow,
        position: new Point(400, 400),
        size: new Size(260, 215)
      })
      commit('addObject', object)

      commit('ensureFixedObjectOrder')
      return object
    },

    //
    // ─── TEXT ────────────────────────────────────────────────────────
    //
    addText ({ commit }, { text, color }) {
      const object = new SignEditorTextObject({
        text,
        position: new Point(500, 500),
        color
        // size: new Size(300, 40)
      })
      commit('addObject', object)
      commit('ensureFixedObjectOrder')
      return object
    },

    //
    // ─── IMAGE ───────────────────────────────────────────────────────
    //
    addImage ({ commit }, imageReference) {
      const object = new SignEditorImageObject({
        imageReference,
        position: new Point(500, 500)
      })
      commit('addObject', object)
      commit('ensureFixedObjectOrder')
      return object
    },

    async updateFixedImage ({ getters, state, commit }, { layoutKey, imageReference, resolver }) {
      const objectId = state.fixedObjectIdsByLayoutKey[layoutKey]
      let object
      if (imageReference == null) {
        // Remove
        if (objectId != null) {
          commit('removeObjectsByIds', [objectId])
        }
        return null
      } else {
        if (objectId) {
          commit('removeObjectsByIds', [objectId])
        }

        const scaleOverride = getters.signTypeProperties.imageScaleOverrides?.[layoutKey] ?? 1

        // Add or update
        const definition = SIGN_BOX_FIXED_ELEMENTS[layoutKey]
        const target = definition.frame
        const resized = target.alignSize(
          target.size.multipliedByValue(scaleOverride),
          definition.verticalAlignment,
          definition.horizontalAlignment
        )

        const imageSize = await resolver.getImageSize(imageReference)
        const fitted = resized.fitSize(imageSize, definition.verticalAlignment, definition.horizontalAlignment)
        const props = {
          imageReference,
          position: new Point(fitted.midX, fitted.midY),
          size: imageSize,
          scaleX: fitted.width / imageSize.width,
          scaleY: fitted.height / imageSize.height
        }

        // Create new image
        object = new SignEditorImageObject(props)
        commit('addObject', object)
        commit('setFixedObject', { layoutKey, object })
        commit('ensureFixedObjectOrder')
      }
      return object
    },

    async updateFixedImagePosition ({ getters, state, commit }, { layoutKey, resolver }) {
      const fixedObject = getters.fixedObjectForLayoutKey(layoutKey)
      const imageReference = fixedObject.imageReference
      const scaleOverride = getters.signTypeProperties.imageScaleOverrides?.[layoutKey] ?? 1

      // Add or update
      const definition = SIGN_BOX_FIXED_ELEMENTS[layoutKey]
      const target = definition.frame
      const resized = target.alignSize(
        target.size.multipliedByValue(scaleOverride),
        definition.verticalAlignment,
        definition.horizontalAlignment
      )

      const imageSize = await resolver.getImageSize(imageReference)
      const fitted = resized.fitSize(imageSize, definition.verticalAlignment, definition.horizontalAlignment)
      const props = {
        position: new Point(fitted.midX, fitted.midY),
        scaleX: fitted.width / imageSize.width,
        scaleY: fitted.height / imageSize.height
      }

      commit('updateObjectProperties', { id: fixedObject.id, data: props, event: true })
    },

    async _executeSave ({ state, commit, getters, dispatch }) {
      commit('incrementRevision')
      commit('setHasUnsavedChanges', false)
      // Check if has unsaved changes
      let sign
      if (state._id === null) {
        // Create object
        sign = await dispatch('custom-signs/create', getters.signData, { root: true })
      } else {
        // Patch object
        sign = await dispatch('custom-signs/patch', [state._id, getters.signData], { root: true })
      }
      commit('mergeEssentialData', sign) // TODO: Don't write back data if revision changed
      return sign
    },

    //
    // ─── SAVE IT TO API ──────────────────────────────────────────────
    //
    async save ({ state, dispatch, commit, getters }) {
      if (state.isSavePending) {
        console.log('Save is in already progress')
        return state.savedPromise
      }
      if (state.hasUnsavedChanges === false) {
        console.warn('Skipping save: No unsaved changes')
        return state.savedPromise
      }
      const savedPromise = dispatch('_executeSave')
      commit('setIsSavePending', { isPending: true, promise: savedPromise })
      const res = await savedPromise
      commit('setIsSavePending', { isPending: false, promise: savedPromise })
      return res
    },
    reset ({ commit }) {
      commit('reset')
    }
  }
}
