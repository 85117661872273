import FileNodeTreeItem from './FileNodeTreeItem'

export default class FileNodeTree extends FileNodeTreeItem {
  constructor (fileNodes) {
    // Find root node and set it as self
    const rootNode = fileNodes.find((n) => n.path === null)
    super(rootNode)
    // Expand tree with child nodes
    const treeNodes = fileNodes.filter((n) => n.path !== null)
    this.expandTree(treeNodes)
  }

  getItemAt (itemPath) {
    const items = this.getItemsInPath(itemPath)
    return items[items.length - 1]
  }

  getItemsInPath (itemPath) {
    if (itemPath === null) {
      return []
    }

    let path = itemPath.split(',').slice(1, -1)
    if (path[0] !== this._id) {
      throw new Error(`FileNodeTree::getNodeAt: Node at ${itemPath} is not part of this tree`)
    }

    const itemsInPath = [this]

    path = path.slice(1)
    for (const id of path) {
      const n = itemsInPath[itemsInPath.length - 1].getChild(id)
      if (!n) {
        throw new Error('Item not found')
      }
      itemsInPath.push(n)
    }
    return itemsInPath
  }

  getParentOf (item) {
    const items = this.getItemsInPath(item.fileNode.path)
    if (items.length === 0) {
      return 0
    }
    return items[items.length - 1]
  }
}
