export default {
  data () {
    return {
      isGenerating: false
    }
  },
  methods: {
    onCopyToClipboard () {
      const input = this.$refs.keyInput.$refs.input

      input.select()
      input.setSelectionRange(0, 99999)

      try {
        navigator.clipboard.writeText(input.value)
        this.$buefy.toast.open({
          message: 'Teilen-Link in die Zwischenablage kopiert'
        })
      } catch (error) {
        this.$buefy.toast.open({
          type: 'is-danger',
          message: 'Fehler beim Kopieren in die Zwischenablage'
        })
        // Handle clipboard write failure
      }
    }
  }
}
