<template>
  <div class="help-view">
    <!-- DOWNLOADS -->
    <section class="downloads">
      <div class="container p-5">
        <h1 class="title">Hilfreiche Downloads</h1>
        <div class="cards columns is-multiline">
          <div class="column is-narrow">
            <div class="card">
              <div class="card-image">
                <figure class="image is-1by1">
                  <img src="@/assets/img/helpcenter/manual-icon.svg" />
                </figure>
              </div>
              <div class="card-content">
                <div class="content">
                  <h4>Handbuch</h4>
                  <p>Das Handbuch als PDF zum Herunterladen</p>
                </div>
              </div>
              <div class="card-footer">
                <div class="card-footer-item">
                  <b-button size="" type="is-primary">Jetzt herunterladen</b-button>
                </div>
              </div>
            </div>
          </div>
          <div class="column is-narrow">
            <div class="card">
              <div class="card-image">
                <figure class="image is-1by1">
                  <img src="@/assets/img/helpcenter/video-icon.svg" />
                </figure>
              </div>
              <div class="card-content">
                <div class="content">
                  <h4>YouTube Videos</h4>
                  <p>Vorstellungsvideos, Anleitungen und Videos auf YouTube</p>
                </div>
              </div>
              <div class="card-footer">
                <div class="card-footer-item">
                  <b-button type="is-primary">Zum YouTube Kanal</b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- FAQS -->
    <section class="faqs">
      <div class="container p-5">
        <h1 class="title">Häufig gestellte Fragen</h1>
        <b-collapse
          class="card"
          animation="slide"
          v-for="(collapse, index) of collapses"
          :key="index"
          :open="isOpen == index"
          @open="isOpen = index"
        >
          <div slot="trigger" slot-scope="props" class="card-header" role="button">
            <p class="card-header-title">
              <b-icon icon="question-circle"></b-icon>
              <span>{{ collapse.title }}</span>
            </p>
            <a class="card-header-icon">
              <b-icon :icon="props.open ? 'chevron-up' : 'chevron-down'"></b-icon>
            </a>
          </div>
          <div class="card-content">
            <div class="content">
              {{ collapse.text }}
            </div>
          </div>
        </b-collapse>
      </div>
    </section>
    <section>
      <div class="container p-5">
        <div class="column">
          <h1 class="title">Hilfe & Anleitungen</h1>
          <div class="tags">
            <b-tag type="is-primary">Zusatzleistung</b-tag>
            <b-tag type="is-secondary">Prio 2</b-tag>
          </div>
          <p>Redaktioneller Bereich mit Hilfe- und Anleitungsmaterial.</p>
          <ul>
            <li>Hilfe-Artikel</li>
            <li>FAQs</li>
            <li>PDF-Anleitung</li>
            <li>Ggf. extern verfügbar</li>
            <li>Hilfe-Popups in App</li>
          </ul>
          <p>Definition, Aufwandsschätzung, Angebot ausstehend</p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data () {
    return {
      isOpen: -1,
      collapses: [
        { title: 'Darf ich die Bilder verwenden in... ?', text: 'Text 1' },
        { title: 'Kann ich die Videos herunterladen?', text: 'Text 2' },
        { title: 'Darf ich meine Zugangsdaten weitergeben?', text: 'Text 3' },
        { title: 'Hier steht eine Frage?', text: 'Text 4' },
        { title: 'Hier steht eine weitere Frage?', text: 'Text 5' },
        { title: 'Hier steht noch eine weitere Frage?', text: 'Text 6' },
        { title: 'Warum ist die Gebärde für ... nicht dabei?', text: 'Text 7' }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/bulma-variables.scss';
section.downloads {
  .cards {
    .column {
      display: flex;
      .card {
        display: flex;
        flex-direction: column;
        width: 260px;
        .card-content {
          flex-grow: 1;
        }
      }
    }
  }
}
section.faqs {
  .card {
    margin-bottom: 1em;

    .card-header-title {
      .icon {
        margin-right: 1em;
      }
    }
    .card-header-icon {
      color: $primary;
    }
  }
}
</style>
