<template>
  <div class="team-seats">
    <b-field label="Belegung der Lizenz">
      <b-progress format="raw" :max="seats" class="mb-1">
        <template #bar>
          <b-progress-bar
            v-if="seatsTakenByOwner"
            :value="seatsTakenByOwner"
            show-value
            type="is-warning"
          ></b-progress-bar>
          <b-progress-bar
            v-if="seatsTakenByMembers"
            :value="seatsTakenByMembers"
            show-value
            type="is-info"
          ></b-progress-bar>
          <b-progress-bar
            v-if="seatsTakenByInvitations"
            :value="seatsTakenByInvitations"
            show-value
            type="is-light"
          ></b-progress-bar>
          <b-progress-bar v-if="freeSeats" :value="freeSeats" show-value type="is-success"></b-progress-bar>
        </template>
      </b-progress>
    </b-field>
    <b-taglist>
      <b-tag v-if="seatsTakenByOwner" type="is-warning">{{ seatsTakenByOwner }} Team-Besitzer</b-tag>
      <b-tag v-if="seatsTakenByMembers" type="is-info"
        >{{ seatsTakenByMembers }} {{ 'Teammitglied' | pluralize(seatsTakenByMembers, 'er') }}</b-tag
      >
      <b-tag v-if="seatsTakenByInvitations" type="is-light"
        >{{ seatsTakenByInvitations }} offene {{ 'Einladung' | pluralize(seatsTakenByInvitations, 'en') }}</b-tag
      >
      <b-tag v-if="freeSeats" type="is-success"
        >{{ freeSeats }} {{ 'freier Platz' | pluralizeReplace(freeSeats, 'freie Plätze') }}
      </b-tag>
    </b-taglist>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters('teamSettings', [
      'seats',
      'seatsTakenByMembers',
      'seatsTakenByInvitations',
      'seatsTakenByOwner',
      'freeSeats'
    ])
  }
}
</script>
