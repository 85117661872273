export default {
  namespaced: true,
  state: () => {
    return {
      openInvitationCount: null,

      isFetchPending: false,
      isFetchComplete: false,
      errorOnFetch: null
    }
  },
  getters: {
    seats (state, getters, rootState) {
      return rootState.session.license?.size
    },
    seatsTakenByMembers (state, getters, rootState) {
      return rootState.session.team?.members?.length ?? 0
    },
    seatsTakenByInvitations (state) {
      return state.openInvitationCount ?? 0
    },
    seatsTakenByOwner (state, getters, rootState) {
      return rootState.session.team.ownerHasAccess ? 1 : 0
    },
    freeSeats (state, getters) {
      return getters.seats - getters.seatsTakenByMembers - getters.seatsTakenByInvitations - getters.seatsTakenByOwner
    },
    owner (state, getters, rootState) {
      return rootState.session.team?.owner
    }
  },
  mutations: {
    init (state, { openInvitationCount, rootGetters }) {
      state.openInvitationCount = openInvitationCount
    },
    reset (state) {
      state.openInvitationCount = null

      state.isFetchPending = false
      state.isFetchComplete = false
      state.errorOnFetch = null
    },
    setPending (state, { pending, complete, error }) {
      state.isFetchPending = pending
      state.errorOnFetch = error
      state.isFetchComplete = !!complete
    }
  },
  actions: {
    async fetch ({ rootGetters, rootState, commit, dispatch }) {
      commit('reset')

      const teamRole = rootState.session.teamRole
      if (['owner', 'manager'].includes(teamRole)) {
        const invitationsQuery = {
          team: rootState.session.team._id,
          consumedAt: null,
          invalidatedAt: null,
          expiresAt: {
            $gt: new Date()
          },
          $limit: 0
        }
        commit('setPending', { pending: true, complete: false, error: null })

        try {
          const openInvitationCount = (await dispatch('invitations/find', { query: invitationsQuery }, { root: true }))
            .total
          commit('init', { openInvitationCount })
          commit('setPending', { pending: false, complete: true, error: null })
        } catch (error) {
          console.error('Error on fetching team settings: ' + error)
          commit('setPending', { pending: false, complete: false, error })
        }
      }
    }
  }
}
