<template>
  <div class="billing-form">
    <validation-observer slim v-slot="{ invalid }" ref="validationObserver">
      <validation-observer ref="billing" slim>
        <!-- Select customer type -->
        <b-field :label="customerTypeTitle">
          <b-radio-button v-model="customerClone.customerType" native-value="person" type="is-primary ">
            <span>Privatkunde</span>
          </b-radio-button>
          <b-radio-button v-model="customerClone.customerType" native-value="company" type="is-primary ">
            <span>Geschäftskunde</span>
          </b-radio-button>
        </b-field>

        <!-- Company [company] -->
        <validation-provider
          name="Name der Firma"
          rules="required"
          v-slot="{ errors, valid }"
          slim
          v-if="customerClone.customerType === 'company'"
        >
          <b-field
            label="Firma, Organisation oder Einrichtung"
            :type="{ 'is-danger': errors.length > 0, 'is-primary': valid }"
            :message="errors[0]"
          >
            <b-input type="text" v-model="customerClone.company" key="company-input" />
          </b-field>
        </validation-provider>

        <!-- Vat id -->
        <validation-provider
          name="USt.-Id."
          rules="required"
          v-slot="{ errors, valid }"
          class="is-expanded"
          slim
          v-if="isVatIdRequired"
        >
          <b-field
            label="Umsatzsteuer-Identifikationsnummer"
            :type="{ 'is-danger': errors.length > 0, 'is-primary': valid }"
            :message="errors[0]"
            expanded
          >
            <b-input type="text" v-model="customerClone.vatId" key="vat-id" />
          </b-field>
        </validation-provider>

        <!-- Name -->
        <validation-provider name="Dein Name" rules="required" v-slot="{ errors, valid }" slim>
          <b-field
            label="Vor- und Nachname"
            :type="{ 'is-danger': errors.length > 0, 'is-primary': valid }"
            :message="errors[0]"
          >
            <b-input type="text" v-model="customerClone.name" key="name-input" />
          </b-field>
        </validation-provider>

        <!-- Abteilung, Appartment, Gebäude -->
        <b-field label="Abteilung, Apartment, Gebäude (optional)">
          <b-input type="text" v-model="customerClone.additional" />
        </b-field>

        <!-- Street and number -->
        <b-field grouped>
          <!-- Street -->
          <validation-provider name="Straße" rules="required" v-slot="{ errors, valid }" slim>
            <b-field
              label="Straße"
              :type="{ 'is-danger': errors.length > 0, 'is-primary': valid }"
              :message="errors[0]"
              expanded
            >
              <b-input type="text" v-model="customerClone.street" key="street-input" />
            </b-field>
          </validation-provider>
          <!-- Number -->
          <validation-provider name="Hausnummer" rules="required" v-slot="{ errors, valid }" slim>
            <b-field
              label="Hausnummer"
              :type="{ 'is-danger': errors.length > 0, 'is-primary': valid }"
              :message="errors[0]"
              expanded
            >
              <b-input type="text" v-model="customerClone.number" key="number-input" />
            </b-field>
          </validation-provider>
        </b-field>

        <!-- Postcode and city -->
        <b-field grouped>
          <!-- Postcode -->
          <validation-provider name="Postleitzahl" rules="required" v-slot="{ errors, valid }" slim>
            <b-field
              label="Postleitzahl"
              :type="{ 'is-danger': errors.length > 0, 'is-primary': valid }"
              :message="errors[0]"
              expanded
            >
              <b-input type="text" v-model="customerClone.postcode" key="postcode-input" />
            </b-field>
          </validation-provider>

          <!-- City -->
          <validation-provider name="Ort" rules="required" v-slot="{ errors, valid }" class="is-expanded" slim>
            <b-field
              label="Ort"
              :type="{ 'is-danger': errors.length > 0, 'is-primary': valid }"
              :message="errors[0]"
              expanded
            >
              <b-input type="text" v-model="customerClone.city" key="city-input" />
            </b-field>
          </validation-provider>
        </b-field>

        <!-- Country -->
        <validation-provider name="Land" rules="required" v-slot="{ errors, valid }" class="is-expanded" slim>
          <b-field
            label="Land"
            :type="{ 'is-danger': errors.length > 0, 'is-primary': valid }"
            :message="errors[0]"
            expanded
          >
            <country-select v-model="customerClone.countryCode" />
          </b-field>
        </validation-provider>

        <!-- Invoice reference -->
        <b-field
          label="Interne Referenz, Vorgangsnummer oder Kostenstelle"
          message="Wird auf die Rechnung gedruckt"
          v-if="customerClone.customerType === 'company'"
        >
          <b-input type="text" v-model="customerClone.reference" />
        </b-field>
      </validation-observer>

      <!-- Submit button -->
      <b-button v-if="submit" type="is-primary" :disabled="invalid" expanded @click="onSubmit">{{
        submitTitle
      }}</b-button>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate/dist/vee-validate.full'
import CountrySelect from '@custom-media/signdigital-web-shared/src/components/CountrySelect.vue'
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    CountrySelect
  },
  props: {
    customer: Object,
    customerTypeTitle: {
      type: String,
      default: 'Ich bestelle als...'
    },
    submitTitle: {
      type: String,
      default: 'Speichern'
    },
    submit: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    isVatIdRequired () {
      return this.customerClone.customerType === 'company' && !['DE', 'CH'].includes(this.customerClone.countryCode)
    }
  },
  mounted () {
    this.reset()
  },
  data () {
    return {
      customerClone: {}
    }
  },
  methods: {
    reset () {
      this.customerClone = this.customer.clone()
      this.customerClone.customerType ??= 'person'
      this.customerClone.countryCode ??= 'DE'
    },
    getCustomer () {
      if (this.customerClone.customerType === 'person') {
        this.customerClone.company = null
        this.customerClone.reference = null
        this.customerClone.vatId = null
      }
      return this.customerClone
    },
    onSubmit () {
      this.$emit('submit', this.getCustomer())
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/bulma-variables.scss';
.field {
  margin-bottom: 0.75rem;
}

@include until($tablet) {
  ::v-deep .b-radio.radio.button {
    font-size: 0.8rem;
  }
}
</style>
