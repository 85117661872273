<template>
  <div class="payment-method">
    <div class="method">
      <b-icon :icon="icon" :pack="iconPack" size="is-large" type="is-dark" />
      <span class="text-wrapper">
        <span class="type">{{ title }}</span>
        <span class="meta has-text-grey">{{ meta }}</span>
      </span>
    </div>
    <div class="actions" v-if="actions">
      <b-button type="is-light" icon-left="trash" @click="$emit('remove')"></b-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    paymentMethod: Object,
    actions: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    icon () {
      switch (this.paymentMethod.type) {
        case 'card':
          switch (this.paymentMethod.card.brand) {
            case 'visa':
              return 'cc-visa'
            case 'amex':
              return 'cc-amex'
            case 'mastercard':
              return 'cc-mastercard'
            case 'diners':
              return 'cc-diners-club'
            default:
              return 'credit-card'
          }
        case 'sepa_debit':
          return 'money-check-edit'
        case 'invoice':
          return 'file-invoice'
      }
      return null
    },
    iconPack () {
      if (
        this.paymentMethod.type === 'card' &&
        ['visa', 'amex', 'mastercard', 'diners'].includes(this.paymentMethod.card.brand)
      ) {
        return 'fab'
      } else {
        return 'far'
      }
    },
    title () {
      switch (this.paymentMethod.type) {
        case 'card':
          switch (this.paymentMethod.card.brand) {
            case 'visa':
              return 'Kreditkarte (VISA)'
            case 'amex':
              return 'Kreditkarte (American Express)'
            case 'mastercard':
              return 'Kreditkarte (Mastercard)'
            case 'diners':
              return 'Kreditkarte (Diners Club)'
            default:
              return 'Kreditkarte'
          }
        case 'sepa_debit':
          return 'SEPA-Lastschrift'
        case 'invoice':
          return 'Kauf auf Rechnung'
      }
      return null
    },
    meta () {
      switch (this.paymentMethod.type) {
        case 'card':
        case 'sepa_debit':
          return 'Endet auf ' + this.paymentMethod[this.paymentMethod.type].last4
        case 'invoice':
          return 'Nur für Unternehmen. Bestellung wird erst nach erfolgreicher Überprüfung bearbeitet.'
      }
      return null
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/bulma-variables.scss';
.payment-method {
  display: flex;
  justify-content: space-between;

  .method {
    display: flex;
  }
  .actions {
    display: flex;
  }

  .text-wrapper {
    display: flex;
    flex-direction: column;
  }

  .icon {
    margin-right: 1em;
  }

  .type {
    font-weight: bold;
  }
}
</style>
