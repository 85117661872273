<template>
  <tr
    class="node-item"
    :class="{ ghost: insertGhost, selected: isSelected, 'is-directory': isDirectory, 'is-file': isFile }"
    @click="onItemSelected($event, item)"
  >
    <td class="checkbox-column">
      <!-- <b-checkbox size="is-small"></b-checkbox> -->
    </td>
    <td class="main-column">
      <b-icon icon="level-down" size="is-small" class="level-down-icon" v-if="insertGhost" />
      <b-button
        size=""
        :icon-left="$options.filters.fileNodeIcon(item.fileNode)"
        type="is-ghost"
        :class="{ 'file-button': isFile, 'directory-button': isDirectory }"
        :disabled="insertGhost"
        @click.stop="onItemNavigation(item)"
        >{{ item.fileNode.name }}</b-button
      >
    </td>
    <td class="info-column">
      <div class="file-node-info">
        <small class="is-size-7">{{ item.fileNode | fileNodeType }}</small>
        <small class="is-size-7">{{ item.fileNode | fileNodeTypeDetailsOnly }}</small>
      </div>
    </td>
    <td class="actions-column">
      <file-node-actions
        @action="onItemAction"
        :item="item"
        v-if="actions.length && !insertGhost"
        :actions="actionsOverride"
      />
    </td>
  </tr>
</template>

<script>
import FileNodeActions from './FileNodeActions.vue'
import { fileNodeMixin } from '@custom-media/signdigital-web-shared/src/mixins/file-node-mixins'
export default {
  mixins: [fileNodeMixin],
  props: {
    item: Object,
    insertGhost: {
      type: Boolean,
      default: false
    },
    isSelected: {
      type: Boolean,
      default: false
    },
    actions: {
      type: Array,
      default () {
        return ['open', 'edit', 'rename', 'duplicate', 'move']
      }
    }
  },
  components: {
    FileNodeActions
  },
  computed: {
    isFile () {
      return this.item?.fileNode?.nodeType === 'file'
    },
    isDirectory () {
      return this.item?.fileNode?.nodeType === 'directory'
    },
    actionsOverride () {
      if (this.isFile && this.item?.fileNode.fileType === 'lists' && this.actions.includes('open')) {
        return ['play', 'edit', ...this.actions.filter((a) => a !== 'open')]
      } else if (this.isFile && this.item?.fileNode.fileType === 'documents') {
        return ['pdf', ...this.actions]
      } else if (this.isFile && this.item?.fileNode.fileType === 'custom-signs') {
        return ['download', ...this.actions]
      } else {
        return this.actions
      }
    }
  },
  methods: {
    onItemNavigation (item) {
      if (!this.actions.includes('open')) {
        return false
      }
      this.$emit('item-navigation', item)
    },
    onItemSelected ($event, item) {
      if ($event.target.closest('.dropdown') != null) {
        return
      }
      this.$emit('item-selected', item)
    },
    onItemAction ({ action }) {
      this.$emit('action', { action, item: this.item })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/bulma-variables.scss';
tr {
  td {
    border-bottom: 2px solid transparent;
  }
  td:first-child {
    border-top-left-radius: $lg-radius;
    border-bottom-left-radius: $lg-radius;
  }
  td:last-child {
    border-top-right-radius: $lg-radius;
    border-bottom-right-radius: $lg-radius;
  }
  &:not(:last-child) {
    td:not(:first-child):not(:last-child) {
      border-bottom: 2px solid $grey-lightest;
    }
  }
}

tr.selected {
  background: lighten($primary, 40%);
  td:not(:first-child):not(:last-child) {
    border-bottom: 2px solid lighten($primary, 40%);
  }
}

tr.is-file:hover {
  cursor: pointer;
}
tr:hover {
  td {
    background: $grey-lighter;

    &:not(:first-child):not(:last-child) {
      border-bottom: 2px solid $grey-lighter;
    }
  }
}

tr.ghost {
  background-color: lighten($primary, 35%);
  color: darken($primary, 30%);
  border-bottom: 0;
  opacity: 60%;
  pointer-events: none;
  border-radius: $lg-radius;
  margin-top: 0.3rem;
  overflow: hidden;
  border: 3px solid $primary;

  .level-down-icon {
    font-size: 0.8rem;
  }

  .button {
    background: transparent;
    color: darken($primary, 30%);
    border: 0;
    opacity: 100%;
  }
  td:nth-child(2) {
    display: flex;
    align-items: center;
    max-width: none;
  }
}

td.actions-column {
}
.file-node-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.button::v-deep {
  &.file-button,
  &.directory-button {
    padding-top: calc(0.2rem - 1px);
    padding-bottom: calc(0.2rem - 1px);
    height: 2rem;
    max-width: 100%;
    span:last-child {
      max-width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  &.file-button {
    // color: $grey-darker;
    span.icon {
      // color: darken($primary, 20%);
    }
  }
  &.directory-button {
    color: $text;
  }
}
</style>
