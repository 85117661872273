<template>
  <div class="custom-sign-editor" :style="windowHeightCSS">
    <!-- NAVBAR -->
    <b-navbar class="app-nav is-mobile" :mobile-burger="false" :active="true">
      <template slot="brand">
        <b-navbar-item tag="div">
          <nav class="breadcrumb" aria-label="breadcrumbs">
            <ul>
              <li>
                <router-link :to="{ name: 'custom-signs' }">Eigene Gebärden</router-link>
              </li>
              <li>
                <editable-button :value="name" icon-only @input="onSignNameChanged" />
              </li>
            </ul>
          </nav>
        </b-navbar-item>
      </template>
      <template slot="start"> </template>
      <template slot="end">
        <b-navbar-item tag="div">
          <saving-indicator :saving="isSavePending" :saved-at="updatedAt" :has-changes="hasUnsavedChanges" />
        </b-navbar-item>
        <b-navbar-item tag="div">
          <b-dropdown class="general-actions-dropdown" position="is-bottom-left" ref="generalActionsDropdown">
            <template #trigger>
              <b-button type="is-white" size="is-small" icon-left="cog">Ansicht</b-button>
            </template>
            <general-actions />
          </b-dropdown>
        </b-navbar-item>
        <b-navbar-item tag="div">
          <div class="buttons">
            <router-link :to="exitRoute" tag="button" class="button is-light" :disabled="isSavePending">
              Schließen
            </router-link>
          </div>
        </b-navbar-item>
      </template>
    </b-navbar>

    <b-message
      v-if="isDuplicateHintShown"
      type="is-primary"
      class="mx-6 my-3"
      title="Datei aus Team-Ordner"
      @close="closedDuplicateHint = true"
    >
      <p>
        Du betrachtest gerade eine eigene Gebärde, die von einem anderen Team-Mitglied erstellt wurde. Du kannst sie
        zwar bearbeiten, veränderst dadurch aber die Originaldatei im Team-Ordner.
      </p>
      <p>Wir empfehlen, eine Kopie in deinem persönliche Ordner zu speichern und die Kopie zu bearbeiten.</p>
      <b-button @click="onCreateCopy" expanded class="mt-5" type="is-primary">Kopie erstellen</b-button>
    </b-message>

    <!-- CONTENT AREA -->
    <section class="editor-content">
      <div class="main">
        <!-- CANVAS -->
        <b-loading :active="!isLoaded"></b-loading>

        <custom-sign-canvas ref="canvas" v-if="isLoaded" />
        <!-- ACTIONS BELOW -->
        <selection-actions :canvas="$refs.canvas" v-if="signType === 'signOnly'" class="mt-1" />
      </div>
      <div class="sidebar">
        <!-- SIDEBAR -->
        <custom-sign-sidebar v-if="isLoaded && signType === 'signOnly'" />
        <custom-sign-box-sidebar v-else-if="isLoaded && signType === 'signBox'" />
      </div>
    </section>
  </div>
</template>

<script>
import CustomSignCanvas from '@/components/custom-signs/CustomSignCanvas'
import CustomSignSidebar from '@/components/custom-signs/CustomSignSidebar'
import CustomSignBoxSidebar from '@/components/custom-signs/CustomSignBoxSidebar'
import EditableButton from '@/components/EditableButton.vue'
import SavingIndicator from '@/components/SavingIndicator.vue'
import SelectionActions from '@/components/custom-signs/SelectionActions.vue'
import GeneralActions from '@/components/custom-signs/GeneralActions.vue'
import { mapState } from 'vuex'
import { debounce } from 'lodash'
import windowHeightMixin from '@/mixins/window-height-mixin'
import { customSignsMixin } from '@custom-media/signdigital-web-shared/src/mixins/custom-signs-mixin'
import { globalEventBus, globalEvents } from '@/lib/events/global-event-bus'

export default {
  mixins: [windowHeightMixin, customSignsMixin],
  components: {
    CustomSignCanvas,
    CustomSignSidebar,
    CustomSignBoxSidebar,
    EditableButton,
    SavingIndicator,
    SelectionActions,
    GeneralActions
  },
  data () {
    return {
      unsubscribe: null,
      debouncedSave: debounce(this.save, 2000, { maxWait: 5000 }),
      isLoaded: false,
      closedDuplicateHint: false
    }
  },
  computed: {
    ...mapState('signEditor', [
      '_id',
      'node',
      'name',
      'isPlaceholderId',
      'isSavePending',
      'hasUnsavedChanges',
      'updatedAt',
      'ownerType',
      'author',
      'signType'
    ]),
    exitRoute () {
      return this.$store.state.session.exitRoute?.['custom-signs'] ?? { name: 'custom-signs' }
    },
    isOwnedByTeam () {
      return this.ownerType === 'teams'
    },
    isUserAuthor () {
      return this.author === this.$store.state.auth.user._id
    },
    isDuplicateHintShown () {
      return this.isOwnedByTeam && !this.isUserAuthor && !this.closedDuplicateHint
    }
  },
  watch: {
    _id (value) {
      if (this.$route.params.id == null) {
        console.log('Replacing url with for final id ' + value)
        this.updateRoute()
      }
    }
  },
  async mounted () {
    await this.initView()

    this.unsubscribe = this.$store.subscribe(this.onSignEditorMutation)
    // TODO: TODO: Populate
  },
  beforeDestroy () {
    if (this.unsubscribe) {
      this.unsubscribe()
    }
  },
  async beforeRouteLeave (to, from, next) {
    console.log('Route leave', to, from)
    this.debouncedSave.cancel()
    await this.save()
    this.$store.dispatch('signEditor/reset')

    // Close search dropdowns
    document.querySelectorAll('.search-dropdown').forEach((modal) => {
      modal.__vue__?.$vnode?.context?.close()
    })
    next()
  },
  methods: {
    async initView () {
      await this.maybeLoadDocument()
    },
    onSignEditorMutation (mutation, state) {
      if (!mutation.type.startsWith('signEditor/')) {
        return
      }
      const editorMutation = mutation.type.split('/')[1]
      const triggerMutations = [
        'setName',
        'moveObjectsToFrontByIds',
        'moveObjectsToBackByIds',
        'addObject',
        'addObjects',
        'removeObjectsByIds',
        'updateObjectProperty',
        'updateObjectProperties'
      ]

      if (triggerMutations.includes(editorMutation)) {
        console.log('Save triggered by ' + editorMutation)
        if (this._id == null) {
          console.log('Is initial change. Saving immediately')
          this.save()
        } else {
          this.debouncedSave()
        }
      }
    },
    async maybeLoadDocument () {
      const id = this.$route?.params?.id

      if (id != null) {
        // Edit existing document
        await this.$store.dispatch('signEditor/load', this.$route.params.id)
        this.$store.dispatch('preferences/trackRecentlyUsed', { fileType: 'custom-signs', id: this.$route.params.id })
        this.updateRoute()
      } else {
        this.$route.replace({ name: 'custom-signs' })
        return
      }
      console.log('CustomSignEditor::maybeLoadDocument: ', 'Document loaded')
      this.isLoaded = true
    },
    updateRoute () {
      if (this.$route.name !== 'custom-sign-editor' || this.$route.params?.id !== this._id) {
        this.$router.replace({
          name: 'custom-sign-editor',
          params: { id: this._id }
        })
      }
    },
    async onSignNameChanged (name) {
      try {
        await this.$store.dispatch('signEditor/rename', name)
        globalEventBus.emit(globalEvents.contentAreaChanged)
      } catch (error) {
        this.$buefy.dialog.alert({
          title: 'Fehler beim Umbenennen',
          type: 'is-danger',
          icon: 'triangle-exclamation',
          message:
            error?.code === 409
              ? `Am Speicherort dieser eigenen Gebärde existiert bereits eine Datei mit dem Namen „${name}”.<br/><br/> Bitte wähle einen anderen Namen.`
              : error
        })
      }
    },
    async save () {
      console.log('Saving sign ' + this._id)
      await this.$store.dispatch('signEditor/save')
      // TODO: Handle & Display Save error
    },
    async onCreateCopy () {
      await this.$store.dispatch('fileNodeTree/maybeFetchInPath', this.node)
      const originalItem = this.$store.state.fileNodeTree.itemsById[this.node]
      const node = await this.$fileDialog.duplicate({
        item: originalItem,
        currentItem: this.$store.getters['fileNodeTree/userItem'],
        parent: this
      })

      // Open duplicate in editor
      const signId = node.file
      await this.$store.dispatch('signEditor/load', signId)
      this.updateRoute()
      this.$nextTick().then(() => {
        globalEventBus.emit(globalEvents.contentAreaChanged)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/bulma-variables.scss';
.custom-sign-editor {
  display: flex;
  flex-direction: column;

  .navbar {
    border-bottom: 1px solid $grey-light;
  }
  @include until($desktop) {
    .custom-sign-canvas {
      max-height: 50vh;
    }
  }
  @include from($desktop) {
    // Editor layout with sidebar
    .editor-content {
      position: relative;
      height: 100%;

      .main {
        position: absolute;
        top: 0;
        left: 0;
        right: 320px;
        bottom: 0;
        display: flex;
        flex-direction: column;
      }
      .sidebar {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 320px;
      }
    }
  }
}
</style>
