<template>
  <div class="hint mb-4">
    <b-notification v-if="isVisible" class="mt-2" type="is-white" @close="onDismiss">
      <slot></slot>
    </b-notification>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  props: {
    hintKey: String
  },
  computed: {
    ...mapGetters('preferences', ['isHintDismissed']),
    isVisible () {
      return this.isHintDismissed(this.hintKey) === false
    }
  },
  methods: {
    ...mapActions('preferences', ['dismissHint']),
    onDismiss () {
      this.dismissHint(this.hintKey)
    }
  }
}
</script>
