var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"team-details-form"},[_c('validation-observer',{ref:"validationObserver",attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var handleSubmit = ref.handleSubmit;
return [(_vm.localTeam)?_c('form',{ref:"teamDetailsForm",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('validation-provider',{attrs:{"name":"Name deines Teams","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"label":"Name deines Teams","type":{ 'is-danger': errors.length > 0, 'is-primary': valid },"message":errors[0]}},[_c('b-input',{key:"team",attrs:{"type":"text","placeholder":"z.B. Musterschule, Praxis am Musterweg, Familie Mustermann, ..."},model:{value:(_vm.localTeam.name),callback:function ($$v) {_vm.$set(_vm.localTeam, "name", $$v)},expression:"localTeam.name"}})],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Beschreibung deines Teams","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"label":"Beschreibung deines Teams","type":{ 'is-danger': errors.length > 0, 'is-primary': valid },"message":errors[0]}},[_c('b-input',{key:"team-description",attrs:{"type":"textarea","placeholder":"Diese Beschreibung sehen neue Mitglieder zum Beispiel in der Einladungs-E-Mail"},model:{value:(_vm.localTeam.description),callback:function ($$v) {_vm.$set(_vm.localTeam, "description", $$v)},expression:"localTeam.description"}})],1)]}}],null,true)}),_c('b-field',{attrs:{"label":"Rolle des Team-Besitzers","message":"Wenn angewählt, kann der Team-Besitzer SIGNdigital ebenfalls benutzen, verbraucht aber einen Platz der Lizenz."}},[_c('b-checkbox',{model:{value:(_vm.localTeam.ownerHasAccess),callback:function ($$v) {_vm.$set(_vm.localTeam, "ownerHasAccess", $$v)},expression:"localTeam.ownerHasAccess"}},[_vm._v("Besitzer hat SIGNdigital Zugang")])],1),(_vm.submit)?_c('b-button',{staticClass:"mt-5",attrs:{"native-type":"submit","type":"is-primary","disabled":invalid,"expanded":""}},[_vm._v(_vm._s(_vm.submitText))]):_vm._e()],1):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }