var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"team-invitations-view"},[_c('div',{staticClass:"container p-5"},[_c('h1',{staticClass:"title"},[_vm._v("Einladungen")]),_c('div',{staticClass:"card"},[_vm._m(0),_c('div',{staticClass:"card-content"},[_c('b-table',{attrs:{"data":_vm.openInvitations},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('div',{staticClass:"has-text-centered"},[_c('p',[_vm._v("Momentan keine offenen Einladungen")]),_c('b-button',{staticClass:"mt-4",attrs:{"tag":"router-link","to":{ name: 'team-invite' }}},[_vm._v("Jetzt Teammitglieder einladen")])],1)]},proxy:true}])},[_c('b-table-column',{attrs:{"label":"E-Mail"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invitation = ref.row;
return [_vm._v(_vm._s(invitation.email))]}}])}),_c('b-table-column',{attrs:{"label":"Aktionen"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invitation = ref.row;
return [_c('b-button',{attrs:{"size":"is-small","type":"is-danger","icon-left":"times"},on:{"click":function($event){return _vm.onCancelButtonClicked(invitation)}}},[_vm._v("Stornieren")])]}}])})],1)],1)]),_c('div',{staticClass:"card mt-4"},[_vm._m(1),_c('div',{staticClass:"card-content"},[_c('b-table',{attrs:{"data":_vm.invitations},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('div',{staticClass:"has-text-centered"},[_c('p',[_vm._v("Noch keine Einladungen erstellt")])])]},proxy:true}])},[_c('b-table-column',{attrs:{"label":"E-Mail"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invitation = ref.row;
return [_vm._v(_vm._s(invitation.email))]}}])}),_c('b-table-column',{attrs:{"label":"Status"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invitation = ref.row;
return [(invitation.isConsumed)?_c('div',{staticClass:"tags has-addons"},[_c('span',{staticClass:"tag is-primary"},[_vm._v("Eingelöst")]),_c('span',{staticClass:"tag is-light"},[_vm._v(_vm._s(_vm._f("dateTimeAt")(invitation.consumedAt)))])]):(invitation.isInvalidated)?_c('div',{staticClass:"tags has-addons"},[_c('span',{staticClass:"tag is-danger"},[_vm._v("Storniert")]),_c('span',{staticClass:"tag is-light"},[_vm._v(_vm._s(_vm._f("dateTimeAt")(invitation.invalidatedAt)))])]):(invitation.isExpired)?_c('div',{staticClass:"tags has-addons"},[_c('span',{staticClass:"tag is-dark"},[_vm._v("Abgelaufen")]),_c('span',{staticClass:"tag is-light"},[_vm._v(_vm._s(_vm._f("dateTimeAt")(invitation.expiresAt)))])]):_c('div',{staticClass:"tags has-addons"},[_c('span',{staticClass:"tag is-success"},[_vm._v("Offen")]),_c('span',{staticClass:"tag is-light"},[_vm._v("bis zum "+_vm._s(_vm._f("dateTimeAt")(invitation.expiresAt)))])])]}}])})],1),_c('b-pagination',{staticClass:"mt-2",attrs:{"total":_vm.totalInvitations,"per-page":_vm.invitationsLimit,"aria-next-label":"Nächste Seite","aria-previous-label":"Vorherige Seite","aria-page-label":"Seite","aria-current-label":"Aktuelle Seite"},model:{value:(_vm.currentInvitationsPage),callback:function ($$v) {_vm.currentInvitationsPage=$$v},expression:"currentInvitationsPage"}})],1)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-header"},[_c('div',{staticClass:"card-header-title"},[_vm._v("Offene Einladungen")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-header"},[_c('div',{staticClass:"card-header-title"},[_vm._v("Alle Einladungen")])])}]

export { render, staticRenderFns }