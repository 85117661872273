<template>
  <div class="own-sign-page-content">
    <document-watermark
      :frame="watermarkFrame"
      :image="documentProperties.logo"
      @update:image="onDocumentLogoChanged"
      editable
    />
    <document-text-field
      :frame="titleFrame"
      placeholder="Überschrift"
      :font-style="titleStyle"
      :value="documentProperties.title"
      @update:value="onDocumentTitleChanged"
    />
    <div class="column-labels">
      <document-text-field
        :frame="ownSignColumnHeaderFrame"
        placeholder="Meine Gebärde"
        :font-style="columnHeaderStyle"
        :value="documentProperties.firstColumnTitle"
        @update:value="onFirstColumnTitleChanged"
      />
      <document-text-field
        :frame="signColumnHeaderFrame"
        placeholder="SIGNbox"
        :font-style="columnHeaderStyle"
        :value="documentProperties.secondColumnTitle"
        @update:value="onSecondColumnTitleChanged"
      />
    </div>
    <div class="elements-wrapper" :style="elementsWrapperFrameStyle">
      <draggable :value="page.elementIds" @input="onDraggableInput" group="own-sign" handle=".drag-handle">
        <transition-group>
          <own-sign-element
            v-for="(elementId, index) in page.elementIds"
            :key="elementId"
            :element-id="elementId"
            :frame="getElementFrame(index)"
          />
        </transition-group>
      </draggable>
    </div>

    <document-text-field
      v-if="documentProperties.footerText || $authorization.hasMultiplicatorPermission"
      placeholder="Optionaler Vermerk für Referenten"
      :frame="footerTextFrame"
      :font-style="footerTextStyle"
      :value="documentProperties.footerText"
      @update:value="onFooterTextChanged"
      :editable="$authorization.hasMultiplicatorPermission"
      class="height-auto"
    />
    <page-copyright-note v-if="documentProperties.logo" :frame="copyrightNoteFrame" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import draggable from 'vuedraggable'

import { FontStyle } from '@custom-media/signdigital-lib/src/documents/font-style'

import OwnSignElement from '@/components/document/OwnSignElement'

import DocumentWatermark from '@/components/document/DocumentWatermark'
import DocumentTextField from '@/components/document/DocumentTextField'
import PageCopyrightNote from '@/components/document/PageCopyrightNote'

import { frameStyleMixin } from '@/mixins/document-element-mixin'
export default {
  components: {
    draggable,
    OwnSignElement,
    DocumentWatermark,
    DocumentTextField,
    PageCopyrightNote
  },
  props: {
    page: Object
  },
  data () {
    return {
      watermarkFrame: { x: 0, y: 0, width: 160, height: 90 },
      footerTextStyle: new FontStyle(10, '400', 'left'),
      titleFrame: { x: this.page.printableArea.width - 424 - 16, y: 0, width: 424, height: 60 },
      ownSignColumnHeaderFrame: { x: 279, y: 78, width: 204, height: 50 },
      signColumnHeaderFrame: { x: 499, y: 78, width: 204, height: 50 },
      columnHeaderStyle: new FontStyle(22, '700', 'center'),
      titleStyle: new FontStyle(30, '700', 'left'),
      copyrightNoteFrame: {
        x: this.page.printableArea.width / 2,
        y: this.page.printableArea.height,
        width: this.page.printableArea.width / 2,
        height: 20
      },
      footerTextFrame: {
        x: 0,
        y: this.page.printableArea.height + 5,
        width: this.page.printableArea.width / 2,
        height: 15
      }
    }
  },
  computed: {
    ...mapState('documentEditor', {
      documentProperties: 'properties'
    }),
    elementsWrapperFrame () {
      return { x: 0, y: 136, width: this.page.printableArea.width, height: 3 * 306 }
    },
    elementsWrapperFrameStyle () {
      return frameStyleMixin.methods.getFrameStyleFor(this.elementsWrapperFrame)
    }
  },
  methods: {
    getElementFrame (index) {
      const HEIGHT = 306
      const OFFSET_TOP = 136
      return {
        x: 0,
        y: index * HEIGHT + OFFSET_TOP,
        width: this.page.printableArea.width,
        height: HEIGHT
      }
    },
    onDocumentTitleChanged (newDocumentTitle) {
      this.$store.commit('documentEditor/updateProperty', { key: 'title', value: newDocumentTitle })
    },
    onDocumentLogoChanged (newLogo) {
      this.$store.commit('documentEditor/updateProperty', { key: 'logo', value: newLogo })
    },
    onFirstColumnTitleChanged (newFirstColumnTitle) {
      this.$store.commit('documentEditor/updateProperty', { key: 'firstColumnTitle', value: newFirstColumnTitle })
    },
    onSecondColumnTitleChanged (newSecondColumnTitle) {
      this.$store.commit('documentEditor/updateProperty', { key: 'secondColumnTitle', value: newSecondColumnTitle })
    },
    onFooterTextChanged (newFooterText) {
      this.$store.commit('documentEditor/updateProperty', { key: 'footerText', value: newFooterText })
    },
    onDraggableInput (value) {
      // TODO: [SIGN-242] OwnSignPage: Drag between pages
      this.$store.commit('documentEditor/patchPage', { id: this.page.id, data: { elementIds: value } })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/bulma-variables.scss';
.own-sign-page-content {
  position: absolute;
  width: 100%;
  height: 100%;
  .own-sign-element {
    border-top: 1px solid #ccc;

    &.sortable-ghost {
      opacity: 0.5;
      background: rgba($primary, 0.5);
    }
  }
}
</style>
