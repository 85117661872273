<template>
  <div class="content-navigator" ref="navigator">
    <div class="content-padding" :style="contentPaddingStyle">
      <div class="zoom-outer" :style="outerZoomStyle">
        <div class="zoom-inner" :style="innerZoomStyle">
          <div class="document">
            <page
              v-for="page in pages"
              :key="page.id"
              :page="page"
              @update:page="onPageUpdated"
              @update:document="onDocumentUpdated"
            />
          </div>
        </div>
        <div class="add-page-button-wrapper">
          <b-button ref="addPageButton" type="is-primary" outlined @click="onAddPageButtonClicked()"
            >Neue Seite hinzufügen</b-button
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import Page from '@/components/document/Page'
import { clone } from 'lodash'
const PAGE_MARGIN = 10
const PAGE_MARGIN_BOTTOM = 20
const EDITOR_PADDING = 20
export default {
  components: {
    Page
  },
  props: {
    zoom: Number,
    size: Object
  },
  data () {
    return {}
  },
  computed: {
    ...mapGetters('documentEditor', ['pages']),
    innerZoomStyle () {
      return { transform: `scale(${this.zoom})` }
    },
    outerZoomStyle () {
      if (this.size == null) {
        return null
      }
      return {
        width: this.outerDimensions.width + 'px',
        height: this.outerDimensions.height + 'px',
        left: Math.max((this.size.width - this.outerDimensions.width) / 2, EDITOR_PADDING) + 'px',
        top: EDITOR_PADDING + 'px'
      }
    },
    contentPaddingStyle () {
      if (this.size == null) {
        return null
      }
      return {
        width: this.outerDimensions.width + 2 * EDITOR_PADDING + 'px',
        height: this.outerDimensions.height + 2 * EDITOR_PADDING + 'px'
      }
    },
    /**
     * Dimensions of the page with margin
     */
    contentDimensions () {
      return this.pages.reduce(
        (dimensions, p, i) => {
          dimensions.width = Math.max(dimensions.width, p.dimensions.width + 2 * PAGE_MARGIN)
          dimensions.height += p.dimensions.height + (i > 0 ? PAGE_MARGIN_BOTTOM : PAGE_MARGIN)
          return dimensions
        },
        { width: 0, height: 0 }
      )
    },
    /**
     * Outer dimensions of the zoom container (scaled content)
     */
    outerDimensions () {
      return {
        width: Math.max(this.contentDimensions.width * this.zoom, 300),
        height: this.contentDimensions.height * this.zoom + 70
      }
    },
    /**
     * All dimensions of the document content
     */
    dimensions () {
      return {
        content: this.contentDimensions,
        editorPadding: EDITOR_PADDING
      }
    }
  },
  methods: {
    onPageUpdated (page) {
      const pageIndex = this.document.pages.findIndex((p) => p.id === page.id)
      if (pageIndex === -1) {
        throw new Error('DocumentContentNavigator::onPageUpdated: Could not find page in document ' + page.id)
      }
      const copy = clone(this.document)
      copy.pages[pageIndex] = page
      this.$emit('update:page', page)
    },
    onDocumentUpdated (document) {
      this.$emit('update:document', document)
    },
    async onAddPageButtonClicked () {
      await this.$store.dispatch('documentEditor/addNewPage')
      // TODO: Refresh dimensions
    }
  }
}
</script>

<style lang="scss" scoped>
.content-navigator {
  position: absolute;
  overflow-x: auto;
  overflow-y: auto;
  height: 100%;
  width: 100%;

  .content-padding {
    position: absolute;
  }

  .add-page-button-wrapper {
    text-align: center;
    z-index: 15;
    width: 100%;
    position: absolute;
    bottom: 0;
  }

  .zoom-outer {
    overflow: visible;
    position: absolute;

    .zoom-inner {
      transform-origin: 0 0;
      position: absolute;

      .document {
        position: absolute;
        .page {
          margin: 10px;
          margin-bottom: 20px;
        }
      }
    }
  }
}
</style>
