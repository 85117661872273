var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"subscription-change"},[_c('card-step',{staticClass:"mb-5",attrs:{"step-title":"1.","card-title":"Lizenz auswählen","active":_vm.currentStep === 'license',"disabled":_vm.isChangingSubscription,"completed":_vm.isLicenseSelectionCompleted},on:{"edit":function($event){_vm.currentStep = 'license'}},scopedSlots:_vm._u([{key:"default",fn:function(){return [(_vm.licenseIndex != null)?_c('license-seats-slider',{attrs:{"license-groups":_vm.licenseGroups},on:{"input":_vm.onLicenseIndexChanged},model:{value:(_vm.licenseIndex),callback:function ($$v) {_vm.licenseIndex=$$v},expression:"licenseIndex"}}):_vm._e(),(_vm.selectedGroup != null)?_c('div',{staticClass:"licenses"},_vm._l((_vm.selectedGroup.licenses),function(license){return _c('license-card',{key:license.id,attrs:{"selectable":"","selected":_vm.selectedLicense === license,"license":license,"vat":_vm.vat,"country":_vm.customer.countryCode},on:{"selected":function($event){return _vm.onLicenseSelected(license)}}})}),1):_vm._e()]},proxy:true},{key:"footer",fn:function(){return [_c('div',{staticClass:"card-footer-item"},[_c('b-button',{attrs:{"expanded":"","type":"is-primary","disabled":!_vm.canCompleteLicenseSelection},on:{"click":_vm.onCompleteLicenseSelection}},[_vm._v("Weiter")])],1)]},proxy:true}])}),_c('validation-observer',{attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('card-step',{staticClass:"mb-5",attrs:{"step-title":"2.","card-title":"Zahlungsmethode auswählen","active":_vm.currentStep === 'payment',"disabled":_vm.isChangingSubscription,"completed":_vm.isPaymentMethodSelectionCompleted},on:{"edit":function($event){_vm.currentStep = 'payment'}},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('payment-method-select-form',{attrs:{"has-submit-button":false,"selected-payment-method":_vm.selectedPaymentMethod,"payment-methods":_vm.paymentMethods,"required":_vm.createPaymentMethod !== 'true'},on:{"update:selected-payment-method":_vm.onExistingPaymentMethodSelected,"remove":_vm.onRemovePaymentMethod}}),_c('b-field',[_c('b-radio',{attrs:{"native-value":"true","expanded":""},on:{"input":_vm.onCreateNewPaymentMethodSelected},model:{value:(_vm.createPaymentMethod),callback:function ($$v) {_vm.createPaymentMethod=$$v},expression:"createPaymentMethod"}},[_c('span',[_vm._v("Neue Zahlungsmethode anlegen")])])],1),(_vm.createPaymentMethod === 'true')?_c('create-payment-method-form',{ref:"createPaymentForm",attrs:{"required":true,"customer":_vm.customer,"stripe":_vm.stripe,"stripe-elements":_vm.stripeElements,"enabled-payment-methods":_vm.enabledPaymentMethods,"has-submit-button":false}}):_vm._e()]},proxy:true},{key:"footer",fn:function(){return [_c('div',{staticClass:"card-footer-item"},[_c('b-button',{attrs:{"expanded":"","type":"is-primary","disabled":invalid},on:{"click":_vm.onCompletePaymentMethodSelection}},[_vm._v("Weiter")])],1)]},proxy:true}],null,true)})]}}])}),_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('card-step',{staticClass:"mb-5",attrs:{"step-title":"3.","card-title":"Bestellung bestätigen","active":_vm.currentStep === 'invoice-preview',"loading":_vm.isLoadingInvoicePreview,"completed":_vm.isInvoicePreviewCompleted,"disabled":_vm.isChangingSubscription},on:{"edit":function($event){_vm.currentStep = 'invoice-preview'}},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('feathers-error',{staticClass:"mt-4",attrs:{"error":_vm.invoicePreviewError,"title":"Fehler beim Verarbeiten der Bestellung"}}),(_vm.invoicePreview)?_c('stripe-invoice-preview-table',{attrs:{"invoice-preview":_vm.invoicePreview}}):_vm._e(),_c('validation-provider',{attrs:{"name":"Allgemeine Geschäftsbedingungen","bails":false,"rules":{ required: { allowFalse: false } }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{staticClass:"mt-4",attrs:{"type":{ 'is-danger': errors.length > 0, 'is-primary': valid }},scopedSlots:_vm._u([{key:"message",fn:function(){return [_c('p',[(errors.length > 0)?_c('span',[_vm._v("Zur Nutzung von SIGNdigital müssen die Allgemeinen Geschäftsbedingungen akzeptiert werden. Bei Fragen kannst du einfach "),_c('a',{attrs:{"href":"mailto:support@sign-digital.de"}},[_vm._v("Kontakt mit uns aufnehmen.")])]):_vm._e()])]},proxy:true}],null,true)},[_c('b-checkbox',{model:{value:(_vm.acceptedTerms),callback:function ($$v) {_vm.acceptedTerms=$$v},expression:"acceptedTerms"}},[_vm._v("Ich akzeptiere die "),_c('a',{attrs:{"href":_vm.landingPageUrl + '/agb',"target":"_blank"}},[_vm._v("Allgemeinen Geschäftsbedingungen")])])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Datenschutzerklärung","bails":false,"rules":{ required: { allowFalse: false } }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{staticClass:"mt-2",attrs:{"type":{ 'is-danger': errors.length > 0, 'is-primary': valid }},scopedSlots:_vm._u([{key:"message",fn:function(){return [_c('p',[(errors.length > 0)?_c('span',[_vm._v("Zur Nutzung von SIGNdigital muss die Datenschutzerklärung akzeptiert werden. Bei Fragen kannst du einfach "),_c('a',{attrs:{"href":"mailto:support@sign-digital.de"}},[_vm._v("Kontakt mit uns aufnehmen.")])]):_vm._e()])]},proxy:true}],null,true)},[_c('b-checkbox',{model:{value:(_vm.acceptedPrivacy),callback:function ($$v) {_vm.acceptedPrivacy=$$v},expression:"acceptedPrivacy"}},[_vm._v("Ich bin mit der Verarbeitung meiner Daten im Rahmen der "),_c('a',{attrs:{"href":_vm.landingPageUrl + '/datenschutz',"target":"_blank"}},[_vm._v("Datenschutzerklärung")]),_vm._v(" einverstanden")])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Verzicht Widerrufsrecht","bails":false,"rules":{ required: { allowFalse: false } }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{staticClass:"mt-2 mb-4",attrs:{"type":{ 'is-danger': errors.length > 0, 'is-primary': valid }},scopedSlots:_vm._u([{key:"message",fn:function(){return [_c('p',[(errors.length > 0)?_c('span',[_vm._v("Da auf die digitalen Inhalte von SIGNdigital nach der Bestellung umgehend und vollständig zugegriffen werden kann, benötigen wir den Verzicht auf das Widerrufsrecht. Bei Fragen kannst du einfach "),_c('a',{attrs:{"href":"mailto:support@sign-digital.de"}},[_vm._v("Kontakt mit uns aufnehmen.")])]):_vm._e()])]},proxy:true}],null,true)},[_c('b-checkbox',{model:{value:(_vm.acceptedReturnPolicy),callback:function ($$v) {_vm.acceptedReturnPolicy=$$v},expression:"acceptedReturnPolicy"}},[_vm._v("Ich verzichte bei der Bestellung dieses digitalen Produktes auf mein 14-tägiges Widerrufsrecht")])],1)]}}],null,true)})]},proxy:true},{key:"footer",fn:function(){return [_c('div',{staticClass:"card-footer-item"},[_c('b-button',{attrs:{"expanded":"","type":"is-primary","disabled":!_vm.invoicePreview || invalid},on:{"click":_vm.onCompleteSubscriptionChange}},[_vm._v("Jetzt kostenpflichtig bestellen")])],1)]},proxy:true}],null,true)})]}}])}),(_vm.currentStep === 'submit')?_c('card-step',{staticClass:"mb-5",attrs:{"step-title":"4.","active":_vm.currentStep === 'invoice-preview',"card-title":"Lizenz wird geändert","editable":false,"completed":_vm.isSubmitCompleted,"loading":_vm.isChangingSubscription},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('feathers-error',{staticClass:"mt-4",attrs:{"error":_vm.changeSubscriptionError,"title":"Fehler beim Ändern der Lizenz"}})]},proxy:true}],null,false,1084074250)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }