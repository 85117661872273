<template>
  <li>
    <div class="node-item-title" :class="{ selected: isCurrent }" :style="{ 'padding-left': indentation }">
      <b-button
        class="expand-button"
        type="is-ghost"
        size="is-small"
        :icon-left="isExpanded ? 'chevron-down' : 'chevron-right'"
        @click="onExpandButtonClicked"
        :loading="item.isFindChildrenLoading"
      >
      </b-button>
      <b-button
        class="node-button"
        :icon-left="nodeIcon"
        type="is-ghost"
        size="is-small"
        @click="onItemSelected(item)"
        expanded
      >
        {{ item.fileNode.name }}
      </b-button>
    </div>

    <ul v-if="isExpanded && childDirectoryNodes.length">
      <file-node-explorer-item
        v-for="child of childDirectoryNodes"
        :item="child"
        :level="level + 1"
        :browser-ctrl="browserCtrl"
        :key="child._id"
        @item-selected="onItemSelected"
      />
    </ul>
  </li>
</template>

<script>
export default {
  name: 'file-node-explorer-item',
  props: {
    item: Object,
    browserCtrl: Object,
    level: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      isExpanded: false
    }
  },
  computed: {
    nodeIcon () {
      if (this.item._id?.startsWith('user_')) {
        return 'user'
      }
      if (this.item._id?.startsWith('team_')) {
        return 'users'
      }

      if (this.isExpanded) {
        return 'folder-open'
      }
      return 'folder'
    },
    isCurrent () {
      return this.browserCtrl.currentItem?._id === this.item?._id ?? false
    },
    isParentOfCurrent () {
      return this.browserCtrl.currentItem?.fileNode?.path?.includes(`,${this.item._id},`) ?? false
    },
    indentation () {
      return `${this.level * 0.75}em`
    },
    childDirectoryNodes () {
      return this.item?.children?.filter((c) => c.fileNode.nodeType === 'directory') ?? []
    },
    expandedByDefaultForCurrentItem () {
      return this.isCurrent || this.isParentOfCurrent
    }
  },
  watch: {
    'browserCtrl.currentItem' (n, o) {
      // If i am a parent of the node or the node itself, ensure I'm expanded
      if (this.expandedByDefaultForCurrentItem) {
        this.isExpanded = true
      }
    }
  },
  methods: {
    onItemSelected (item) {
      this.isExpanded = true
      this.$emit('item-selected', item)
    },
    onExpandButtonClicked () {
      this.isExpanded = !this.isExpanded
      if (this.isExpanded) {
        this.$store.dispatch('fileNodeTree/maybeFetchChildren', this.item)
      }
    }
  },
  mounted () {
    if (this.expandedByDefaultForCurrentItem) {
      this.isExpanded = true
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/bulma-variables.scss';
li {
  list-style: none;
  overflow: hidden;
}
.node-item-title {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: $sm-radius;
  &:hover {
    background: $grey-lightest;
  }

  &.selected {
    background: $grey-lighter;
    font-weight: bold;
    .button::v-deep {
      font-weight: bold;

      &.is-loading::after {
        border-bottom-color: $dark;
        border-left-color: $dark;
      }
    }
  }
}

.button::v-deep {
  &.node-button,
  &.expand-button {
    color: $text;
  }
  &.expand-button {
    padding-right: 0.75em;
  }
  &.node-button {
    padding-left: 0.75em;
    justify-content: start;
    overflow: hidden;

    span:last-child {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
</style>
