<template>
  <div class="reavealable-text">
    <slot v-if="value"></slot>
    <b-button v-else type="is-primary" icon-right="sparkles" @click="reveal">{{ title }} aufdecken</b-button>
    <!-- <span v-else class="reveal-placeholder" @click="reveal">
      <span class="hint">{{ title }} aufdecken <b-icon size="is-medium" icon="sparkles" class="ml-1"></b-icon></span>
    </span> -->
  </div>
</template>

<script>
export default {
  data () {
    return {}
  },
  props: {
    value: Boolean,
    title: String
  },
  methods: {
    reveal () {
      this.$emit('input', true)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/bulma-variables.scss';
</style>
