<template>
  <div class="list-editor" v-if="list">
    <b-navbar class="app-nav is-mobile" :mobile-burger="false" :active="true">
      <template slot="brand">
        <b-navbar-item tag="div">
          <nav class="breadcrumb" aria-label="breadcrumbs">
            <ul>
              <li><div>Lernliste</div></li>
              <li class="is-active">
                <a>{{ list.name }}</a>
              </li>
            </ul>
          </nav>
        </b-navbar-item>
      </template>
      <template slot="start">
        <template>
          <b-navbar-item @click="scrollToId('what-is-sign-digital')"> Was ist SIGNdigital? </b-navbar-item>
          <b-navbar-item @click="scrollToId('about-lists')">Über Lernlisten </b-navbar-item>
          <b-navbar-item @click="scrollToId('how-much-does-it-cost')"> Preise </b-navbar-item>
        </template>
      </template>
      <template slot="end">
        <LoginButtonTopRight v-if="!isLoggedIn" />
      </template>
    </b-navbar>

    <section class="editor" v-if="list.signs">
      <div class="container p-5">
        <div class="level">
          <div class="level-left">
            <h2 class="subtitle">Lernliste „{{ list.name }}”</h2>
          </div>
          <div class="level-right">
            <router-link
              :disabled="list.signs.length === 0"
              :to="linkToListPlayerPublic"
              tag="a"
              class="button is-primary"
            >
              <b-icon icon="play" class="mr-1"></b-icon>Öffnen</router-link
            >
          </div>
        </div>
        <div>
          <div class="grid-container">
            <div class="grid-item" v-for="(item, index) in displayedSigns" :key="index">
              <div class="card">
                <div class="card-image">
                  <figure class="image">
                    <image-component :image="images[index]" />
                  </figure>
                </div>
                <div class="card-content">
                  <div class="media">
                    <div class="media-content">
                      <p class="title is-6">{{ item.name }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <template>
              <div class="grid-item" v-for="item in hiddenSigns" :key="'filler-card-' + item._id">
                <div class="card blank-card">
                  <b-icon icon="lock" type="is-grey-lighter" size="is-large" />
                  <div class="card-image">
                    <figure class="image">
                      <img
                        class="has-ratio"
                        width="254"
                        :src="signOnlyPlaceholder"
                        alt="Versteckte Gebärdenzeichnung"
                      />
                    </figure>
                  </div>
                  <div class="card-content">
                    <div class="media">
                      <div class="media-content">
                        <p class="title is-6 has-text-grey-lighter">{{ item.name }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
          <template v-if="list.signs.length === 0">
            <div class="content has-text-centered">
              <b-icon icon="ellipsis-h" size="is-large" type="is-light" />
              <p>Diese Lernliste ist noch leer</p>
            </div>
          </template>
        </div>

        <div class="notificationContainer" :class="{ 'is-overlapping': hasFillerCardOnlyRow }">
          <b-notification
            v-if="list && !hasActiveSubscription"
            type="is-secondary is-light"
            role="alert"
            class="my-4"
            :closable="false"
          >
            <span> „{{ list.authorFullname }}” hat dir diese Lernliste auf SIGNdigital freigegeben. </span>
            <span v-if="visibleSignsForUnsubscribedUsers < this.list.signs.length"
              >Du {{ !isLoggedIn ? 'bist nicht angemeldet' : 'hast keine aktive Lizenz' }} und kannst daher nur
              {{ visibleSignsForUnsubscribedUsers }} von {{ this.list.signs.length }} Gebärden auf dieser Lernliste
              sehen.
            </span>
            <span>Ohne aktive Lizenz werden dir nur Gebärden mit Wasserzeichen angezeigt. </span>
            <span v-if="!isLoggedIn"
              >Mit deiner
              <router-link :to="{ name: 'login' }"> bestehenden SIGNdigital-Lizenz anmelden</router-link> oder
              <router-link :to="{ name: 'signup' }">eine neue Lizenz erwerben</router-link>, um vollen Zugriff zu
              erhalten.
            </span>
            <span v-else-if="!hasActiveSubscription"
              ><router-link tag="span" :to="{ name: 'user-subscription' }">Eine Lizenz erwerben</router-link>, um vollen
              Zugriff zu erhalten.
            </span>
          </b-notification>
        </div>
      </div>
    </section>

    <SignDigitalInformation />
  </div>
</template>

<script>
import ImageComponent from '@custom-media/signdigital-web-shared/src/components/ImageComponent.vue'
import { mapActions, mapState } from 'vuex'
import { SignImageReference } from '@custom-media/signdigital-lib/src/image-reference'
import { ResolvableImage } from '@custom-media/signdigital-web-shared/src/lib/resolvable-image'
import LoginButtonTopRight from '@/components/LoginButtonTopRight.vue'
import SignDigitalInformation from '@/components/SignDigitalInformation.vue'

import signOnlyPlaceholder from '@custom-media/signdigital-web-shared/src/assets/img/sign-only-placeholder-thumbnail.png'
import scrollUtilsMixin from '@/mixins/scroll-utils-mixin'
import { UNLICENSED_VISIBLE_SIGN_COUNT } from '@/lib/constants/unlicensed-constants'

export default {
  mixins: [scrollUtilsMixin],
  components: {
    ImageComponent,
    SignDigitalInformation,
    LoginButtonTopRight
  },
  data () {
    return {
      list: {},
      visibleSignsForUnsubscribedUsers: UNLICENSED_VISIBLE_SIGN_COUNT,
      signOnlyPlaceholder
    }
  },
  mounted () {
    this.init()
  },
  beforeDestroy () {},
  computed: {
    ...mapState('auth', ['user']),
    images () {
      return this.displayedSigns.map((s) => {
        return new ResolvableImage({
          $store: this.$store,
          $resolver: this.$resolver,
          reference: new SignImageReference({
            objectId: s._id ?? s.__id,
            imageType: 'signOnly'
          }),
          variant: 'watermarked'
        })
      })
    },
    hasActiveSubscription () {
      return this.$store.getters['auth/user']?.hasActiveSubscription
    },
    isLoggedIn () {
      return !!this.$store.getters['auth/user']
    },
    columns () {
      if (this.$screen.fullhd) {
        return 5
      } else if (this.$screen.desktop) {
        return 4
      } else if (this.$screen.tablet) {
        return 3
      } else {
        return 2
      }
    },
    totalSignCount () {
      return this.list.signs.length
    },
    visibleSignCount () {
      return Math.min(this.totalSignCount, this.visibleSignsForUnsubscribedUsers)
    },
    hiddenSignCount () {
      return this.totalSignCount - this.visibleSignCount
    },
    hasHiddenSigns () {
      return this.hiddenSignCount > 0
    },
    totalRows () {
      return Math.ceil(this.visibleSignCount / this.columns)
    },
    // All cards including fillers
    totalCardSpaceCount () {
      return this.totalRows * this.columns
    },
    displayedSigns () {
      return this.list.signs.slice(0, this.visibleSignCount)
    },
    hiddenSigns () {
      return this.hasHiddenSigns
        ? this.list.signs.slice(this.visibleSignCount, this.visibleSignCount + this.fillerCardCount)
        : []
    },
    lastRowFillerCardSlots () {
      const trailing = this.visibleSignCount % this.columns
      const trailingSlotsLastRow = trailing === 0 ? 0 : this.columns - trailing
      return trailingSlotsLastRow
    },
    hasFillerCardOnlyRow () {
      return this.hiddenSignCount > this.lastRowFillerCardSlots
    },
    fillerCardCount () {
      // If has excess that is not shown
      if (this.hiddenSignCount > this.lastRowFillerCardSlots) {
        // Show extra row of blank cards
        return Math.min(this.columns + this.lastRowFillerCardSlots, this.hiddenSignCount)
      } else {
        return Math.min(this.lastRowFillerCardSlots, this.hiddenSignCount)
      }
    },
    linkToListPlayerPublic () {
      return this.list.signs.length === 0
        ? ''
        : { name: 'list-player-public', params: { shareKey: this.$route.params.shareKey } }
    },
    textSize () {
      return !this.$screen.tablet ? 'is-size-6' : ''
    }
  },
  beforeRouteLeave (to, from, next) {
    // Close search dropdowns
    document.querySelectorAll('.search-dropdown').forEach((modal) => {
      modal.__vue__?.$vnode?.context?.close()
    })
    next()
  },
  methods: {
    ...mapActions('lists', {
      getList: 'get'
    }),
    async init () {
      if (this.$route.params.shareKey != null) {
        const { List } = this.$FeathersVuex.api
        const res = await List.find({ query: { shareKey: this.$route.params.shareKey } })
        if (res.data.length === 0) {
          this.$buefy.toast.open({
            message: 'Keine passende Lernliste gefunden',
            duration: 5000,
            type: 'is-warning'
          })
          this.recoverFromInvalidParams()
          return
        }
        this.list = res.data[0]

        if (this.$store.getters['auth/user']?.hasWriteAccess(this.list)) {
          // Is owned document, redirect to editor
          this.$router.replace({
            name: 'list-editor',
            params: {
              id: this.list._id
            }
          })
        }
      } else {
        this.recoverFromInvalidParams()
      }
    },
    recoverFromInvalidParams () {
      if (this.isLoggedIn) {
        this.$router.replace({ name: 'lists' })
      } else {
        this.$router.replace({ name: 'login' })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/bulma-variables.scss';

.list-editor {
  overflow: hidden;
  width: 100%;
}
.navbar {
  &::v-deep {
    .navbar-menu {
      flex-wrap: wrap;
    }

    .navbar-start {
      flex-wrap: wrap;
    }
  }

  .navbar-item {
    display: flex;
    align-items: center;
  }
}

figure.sign-image {
  max-width: 120px;
}
.b-table::v-deep {
  table.table {
    td {
      vertical-align: middle;
    }
  }
}

/* Default grid layout with 5 columns maximum */
.grid-container {
  display: grid;
  gap: 0.75rem;
  grid-template-rows: auto;
  row-gap: 0.75rem;
  grid-template-columns: repeat(2, minmax(9rem, 1fr));

  @include from($tablet) {
    grid-template-columns: repeat(3, minmax(9rem, 1fr));
  }
  @include from($desktop) {
    grid-template-columns: repeat(4, minmax(6rem, 1fr));
  }
  @include from($fullhd) {
    grid-template-columns: repeat(5, minmax(6rem, 1fr));
  }
}

/* Makes all cards have the same width and height as the first card */
.grid-item {
  aspect-ratio: 1;
}

.button-group {
  padding-right: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.costs {
  padding: 0 1.5rem;
}

.blank-card {
  width: 100%;
  height: 100%;
  background: $light;
  background: linear-gradient(180deg, white 0%, $light 100%);
  box-shadow: none !important;
  position: relative;

  .icon {
    display: flex;
    position: absolute;
    padding-top: 3rem;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    justify-content: center;
    align-items: center;
  }

  & > .card-image {
    filter: blur(4px);
    display: block;
    position: relative;
    -webkit-mask-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 1)), to(rgba(0, 0, 0, 0)));
    mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));

    & > .has-ratio {
      bottom: 0;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}

.notificationContainer.is-overlapping {
  position: absolute;
  bottom: -4rem;
  width: 100%;
  padding-right: 3rem;
}

.editor {
  margin-bottom: 5.5rem;
}
</style>
