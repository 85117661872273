<template>
  <div class="editable-button" ref="buttonWrapper" :style="style">
    <b-button
      v-show="!isEditing"
      class="edit-button"
      @click="onEditButtonClicked()"
      icon-right="pencil"
      :loading="loading"
      :disabled="disabled"
      type="is-text"
    >
      {{ value }}</b-button
    >
    <b-input
      v-show="isEditing"
      ref="inputField"
      v-model="localValue"
      :loading="loading"
      @keydown.native="onKeydown"
    ></b-input>
    <b-button
      v-show="isEditing"
      class="apply-button"
      ref="applyButton"
      @click="onApplyButtonClicked()"
      :type="applyButtonType"
      :icon-left="applyButtonIcon"
    >
      <span v-if="applyButtonLabel != null">{{ applyButtonLabel }}</span>
    </b-button>
  </div>
</template>

<script>
export default {
  props: {
    value: String,
    loading: {
      type: Boolean,
      default: false
    },
    submitLabel: {
      type: String,
      default: 'Anwenden'
    },
    iconOnly: {
      type: Boolean,
      default: false
    },
    abortLabel: {
      type: String,
      default: 'Abbrechen'
    },
    maxWidth: {
      type: Number,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isEditing: false,
      localValue: null
    }
  },
  computed: {
    applyButtonType () {
      if (this.iconOnly) {
        return this.didChange ? 'is-primary' : 'is-outlined'
      }
      return this.didChange ? 'is-primary' : 'is-text'
    },
    applyButtonLabel () {
      if (this.iconOnly) {
        return null
      }
      return this.didChange ? this.submitLabel : this.abortLabel
    },
    applyButtonIcon () {
      if (!this.iconOnly) {
        return null
      }

      return this.didChange ? 'check' : 'times'
    },
    didChange () {
      return this.localValue !== this.value
    },
    style () {
      if (!this.maxWidth) {
        return {}
      }

      return {
        'max-width': `${this.maxWidth}px`
      }
    }
  },
  watch: {
    value (newValue) {
      this.localValue = newValue
    }
  },
  mounted () {
    this.localValue = this.value
  },
  beforeDestroy () {
    this.removeDocumentEventListener()
  },
  methods: {
    addDocumentEventListener () {
      document.body.addEventListener('click', this.onDocumentClick)
    },
    removeDocumentEventListener () {
      document.body.removeEventListener('click', this.onDocumentClick)
    },
    onDocumentClick (event) {
      const isInside = this.$refs.buttonWrapper.contains(event.target)
      if (isInside === false) {
        this.resetChange()
      }
    },
    onEditButtonClicked () {
      this.isEditing = true
      this.addDocumentEventListener()
      this.$refs.inputField.focus()
    },
    onApplyButtonClicked () {
      this.isEditing = false
      this.maybeCommitChange()
    },
    onKeydown (event) {
      if (event.key === 'Enter') {
        this.onApplyButtonClicked()
      } else if (event.key === 'Escape') {
        this.resetChange()
      }
    },
    maybeCommitChange () {
      if (this.localValue !== this.value) {
        this.$emit('input', this.localValue)
      }
      this.$refs.inputField.$refs.input.blur()
    },
    resetChange () {
      this.$refs.inputField.$refs.input.blur()
      this.localValue = this.value.slice()
      this.isEditing = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/bulma-variables.scss';
.editable-button {
  display: flex;
  .edit-button {
    padding-left: calc(0.75em - 1px);
    padding-right: calc(0.75em - 1px);
    max-width: 100%;

    text-decoration: none;
    &::v-deep {
      .icon {
        padding-left: 1em;
        padding-right: 1em;
        opacity: 0.6;
      }
      &:hover .icon {
        opacity: 1;
      }

      span:first-child {
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  .apply-button {
    margin-left: 0.5rem;
  }
}
</style>
