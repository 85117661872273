<template>
  <div class="team-role-modal">
    <div class="modal-card" style="margin: 0 auto">
      <header class="modal-card-head">
        <p class="modal-card-title">Rolle ändern</p>
      </header>
      <section class="modal-card-body">
        <div class="content">
          <p class="mb-2">Rolle von {{ user.name }} ({{ user.email }}) ändern:</p>
          <b-field>
            <b-radio v-model="localRole" native-value="manager" type="is-info">Verwalter</b-radio>
          </b-field>
          <b-field>
            <b-radio v-model="localRole" native-value="member">Mitglied</b-radio>
          </b-field>

          <p class="mt-5">Wer darf was?</p>
          <table>
            <tr>
              <th>Mitglied</th>
              <td>
                Normales Team-Mitglied, nutzt die Lizenz des Teams, kann aber weder die anderen Mitglieder sehen, noch
                einladen.
              </td>
            </tr>
            <tr>
              <th>Verwalter</th>
              <td>
                Kann Details und alle Mitglieder des Teams einsehen, einladen und die Rolle von Mitgliedern ändern.
              </td>
            </tr>
            <tr>
              <th>Besitzer</th>
              <td>
                Besondere Rolle der Person, die das Team angelegt hat und die Abrechnung verwaltet. Es kann nur einen
                Besitzer pro Team geben.
              </td>
            </tr>
          </table>
        </div>
      </section>

      <footer class="modal-card-foot">
        <b-button @click="$emit('close')">Abbrechen</b-button>
        <b-button @click="onSave()" type="is-primary">Speichern</b-button>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    user: {
      type: Object
    },
    role: {
      type: String
    }
  },
  data () {
    return {
      localRole: null
    }
  },
  created () {
    this.localRole = this.role
  },
  methods: {
    onSave () {
      if (this.localRole !== this.role) {
        this.$emit('submit', this.localRole)
      }
      this.$emit('close')
    }
  }
}
</script>
