<template>
  <div class="user-details">
    <div class="container p-5">
      <h1 class="title">Rechnungsadresse</h1>

      <!-- Billing address for paying customers -->
      <div class="card" v-if="billingType === 'paying'">
        <validation-observer slim v-slot="{ invalid }">
          <div class="card-header">
            <div class="card-header-title">Deine Rechnungsadresse</div>
            <div class="buttons">
              <b-button v-if="!isEditing" @click="isEditing = true" size="is-small">Ändern</b-button>
              <b-button v-if="isEditing" @click="onCancel" size="is-small">Abbrechen</b-button>
              <b-button v-if="isEditing" :disabled="invalid" type="is-primary" @click="onSave" size="is-small"
                >Speichern</b-button
              >
            </div>
          </div>
          <div class="card-content">
            <div class="content">
              <b-loading :active="isPatchCustomerPending" />
              <div v-if="user.role === 'customers' && entity != null">
                <p v-if="!isEditing">Diese Adresse wird für die nächste Rechnungsstellung verwendet:</p>
                <billing-address v-if="!isEditing" :customer="entity" />
                <billing-form
                  v-else
                  ref="billingForm"
                  customer-type-title="Ich bin ein..."
                  :customer="entity"
                  :submit="false"
                />
              </div>
            </div>
          </div>
        </validation-observer>
      </div>
      <div class="card" v-else-if="billingType === 'member'">
        <div class="card-content">
          <div class="content">
            Du nutzt die Lizenz deines Teams - die Rechnungsadresse wird vom Team-Besitzer verwaltet.
          </div>
        </div>
      </div>
      <div class="card" v-else-if="billingType === 'free'">
        <div class="card-content">
          <div class="content">
            Du nutzt einen kostenlosen Zugang. Es gibt daher keine Rechnungsadresse zu verwalten.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BillingForm from '@/components/registration/steps/billing-data/BillingForm.vue'
import { mapGetters, mapState } from 'vuex'
import BillingAddress from '@custom-media/signdigital-web-shared/src/components/BillingAddress.vue'

export default {
  components: { BillingForm, BillingAddress },
  data () {
    return {
      isEditing: false
    }
  },
  computed: {
    ...mapState('customers', { isPatchCustomerPending: 'isPatchPending' }),
    ...mapGetters('session', ['billingType']),
    ...mapState('session', ['user', 'entity'])
  },
  methods: {
    async onSubmit (customer) {
      await customer.save()
    },
    onCancel () {
      this.isEditing = false
    },
    async onSave () {
      const customer = this.$refs.billingForm.getCustomer()
      await customer.save()
      this.isEditing = false
    }
  }
}
</script>

<style lang="scss" scoped>
.card-header {
  .buttons {
    margin-right: 1em;
  }
}
</style>
