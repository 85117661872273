<template>
  <table class="file-node-table">
    <thead>
      <tr>
        <th class="checkbox-column"></th>
        <th class="main-column">Name</th>
        <th class="info-column">Dateityp</th>
        <th class="actions-column"></th>
      </tr>
    </thead>
    <tbody style="position: relative">
      <file-node-table-row v-if="itemToInsertPreview" :item="itemToInsertPreview" insert-ghost> </file-node-table-row>
      <b-loading
        style="min-height: 5em"
        :active="browserCtrl.currentItem.isFindChildrenLoading"
        :is-full-page="false"
      ></b-loading>
      <file-node-table-row
        v-for="item of children"
        :item="item"
        :key="item._id"
        :is-selected="browserCtrl.isItemSelected(item)"
        @item-navigation="onItemNavigation"
        @item-selected="onItemSelected"
        @action="onItemAction"
        :actions="item.fileNode.nodeType === 'file' ? fileActions : directoryActions"
      >
      </file-node-table-row>

      <tr
        v-if="browserCtrl.currentItem.isFindChildrenLoading === false && browserCtrl.currentItem.children.length === 0"
      >
        <td></td>
        <td colspan="3"><b-message size="is-small">Dieser Ordner hat noch keine Inhalte..</b-message></td>
      </tr>

      <tr
        v-if="
          browserCtrl.currentItem.isFindChildrenLoading === false &&
          children.length < browserCtrl.currentItem.children.length
        "
      >
        <td></td>
        <td colspan="3">
          <b-message size="is-small"
            >Einige Inhalte wurden durch
            <b-tag type="is-white" closable @close="onClearFileTypeFilterButtonClicked"
              ><strong>Filter: </strong>{{ browserCtrl.fileTypeFilter | fileTypeName(true) }}</b-tag
            >
            ausgeblendet.</b-message
          >
        </td>
      </tr>
      <!-- <pre>{{ $store.state['file-nodes'].pagination[`node_${browserCtrl.currentItem._id}`].mostRecent }}</pre> -->
      <!-- <b-pagination></b-pagination> -->
    </tbody>
  </table>
</template>

<script>
import FileNodeTableRow from './FileNodeTableRow.vue'
import { fileNodeMixin } from '@custom-media/signdigital-web-shared/src/mixins/file-node-mixins'
export default {
  mixins: [fileNodeMixin],
  components: {
    FileNodeTableRow
  },
  props: {
    browserCtrl: Object,
    fileActions: Array,
    directoryActions: Array,
    itemToInsertPreview: Object
  },
  data () {
    return {}
  },
  computed: {
    children () {
      if (this.browserCtrl.fileTypeFilter) {
        return this.browserCtrl.currentItem.sortedChildren.filter(
          (f) => f.fileNode.nodeType === 'directory' || f.fileNode.fileType === this.browserCtrl.fileTypeFilter
        )
      }
      return this.browserCtrl.currentItem.sortedChildren
    }
  },
  methods: {
    onItemNavigation (item) {
      if (item.fileNode.nodeType === 'directory') {
        this.browserCtrl.goTo(item)
      } else {
        this.$emit('action', { action: 'open', item })
      }
    },
    onItemSelected (item) {
      if (this.browserCtrl.isItemSelected(item)) {
        this.browserCtrl.clearSelectedItems()
      } else {
        if (item.fileNode.nodeType !== 'file') {
          return
        }
        this.browserCtrl.select([item])
      }
    },
    onItemAction ({ action, item }) {
      this.$emit('action', { action, item })
    },

    onClearFileTypeFilterButtonClicked () {
      this.browserCtrl.clearFileTypeFilter()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/bulma-variables.scss';
table thead tr {
  th:not(:first-child):not(:last-child) {
    border-bottom: 4px solid $grey-lighter;
  }
}

table tbody tr.node-item.ghost {
  border-bottom: 0;
}

table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 0.3rem;
}

table::v-deep {
  td,
  th {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    vertical-align: middle;
    padding: 0.3rem 0.2rem;
    font-size: 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .checkbox-column {
    width: 5%;
  }
  .main-column {
    width: 60%;
  }
  .info-column {
    width: 20%;
  }
  .actions-column {
    width: 15%;
  }

  @include until($tablet) {
    display: block;
    thead {
      display: none;
    }
    tbody {
      display: flex;
      flex-direction: column;

      tr {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        border: 2px solid $grey-lighter;
        border-radius: $sm-radius;
        margin-bottom: 0.25rem;
        background: $white;

        &:hover {
          td {
            background: transparent;
          }
        }
        td {
          border: none !important;
          display: flex;
          width: auto;
          max-width: none;

          &.checkbox-column {
            display: none;
          }
          &.main-column {
            width: 100%;
          }
          &.info-column {
            padding-left: 20px;
            width: auto;
          }
          &.actions-column {
            padding-right: 20px;
          }
        }
      }
    }
  }
}
</style>
