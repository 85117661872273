<template>
  <div class="favorites-view">
    <!-- NAVBAR -->
    <b-navbar class="app-nav is-mobile" :active="true" :mobile-burger="false">
      <template slot="brand">
        <b-navbar-item tag="div">
          <nav class="breadcrumb" aria-label="breadcrumbs">
            <ul>
              <li><router-link :to="{ name: 'search' }">Gebärden</router-link></li>
              <li class="is-active"><a href="#">Favoriten</a></li>
            </ul>
          </nav>
        </b-navbar-item>
      </template>
      <template slot="start"> </template>
      <template slot="end">
        <b-navbar-item tag="div">
          <!-- APPEARANCE -->
          <b-dropdown aria-role="list" custom position="is-bottom-left">
            <b-button type="is-white" size="is-small" icon-left="cog" slot="trigger" slot-scope="{ active }">
              <span>Ansicht</span>
              <b-icon :icon="active ? 'caret-up' : 'caret-down'"></b-icon>
            </b-button>

            <search-settings-dropdown />
          </b-dropdown>
        </b-navbar-item>
      </template>
    </b-navbar>
    <div class="container p-5">
      <hint v-if="wrappedSearchResults.length > 0" hint-key="favorites-remove">
        Erneut auf das <b-icon icon="heart" /> klicken, um Gebärden aus den Favoriten zu entfernen
      </hint>

      <b-loading :active="isSearchPending" :is-full-page="false" />

      <div class="results-wrapper" v-if="wrappedSearchResults.length > 0">
        <div class="results columns is-mobile is-multiline">
          <image-result
            v-for="(image, index) in wrappedSearchResults"
            :key="image.reference.objectId"
            :image="image"
            :showNames="searchPreferences.showNames"
            :showKeywords="searchPreferences.showKeywords"
            :showFavorite="true"
            @click="onSearchResultClicked($event, image)"
            @favorite-removed="onFavoriteRemoved"
            :isFirstOfRow="index % actualColumns === 0"
            :isLastOfRow="index % actualColumns === actualColumns - 1"
            :style="{ width: 100.0 / actualColumns + '%' }"
          />
        </div>
        <!-- PAGINATION -->
        <b-pagination
          :total="searchPagination.total"
          v-model="searchResultsPage"
          :per-page="searchPagination.limit"
          aria-next-label="Nächste Seite"
          aria-previous-label="Vorherige Seite"
          aria-page-label="Seite"
          aria-current-label="Aktuelle Seite"
        >
        </b-pagination>
      </div>
      <!-- NO RESULTS -->
      <div class="container" v-else-if="!isSearchPending">
        <div class="columns is-centered">
          <div class="column">
            <div class="p-4 content">
              <h3 class="subtitle">Noch keine Favoriten gespeichert</h3>
              <p>
                In der <router-link :to="{ name: 'search' }">Gebärden-Suche</router-link> können Gebärden mit dem
                <b-icon icon="heart" /> als Favorit gespeichert werden.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { searchMixin } from '@custom-media/signdigital-web-shared/src/mixins/search-mixin'
import { mapState } from 'vuex'
import { ResolvableImage } from '@custom-media/signdigital-web-shared/src/lib/resolvable-image'
import { SignImageReference } from '@custom-media/signdigital-lib/src/image-reference'

import SignDetails from '@/components/SignDetails'
import ImageResult from '@/components/ImageResult'
import Hint from '@/components/Hint'
import SearchSettingsDropdown from '@/components/SearchSettingsDropdown'

// TODO: [SIGN-269] Favorites: Maybe don't reload all resolvable images on remove

export default {
  mixins: [searchMixin],
  components: {
    Hint,
    ImageResult,
    SearchSettingsDropdown
  },
  data () {
    return {
      favoritesOnly: true,
      searchResults: [],
      query: ''
    }
  },
  computed: {
    ...mapState('preferences', { searchPreferences: 'search' }),
    wrappedSearchResults () {
      return this.searchResults.map(
        (s) =>
          new ResolvableImage({
            $store: this.$store,
            $resolver: this.$resolver,
            reference: new SignImageReference({ objectId: s._id, imageType: this.searchPreferences.imageType }),
            instance: s,
            variant: 'default'
          })
      )
    },
    actualColumns () {
      const c = this.searchPreferences?.columns ?? 4
      if (this.$screen.widescreen) {
        return Math.min(c, 10)
      } else if (this.$screen.desktop) {
        return Math.min(c, 7)
      } else if (this.$screen.tablet) {
        return Math.min(c, 5)
      } else if (this.$screen.mobile) {
        return Math.min(c, 2)
      }
      return c
    }
  },
  mounted () {
    this.throttledSearch()
  },
  methods: {
    onFavoriteRemoved (image) {
      const i = this.searchResults.findIndex((sign) => sign._id === image.instance._id)
      this.searchResults.splice(i, 1)
    },
    async onSearchResultClicked ($event, image) {
      if (image.instance == null) {
        await image.resolve()
      }
      this.$buefy.modal.open({
        parent: this,
        props: {
          sign: image.instance
        },
        width: '100%',
        canCancel: ['escape', 'outside'],
        component: SignDetails,
        customClass: 'sign-details-modal',
        fullScreen: true,
        trapFocus: true
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.favorites-view {
  position: relative;
}
</style>
