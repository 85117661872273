<template>
  <section class="hero is-primary is-fullheight is-bold">
    <div class="hero-body">
      <div class="container">
        <div class="column is-6 is-offset-3">
          <div class="login-wrapper">
            <!-- BRANDING -->
            <div class="logo-wrapper">
              <img class="logo" src="@/assets/img/sign-digital-logo_white.svg" />
            </div>

            <team-invitation-card v-if="invitation">
              <p class="mt-4">
                <strong>Hast du bereits das SIGNdigital Konto {{ invitation.email }}?</strong><br />
                Dann gib bitte unten deine E-Mail Adresse und dein Passwort ein, um die Einladung anzunehmen.
              </p>
              <p class="mt-2">Falls du noch kein Konto hast, kannst du unten auf „Neues Konto erstellen” klicken.</p>
            </team-invitation-card>

            <b-message v-if="$route.query && $route.query.message === 'token-not-allowed'" type="is-warning">
              <p>
                Deine Sitzung ist abgelaufen oder die maximale Anzahl an Anmeldungen mit deinem Konto wurde erreicht.
                Bitte melde dich erneut an.
              </p>
            </b-message>
            <b-message v-if="$route.query && $route.query.message === 'session-expired'" type="is-warning">
              <p>Deine Sitzung ist abgelaufen. Bitte melde dich erneut an.</p>
            </b-message>

            <!-- LOGIN -->
            <div class="box box-shadow">
              <h1 class="title">Anmeldung</h1>

              <validation-observer v-slot="{ handleSubmit, invalid }" ref="form">
                <form @submit.prevent="handleSubmit(onLoginFormSubmit)">
                  <!-- E-Mail address -->

                  <validation-provider name="E-Mail Adresse" rules="required">
                    <b-field label="E-Mail Adresse">
                      <b-input
                        id="username"
                        name="username"
                        autocomplete="username"
                        type="email"
                        icon="envelope"
                        placeholder="Deine E-Mail Adresse"
                        v-model="email"
                      />
                    </b-field>
                  </validation-provider>
                  <!-- Password field -->

                  <validation-provider name="Passwort" rules="required">
                    <b-field label="Passwort">
                      <b-input
                        id="current-password"
                        name="current-password"
                        autocomplete="current-password"
                        type="password"
                        icon="key"
                        placeholder="Passwort"
                        password-reveal
                        v-model="password"
                      />
                    </b-field>
                  </validation-provider>

                  <div class="has-text-grey is-size-7">
                    <router-link :to="{ name: 'password-reset-request' }">Passwort vergessen?</router-link>
                  </div>

                  <!-- Login error message -->
                  <feathers-error vuex-store="auth" vuex-action="authenticate" />

                  <!-- Submit button -->
                  <b-button
                    type="is-primary"
                    size="is-large"
                    value="Anmelden"
                    id="submit"
                    native-type="submit"
                    :class="{ 'is-disabled': invalid }"
                    expanded
                    :loading="isAuthenticatePending"
                    >Anmelden</b-button
                  >

                  <b-button type="is-secondary" outlined expanded router tag="router-link" to="/anmeldung"
                    >Neues Konto erstellen</b-button
                  >

                  <div class="has-text-centered pt-4">
                    <a class="about-link" :href="landingPageUrl" target="_blank">Was ist SIGNdigital?</a>
                  </div>
                </form>
              </validation-observer>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import TeamInvitationCard from '@/components/TeamInvitationCard.vue'
const { mapState } = require('vuex')
export default {
  components: {
    TeamInvitationCard
  },
  data () {
    return {
      email: null,
      password: null,
      landingPageUrl: process.env.VUE_APP_LANDING_HOST
    }
  },
  computed: {
    ...mapState('auth', ['isAuthenticatePending', 'errorOnAuthenticate']),
    ...mapState('teamInvitation', ['invitation']),
    canSubmit () {
      return this.email && this.email.length && this.password && this.password.length
    },
    shownErrorMessage () {
      if (!this.errorOnAuthenticate) {
        return null
      }
      const error = this.errorOnAuthenticate
      if (error.code && error.code === '401' && error.message === 'No accessToken found in storage') {
        return null
      }
      if (error.message) {
        return error.message
      }
      return error.toSring()
    }
  },
  mounted () {
    window.localStorage.removeItem('feathers-jwt')
  },
  methods: {
    async onLoginFormSubmit () {
      if (!this.canSubmit) {
        return
      }
      await this.$store.dispatch('auth/authenticate', {
        strategy: 'local',
        email: this.email,
        password: this.password
      })

      // Maybe accept invitation
      if (this.invitation) {
        await this.$store.dispatch('teamInvitation/accept')
        this.$buefy.toast.open({
          type: 'is-success',
          message: 'Team erfolgreich beigetreten!'
        })

        await this.$store.dispatch('session/fetch')
        this.$router.push({ name: 'order-completed' })
        return
      }

      await this.$store.dispatch('session/fetch')

      // This navigation will be redirected often. Don't throw unhandled promise error
      try {
        await this.$router.push({ path: 'app' })
      } catch (error) {
        console.warn(error)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/bulma-variables.scss';
.button.is-disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.logo-wrapper {
  text-align: center;
  margin-bottom: 1em;
  .logo {
    max-width: 250px;
  }
}
.hero .box {
  padding: 2em;
  p,
  .title {
    color: $dark;
  }
  form {
    .button {
      margin-top: 1em;
      margin-bottom: 0.5em;
    }
  }
}
</style>
