<template>
  <div
    class="document-card card"
    :class="{ 'is-recommended': recommended, 'is-selected': selected, 'is-small': small }"
    @click="$emit('click', $event)"
  >
    <div class="card-image">
      <span v-if="recommended" class="tag is-primary is-small">Empfohlen</span>
      <figure class="image is-5by4">
        <img :src="image" alt="Vorschau: Dokument erstellen" />
      </figure>
    </div>
    <div class="card-content" :class="{ 'p-4': !small, 'p-1': small }">
      <h1 class="title" :class="{ 'mb-2': hasDescription }">{{ title }}</h1>
      <p v-if="hasDescription && description" class="description mb-0">{{ description }}</p>
    </div>
    <div class="card-footer">
      <div class="card-footer-item" v-if="hasButton">
        <b-button class="add" expanded icon-left="plus" type="is-ghost" size="is-small" @click="$emit('click', $event)"
          >Neu erstellen</b-button
        >
      </div>
      <!-- <div class="card-footer-item">
        <b-button class="info" expanded icon-left="book-open" type="is-secondary" outlined size="is-small" @click="$emit('info', $event)">Infos</b-button>
      </div> -->
    </div>
  </div>
</template>

<script>
import createOwnSignImage from '@/assets/img/documents/create-own-sign.svg'
import createTargetVocabularyImage from '@/assets/img/documents/create-target-vocabulary.svg'
import createGridImage from '@/assets/img/documents/create-grid.svg'
export default {
  props: {
    type: String,
    recommended: {
      type: Boolean,
      default: false
    },
    selected: {
      type: Boolean,
      default: false
    },
    hasButton: {
      type: Boolean,
      default: true
    },
    hasDescription: {
      type: Boolean,
      default: true
    },
    small: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    image () {
      switch (this.type) {
        case 'own-sign':
          return createOwnSignImage
        case 'target-vocabulary':
          return createTargetVocabularyImage
        case 'grid':
          return createGridImage
      }
      return null
    },
    title () {
      switch (this.type) {
        case 'own-sign':
          return 'Eigene Gebärden'
        case 'target-vocabulary':
          return 'Zielwortschatz'
        case 'grid':
          return 'Raster'
      }
      return null
    },
    description () {
      switch (this.type) {
        case 'own-sign':
          return 'Gegenüberstellung von eigenen Gebärden und Standard-Gebärden'
        case 'target-vocabulary':
          return 'Fortschritt-Dokumentation bei der Erlernung eines Wortschatzes'
        case 'grid':
          return 'Ein anpassbares Raster zum Ausdrucken von vielen Gebärden auf einmal'
      }
      return null
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/bulma-variables.scss';
.document-card {
  display: inline-block;
  transition:
    transform ease-out 0.25s,
    box-shadow ease-out 0.25s;
  display: flex;
  flex-direction: column;

  .tag {
    position: absolute;
    top: 0.5em;
    left: 0.5em;
    z-index: 10;
  }

  border: 3px solid $grey-lighter;
  &.is-recommended,
  &.is-selected {
    border: 3px solid $primary;
  }
  .card-image {
    transition: background-color ease-out 0.25s;
    background-color: $grey-lighter;
    perspective: 800px;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;

    &::v-deep:first-child img {
      border-radius: 0;
    }

    img {
      transform-origin: center bottom;
      transition: transform ease-out 0.25s;
      user-drag: none;
      -webkit-user-drag: none;
      user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;
    }
  }

  &:hover {
    transform: scale(1.02);
    box-shadow:
      0 0em 1.5em -0.05em rgba(10, 10, 10, 0.1),
      0 0px 0 0px rgba(10, 10, 10, 0.02);
    cursor: pointer;

    .card-image {
      background-color: $primary;
      img {
        transform: scale(1.02) rotate3d(1, 0, 0, -6deg);
      }
    }

    .button.add {
      background-color: $primary;
      color: white;
      &:hover {
        text-decoration: none;
      }
    }
  }

  .button.info {
    border: none;
  }

  .card-content {
    font-size: 0.8rem;
    position: relative;
    flex-grow: 1;

    h1.title {
      font-size: 1rem;
    }

    p.description {
      margin-bottom: 1em;
    }
  }
}
</style>
