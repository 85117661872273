<template>
  <div class="payment-method-select-form">
    <validation-observer slim v-slot="{ invalid }">
      <!-- Payment method select -->
      <validation-provider name="Zahlungsmethode" :rules="required ? 'required' : null" v-slot="{ errors, valid }" slim>
        <b-field
          label="Deine Zahlungsmethoden"
          :type="{ 'is-danger': errors.length > 0, 'is-primary': valid }"
          :message="errors[0]"
        >
        </b-field>
        <b-field v-for="paymentMethod in paymentMethods" :key="paymentMethod.id">
          <b-radio
            :value="selectedId"
            :native-value="paymentMethod.id"
            @input="onSelectedPaymentMethodChanged"
            expanded
          >
            <payment-method :payment-method="paymentMethod" @remove="onRemovePaymentMethod(paymentMethod)" />
          </b-radio>

          <!-- Submit button -->
          <b-button
            v-if="selectedId === paymentMethod.id && hasSubmitButton"
            class="mt-1 mb-6"
            type="is-primary"
            :disabled="invalid"
            expanded
            @click="onSelectButtonClicked"
          >
            Mit dieser Zahlungsmethode fortfahren
          </b-button>
        </b-field>
      </validation-provider>
    </validation-observer>
  </div>
</template>

<script>
import PaymentMethod from '@/components/registration/steps/summary/PaymentMethod'
import { ValidationObserver, ValidationProvider } from 'vee-validate/dist/vee-validate.full'
export default {
  components: {
    PaymentMethod,
    ValidationObserver,
    ValidationProvider
  },
  props: {
    paymentMethods: Array,

    selectedPaymentMethod: {
      type: Object,
      default: null
    },

    hasSubmitButton: {
      type: Boolean,
      default: true
    },
    required: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      selected: null
    }
  },
  watch: {
    selectedPaymentMethod () {
      this.selected = this.selectedPaymentMethod
    }
  },
  mounted () {
    this.selected = this.selectedPaymentMethod
  },
  computed: {
    selectedId () {
      return this.selected?.id
    }
  },
  methods: {
    onSelectedPaymentMethodChanged (paymentMethodId) {
      const paymentMethod = this.paymentMethods.find((pm) => pm.id === paymentMethodId)
      this.selected = paymentMethod
      this.$emit('update:selected-payment-method', paymentMethod)
    },
    onSelectButtonClicked () {
      this.$emit('submit', this.selected)
    },
    onRemovePaymentMethod (paymentMethod) {
      this.$emit('remove', paymentMethod)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/bulma-variables.scss';
label.b-radio {
  width: 100%;
  &::v-deep .control-label {
    width: 100%;
  }
}
</style>
