<template>
  <div class="team-members">
    <div class="container p-5">
      <h1 class="title">Mitglieder</h1>
      <div class="card" v-if="team">
        <div class="card-header">
          <div class="card-header-title">{{ team.name }}</div>
          <div class="buttons are-small mr-4">
            <b-button size="is-small" @click="reloadTeam" :loading="isGetPending" icon-left="sync"></b-button>
            <b-button tag="router-link" size="is-small" :to="{ name: 'team-invite' }">Mitglieder einladen</b-button>
          </div>
        </div>
        <div class="card-content">
          <div class="content">
            <b-table :data="team.members">
              <b-table-column label="Rolle" field="role" v-slot="{ row }">{{ row.role | memberRole }}</b-table-column>
              <b-table-column label="Benutzer" field="user" v-slot="{ row }"
                >{{ row.user.name }} ({{ row.user.email }})</b-table-column
              >
              <b-table-column label="Aktionen" v-slot="{ row }">
                <div class="buttons">
                  <b-button v-if="canRemoveMember(row)" @click="onRemoveMember(row)" size="is-small" type="is-danger"
                    >Entfernen</b-button
                  >
                  <b-button v-if="canChangeRole(row)" @click="onChangeMemberRole(row)" size="is-small"
                    >Rolle ändern</b-button
                  >
                </div>
              </b-table-column>
              <template #empty>
                <div class="content has-text-centered">
                  <p>Noch keine Team-Mitglieder</p>
                  <b-button
                    tag="router-link"
                    type="is-primary"
                    icon-left="user-plus"
                    size="is-small"
                    :to="{ name: 'team-invite' }"
                    >Jetzt Teammitglieder einladen</b-button
                  >
                </div>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import TeamRoleModal from '@/components/team/TeamRoleModal.vue'

export default {
  computed: {
    ...mapState('session', ['team', 'teamRole']),
    ...mapState('teams', ['isGetPending']),
    canRemoveMember () {
      return (member) => {
        if (member.role === 'owner') {
          return false
        } else {
          return true
        }
      }
    },
    canChangeRole () {
      return (member) => {
        const roles = ['owner', 'manager', 'member']
        const myIndex = roles.indexOf(this.teamRole)
        const memberIndex = roles.indexOf(member.role)
        if (myIndex <= memberIndex) {
          return true
        }
      }
    }
  },
  filters: {
    memberRole (value) {
      switch (value) {
        case 'member':
          return 'Mitglied'
        case 'manager':
          return 'Verwalter'
        case 'owner':
          return 'Besitzer'
        default:
          return value
      }
    }
  },
  created () {},
  methods: {
    onRemoveMember (member) {
      this.$buefy.dialog.confirm({
        type: 'is-danger',
        title: 'Teammitglied entfernen',
        message: `Soll das Teammitglied ${member.user.name} (${member.user.email}) wirklich aus dem Team entfernt werden? Die entfernte Person verliert damit Zugang zur SIGNdigital-Lizenz deines Teams.`,
        cancelText: 'Abbrechen',
        onConfirm: async () => {
          try {
            await this.$store.dispatch('users/patch', [member.user._id, { team: null }])
          } catch (error) {
            console.error('Error on removing team member', error)
          }
          await this.reloadTeam()
          this.$buefy.toast.open({
            type: 'is-success',
            message: 'Teammitglied erfolgreich entfernt'
          })
        }
      })
    },
    async reloadTeam () {
      await this.$store.dispatch('teams/get', [this.team._id])
      await this.$store.dispatch('teamSettings/fetch')
    },
    onChangeMemberRole (member) {
      this.$buefy.modal.open({
        component: TeamRoleModal,
        props: {
          user: member.user,
          role: member.role
        },
        events: {
          submit: async (newRole) => {
            member.role = newRole
            await this.team.save()
            const updatedCurrentUser = member.user?._id === this.$store.getters['auth/user']._id
            if (updatedCurrentUser) {
              this.$router.go(this.$router.currentRoute)
            }
          }
        }
      })
    }
  }
}
</script>
