<template>
  <div class="selection-actions p-3">
    <div class="level mb-2">
      <div class="level-left">
        <!-- Current selection -->
        <b-tag type="is-light" :closable="selectedObjectIds.length > 0" @close="onClear"
          >{{ selectedObjectIds.length }} Element{{ selectedObjectIds.length !== 1 ? 'e' : '' }} ausgewählt</b-tag
        >
      </div>
      <div class="level-right">
        <!-- Remove selected elements -->
        <b-button type="is-danger" size="is-small" :disabled="!hasSelection" icon-left="trash" @click="onDelete"
          >Entfernen</b-button
        >
      </div>
    </div>
    <div class="level mb-0">
      <div class="level-left">
        <!-- Element order -->
        <b-field label="Reihenfolge ändern">
          <div class="buttons">
            <b-button :disabled="!hasSelection" size="is-small" icon-left="bring-front" @click="onMoveToFront"
              >Nach vorne</b-button
            >
            <b-button :disabled="!hasSelection" size="is-small" icon-left="send-back" @click="onMoveToBack"
              >Nach hinten</b-button
            >
          </div>
        </b-field>
      </div>
      <div class="level-right" v-if="hasTextSelection">
        <!-- Text color -->
        <b-field label="Textfarbe" class="color-picker">
          <b-radio-button v-model="textColor" native-value="#000000" type="is-primary" size="is-small" class="black">
            <b-icon icon="square"></b-icon>
            <span>Schwarz</span>
          </b-radio-button>

          <b-radio-button v-model="textColor" native-value="#FFFFFF" type="is-primary" size="is-small" class="white">
            <b-icon icon="square"></b-icon>
            <span>Weiß</span>
          </b-radio-button>
        </b-field>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  props: {
    canvas: Object // Reference to the canvas object
  },
  computed: {
    ...mapState('signEditor', ['selectedObjectIds']),
    hasSelection () {
      return this.selectedObjectIds.length > 0
    },
    selectedTextObjects () {
      return this.$store.getters['signEditor/selectedObjects'].filter((o) => o.objectType === 'text')
    },
    hasTextSelection () {
      return this.selectedTextObjects.length > 0
    },
    textColor: {
      get () {
        if (!this.selectedTextObjects.length) {
          return
        }
        const [first, ...others] = this.selectedTextObjects
        if (others == null || others.every((o) => o.color === first.color)) {
          return first.color
        } else {
          return null
        }
      },
      set (newTextColor) {
        const objects = this.selectedTextObjects
        objects.forEach((o) => {
          this.$store.commit('signEditor/updateObjectProperty', { id: o.id, key: 'color', value: newTextColor })
          this.canvas.updateRenderedObject(o)
        })
      }
    }
  },
  methods: {
    onClear () {
      this.$store.commit('signEditor/clearSelection')
    },
    onDelete () {
      this.$store.commit('signEditor/removeObjectsByIds', [...this.selectedObjectIds])
    },
    onMoveToFront () {
      this.$store.commit('signEditor/moveObjectsToFrontByIds', this.selectedObjectIds)
    },
    onMoveToBack () {
      this.$store.commit('signEditor/moveObjectsToBackByIds', this.selectedObjectIds)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/bulma-variables.scss';
.selection-actions {
  width: 100%;
  border-top: 1px solid $grey-light;
  background: white;
  // @include from($tablet) {
  //   height: 150px;
  // }
}
::v-deep .label {
  font-size: 0.8rem;
}
.color-picker {
  .black .icon {
    color: #000;
  }
  .white .icon {
    color: #fff;
  }
}
</style>
