<template>
  <div class="page" :style="style">
    <actions-dropdown
      icon="ellipsis-h"
      size="is-tiny"
      position="is-bottom-left"
      :actions="pageActions"
      @action="onPageAction"
    />
    <div class="printable-area" :style="printableAreaStyle">
      <own-sign-page-content v-if="documentType === 'own-sign'" :page="page" />
      <target-vocabulary-page-content v-else-if="documentType === 'target-vocabulary'" :page="page" />
      <grid-page-content v-if="documentType === 'grid'" :page="page" />
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import OwnSignPageContent from '@/components/document/OwnSignPageContent'
import TargetVocabularyPageContent from './TargetVocabularyPageContent.vue'
import GridPageContent from './GridPageContent.vue'
import ActionsDropdown from '@/components/ActionsDropdown'
export default {
  components: {
    OwnSignPageContent,
    TargetVocabularyPageContent,
    GridPageContent,
    ActionsDropdown
  },
  props: {
    page: Object
  },
  data () {
    return {
      pageActions: [{ icon: 'trash', text: 'Seite löschen', value: 'delete-page' }]
    }
  },
  computed: {
    ...mapState('documentEditor', {
      documentType: 'type',
      documentProperties: 'properties'
    }),
    style () {
      return {
        width: this.page.dimensions.width + 'px',
        height: this.page.dimensions.height + 'px'
      }
    },
    printableAreaStyle () {
      return {
        top: this.page.printableArea.y + 'px',
        left: this.page.printableArea.x + 'px',
        width: this.page.printableArea.width + 'px',
        height: this.page.printableArea.height + 'px'
      }
    }
  },
  methods: {
    onDocumentUpdated (document) {
      this.$emit('update:document', document)
    },
    onPageAction (action) {
      if (action === 'delete-page') {
        this.$store.dispatch('documentEditor/removePage', this.page)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/bulma-variables.scss';
.page {
  position: relative;
  background: white;

  .actions-dropdown {
    position: absolute;
    top: 0.25rem;
    right: 0.25rem;
    z-index: 10;
    opacity: 0;
    transition: opacity linear 0.1s;
  }

  &:hover .actions-dropdown {
    opacity: 1;
  }

  .printable-area {
    position: absolute;
    background: white;
  }
}
</style>
