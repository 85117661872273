export default class FileNodeTreeItem {
  constructor (fileNode) {
    this.fileNode = fileNode
    this.children = []

    this.isFindChildrenLoading = false
    this.didFetchChildren = false
    this.didFetchContent = false
  }

  contains (itemId) {
    return this.children.some((c) => c.contains(itemId))
  }

  get _id () {
    return this.fileNode?._id
  }

  get sortedChildren () {
    console.log(`${this._id}::sortedChildren`)
    return [...this.childDirectoryItems, ...this.childFileItems]
  }

  get childDirectoryItems () {
    return this.children
      .filter((c) => c.fileNode?.nodeType === 'directory')
      .sort((a, b) => a.fileNode.name.localeCompare(b.fileNode.name))
  }

  get childFileItems () {
    return this.children
      .filter((c) => c.fileNode?.nodeType === 'file')
      .sort((a, b) => a.fileNode.name.localeCompare(b.fileNode.name))
  }

  get childrenPath () {
    if (this.fileNode.path != null) {
      return `${this.fileNode.path}${this._id},`
    } else {
      return `,${this._id},`
    }
  }

  get parentId () {
    if (this.fileNode.path == null) {
      return null
    }

    const pathItems = this.fileNode.path.split(',')
    return pathItems[pathItems.length - 2]
  }

  get rootId () {
    return this.fileNode.path?.split(',').slice(1, 2)[0] ?? this._id
  }

  get isInTeamDirectory () {
    return this.rootId?.startsWith('team_')
  }

  getRootId () {
    return this.rootId
  }

  getChild (childId) {
    return this.children.find((child) => child._id === childId)
  }

  getIdsInBranch (includeSelf) {
    const childIds = this.children?.reduce((acc, child) => [...acc, ...child.getIdsInBranch(true)], []) ?? []
    if (includeSelf) {
      return [this._id, ...childIds]
    } else {
      return childIds
    }
  }

  getChildrenInBranch (includeSelf) {
    const children = this.children?.reduce((acc, child) => [...acc, ...child.getChildrenInBranch(true)], []) ?? []
    if (includeSelf) {
      return [this, ...children]
    } else {
      return children
    }
  }

  addChild (childNode) {
    if (this.getChild(childNode._id) != null) {
      throw new Error('Already has child node with this id')
    }
    this.children.push(childNode)
    this.children = this.children.sort((a, b) => a.fileNode.name.localeCompare(b.fileNode.name))
  }

  removeChild (childNode) {
    const i = this.children.findIndex((c) => c._id === childNode._id)
    const idsInBranch = this.children[i].getIdsInBranch(true)
    this.children.splice(i, 1)
    return idsInBranch
  }

  hasParent () {
    return this.fileNode.path != null
  }

  expandTree (fileNodes) {
    const sortedByLevel = fileNodes.sort((a, b) => a.path.split(',').length - b.path.split(',').length)
    for (const fileNode of sortedByLevel) {
      const parentItem = this
      parentItem.addChild(new FileNodeTreeItem(fileNode))
    }
  }

  // getParents() {
  //   return this.rootItem?.getItemsInPath(this.fileNode.path) ?? []
  // }
}
