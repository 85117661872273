<template>
  <div class="sign-box-overlay-controls">
    <document-image
      v-for="(fixedImage, key) in visibleFixedImages"
      :frame="scaledElementFrames[key]"
      :allowedSignImageTypes="fixedImage.allowedSignImageTypes"
      :allowedContentTypes="fixedImage.allowedContentTypes"
      :allowedCustomSignTypes="fixedImage.allowedCustomSignTypes"
      :image-reference="fixedImageReferencesByLayoutKey[key]"
      :owner="owner"
      :ownerType="ownerType"
      :key="key"
      image-hidden
      @update:image-reference="updatedFixedImage({ imageReference: $event, layoutKey: key })"
    ></document-image>
  </div>
</template>

<script>
import DocumentImage from '@/components/document/DocumentImage.vue'
import { SIGN_BOX_FIXED_ELEMENTS } from '@/lib/constants/sign-editor-constants.js'
import { pick } from 'lodash'
import { mapGetters, mapState } from 'vuex'
// import { pick } from 'lodash'

export default {
  components: {
    DocumentImage
  },
  data () {
    return {
      overlayFrames: null
      // imageReferences: {}
    }
  },
  computed: {
    ...mapState('signEditor', [
      'signType',
      'ownerType',
      'owner',
      'canvasScale',
      'signBoxLayout',
      'fixedObjectIdsByLayoutKey',
      'objectsKeyedById'
    ]),
    ...mapGetters('signEditor', ['contentSize', 'visibleFixedObjectDefinitions']),
    visibleFixedImages () {
      const allVisible = this.visibleFixedObjectDefinitions
      const imageLayoutKeys = Object.entries(allVisible)
        .filter(([_, def]) => def.objectType === 'image')
        .map(([layoutKey, _]) => layoutKey)
      return pick(allVisible, imageLayoutKeys)
    },
    fixedImageReferencesByLayoutKey () {
      const imageReferences = {}
      for (const layoutKey of Object.keys(this.visibleFixedImages)) {
        const id = this.fixedObjectIdsByLayoutKey[layoutKey]
        const o = this.objectsKeyedById[id]
        imageReferences[layoutKey] = o?.imageReference ?? null
      }

      return imageReferences
    },
    scaledElementFrames () {
      if (this.signType === 'signBox') {
        return Object.keys(this.visibleFixedImages).reduce((acc, key) => {
          acc[key] = SIGN_BOX_FIXED_ELEMENTS[key].frame.multipliedByValue(this.canvasScale)
          return acc
        }, {})
      } else {
        return {}
      }
    }
  },
  methods: {
    async updatedFixedImage ({ imageReference, layoutKey }) {
      await this.$store.dispatch('signEditor/updateFixedImage', {
        layoutKey,
        imageReference,
        resolver: this.$resolver
      })
    }
  }
}
</script>

<style scoped>
.sign-box-overlay-controls {
  pointer-events: all;
}
</style>
