import feathersClient from '@/feathers-rest-client'

export default {
  namespaced: true,
  state: () => {
    return {
      isFetchPending: false,
      fetchError: null,
      isCreatePending: false,
      createError: null,

      customer: null,
      subscription: null,
      stripeSubscription: null,
      defaultPaymentMethod: null,
      stripeClientSecret: null,
      idempotencyKey: null,

      selectedLicense: null,
      selectedPaymentMethod: null
    }
  },
  mutations: {
    init (state, data) {
      state.customer = data.customer
      state.subscription = data.subscription
      state.stripeSubscription = data.subscription
      state.defaultPaymentMethod = data.defaultPaymentMethod
      state.stripeClientSecret = data.stripeClientSecret
      state.idempotencyKey = data.idempotencyKey
    }
  },
  actions: {
    async fetch ({ commit }) {
      const data = await feathersClient.service('checkout').find({})
      commit('init', data)
    }
  }
}
