<template>
  <div class="verification-view">
    <div class="hero is-fullheight">
      <div class="hero-body">
        <div class="container">
          <div class="column is-6 is-offset-3">
            <!-- Reset request -->
            <div class="logo-wrapper">
              <img class="logo" src="@/assets/img/sign-digital-logo_white.svg" />
            </div>
            <div class="box box-shadow">
              <!-- E-Mail signup verification -->
              <signup-email-verification v-if="action === 'signupEmail'" />

              <!-- Passwort reset -->
              <password-reset-verification v-else-if="action === 'passwordReset' || action === 'initialPassword'" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PasswordResetVerification from '@/components/PasswordResetVerification.vue'
import SignupEmailVerification from '@/components/SignupEmailVerification.vue'

export default {
  components: {
    PasswordResetVerification,
    SignupEmailVerification
  },

  computed: {
    action () {
      if (!this.$route.params) {
        return null
      }
      return this.$route.params.action
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/bulma-variables.scss';
.hero {
  background-color: $primary;
}
.logo-wrapper {
  text-align: center;
  margin-bottom: 1em;
  .logo {
    max-width: 250px;
  }
}

.title.loading {
  padding: 1em;
  padding-bottom: 100px;
}
.hero .box {
  .title {
    color: $dark;
  }
}
</style>
