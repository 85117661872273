<template>
  <div class="login-button-top-right">
    <div v-if="isLoggedIn && !hasActiveSubscription">
      <b-navbar-item tag="div">
        <b-button type="is-primary">
          <router-link tag="span" :to="{ name: 'user-subscription' }" :size="buttonSize">Lizenz erwerben</router-link>
        </b-button>
      </b-navbar-item>
    </div>

    <div v-else-if="!isLoggedIn">
      <b-navbar-item tag="div">
        <b-button type="is-secondary" outlined router tag="router-link" to="/anmeldung" :size="buttonSize"
          >Konto erstellen</b-button
        >
      </b-navbar-item>

      <b-navbar-item tag="div">
        <b-button type="is-primary" tag="a" href="/login" :size="buttonSize">Anmelden</b-button>
      </b-navbar-item>
    </div>
  </div>
</template>

<script>
export default {
  mixins: [],
  components: {},
  props: {},
  data () {
    return {}
  },
  computed: {
    hasActiveSubscription () {
      return this.$store.getters['auth/user']?.hasActiveSubscription
    },
    isLoggedIn () {
      return !!this.$store.getters['auth/user']
    },
    buttonSize () {
      return !this.$screen.tablet ? 'is-small' : 'is-default'
    }
  },
  watch: {},
  mounted () {},
  methods: {}
}
</script>

<style lang="scss" scoped>
.login-button-top-right {
  display: flex;
  flex-wrap: wrap;
  > div {
    display: flex;
    flex-wrap: wrap;
  }
}
// @import '@/assets/scss/bulma-variables.scss';
</style>
