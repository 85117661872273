<!-- TODO: Maybe show loader when image load takes longer than x seconds -->

<template>
  <div class="result-wrapper p-2" :class="wrapperClasses" @click="$emit('click')">
    <!-- Image component -->
    <div class="image-wrapper">
      <image-component :image="image" />
    </div>

    <!-- Caption of the image -->
    <div class="caption" :class="{ 'is-justify-content-flex-end': !showNames, 'has-favorite-button': showFavorite }">
      <!-- SIGN meta -->
      <template v-if="isSignImage && image.instance">
        <label v-if="showNames" :class="[size]">{{ image.instance.name }}</label>

        <div class="buttons">
          <b-button
            class="add-button"
            v-if="showAddTo && isSignImage"
            @click.stop="onAddToButtonClicked"
            :size="size"
            icon-left="plus"
            type="is-light"
          ></b-button>

          <!-- Favorite button -->
          <favorite-button
            v-if="showFavorite"
            :sign="image.instance"
            @add="$emit('favorite-added', image)"
            @remove="$emit('favorite-removed', image)"
            :size="size"
          />
        </div>
      </template>
      <!-- Custom sign meta -->
      <template v-else-if="isCustomSignImage">
        <label :class="size">{{ image.instance.name }}</label>
      </template>

      <!-- Image meta -->
      <template v-else-if="isImage">
        <b-button
          class="delete-button"
          icon-left="trash"
          size="is-small"
          type="is-danger"
          outlined
          @click.stop="$emit('delete')"
        />
      </template>

      <!-- DGS tooltip -->
      <dgs-tooltip :sign="isSignImage ? image.instance : null" :tooltipPosition="tooltipPosition" short />
    </div>
  </div>
</template>

<script>
import FavoriteButton from '@custom-media/signdigital-web-shared/src/components/FavoriteButton'
import DgsTooltip from '@custom-media/signdigital-web-shared/src/components/DgsTooltip'
import ImageComponent from '@custom-media/signdigital-web-shared/src/components/ImageComponent.vue'
import AddToListModal from '@/components/AddToListModal'
import {
  SignImageReference,
  CustomSignImageReference,
  ImageReference
} from '@custom-media/signdigital-lib/src/image-reference'

export default {
  components: {
    FavoriteButton,
    ImageComponent,
    DgsTooltip
  },
  props: {
    image: {
      type: Object
    },
    showNames: {
      type: Boolean,
      default: false
    },
    isFavorite: {
      type: Boolean,
      default: false
    },
    showFavorite: {
      type: Boolean,
      default: false
    },
    showAddTo: {
      type: Boolean,
      default: false
    },
    selected: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'is-default'
    },
    isFirstOfRow: {
      type: Boolean,
      default: false
    },
    isLastOfRow: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isLoading: true,
      resolver: null
    }
  },
  computed: {
    wrapperClasses () {
      return {
        'is-selected': this.selected,
        [this.size]: true
      }
    },
    isSignImage () {
      return this.image?.reference instanceof SignImageReference
    },
    isCustomSignImage () {
      return this.image?.reference instanceof CustomSignImageReference
    },
    isImage () {
      return this.image?.reference instanceof ImageReference
    },
    tooltipPosition () {
      return this.isFirstOfRow ? 'is-right' : this.isLastOfRow ? 'is-left' : 'is-top'
    }
  },
  methods: {
    onAddToButtonClicked () {
      this.$buefy.modal.open({
        component: AddToListModal,
        props: {
          signId: this.image.reference.objectId
        },
        parent: this,
        width: 450
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/bulma-variables.scss';

.result-wrapper {
  position: relative;
  border-radius: 3px;
  padding: 0.5em 0.1em;

  .delete-button {
    opacity: 0;
    transition: opacity ease-in-out 0.15s;
  }
  &:hover .delete-button {
    opacity: 1;
  }

  .add-button {
    display: none;
    &.is-tiny {
      font-size: 0.6rem;
      padding-left: 0.6em;
      padding-right: 0.6em;
      padding-top: 0;
      padding-bottom: 0;
      height: 1.5em;
    }
  }
  &:hover .add-button {
    display: block;
  }
  .buttons {
    flex-wrap: nowrap;
    .button {
      margin-right: 0.25rem;
    }
  }

  &.panel-block {
    display: flex;
    flex-direction: row;
    .image-wrapper {
      width: 80px;
    }
    figure {
      margin: 0;
    }
  }
  &.grid-element {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 0;
    .image-wrapper {
      width: 100px;
    }
    figure {
      margin: 0;
    }
    .caption {
      padding-top: 0.5em;
      justify-content: center;
      label {
        font-size: 0.7em;
      }
    }
  }

  .dgs-tooltip {
    position: absolute;
    top: 0.5em;
    right: 0.5em;
    opacity: 0.6;
    &:hover {
      opacity: 1;
    }
  }

  .caption {
    display: flex;
    align-items: center;
    padding-top: 0.4em;
    padding-left: 0.6em;
    padding-right: 0.6em;

    .b-tooltip {
      flex-grow: 1;
    }

    label {
      display: block;
      text-overflow: ellipsis;
      overflow: hidden;
      flex-grow: 1;

      font-size: 1rem;

      &.is-small {
        font-size: 0.8rem;
      }
      &.is-tiny {
        font-size: 0.5rem;
      }
    }

    .delete-button {
      position: absolute;
      top: 0.5em;
      right: 0.5em;
      color: $danger;
      border: 0;
    }
  }

  &.is-small .caption {
    padding-left: 0.4em;
    padding-right: 0.4em;
    padding-top: 0.2em;
  }

  &.is-tiny .caption {
    padding-left: 0.2em;
    padding-right: 0.2em;
    padding-top: 0.1em;
  }

  .actions {
    width: 100%;
    button {
      margin-right: 0.4em;
    }
  }

  &:hover {
    background: $grey-lighter;
    cursor: pointer;
  }
  &.is-selected {
    background: $grey-lightest;
  }
}
</style>
