<template>
  <div class="target-vocabulary-page-content">
    <document-watermark
      :frame="watermarkFrame"
      :image="documentProperties.logo"
      @update:image="onDocumentLogoChanged"
      editable
    />
    <document-text-field
      :frame="titleFrame"
      placeholder="Überschrift"
      :font-style="titleStyle"
      :value="documentProperties.title"
      @update:value="onDocumentTitleChanged"
    />
    <div class="elements-wrapper" :style="elementsWrapperFrameStyle">
      <draggable :value="page.elementIds" @input="onDraggableInput" group="own-sign">
        <transition-group tag="div" class="elements-transition-group">
          <!-- ELEMENTS -->
          <target-vocabulary-element
            v-for="elementId in page.elementIds"
            :key="elementId"
            :element-id="elementId"
            :size="elementSize"
            @delete="onDeleteElement"
          />
          <!-- ADD BUTTON -->
          <div
            key="add-button"
            v-if="page.elementIds.length < 10"
            class="add-button-wrapper"
            :style="addButtonWrapperStyle"
          >
            <b-button type="is-primary" outlined @click="onAddElementButtonClicked()">Element hinzufügen</b-button>
          </div>
        </transition-group>
      </draggable>
    </div>

    <document-text-field
      v-if="documentProperties.footerText || $authorization.hasMultiplicatorPermission"
      placeholder="Optionaler Vermerk für Referenten"
      :frame="footerTextFrame"
      :font-style="footerTextStyle"
      :value="documentProperties.footerText"
      @update:value="onFooterTextChanged"
      :editable="$authorization.hasMultiplicatorPermission"
      class="height-auto"
    />
    <page-copyright-note v-if="documentProperties.logo" :frame="copyrightNoteFrame" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import TargetVocabularyElement from '@/components/document/TargetVocabularyElement'

import DocumentWatermark from '@/components/document/DocumentWatermark'
import DocumentTextField from '@/components/document/DocumentTextField'
import PageCopyrightNote from '@/components/document/PageCopyrightNote'

import { FontStyle } from '@custom-media/signdigital-lib/src/documents/font-style'
import draggable from 'vuedraggable'
import { frameStyleMixin } from '@/mixins/document-element-mixin'
export default {
  components: {
    draggable,
    TargetVocabularyElement,
    DocumentWatermark,
    DocumentTextField,
    PageCopyrightNote
  },
  props: {
    page: Object
  },
  data () {
    return {
      titleStyle: new FontStyle(30, '700', 'left'),
      footerTextStyle: new FontStyle(10, '400', 'left'),
      watermarkFrame: { x: 0, y: 0, width: 160, height: 90 },
      titleFrame: { x: this.page.printableArea.width - 424 - 16, y: 0, width: 424, height: 60 },
      ownSignColumnHeaderFrame: { x: 279, y: 78, width: 204, height: 50 },
      signColumnHeaderFrame: { x: 499, y: 78, width: 204, height: 50 },
      elementSize: { width: 359.5, height: 190 },

      copyrightNoteFrame: {
        x: this.page.printableArea.width / 2,
        y: this.page.printableArea.height,
        width: this.page.printableArea.width / 2,
        height: 20
      },
      footerTextFrame: {
        x: 0,
        y: this.page.printableArea.height + 5,
        width: this.page.printableArea.width / 2,
        height: 15
      }
    }
  },
  computed: {
    ...mapState('documentEditor', {
      documentProperties: 'properties'
    }),
    elementsWrapperFrame () {
      return { x: 0, y: 100, width: this.page.printableArea.width, height: 5 * 190 }
    },
    elementsWrapperFrameStyle () {
      return frameStyleMixin.methods.getFrameStyleFor(this.elementsWrapperFrame)
    },
    addButtonWrapperStyle () {
      return frameStyleMixin.methods.getSizeStyleFor(this.elementSize)
    }
  },
  methods: {
    getElementFrame (index) {
      const HEIGHT = 306
      const OFFSET_TOP = 136
      return {
        x: 0,
        y: index * HEIGHT + OFFSET_TOP,
        width: this.page.printableArea.width,
        height: HEIGHT
      }
    },
    onDocumentTitleChanged (newDocumentTitle) {
      this.$store.commit('documentEditor/updateProperty', { key: 'title', value: newDocumentTitle })
    },
    onDocumentLogoChanged (newLogo) {
      this.$store.commit('documentEditor/updateProperty', { key: 'logo', value: newLogo })
    },
    onFooterTextChanged (newFooterText) {
      this.$store.commit('documentEditor/updateProperty', { key: 'footerText', value: newFooterText })
    },
    onAddElementButtonClicked () {
      this.$store.dispatch('documentEditor/addNewElement', this.page.id)
    },
    onDeleteElement (element) {
      this.$store.dispatch('documentEditor/removeElementById', element.id)
    },
    onDraggableInput (value) {
      // TODO: TargetVocabularyPage drag between pages
      this.$store.commit('documentEditor/patchPage', { id: this.page.id, data: { elementIds: value } })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/bulma-variables.scss';
.target-vocabulary-page-content {
  position: absolute;
  width: 100%;
  height: 100%;
  .elements-wrapper {
    .elements-transition-group {
      display: flex;
      flex-wrap: wrap;
    }

    .target-vocabulary-element {
      &.sortable-ghost {
        opacity: 0.5;
        background: rgba($primary, 0.5);
      }
    }

    .add-button-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 16px;
      .button {
        width: 100%;
        height: 100%;
        border-width: 2px;
      }
    }
  }
}
</style>
