<template>
  <div class="zoom-control">
    <b-dropdown aria-role="list">
      <b-button slot="trigger" slot-scope="{ active }" size="is-small" :active="active" @click="onZoomButtonClicked">
        <div class="zoom-button-content">
          <b-input
            placeholder="%"
            type="text"
            icon="search-plus"
            size="is-small"
            v-model="zoomModel"
            @blur="onZoomModelBlurred"
            @keydown.native="onKeydown"
            ref="zoomInput"
            expanded
          >
          </b-input>
          <b-icon class="caret-icon" :icon="active ? 'caret-up' : 'caret-down'"></b-icon>
        </div>
      </b-button>
      <b-dropdown-item aria-role="listitem" @click="onZoomToFitButtonClicked()">
        <b-icon icon="expand" />
        <span>Ansicht füllen</span>
      </b-dropdown-item>
      <b-dropdown-item separator></b-dropdown-item>
      <!-- Different zoom levels -->
      <b-dropdown-item
        v-for="zoomLevel in zoomLevels"
        :key="zoomLevel.factor"
        aria-role="listitem"
        @click="onZoomLevelSelected(zoomLevel)"
        >{{ zoomLevel.label }}</b-dropdown-item
      >
    </b-dropdown>
  </div>
</template>

<script>
const MAX_ZOOM_LEVEL = 2.0
const MIN_ZOOM_LEVEL = 0.5
export default {
  props: {
    zoom: Number
  },
  data () {
    return {
      zoomLevels: [
        { factor: 0.5, label: '50%' },
        { factor: 0.75, label: '75%' },
        { factor: 1.0, label: '100%' },
        { factor: 1.5, label: '150%' },
        { factor: 2, label: '200%' }
      ],
      zoomModel: null
    }
  },
  watch: {
    zoom (newValue) {
      this.zoomModel = this.formattedZoomLevel
    }
  },
  computed: {
    formattedZoomLevel () {
      return Math.floor(this.zoom * 100) + '%'
    }
  },
  mounted () {
    this.zoomModel = this.formattedZoomLevel
  },
  methods: {
    onZoomButtonClicked () {
      this.$refs.zoomInput.$refs.input.select()
    },
    onZoomLevelSelected (zoomLevel) {
      this.$emit('update:zoom', zoomLevel.factor)
    },
    onZoomModelBlurred () {
      const zoomLevel = Number.parseFloat(this.zoomModel.trim().replace(/[^.,0-9]/g, ''))
      if (isNaN(zoomLevel)) {
        this.zoomModel = this.formattedZoomLevel
        return
      }
      const zoom = Math.max(Math.min(zoomLevel / 100.0, MAX_ZOOM_LEVEL), MIN_ZOOM_LEVEL)
      this.$emit('update:zoom', zoom)
      this.zoomModel = this.formattedZoomLevel
    },
    onKeydown (event) {
      if (event.key === 'Enter') {
        this.$refs.zoomInput.$refs.input.blur()
      } else if (event.key === 'Escape') {
        this.zoomModel = this.formattedZoomLevel
        this.$refs.zoomInput.$refs.input.blur()
      }
    },

    onZoomToFitButtonClicked () {
      this.$emit('fit')
    }
  }
}
</script>

<style lang="scss" scoped>
.zoom-control {
  .button {
    padding: 0;
    .zoom-button-content {
      display: flex;
      justify-content: center;
      align-items: center;

      &::v-deep {
        input {
          border: 0;
          width: 5.8rem;
          background: transparent;
        }
      }

      .caret-icon {
        position: absolute;
        right: 1rem;
        pointer-events: none;
      }
    }
  }
}
</style>
