import { ModalProgrammatic as Modal } from 'buefy'
import { nodeTypeName } from '@custom-media/signdigital-web-shared/src/mixins/file-node-mixins'
import FileSaveDialog from '@/components/folders/FileSaveDialog.vue'
import FileBrowserController from '@/lib/folders/FileBrowserController'

async function createFile ({ $store, filename, fileType, documentType, customSignType, fileData = {}, node }) {
  const userId = $store.state.auth.user._id
  const ownerDetails = node?.ownerDetails ?? { ownerType: 'users', owner: userId }
  switch (fileType) {
    case 'documents':
      await $store.dispatch('documentEditor/createNewDocument', {
        name: filename,
        documentType,
        node: node._id,
        ...ownerDetails
      })
      return $store.dispatch('documentEditor/save')
    case 'lists':
      return $store.dispatch('lists/create', {
        ...ownerDetails,
        author: userId,
        name: filename,
        node: node._id,
        ...fileData
      })
    case 'custom-signs':
      await $store.dispatch('signEditor/create', {
        ...ownerDetails,
        author: userId,
        name: filename,
        signType: customSignType,
        node: node._id
      })
      $store.commit('signEditor/setHasUnsavedChanges', true)
      return $store.dispatch('signEditor/save')
  }
}

async function maybeConfirmTransition (originalRoot, selectedRoot, item, parent) {
  const transitionPersonalToTeam = originalRoot.startsWith('user_') && selectedRoot.startsWith('team_')
  const transitionTeamToPersonal = originalRoot.startsWith('team_') && selectedRoot.startsWith('user_')

  if (transitionPersonalToTeam) {
    return new Promise((resolve) => {
      parent.$buefy.dialog.confirm({
        message: `Du bist dabei die Datei „${item.fileNode.name}” aus deinem persönlichen Ordner in den Team-Ordner zu verschieben.<br/>Im Team-Ordner haben alle deine Team-Mitglieder Zugriff auf die Datei, können sie verändern und auch löschen.`,
        confirmText: 'Trotzdem verschieben',
        cancelText: 'Abbrechen',
        onConfirm: () => resolve(true),
        onCancel: () => resolve(false)
      })
    })
  }

  if (transitionTeamToPersonal) {
    return new Promise((resolve) => {
      parent.$buefy.dialog.confirm({
        message: `Du bist dabei die Datei „${item.fileNode.name}” aus deinem Team-Ordner in deinen persönlichen Ordner zu verschieben.<br/>Dein Team verliert dadurch den Zugriff darauf.`,
        confirmText: 'Trotzdem verschieben',
        cancelText: 'Abbrechen',
        onConfirm: () => resolve(true),
        onCancel: () => resolve(false)
      })
    })
  }

  return true
}

export default {
  install (Vue, options) {
    Vue.prototype.$fileDialog = {
      create: ({
        nodeType,
        fileType,
        location,
        parent,
        filename = null,
        documentType = null,
        fileData = null,
        customSignType = null,
        pick = ['filename', 'location', 'documentType', 'customSignType'],
        focus = ['location', 'filename', 'documentType', 'customSignType']
      }) => {
        return new Promise((resolve) => {
          parent.$store?.dispatch('fileNodeTree/ensureInitialized')
          const browserCtrl = new FileBrowserController({
            rootItems: parent.$store.state.fileNodeTree.rootItems,
            currentItem: location
          })
          const title = nodeTypeName(nodeType, fileType)

          const modal = Modal.open({
            component: FileSaveDialog,
            parent,
            canCancel: ['outside', 'escape'],
            props: {
              title: `${title} erstellen`,
              browserCtrl,
              filename,
              nodeType,
              fileType,
              documentType,
              customSignType,
              pick,
              focus
            },
            events: {
              cancel: () => {
                modal.close()
                resolve(null)
              },
              submit: async ({ filename, location, nodeType, fileType, documentType, customSignType }) => {
                const dialog = modal.$children[0]
                dialog.error = null
                try {
                  // Create node
                  const node = new parent.$FeathersVuex.api.FileNode({
                    name: filename,
                    path: location.childrenPath,
                    nodeType,
                    fileType: fileType ?? undefined,
                    fileDetails: {
                      documentType: documentType ?? undefined,
                      customSignType: customSignType ?? undefined
                    }
                  })
                  await node.save()
                  await parent.$store.dispatch('fileNodeTree/rebuild')

                  if (nodeType === 'directory') {
                    dialog.isLoading = true
                    modal.close()
                    resolve({ node })
                  } else {
                    // Create file
                    const file = await createFile({
                      $store: parent.$store,
                      filename,
                      fileType,
                      documentType,
                      customSignType,
                      fileData,
                      node
                    })
                    // Store file reference in node
                    node.file = file._id
                    await node.save()
                    modal.close()
                    resolve({ node, file })
                  }
                } catch (error) {
                  dialog.isLoading = false
                  dialog.error = error
                }
              }
            }
          })
        })
      },
      rename ({ item, parent }) {
        return new Promise((resolve) => {
          parent.$store?.dispatch('fileNodeTree/ensureInitialized')
          const currentParent = parent.$store.state.fileNodeTree.itemsById[item.parentId]
          const browserCtrl = new FileBrowserController({
            rootItems: parent.$store.state.fileNodeTree.rootItems,
            currentItem: currentParent,
            originalFilename: item.fileNode.name,
            originalItem: item,
            allowOriginalFilename: true
          })
          const nodeType = nodeTypeName(item.fileNode.nodeType, item.fileNode.fileType)
          const modal = Modal.open({
            component: FileSaveDialog,
            parent,
            canCancel: ['outside', 'escape'],
            props: {
              title: `${nodeType} umbenennen`,
              actionTitle: 'Umbenennen',
              item,
              name: item.fileNode.name,
              browserCtrl,
              nodeType: item.fileNode.nodeType,
              fileType: item.fileNode.fileType,
              pick: ['filename'],
              forceChange: ['filename']
            },
            events: {
              cancel: () => {
                modal.close()
              },
              submit: async ({ filename }) => {
                const dialog = modal.$children[0]
                dialog.error = null
                try {
                  dialog.isLoading = true
                  await item.fileNode.patch({ data: { name: filename } })
                  modal.close()
                  resolve(item)
                } catch (error) {
                  dialog.isLoading = false
                  dialog.error = error
                }
              }
            }
          })
        })
      },
      move ({ item, parent }) {
        return new Promise((resolve) => {
          const currentParent = parent.$store.state.fileNodeTree.itemsById[item.parentId]
          parent.$store?.dispatch('fileNodeTree/ensureInitialized')
          const browserCtrl = new FileBrowserController({
            rootItems: parent.$store.state.fileNodeTree.rootItems,
            currentItem: currentParent,
            originalFilename: item.fileNode.name,
            originalItem: item,
            allowOriginalFilename: true
          })

          const nodeType = nodeTypeName(item.fileNode.nodeType, item.fileNode.fileType)
          const modal = Modal.open({
            component: FileSaveDialog,
            parent,
            canCancel: ['outside', 'escape'],
            props: {
              title: `${nodeType} verschieben`,
              actionTitle: 'Hierhin verschieben',
              fileBrowserTitle: `Wähle den Ordner, in den „${item.fileNode.name}” verschoben werden soll`,
              item,
              name: item.fileNode.name,
              browserCtrl,
              nodeType: item.fileNode.nodeType,
              fileType: item.fileNode.fileType,
              pick: ['filename', 'location'],
              focus: ['filename', 'location']
            },
            events: {
              cancel: () => {
                modal.close()
              },
              submit: async ({ location, filename }) => {
                const dialog = modal.$children[0]
                dialog.error = null
                try {
                  dialog.isLoading = true

                  // Maybe confirm team <> personal transition
                  const originalRoot = currentParent.rootId
                  const selectedRoot = location.rootId
                  const shouldContinue = await maybeConfirmTransition(originalRoot, selectedRoot, item, parent)
                  if (shouldContinue === false) {
                    dialog.isLoading = false
                    return
                  }

                  // Perform move
                  await item.fileNode.patch({ data: { path: location.childrenPath, name: filename } })
                  await parent.$store.dispatch('fileNodeTree/rebuild')
                  location.didFetchChildren = false
                  item.didFetchChildren = false
                  modal.close()
                  resolve(item)
                } catch (error) {
                  dialog.isLoading = false
                  dialog.error = error
                }
              }
            }
          })
        })
      },

      duplicate ({ item, currentItem = null, parent }) {
        return new Promise((resolve) => {
          const currentParent = parent.$store.state.fileNodeTree.itemsById[item.parentId]
          parent.$store?.dispatch('fileNodeTree/ensureInitialized')
          const browserCtrl = new FileBrowserController({
            rootItems: parent.$store.state.fileNodeTree.rootItems,
            currentItem: currentItem ?? currentParent,
            originalFilename: item.fileNode.name,
            originalItem: item
          })

          const nodeType = nodeTypeName(item.fileNode.nodeType, item.fileNode.fileType)

          const modal = Modal.open({
            component: FileSaveDialog,
            parent,
            canCancel: ['outside', 'escape'],
            props: {
              title: `${nodeType} duplizieren`,
              actionTitle: 'Kopie hier speichern',
              fileBrowserTitle: `Wähle den Ordner, in dem die Kopie von „${item.fileNode.name}” gespeichert werden soll`,
              item,
              name: `${item.fileNode.name} Kopie`,
              browserCtrl,
              nodeType: item.fileNode.nodeType,
              fileType: item.fileNode.fileType,
              pick: ['location', 'filename'],
              focus: ['location', 'filename']
            },
            events: {
              cancel: () => {
                modal.close()
              },
              submit: async ({ location, filename, item }) => {
                const dialog = modal.$children[0]
                dialog.error = null
                try {
                  dialog.isLoading = true

                  // Maybe confirm team <> personal transition
                  // const originalRoot = currentParent.rootId
                  // const selectedRoot = location.rootId
                  // const shouldContinue = await maybeConfirmTransition(originalRoot, selectedRoot, item, parent)
                  // if (shouldContinue === false) {
                  //   dialog.isLoading = false
                  //   return
                  // }

                  // Perform duplication
                  const duplicate = await parent.$store.dispatch('file-nodes/create', {
                    path: location.childrenPath,
                    name: filename,
                    clone: item._id
                  })
                  await parent.$store.dispatch('fileNodeTree/rebuild')
                  location.didFetchChildren = false
                  item.didFetchChildren = false
                  modal.close()
                  resolve(duplicate)
                } catch (error) {
                  dialog.isLoading = false
                  dialog.error = error
                }
              }
            }
          })
        })
      },

      async delete ({ item, parent }) {
        return new Promise((resolve) => {
          parent.$buefy.dialog.confirm({
            title: 'Wirklich löschen?',
            message:
              `Soll „${item.fileNode.name}” unwiderruflich gelöscht werden?<br/>` +
              (item.fileNode.nodeType === 'directory'
                ? 'Alle in diesem Ordner enthaltenen Objekte werden ebenfalls gelöscht!<br/>`'
                : '') +
              (item.isInTeamDirectory
                ? '<br /><strong>Das Element befindet sich im Team-Ordner. Auch für deine Team-Mitglieder wird dieses Element gelöscht.</strong>'
                : ''),
            type: 'is-danger',
            confirmText: 'Löschen',
            cancelText: 'Abbrechen',
            onConfirm: async () => {
              await item.fileNode.remove()
              await parent.$store.dispatch('fileNodeTree/rebuild')
              resolve(true)
            },
            onCancel: () => {
              resolve(false)
            }
          })
        })
      }
    }
  }
}
