<template>
  <div class="documents-browser">
    <!-- NAVBAR -->
    <b-navbar class="app-nav is-mobile" :mobile-burger="false" :active="true">
      <template slot="brand">
        <b-navbar-item tag="div">
          <nav class="breadcrumb" aria-label="breadcrumbs">
            <ul>
              <li><router-link :to="{ name: 'documents' }">Druckvorlagen</router-link></li>
              <li class="is-active"><a href="#">Alle</a></li>
            </ul>
          </nav>
        </b-navbar-item>
      </template>
      <template slot="start"> </template>
      <template slot="end">
        <b-navbar-item tag="router-link" :to="{ name: 'documents' }">
          <b-button type="is-primary">Zurück zur Übersicht</b-button>
        </b-navbar-item>
      </template>
    </b-navbar>

    <section>
      <div class="container p-4">
        <file-browser :sources="['team', 'user']" :nodeTypes="['documents']"></file-browser>
      </div>
    </section>
  </div>
</template>

<script>
import FileBrowser from '@/components/folders/FileBrowser.vue'
export default {
  components: { FileBrowser },
  data () {
    return {}
  },
  computed: {},
  methods: {}
}
</script>

<style lang="scss" scoped>
.tags.created-tag,
.tags.updated-tag {
  margin-bottom: 0;
}

.b-table.documents-table::v-deep td {
  vertical-align: middle;
}
</style>
