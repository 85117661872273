<template>
  <div class="custom-signs-view">
    <!-- HEADER -->
    <!-- NAVBAR -->
    <b-navbar class="app-nav is-mobile" :mobile-burger="false" :active="true">
      <template slot="brand">
        <b-navbar-item tag="div">
          <nav class="breadcrumb" aria-label="breadcrumbs">
            <ul>
              <li class="is-active"><a href="#">Eigene Gebärden</a></li>
              <!-- <li class="is-active">
                <a href="#">{{ signType | customSignTypeShort }}</a>
              </li> -->
            </ul>
          </nav>
        </b-navbar-item>
      </template>
      <template slot="start"> </template>
      <template slot="end"> </template>
    </b-navbar>

    <!-- Create new document prompt -->
    <section class="create-new-sign">
      <div class="container px-6 pt-3 pb-6">
        <br />
        <h2 class="subtitle">Neue eigene Gebärde erstellen</h2>

        <div class="custom-sign-cards columns is-multiline">
          <div class="column is-one-quarter-widescreen is-one-quarter-desktop is-half-tablet">
            <div class="card" @click="onCreateCardClicked('signOnly')">
              <div class="card-image">
                <img class="" src="@/assets/img/custom-sign-only-placeholder.png" />
              </div>
              <div class="card-content">
                <h1 class="title">Nur Gebärde</h1>
                <p class="description">Gebärdenfoto mit Pfeilen und Text beschriften</p>
              </div>
            </div>
          </div>
          <div class="column is-one-quarter-widescreen is-one-quarter-desktop is-half-tablet">
            <div class="card" @click="onCreateCardClicked('signBox')">
              <div class="card-image">
                <img class="" src="@/assets/img/custom-sign-box-placeholder.png" />
              </div>
              <div class="card-content">
                <h1 class="title">SIGNbox</h1>
                <p class="description">Eine eigene SIGNbox-Karte mit Gebärde, Symbol und Text erstellen</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- CONTENT -->
    <section class="all-signs">
      <div class="container p-5">
        <!-- Heading -->
        <div class="level">
          <div class="level-left">
            <h2 class="subtitle">Zuletzt verwendet</h2>
          </div>
          <div class="level-right">
            <b-button
              tag="router-link"
              :to="{ name: 'file-browser', query: { t: 'custom-signs' } }"
              size=""
              type="is-ghost"
              icon-left="folder-tree"
              >Alle eigenen Gebärden anzeigen</b-button
            >
          </div>
        </div>

        <hint hint-key="file-browser-view-lists">
          <p>
            Deine eigenen Gebärden sind ab jetzt im Bereich „Organisieren” gespeichert. Hier siehst du nur deine zuletzt
            verwendeten eigenen Gebärden.
          </p>
          <p>
            Klicke auf
            <router-link
              :to="{ name: 'file-browser', query: { t: 'custom-signs' } }"
              size=""
              type="is-text"
              icon-left="folder-tree"
              >„Alle eigenen Gebärden anzeigen”</router-link
            >
            um die neue Organisieren-Funktion und die Verwendung von Ordnern auszuprobieren.
          </p>
        </hint>

        <!-- List of custom signs -->
        <b-table
          striped
          hoverable
          :data="recentlyUsedCustomSigns"
          default-sort="updatedAt"
          default-sort-direction="desc"
          :loading="isFindCustomSignsPending"
        >
          <!-- Signs list -->
          <b-table-column field="thumbnail" label="Vorschau" v-slot="{ row }">
            <custom-sign-thumbnail :custom-sign="row" variant="small" />
          </b-table-column>
          <b-table-column field="name" label="Name" v-slot="{ row }">
            <strong>{{ row.name }}</strong>
          </b-table-column>
          <b-table-column label="Datum" field="updatedAt" v-slot="props">
            <div class="tags">
              <b-tag>{{ props.row.updatedAt | dateTime }}</b-tag>
              <b-tag>{{ props.row.signType | customSignType }}</b-tag>
            </div>
          </b-table-column>
          <b-table-column label="Aktionen" v-slot="{ row }">
            <div class="buttons">
              <b-button size="is-small" @click="onEditButtonClicked(row)">Bearbeiten</b-button>
              <!-- <b-button size="is-small" type="is-danger" icon-left="trash" @click="onDeleteButtonClicked(row)"
                >Löschen</b-button
              > -->
              <actions-dropdown
                :actions="customSignActions"
                type="is-default"
                size="is-small"
                icon="ellipsis-h"
                @action="onAction($event, row)"
              ></actions-dropdown>
            </div>
          </b-table-column>

          <!-- Empty state -->
          <template #empty>
            <div class="content has-text-centered">
              <p>Du hast noch keine eigenen Gebärden angelegt.</p>
            </div>
          </template>
        </b-table>
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { makeFindMixin } from 'feathers-vuex'
import CustomSignThumbnail from '@/components/custom-signs/CustomSignThumbnail'
import { customSignsMixin } from '@custom-media/signdigital-web-shared/src/mixins/custom-signs-mixin'
import { CustomSignImageReference } from '@custom-media/signdigital-lib/src/image-reference'
import { ResolvableImage } from '@custom-media/signdigital-web-shared/src/lib/resolvable-image'
import ActionsDropdown from '@/components/ActionsDropdown'
import Hint from '@/components/Hint'

export default {
  mixins: [customSignsMixin, makeFindMixin({ service: 'custom-signs', qid: 'listSigns', watch: true })],
  components: {
    CustomSignThumbnail,
    ActionsDropdown,
    Hint
  },
  data () {
    return {
      customSignActions: [
        { value: 'reveal', icon: 'folder-tree', text: 'In „Organisieren” anzeigen' },
        { value: 'rename', icon: 'comment-edit', text: 'Umbenennen' },
        { value: 'move', icon: 'level-down', text: 'Verschieben' },
        { value: 'duplicate', icon: 'clone', text: 'Duplizieren' },
        { value: 'download', icon: 'file-download', text: 'Herunterladen' },
        { value: 'delete', icon: 'trash', text: 'Löschen' }
      ]
    }
  },
  computed: {
    ...mapState('signEditor', ['signType']),
    ...mapState('preferences', { searchPreferences: 'search' }),
    customSignsParams () {
      return this.$store.getters['preferences/recentlyUsedQuery']('custom-signs', 10)
    },
    recentlyUsedCustomSigns () {
      if (!this.$store.getters['preferences/isActualRecentlyUsedQuery']('custom-signs')) {
        return this.customSigns
      }
      const ids = this.$store.state.preferences.recentlyUsed['custom-signs']
      if (ids.length === 1) {
        return this.customSigns
      }

      const sorted = ids
        .map((i) => this.customSigns.find((d) => d?._id === i))
        .reverse()
        .filter((f) => !!f)
      return sorted
    }
  },
  methods: {
    ...mapActions('custom-signs', { removeSign: 'remove' }),
    async onCreateCardClicked (signType) {
      const { file } = await this.$fileDialog.create({
        nodeType: 'file',
        fileType: 'custom-signs',
        customSignType: signType,
        parent: this
      })

      this.$store.commit('session/clearExitRoute')
      this.$router.push({ name: 'custom-sign-editor', params: { id: file._id } })
    },
    onEditButtonClicked (sign) {
      this.$store.commit('session/clearExitRoute')
      this.$store.dispatch('preferences/trackRecentlyUsed', { fileType: 'custom-signs', id: sign._id })
      this.$router.push({ name: 'custom-sign-editor', params: { id: sign._id } })
    },
    async onDownloadButtonClicked (sign) {
      const image = new ResolvableImage({
        $store: this.$store,
        $resolver: this.$resolver,
        reference: new CustomSignImageReference({ objectId: sign._id }),
        variant: 'original'
      })
      const w = window.open('', '_blank')
      w.focus()
      const url = await image.resolve()
      w.location.href = url + '&dl=1'
    },
    async onDeleteButtonClicked (sign) {
      await this.removeSign(sign._id)
    },
    onPageChange (page) {
      this.skip = (page - 1) * this.limit
    },
    onSort (field, order) {
      this.sortField = field
      this.sortOrder = order
    },
    async onAction (action, customSign) {
      if (action === 'download') {
        return this.onDownloadButtonClicked(customSign)
      }
      const item = await this.$store.dispatch('fileNodeTree/maybeFetchInPath', customSign.node)
      switch (action) {
        case 'reveal':
          this.$router.push({
            name: 'file-browser',
            query: {
              c: item.parentId,
              s: item._id
            }
          })
          return
        case 'rename':
          await this.$fileDialog.rename({ item, parent: this })
          await this.$store.dispatch('custom-signs/get', customSign._id)
          return
        case 'move':
          await this.$fileDialog.move({ item, parent: this })
          await this.$store.dispatch('custom-signs/get', customSign._id)
          return
        case 'duplicate': {
          const duplicate = await this.$fileDialog.duplicate({ item, parent: this })
          await this.$store.dispatch('custom-signs/get', duplicate.file)
          return
        }
        case 'delete':
          return this.$fileDialog.delete({ item, parent: this })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/bulma-variables.scss';
section.create-new-sign {
  background: $grey-lightest;
}

.custom-sign-cards {
  .column {
    display: flex;
  }
}
.card {
  display: inline-block;
  transition:
    transform ease-out 0.25s,
    box-shadow ease-out 0.25s;
  display: flex;
  flex-direction: column;

  .card-image {
    transition: background-color ease-out 0.25s;
    background-color: $grey-lighter;
    perspective: 800px;
    overflow: hidden;
    text-align: center;

    img {
      max-height: 200px;
      transform-origin: center bottom;
      transition: transform ease-out 0.25s;
      user-drag: none;
      -webkit-user-drag: none;
      user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;
    }
  }

  &:hover {
    transform: scale(1.02);
    box-shadow:
      0 0em 1.5em -0.05em rgba(10, 10, 10, 0.1),
      0 0px 0 0px rgba(10, 10, 10, 0.02);
    cursor: pointer;

    .card-image {
      img {
        transform: scale(1.02) rotate3d(1, 0, 0, -6deg);
      }
    }
  }

  .card-content {
    padding: 1rem 1rem;
    font-size: 0.8rem;

    h1.title {
      font-size: 1rem;
      margin-bottom: 0.5rem;
    }

    p.description {
      margin-bottom: 1em;
    }
  }
}
</style>
