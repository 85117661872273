<template>
  <div id="user-subscription-view">
    <div class="container p-5">
      <div v-if="billingType === 'paying'">
        <b-loading :active="isFindPending || isPatchPending" />

        <section v-if="!isFindPending && !isPatchPending && subscription == null">
          <h1 class="title">Deine Lizenz</h1>
          <p>Du hast noch keine aktive Lizenz</p>
          <div class="buttons mt-4">
            <b-button tag="router-link" :to="{ name: 'checkout' }">Jetzt eine Lizenz erwerben</b-button>
          </div>
        </section>
        <section v-else-if="!isEditingSubscription">
          <h1 class="title">Deine Lizenz</h1>
          <subscription-overview-card
            :subscription="subscription"
            :stripeSubscription="stripeSubscription"
            :payment-methods="paymentMethods"
            :renewal="renewal"
            @cancel="onCancelAtPeriodEnd"
            @renew="onRenewAtPeriodEnd"
            @change="onChangeSubscription"
          />
        </section>

        <section v-else>
          <h1 class="title">Lizenz ändern</h1>
          <div class="buttons">
            <b-button icon-left="chevron-left" @click="onCancelSubscriptionChange"
              >Abbrechen und zurück zu meiner Lizenz</b-button
            >
          </div>
          <subscription-change
            :stripe="stripe"
            :stripe-elements="stripeElements"
            :customer="customer"
            :subscription="subscription"
            :stripeSubscription="stripeSubscription"
            :payment-methods="paymentMethods"
            @change="onSubscriptionChanged"
          />
        </section>
      </div>

      <div class="card" v-else-if="billingType === 'member'">
        <div class="card-content">
          <div class="content">
            Du nutzt die SIGNdigital Lizenz deines Teams - die Lizenz wird vom Team-Besitzer verwaltet.
          </div>

          <b-message
            type="is-warning"
            v-if="billingType === 'member' && !$store.getters['auth/user'].hasActiveSubscription"
          >
            Es scheint als hätte dein Team keine aktive SIGNdigital Lizenz. Bitte wende dich bei Fragen an den Verwalter
            deines Teams.
          </b-message>

          <b-message type="is-danger" v-if="subscription && subscription.isExpired">
            Es scheint ein Problem mit der Lizenz deines Teams vorzuliegen. Bitte wende dich an den Verwalter deines
            Teams.
          </b-message>
        </div>
      </div>

      <div class="card" v-else-if="billingType === 'free'">
        <div class="card-content">
          <div class="content">Du nutzt einen kostenlosen Zugang. Es gibt daher keine Lizenz zu verwalten.</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SubscriptionOverviewCard from '@/components/SubscriptionOverviewCard.vue'
import SubscriptionChange from '@/components/SubscriptionChange.vue'
import feathersClient from '@/feathers-rest-client'
import { mapGetters, mapState } from 'vuex'
import { loadStripe } from '@stripe/stripe-js/pure'

export default {
  components: {
    SubscriptionOverviewCard,
    SubscriptionChange
  },
  data () {
    return {
      customer: null,
      stripeCustomer: null,
      subscription: null,
      renewal: null,
      stripeSubscription: null,
      paymentMethods: null,
      stripe: null,
      stripeElements: null,
      isFindPending: false,
      isEditingSubscription: false
    }
  },
  computed: {
    ...mapState('subscriptions', ['isPatchPending']),
    ...mapGetters('session', ['billingType']),
    user () {
      return this.$store.getters['auth/user']
    }
  },
  async beforeRouteEnter (from, to, next) {
    if (window.stripe == null) {
      const STRIPE_PUBLIC_KEY = process.env.VUE_APP_STRIPE_PUBLIC_KEY
      window.stripe = await loadStripe(STRIPE_PUBLIC_KEY)
    }
    next()
  },
  mounted () {
    this.stripe = window.stripe
    this.stripeElements = window.stripe.elements()
    this.fetchData()
  },
  methods: {
    async fetchData () {
      const { Subscription, Customer } = this.$FeathersVuex.api
      this.isFindPending = true
      try {
        const { customer, stripeCustomer, subscription, stripeSubscription, paymentMethods } = await feathersClient
          .service('checkout')
          .find({})

        this.customer = customer == null ? null : new Customer(customer)
        this.stripeCustomer = stripeCustomer
        this.subscription = subscription == null ? null : new Subscription(subscription)
        this.stripeSubscription = stripeSubscription
        this.paymentMethods = paymentMethods

        if (subscription.successor) {
          this.renewal = await this.$FeathersVuex.api.Subscription.get(subscription.successor)
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.isFindPending = false
      }
    },
    async onCancelAtPeriodEnd () {
      this.$buefy.dialog.confirm({
        title: 'Wirklich deaktivieren?',
        message: `Soll die automatische Verlängerung deiner SIGNdigital Lizenz wirklich deaktiviert werden?<br />Am ${this.$options.filters.dateTimeAt(this.subscription.periodEnd)} verlierst du dann den Zugriff auf alle Inhalte von SIGNdigital.`,
        confirmText: 'Deaktivieren',
        cancelText: 'Abbrechen',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: async () => {
          await this.subscription.patch({ data: { cancelAtPeriodEnd: true } })
          this.$buefy.toast.open('Automatische Verlängerung deaktiviert')
        }
      })
    },
    async onRenewAtPeriodEnd () {
      await this.subscription.patch({ data: { cancelAtPeriodEnd: false } })
    },
    async onChangeSubscription () {
      this.isEditingSubscription = true
    },
    async onCancelSubscriptionChange () {
      this.isEditingSubscription = false
    },
    onSubscriptionChanged ({ subscription, stripeSubscription }) {
      this.subscription = subscription
      this.stripeSubscription = stripeSubscription
      this.isEditingSubscription = false
      this.fetchData()
    }
  }
}
</script>
