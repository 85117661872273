<template>
  <div class="document-text-field" :style="frameStyle">
    <input
      class="input"
      ref="input"
      type="text"
      :placeholder="placeholder"
      :value="value"
      @input="onInput"
      :disabled="!editable"
      :style="fontStyle.css"
    />
  </div>
</template>

<script>
import { frameStyleMixin } from '@/mixins/document-element-mixin'
export default {
  mixins: [frameStyleMixin],
  props: {
    frame: Object,
    value: String,
    placeholder: {
      type: String,
      default: 'Text'
    },
    fontStyle: {
      type: Object,
      required: true
    },
    preview: { type: Boolean, default: false },
    editable: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      fontSizeOverride: null
    }
  },
  computed: {
    inputStyle () {
      return {
        ...this.fontStyle,
        height: this.frame.height + 'px',
        width: this.frame.width + 'px'
      }
    },
    shrinkedFontStyle () {
      const style = { ...this.fontStyle }
      if (this.fontSizeOverride != null) {
        style['font-size'] = this.fontSizeOverride
      }
      return style
    }
  },
  mounted () {
    this.fitText()
  },
  methods: {
    async onInput ($event) {
      this.$emit('update:value', $event.target.value)
      this.fitText()
    },
    fitText () {
      const input = this.$refs.input
      // Reset font size
      if (this.fontSizeOverride != null) {
        this.fontSizeOverride = null
        input.style.fontSize = `${this.fontStyle.fontSize}px`
      }

      // Shrink until it fits
      // SIGN-648 Experimental fix for wrongly scaled heading size (rounding-error?) on 1920x1080 screens, 125% windows scaling
      while (input.scrollWidth > input.clientWidth + 1) {
        if (this.fontSizeOverride === null) {
          this.fontSizeOverride = this.fontStyle.fontSize - 1
        } else {
          this.fontSizeOverride -= 1
        }
        if (this.fontSizeOverride == null || this.fontSizeOverride === 0) {
          return
        }
        input.style.fontSize = `${this.fontSizeOverride}px`
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.document-text-field {
  input.input {
    height: 50px;
    padding: 0.2em 0.4em;
  }

  &.height-auto {
    input.input {
      height: auto;
    }
  }

  &.preview {
    input.input {
      border: 0;
    }
  }
}
</style>
