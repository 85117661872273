<template>
  <document-image
    v-if="editable && image"
    :frame="frame"
    :responsive="false"
    :image-reference="image"
    :editable="editable"
    dropdown-position="is-bottom-right"
    :allowed-content-types="allowedContentTypes"
    @update:image-reference="onImageChanged"
    :owner="owner"
    :ownerType="ownerType"
  />
  <figure v-else :style="frameStyle" ref="figure" :class="{ 'is-editable': editable }">
    <img src="@/assets/img/document/sign-digital-document-logo.svg" />
    <b-button
      v-if="editable"
      class="add-button"
      icon-left="plus"
      type="is-primary"
      outlined
      @click="onChangeImageClicked"
    />
  </figure>
</template>

<script>
import { frameStyleMixin } from '@/mixins/document-element-mixin'
import Vue from 'vue'
import SearchDropdown from '@custom-media/signdigital-web-shared/src/components/SearchDropdown'
import DocumentImage from '@/components/document/DocumentImage'
import * as Sentry from '@sentry/vue'
import { ensureImageReferenceOwnership } from '@/lib/util/image-ownership'
import { mapState } from 'vuex'

export default {
  mixins: [frameStyleMixin],
  components: {
    DocumentImage
  },
  props: {
    frame: Object,
    image: Object,
    editable: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      allowedContentTypes: ['images']
    }
  },
  computed: {
    ...mapState('documentEditor', ['owner', 'ownerType'])
  },
  methods: {
    onChangeImageClicked () {
      if (!this.editable) {
        return
      }
      const SearchDropdownCtrl = Vue.extend(SearchDropdown)
      const el = new SearchDropdownCtrl({
        propsData: {
          target: this.$refs.figure,
          minX: 260,
          minY: 70,
          allowedContentTypes: this.allowedContentTypes
        },
        parent: this
      })
      el.$once('selected', async (imageReference) => {
        Sentry.addBreadcrumb({
          category: 'document',
          message: 'User selected new image',
          level: 'info'
        })
        if (imageReference.service === 'images') {
          const { ownerType, owner } = this.$store.state.documentEditor
          imageReference = await ensureImageReferenceOwnership({ ownerType, owner }, imageReference, this)

          if (imageReference === null) {
            return
          }
        }
        this.onImageChanged(imageReference)
      })
      el.$once('closed', () => {
        el.$destroy()
        el.$el.parentNode.removeChild(el.$el)
      })
      el.$mount()
    },
    onImageChanged (imageReference) {
      this.$emit('update:image', imageReference)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/bulma-variables.scss';
figure {
  position: relative;
  .add-button {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border: 2px solid $primary;
    background-color: rgba($primary, 0.25);
    opacity: 0;

    &:hover {
      opacity: 0.6;
    }
  }
}
</style>
