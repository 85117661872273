import { render, staticRenderFns } from "./CustomSignEditor.vue?vue&type=template&id=194f6cab&scoped=true"
import script from "./CustomSignEditor.vue?vue&type=script&lang=js"
export * from "./CustomSignEditor.vue?vue&type=script&lang=js"
import style0 from "./CustomSignEditor.vue?vue&type=style&index=0&id=194f6cab&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "194f6cab",
  null
  
)

export default component.exports