<template>
  <div class="password-reset-form">
    <validation-observer v-slot="{ handleSubmit, invalid }" ref="form">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <!-- PASSWORD -->
        <validation-provider
          name="Neues Passwort"
          :bails="false"
          rules="required|min:8|passwordNumber"
          v-slot="{ errors, valid }"
        >
          <b-field label="Neues Passwort" :type="{ 'is-danger': errors.length > 0, 'is-primary': valid }">
            <template slot="message">
              <ul>
                <li v-for="(error, index) in errors" :key="index">{{ error }}</li>
              </ul>
            </template>
            <b-input
              id="new-password"
              autocomplete="new-password"
              name="new-password"
              type="password"
              placeholder="Neues Passwort"
              password-reveal
              v-model="newPassword"
            />
          </b-field>
        </validation-provider>

        <!-- PASSWORD REPETITION -->
        <validation-provider name="Passwort-Wiederholung" rules="required|match:@Neues Passwort" v-slot="{ errors }">
          <b-field
            class="mt-2"
            label="Neues Passwort wiederholen"
            :type="{ 'is-danger': errors.length > 0 }"
            :message="errors[0]"
          >
            <b-input
              name="new-password-confirmation"
              type="password"
              placeholder="Passwort wiederholen"
              password-reveal
              v-model="newPasswordConfirmation"
            />
          </b-field>
        </validation-provider>

        <!-- OLD PASSWORD -->
        <div class="pt-3" v-if="oldPassword">
          <hr />
          <validation-provider name="Bisheriges Passwort" rules="required" v-slot="{ errors }">
            <b-field
              class="mt-2"
              label="Bisheriges Passwort"
              :type="{ 'is-danger': errors.length > 0 }"
              :message="errors[0]"
            >
              <b-input
                name="old-password"
                type="password"
                placeholder="Bisheriges Passwort"
                password-reveal
                v-model="password"
              />
            </b-field>
          </validation-provider>
        </div>

        <!-- SAVE BUTTON -->
        <b-button v-if="submit" native-type="submit" type="is-primary" :disabled="invalid" expanded class="mt-5"
          >Passwort ändern</b-button
        >
      </form>
    </validation-observer>
  </div>
</template>

<script>
export default {
  props: {
    oldPassword: {
      type: Boolean,
      default: false
    },
    submit: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      password: null,
      newPassword: null,
      newPasswordConfirmation: null
    }
  },
  methods: {
    reset () {
      this.password = null
      this.newPassword = null
      this.newPasswordConfirmation = null
    },
    async onSubmit () {
      this.$emit('submit', this.newPassword, this.password)
    }
  }
}
</script>
