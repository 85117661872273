<template>
  <div class="custom-sign-canvas" ref="canvasWrapper">
    <canvas ref="canvas"></canvas>
    <div ref="canvasOverlayContainer" id="canvas-overlay-container" style="position: absolute">
      <sign-box-overlay-controls></sign-box-overlay-controls>
    </div>
  </div>
</template>

<script>
import { fabric } from 'fabric'
import { mapGetters, mapState } from 'vuex'
import { Point, Size, Rectangle } from '@custom-media/geometry'
import FontFaceObserver from 'fontfaceobserver'
import axios from 'axios'
import { DrawingContext } from '@custom-media/signdigital-lib/src/sign-editor-object'
import { globalEventBus, globalEvents } from '@/lib/events/global-event-bus'
import { customSignsMixin } from '@custom-media/signdigital-web-shared/src/mixins/custom-signs-mixin'
import SignBoxOverlayControls from '@/components/custom-signs/SignBoxOverlayControls.vue'

// const SIGN_BOX_SINGLE_IMAGE_FRAME = Rectangle.fromProperties(36, 36, 720, 708)
// const SIGN_BOX_LEFT_IMAGE_FRAME = Rectangle.fromProperties(36, 195, 360, 550)
// const SIGN_BOX_RIGHT_IMAGE_FRAME = Rectangle.fromProperties(396, 195, 360, 550)
// const SIGN_BOX_BOTTOM_IMAGE_FRAME = Rectangle.fromProperties(46, 818, 332, 332)

const CANVAS_CONTENT_PADDING = 100

/**
 ** CustomSignCanvas
 * @description Canvas component that works in sync with the sign-editor vuex store
 *
 ** Watches changes of signEditor.state.objectIds
 * --> Creates and maintains fabricjs canvas elements for all objectIds
 * --> Removes, adds, reorders canvas elements to comply with
 * NOTE: Changes to an objects visual properties are not tracked and will not be reflected by the canvas objects
 * @see onObjectsChanged
 *
 *
 ** Listens to fabricjs events to update vuex store
 * --> Syncs visual properties into the vuex store (position, scale, rotation)
 * @see onObjectsManipulated
 * @see onSelectionChanged
 *
 */
export default {
  mixins: [customSignsMixin],
  components: {
    // CustomSignSubcanvas
    SignBoxOverlayControls
  },
  data () {
    return {
      canvas: null,
      clippingOverlay: null,
      canvasBorder: null,
      canvasBackground: null,
      fabricObjectsKeyedById: {},
      updatedCanvasAfterObjectChangePromise: null,
      isDigestingObjectChanges: false,
      webfontsLoadedPromise: null,
      dataModelChangedHandler: (id) => this.onDataModelChanged(id)
    }
  },

  // TODO: Wait for webfonts loaded before rendering texts

  async mounted () {
    this.drawingContext = new DrawingContext({
      fabric,
      arrowResolver: async (imageName) => {
        const svg = require(`@custom-media/signdigital-lib/assets/arrows/${imageName}.svg`)
        const res = await axios.get(svg)
        return res.data
      },
      imageReferenceResolver: this.$resolver
    })

    window.addEventListener('resize', this.onWindowResize)
    globalEventBus.on(globalEvents.sidebarChanged, this.onWindowResizeNextTick)
    globalEventBus.on(globalEvents.contentAreaChanged, this.onWindowResizeNextTick)

    this.webfontsLoadedPromise = this.loadWebfonts()
    this.createCanvas()
    this.registerCanvasListeners()
    this.onObjectsChanged()
    this.onWindowResize()

    this.$store.state.signEditor.events.on('object-model-changed', this.dataModelChangedHandler)
  },
  destroyed () {
    window.removeEventListener('resize', this.onWindowResize)
    globalEventBus.off(globalEvents.sidebarChanged, this.onWindowResizeNextTick)
    globalEventBus.off(globalEvents.contentAreaChanged, this.onWindowResizeNextTick)
    this.removeCanvasListeners()
    this.canvas.dispose()
    this.$store.state.signEditor.events.off('object-model-changed', this.dataModelChangedHandler)
  },
  computed: {
    ...mapState('signEditor', [
      'selectedObjectIds',
      'isClippingMaskVisible',
      'objectIds',
      'objectsKeyedById',
      'signBoxLayout',
      'canvasScale'
    ]),
    ...mapGetters('signEditor', ['fixedObjectForLayoutKey', 'isFixedObject', 'contentSize']),

    signType () {
      return this.getCustomSignTypeFromQueryParam(this.$route.query?.type) ?? 'signOnly'
    },

    canvasSize () {
      return new Size(
        this.contentSize.width + CANVAS_CONTENT_PADDING * 2,
        this.contentSize.height + CANVAS_CONTENT_PADDING * 2
      )
    },

    contentRect () {
      return new Rectangle(
        new Point(
          (this.canvasSize.width - this.contentSize.width) / 2,
          (this.canvasSize.height - this.contentSize.height) / 2
        ),
        this.contentSize
      )
    }
  },
  watch: {
    isClippingMaskVisible (value) {
      console.log('isClippingMaskVisible', value)
      this.clippingOverlay.set({ opacity: value ? 1.0 : 0.5 })
      this.ensureOrder()
      this.canvas.renderAll()
    },
    objectIds () {
      this.updatedCanvasAfterObjectChangePromise = this.onObjectsChanged()
    },
    async selectedObjectIds (ids) {
      // Maybe wait for updated canvas
      if (this.updatedCanvasAfterObjectChangePromise) {
        await this.updatedCanvasAfterObjectChangePromise
      }

      const currentlyActive = new Set(this.canvas.getActiveObjects().map((o) => o.cm_object_id))
      if (ids.length === 0 && currentlyActive.size > 0) {
        this.canvas.discardActiveObject().renderAll()
        return
      }

      const selectionDiffers = ids.some((id) => !currentlyActive.has(id))
      if (selectionDiffers) {
        const objects = ids.map((id) => this.fabricObjectsKeyedById[id])
        const newSelection = new fabric.ActiveSelection(objects, { canvas: this.canvas })
        this.canvas.setActiveObject(newSelection)
        this.canvas.requestRenderAll()
      }
    }
  },
  methods: {
    /**
     * Scale the controls by setting the viewport trnasform to keep a consistent control size
     */
    onWindowResize () {
      const container = this.$refs.canvasWrapper
      const canvas = this.canvas
      const baseSize = this.canvasSize
      const availableSpace = new Size(container.clientWidth, container.clientHeight)

      // Calculate if canvas is restricted by height or width
      const widthRatio = availableSpace.width / baseSize.width
      const heightRatio = availableSpace.height / baseSize.height

      let scale
      if (widthRatio < heightRatio) {
        // Width restricted
        scale = widthRatio
      } else {
        // Height restricted
        scale = heightRatio
      }
      this.$store.commit('signEditor/setCanvasScale', scale)

      // Scale canvas
      const canvasSize = baseSize.multipliedByValue(scale)
      const contentSize = this.contentSize.multipliedByValue(scale)
      canvas.setDimensions(canvasSize)
      canvas.setViewportTransform([scale, 0, 0, scale, 0, 0])

      // Scale overlay
      const overlayFrame = new Rectangle(new Point(0, 0), availableSpace).alignSize(contentSize)
      this.$refs.canvasOverlayContainer.style.left = overlayFrame.left + 'px'
      this.$refs.canvasOverlayContainer.style.top = overlayFrame.top + 'px'
      this.$refs.canvasOverlayContainer.style.width = overlayFrame.width + 'px'
      this.$refs.canvasOverlayContainer.style.height = overlayFrame.height + 'px'
    },
    async onWindowResizeNextTick () {
      await this.$nextTick()
      this.onWindowResize()
    },
    createCanvas () {
      this.canvas = new fabric.Canvas(this.$refs.canvas, {
        width: this.canvasSize.width,
        height: this.canvasSize.height,
        preserveObjectStacking: true
      })
      const canvas = this.canvas

      fabric.Object.prototype.set({
        borderColor: '#312F2F',
        cornerSize: 20,
        cornerColor: '#C58C59',
        cornerStrokeColor: '#312F2F',
        cornerStyle: 'circle',
        padding: 12,
        transparentCorners: false
      })
      // Create white canvas background
      const canvasBackgroundColor = this.signType === 'signBox' ? 'transparent' : 'white'
      this.canvasBackground = new fabric.Rect({
        left: this.contentRect.minX,
        top: this.contentRect.minY,
        width: this.contentSize.width,
        height: this.contentSize.height,
        fill: canvasBackgroundColor
      })
      this.canvasBackground.selectable = false
      this.canvasBackground.evented = false
      canvas.add(this.canvasBackground)

      this.canvasBorder = new fabric.Rect({
        left: this.contentRect.minX,
        top: this.contentRect.minY,
        width: this.contentSize.width,
        height: this.contentSize.height,
        fill: 'transparent',
        stroke: '#b5b5b5',
        strokeWidth: 1
      })
      this.canvasBorder.selectable = false
      this.canvasBorder.evented = false
      canvas.add(this.canvasBorder)

      this.clippingOverlay = new fabric.Group([
        // TOP MASK (Full width)
        new fabric.Rect({
          left: 0,
          top: 0,
          width: this.canvasSize.width,
          height: this.contentRect.minY,
          fill: '#f5f5f5'
        }),
        // BOTTOM MASK
        new fabric.Rect({
          left: 0,
          top: this.contentRect.maxY,
          width: this.canvasSize.width,
          height: this.contentRect.minY,
          fill: '#f5f5f5'
        }),
        // LEFT MASK
        new fabric.Rect({
          left: 0,
          top: this.contentRect.minY,
          width: this.contentRect.minX,
          height: this.contentSize.height,
          fill: '#f5f5f5'
        }),
        // RIGHT MASK
        new fabric.Rect({
          left: this.contentRect.maxX,
          top: this.contentRect.minY,
          width: this.contentRect.minX,
          height: this.contentSize.height,
          fill: '#f5f5f5'
        })
      ])
      // this.clippingOverlay.visible = this.isClippingMaskVisible
      this.clippingOverlay.set({ opacity: this.isClippingMaskVisible ? 1.0 : 0.5 })
      this.clippingOverlay.evented = false
      this.clippingOverlay.selectable = false
      canvas.add(this.clippingOverlay)
    },

    async loadWebfonts () {
      const font = new FontFaceObserver('Mulish')
      await font.load()
      console.log('webfont loaded')
    },

    registerCanvasListeners () {
      // Selection listeners
      this.canvas.on('selection:created', this.onSelectionChange)
      this.canvas.on('selection:updated', this.onSelectionChange)
      this.canvas.on('selection:cleared', this.onSelectionChange)
      // Object listeners
      this.canvas.on('object:rotated', this.onObjectsManipulated)
      this.canvas.on('object:scaled', this.onObjectsManipulated)
      this.canvas.on('object:moved', this.onObjectsManipulated)
    },

    removeCanvasListeners () {
      // Selection listeners
      this.canvas.off('selection:created', this.onSelectionChange)
      this.canvas.off('selection:updated', this.onSelectionChange)
      this.canvas.off('selection:cleared', this.onSelectionChange)
      // Object listeners
      this.canvas.off('object:rotated', this.onObjectsManipulated)
      this.canvas.off('object:scaled', this.onObjectsManipulated)
      this.canvas.off('object:moved', this.onObjectsManipulated)
    },

    ensureOrder () {
      // Ensure order in data model
      this.$store.state.signEditor.objectIds.forEach((id) => {
        this.canvas.bringToFront(this.fabricObjectsKeyedById[id])
      })

      this.canvas.bringToFront(this.clippingOverlay)
      this.canvas.bringToFront(this.canvasBorder)
    },

    render () {
      this.ensureOrder()
      this.canvas.renderAll()
    },

    onObjectsManipulated () {
      this.updateObjectsPositioning(this.canvas.getActiveObjects())
    },

    updateObjectsPositioning (objects) {
      objects.forEach((o) => {
        const id = o.cm_object_id

        const t = fabric.util.qrDecompose(o.calcTransformMatrix())
        const data = {
          position: new Point(
            t.translateX - this.contentRect.minX, // Substract offset of the content area
            t.translateY - this.contentRect.minY // Subsctract offset of the content area
          ),
          size: new Size(o.width, o.height),
          scaleX: t.scaleX,
          scaleY: t.scaleY,
          flipX: t.flipX,
          flipY: t.flipY,
          angle: t.angle
        }
        this.$store.commit('signEditor/updateObjectProperties', { id, data })
      })
    },

    onSelectionChange (e) {
      const ids = this.canvas.getActiveObjects().map((o) => o.cm_object_id)
      console.log('Selection changed', ids)
      this.$store.commit('signEditor/setSelectedObjectIds', ids)
    },

    async onObjectsChanged () {
      if (this.isDigestingObjectChanges) {
        console.warn('CustomSignCanvas::onObjectChanges: Object change digestion not yet finished')
        return
      }
      this.isDigestingObjectChanges = true
      // TODO: Ensure only one call of this function is running at once
      console.log('Objects changed')
      const current = new Set(Object.keys(this.fabricObjectsKeyedById))
      const target = new Set(this.objectIds)

      const add = [...target].filter((id) => current.has(id) === false)
      const remove = [...current].filter((id) => target.has(id) === false)
      const keep = [...target].filter((id) => current.has(id))

      console.log(`Adding ${add.length} objects with ids: ${add}`)
      console.log(`Removing ${remove.length} objects with ids: ${remove}`)
      console.log(`Keeping ${keep.length} objects with ids: ${keep}`)

      // Remove objects
      // this.canvas.remove(...remove.map(id => this.fabricObjectsKeyedById[id]))
      remove.forEach((id) => {
        console.log('Removing from canvas ', id, this.fabricObjectsKeyedById[id])
        this.canvas.remove(this.fabricObjectsKeyedById[id])
        delete this.fabricObjectsKeyedById[id]
      })

      // Ensure target drawing order of objects on canvas
      const objectIdsOnCanvas = this.canvas
        .getObjects()
        .filter((o) => o.cm_object_id != null)
        .map((o) => o.cm_object_id)
      const offset = 1 // Background layer is always behind
      keep.forEach((id, i) => {
        const currentIndex = objectIdsOnCanvas.indexOf(id)
        if (currentIndex !== i) {
          console.log(`Moving element from ${currentIndex} to ${i}`)
          this.canvas.moveTo(this.fabricObjectsKeyedById[id], i + offset)
        }
      })

      // Create new objects
      // TODO: Maybe insert objects at correct position

      // await this.updatedCanvasAfterObjectChangePromise

      console.log('Creating fabricObjects for ', add)
      const newObjects = await Promise.all(
        add.map(async (id) => {
          const o = this.objectsKeyedById[id]
          if (o == null) {
            console.warn(`CustomSignCanvas::onObjectsChanged: Inconsistent editor state. Object ${id} is null`)
          }
          return this.createRenderedObject(o)
        })
      )
      console.log('FabricObjects created for ', add)
      add.forEach((id, i) => {
        this.fabricObjectsKeyedById[id] = newObjects[i]
      })
      console.log(this.fabricObjectsKeyedById)
      this.canvas.add(...newObjects)

      // TODO: Add possibility to wait until objects changed
      this.render()

      this.isDigestingObjectChanges = false
    },

    onDataModelChanged (id) {
      console.log('Data model changed for ', id)
      const o = this.objectsKeyedById[id]
      this.updateRenderedObject(o)
    },

    async updateRenderedObject (object) {
      console.log('CustomSignCanvas::updateRenderedObject: ', object?.id, this.fabricObjectsKeyedById[object.id])
      await object.createdPromise
      const canvasObject = this.fabricObjectsKeyedById[object.id]
      object.apply(canvasObject)

      // TODO: Difference between here and creation of object

      canvasObject.left = canvasObject.left + this.contentRect.minX // Add offset of the content area
      canvasObject.top = canvasObject.top + this.contentRect.minY // Add offset of the content area

      this.render()
    },

    async createRenderedObject (object) {
      // TODO: Return rendered objects immediately
      // Add to canvas later
      if (object == null) {
        return null
      }
      const isFixed = this.isFixedObject(object.id)

      console.log('CustomSignCanvas::createRenderedObject: Creating object', object)

      if (object.objectType === 'text' || object.objectType === 'textbox') {
        console.log('CustomSignCanvas::createRenderedObject: Awaiting webfonts', object)
        await this.webfontsLoadedPromise
      }

      object.createdPromise = object.create(this.drawingContext)
      const canvasObject = await object.createdPromise

      console.log('CustomSignCanvas::createRenderedObject: Object created', object, canvasObject)
      canvasObject.left = canvasObject.left + this.contentRect.minX // Add offset of the content area
      canvasObject.top = canvasObject.top + this.contentRect.minY // Add offset of the content area

      if (isFixed) {
        canvasObject.evented = false
        canvasObject.selectable = false
      }
      return canvasObject
    },

    async getImageSize (imageReference) {
      const imageSrc = await this.$resolver.resolveUrl(imageReference, 'default')
      return new Promise((resolve, reject) => {
        const img = new Image()
        img.onload = () => resolve(new Size(img.naturalWidth, img.naturalHeight))
        img.onerror = (err) => reject(err)
        img.src = imageSrc
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/bulma-variables.scss';
.custom-sign-canvas {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  flex-shrink: 1;
  flex-grow: 1;
  overflow: hidden;
  position: relative;
}

#canvas-overlay-container {
  position: absolute;
  pointer-events: none;
}
</style>
