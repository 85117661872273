<template>
  <div class="custom-sign-sidebar p-2">
    <section class="add-image">
      <b-field label="Bild oder Gebärde">
        <b-button icon-left="image" size="is-small" expanded @click="onAddImageButtonClicked">Auswählen</b-button>
      </b-field>
    </section>

    <section class="add-text">
      <b-field label="Text" expanded>
        <b-input size="is-small" type="text" v-model="text" expanded @keyup.native.enter="onAddText" />
        <b-button size="is-small" :disabled="!text" @click="onAddText">Einfügen</b-button>
      </b-field>
    </section>

    <section class="add-arrows">
      <label>Gebärden-Pfeil</label>
      <arrow-list @selected="onArrowSelected" />
    </section>
  </div>
</template>

<script>
import ArrowList from '@/components/custom-signs/ArrowList.vue'
import SearchDropdown from '@custom-media/signdigital-web-shared/src/components/SearchDropdown'
import Vue from 'vue'
import { ensureImageReferenceOwnership } from '@/lib/util/image-ownership'

export default {
  components: {
    ArrowList
  },
  data () {
    return {
      text: null
    }
  },
  methods: {
    async onArrowSelected (arrow) {
      const o = await this.$store.dispatch('signEditor/addArrow', arrow)
      this.$store.commit('signEditor/setSelectedObjectIds', [o.id])
    },
    async onAddText () {
      if (!this.text) {
        return
      }
      const o = await this.$store.dispatch('signEditor/addText', { text: this.text, color: '#000000' })
      this.text = null
      this.$store.commit('signEditor/setSelectedObjectIds', [o.id])
    },
    onAddImageButtonClicked (event) {
      const SearchDropdownCtrl = Vue.extend(SearchDropdown)
      const el = new SearchDropdownCtrl({
        propsData: {
          target: event.target,
          minX: 260,
          minY: 70,
          allowedContentTypes: ['images']
        },
        parent: this
      })
      el.$once('selected', async (reference) => {
        if (reference.service === 'images') {
          const { ownerType, owner } = this.$store.state.signEditor
          reference = await ensureImageReferenceOwnership({ ownerType, owner }, reference, this)
          if (reference === null) {
            return
          }
        }
        const o = await this.$store.dispatch('signEditor/addImage', reference)
        this.$store.commit('signEditor/setSelectedObjectIds', [o.id])
      })
      el.$once('closed', () => {
        el.$destroy()
        el.$el.parentNode.removeChild(el.$el)
      })
      el.$mount()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/bulma-variables.scss';
.custom-sign-sidebar {
  background: $white;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-left: 1px solid $grey-light;

  @include until($tablet) {
    border-top: 1px solid $grey-light;
  }

  section {
    margin-bottom: 1em;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .add-arrows {
    min-height: 100px;
    flex-shrink: 1;
    display: flex;
    flex-direction: column;
    label {
      font-weight: bold;
    }
  }

  .arrow-list {
    padding-right: 1em;
    overflow-y: scroll;
    overflow-x: hidden;
  }
}
</style>
