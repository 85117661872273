<template>
  <section class="hero is-primary is-fullheight is-bold invitation-view">
    <div class="hero-body">
      <div class="container">
        <div class="column is-6 is-offset-3">
          <!-- BRANDING -->
          <div class="logo-wrapper">
            <img class="logo" src="@/assets/img/sign-digital-logo_white.svg" />
          </div>

          <b-loading :active="isGetPending" />

          <feathers-error :error="errorOnGet" title="Fehler beim Abrufen der Einladung" :closable="false">
            <b-button expanded class="mt-4" tag="router-link" :to="{ name: 'login' }">Zur Anmeldung</b-button>
          </feathers-error>

          <!-- Invitation overview -->
          <team-invitation-card v-if="invitation" @cancel="onCancelInvitation" />
        </div>
        <div class="column is-8 is-offset-2">
          <div v-if="invitation">
            <!-- Actions -->
            <div class="box box-shadow">
              <div class="content">
                <p>
                  Glückwunsch, du hast eine Einladung zu einem SIGNdigital-Team erhalten!
                  <a class="has-text-primary" :href="landingPageUrl" target="_blank">Was ist SIGNdigital überhaupt?</a>
                  In der Einladung ist bereits eine SIGNdigital-Lizenz enthalten, sodass du nichts bezahlen brauchst und
                  direkt loslegen kannst!
                </p>

                <div v-if="currentUser" class="mt-2">
                  <div v-if="isInvitedUser">
                    <!-- Already logged in as correct user -->
                    <p>
                      Du bist bereits als <strong>{{ currentUser.email }}</strong> angemeldet.
                    </p>
                    <div class="buttons mt-4">
                      <b-button expanded type="is-primary" @click="onJoinButtonClicked"
                        >Einladung jetzt annehmen</b-button
                      >
                    </div>
                  </div>
                  <div v-else>
                    <!-- Already logged in as wrong user -->
                    <p>
                      Du bist aktuell als <strong>{{ currentUser.email }}</strong> angemeldet, die Einladung gilt aber
                      nur für <strong>{{ invitation.email }}</strong
                      >. Um die Einladung anzunehmen, musst du dich zuerst abmelden:
                    </p>
                    <div class="buttons mt-4">
                      <b-button expanded type="is-primary" @click="onLogoutFirstButtonClicked"
                        >Abmelden und fortfahren</b-button
                      >
                    </div>
                  </div>
                </div>
                <div v-else>
                  <!-- Needs to login first -->
                  <p>
                    <strong>Um dich anzumelden, gibt es zwei Möglichkeiten:</strong>
                  </p>
                  <p class="mb-4">
                    Falls du noch kein SIGNdigital-Konto für die eingeladene E-Mail Adresse {{ invitation.email }} hast,
                    kannst du über den folgenden Button ein neues Konto erstellen.
                  </p>

                  <div class="buttons mt-4">
                    <b-button expanded type="is-primary" @click="onCreateAccountButtonClicked"
                      >Ein neues Konto erstellen</b-button
                    >
                  </div>
                  <divider label="oder" />
                  <p class="mb-2">
                    Wenn du bereits ein SIGNdigital-Konto für die E-Mail Adresse
                    {{ invitation.email }} hast:
                  </p>
                  <b-button expanded type="is-primary" @click="onLoginButtonClicked"
                    >Bei meinem bestehenden Konto anmelden</b-button
                  >
                </div>
                <p class="mt-5">
                  Du möchtest die Einladung in diesem Moment noch nicht annehmen? Dann klicke auf den oben stehenden
                  Button „Einladung vorerst ignorieren“.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import TeamInvitationCard from '@/components/TeamInvitationCard.vue'
import Divider from '@/components/Divider.vue'
import { mapState } from 'vuex'
export default {
  components: { TeamInvitationCard, Divider },
  data () {
    return {
      landingPageUrl: process.env.VUE_APP_LANDING_HOST
    }
  },
  computed: {
    ...mapState('teamInvitation', ['isGetPending', 'errorOnGet', 'invitation']),
    currentUser () {
      return this.$store.getters['auth/user']
    },
    isInvitedUser () {
      return this.currentUser?.email?.toLowerCase() === this.invitation.email?.toLowerCase()
    },
    invitationIsInvalid () {
      return (
        this.invitation &&
        (this.invitation.invalidatedAt != null || this.invitation.consumedAt != null || this.invitation.new)
      )
    }
  },
  created () {
    this.init()
  },
  methods: {
    async init () {
      const { invitationId, invitationToken } = this.$route.params
      this.$store.dispatch('teamInvitation/start', { invitationId, invitationToken })
    },
    onCancelInvitation () {
      this.$router.replace({ name: 'login' })
    },
    async onJoinButtonClicked () {
      await this.$store.dispatch('teamInvitation/accept')
      this.$buefy.toast.open({
        type: 'is-success',
        message: 'Team erfolgreich beigetreten!'
      })
      await this.$store.dispatch('session/fetch')
      this.$router.push({
        name: 'order-completed'
      })
    },
    async onLogoutFirstButtonClicked () {
      await this.$store.dispatch('auth/logout')
      window.location.reload()
    },
    onLoginButtonClicked () {
      this.$router.push({ name: 'login' })
    },
    onCreateAccountButtonClicked () {
      this.$router.push({ name: 'signup' })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/bulma-variables.scss';

.logo-wrapper {
  text-align: center;
  margin-bottom: 1em;

  .logo {
    max-width: 250px;
  }
}

.hero .box {
  padding: 2em;

  form {
    .button {
      margin-top: 1em;
      margin-bottom: 0.5em;
    }
  }
}
</style>
