<template>
  <div id="user-invoices-view">
    <div class="container p-5">
      <h1 class="title">Deine Rechnungen</h1>

      <div class="card" v-if="userBillingType === 'paying' && !hasManualSubscription">
        <div class="card-content">
          <div class="content">
            <!-- Invoices -->
            <b-loading :active="isFindInvoicesPending" :is-full-page="false" />
            <b-table :data="invoices" v-if="invoices">
              <b-table-column field="created" label="Datum" v-slot="{ row }">
                <span>Rechnung vom {{ new Date(row.created * 1000) | date }}</span>
                <div class="tags">
                  <span class="tag">{{ row.id }}</span>
                </div>
              </b-table-column>
              <b-table-column field="total" label="Summe" v-slot="{ row }">
                {{ (row.total / 100) | price }}
              </b-table-column>
              <b-table-column field="status" label="Status" v-slot="{ row }">
                {{ row.status }}
              </b-table-column>
              <b-table-column label="Aktionen" v-slot="{ row }">
                <div class="buttons">
                  <b-button tag="a" :href="row.invoice_pdf" target="_blank" size="is-small">Download</b-button>
                </div>
              </b-table-column>
              <template #empty>
                <div class="content has-text-centered">
                  <p>Es sind noch keine Rechnungen hinterlegt.</p>
                </div>
              </template>
            </b-table>
          </div>
        </div>
      </div>
      <div class="card" v-else-if="userBillingType === 'paying' && hasManualSubscription">
        <div class="card-content">
          <div class="content">
            Die Bereitstellung von Rechnungen im Benutzerkonto ist nur für Lizenzen verfügbar, die per Kreditkarte oder
            Lastschrift bezahlt werden.
          </div>
        </div>
      </div>
      <div class="card" v-else-if="userBillingType === 'member'">
        <div class="card-content">
          <div class="content">
            Du nutzt die Lizenz deines Teams - die Rechnungen werden vom Team-Besitzer verwaltet.
          </div>
        </div>
      </div>
      <div class="card" v-else-if="userBillingType === 'free'">
        <div class="card-content">
          <div class="content">Du nutzt einen kostenlosen Zugang. Es gibt daher keine Rechnungen abzurufen.</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import feathersClient from '@/feathers-rest-client'
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      isFindInvoicesPending: false,
      invoices: null
    }
  },
  computed: {
    ...mapGetters('session', ['hasManualSubscription']),
    user () {
      return this.$store.getters['auth/user']
    },
    userBillingType () {
      return this.user?.billingType
    }
  },
  mounted () {
    if (!this.hasManualSubscription) {
      this.findInvoices()
    }
  },
  methods: {
    async findInvoices () {
      this.isFindInvoicesPending = true
      const { data: invoices } = await feathersClient.service('stripe-invoices').find()
      this.invoices = invoices
      this.isFindInvoicesPending = false
    }
  }
}
</script>
