<template>
  <div class="file-type-picker" :class="{ 'is-small': small }">
    <b-field v-if="canPickFileType">
      <b-radio-button :value="fileType" @input="$emit('update:fileType', $event)" native-value="lists" size="is-small">
        <b-icon icon="list"></b-icon>
        <span>Lernliste</span>
      </b-radio-button>
      <b-radio-button
        :value="fileType"
        @input="$emit('update:fileType', $event)"
        size="is-small"
        native-value="documents"
      >
        <b-icon icon="file"></b-icon>
        <span>Druckvorlage</span>
      </b-radio-button>
      <b-radio-button
        :value="fileType"
        @input="$emit('update:fileType', $event)"
        size="is-small"
        native-value="custom-signs"
      >
        <b-icon icon="vector-square"></b-icon>
        Eigene Gebärde
      </b-radio-button>
    </b-field>

    <document-type-picker
      v-if="fileType === 'documents'"
      :disabled="disabled"
      :value="documentType"
      @input="$emit('update:documentType', $event)"
      description
      selectable
      :small="small"
    />
    <custom-sign-format-switch
      v-if="fileType === 'custom-signs'"
      :label="false"
      :value="customSignType"
      @input="$emit('update:customSignType', $event)"
    />
  </div>
</template>

<script>
// TODO: Maybe support passing a list of allowed/restricted types
import DocumentTypePicker from '@/components/document/DocumentTypePicker.vue'
import CustomSignFormatSwitch from '@custom-media/signdigital-web-shared/src/components/CustomSignFormatSwitch.vue'
export default {
  components: {
    DocumentTypePicker,
    CustomSignFormatSwitch
  },
  props: {
    fileType: {
      type: String
    },
    documentType: {
      type: String
    },
    customSignType: {
      type: String
    },
    pick: {
      type: Array,
      default () {
        return ['fileType', 'documentType', 'customSignType']
      }
    },
    disabled: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    canPickFileType () {
      return this.canPick('fileType')
    },
    canPickDocumentType () {
      return this.canPick('documentType')
    },
    canPickCustomSignType () {
      return this.canPick('documentType')
    }
  },
  methods: {
    canPick (property) {
      return this.pick.includes(property)
    }
  }
}
</script>
