<template>
  <div class="documents-browser">
    <!-- NAVBAR -->
    <b-navbar class="app-nav is-mobile" :mobile-burger="false" :active="true">
      <template slot="brand">
        <b-navbar-item tag="div">
          <nav class="breadcrumb" aria-label="breadcrumbs">
            <ul>
              <li class="is-active"><a href="#">Organisieren</a></li>
              <li class="is-active" v-if="browserCtrl && browserCtrl.fileTypeFilter">
                <a href="#">{{ browserCtrl.fileTypeFilter | fileTypeName(true) }}</a>
              </li>
            </ul>
          </nav>
        </b-navbar-item>
      </template>
      <template slot="start"> </template>
      <template slot="end">
        <b-navbar-item tag="div" v-if="browserCtrl && browserCtrl.fileTypeFilter">
          <b-button size="is-small" type="is-ghost" icon-right="times-circle" @click="clearFilter"
            >Filter „{{ browserCtrl.fileTypeFilter | fileTypeName(true) }}” entfernen</b-button
          >
        </b-navbar-item>
        <b-navbar-item tag="div" v-else>
          <b-dropdown append-to-body aria-role="list" position="is-bottom-left">
            <template #trigger>
              <b-button size="is-small" type="is-ghost" icon-left="filter" icon-right="chevron-down"
                >Inhalte filtern</b-button
              >
            </template>

            <b-dropdown-item aria-role="listitem" @click="applyFilter('lists')">
              <b-icon icon="list" />
              Lernlisten
            </b-dropdown-item>
            <b-dropdown-item aria-role="listitem" @click="applyFilter('documents')">
              <b-icon icon="file" />
              Druckvorlagen
            </b-dropdown-item>
            <b-dropdown-item aria-role="listitem" @click="applyFilter('custom-signs')">
              <b-icon icon="vector-square" />
              Eigene Gebärden
            </b-dropdown-item>
          </b-dropdown>
        </b-navbar-item>
        <b-navbar-item tag="div"> </b-navbar-item>
      </template>
    </b-navbar>

    <section>
      <div class="container">
        <b-loading :active="isLoading" />
        <file-browser
          v-if="!isLoading"
          :browserCtrl="browserCtrl"
          :sources="['team', 'user']"
          :nodeTypes="['documents']"
        ></file-browser>

        <hint hint-key="file-browser-view">
          <p>Das hier ist deine neue Dateiverwaltung 🎉</p>
          <p></p>
          <p>
            Hier kannst du endlich alle deine Inhalte in einer Ansicht verwalten und in Ordnern
            <b-icon icon="folder" type="is-grey" /> organisieren.
          </p>
        </hint>
      </div>
    </section>
  </div>
</template>

<script>
import FileBrowser from '@/components/folders/FileBrowser.vue'
import FileBrowserController from '@/lib/folders/FileBrowserController'
import { fileNodeMixin } from '@custom-media/signdigital-web-shared/src/mixins/file-node-mixins'
import Hint from '@/components/Hint'
import { omit } from 'lodash'

export default {
  mixins: [fileNodeMixin],
  components: { FileBrowser, Hint },
  data () {
    return {
      browserCtrl: null,
      isLoading: true
    }
  },
  computed: {},
  mounted () {
    this.$store?.dispatch('fileNodeTree/ensureInitialized')
    let fileTypeFilter = null
    if (this.$route.query.t) {
      fileTypeFilter = this.$route.query.t
    }
    if (this.$route.query.c) {
      this.$store?.dispatch('fileNodeTree/maybeFetchInPath', this.$route.query.c).then(() => {
        const i = this.$store.state.fileNodeTree.itemsById[this.$route.query.c]

        const selectedItems = this.getSelectedItemsFromQuery()
        this.browserCtrl = new FileBrowserController({
          rootItems: this.$store.state.fileNodeTree.rootItems,
          currentItem: i,
          currentRoot: this.$store.state.fileNodeTree.itemsById[i.rootId],
          fileTypeFilter,
          selectedItems
        })
        this.isLoading = false
      })
    } else {
      this.browserCtrl = new FileBrowserController({
        rootItems: this.$store.state.fileNodeTree.rootItems,
        fileTypeFilter
      })
      this.isLoading = false
    }
  },
  watch: {
    'browserCtrl.currentItem' (n) {
      if (this.$route.query?.c === n._id) {
        return
      }
      const routeData = { name: 'file-browser', query: { ...this.$route.query, c: n._id } }
      if (this.$route.query?.c == null) {
        this.$router.replace(routeData)
      } else {
        this.$router.push(routeData)
      }
    },
    'browserCtrl.selectedItems' (n) {
      const s = this.getSelectedItemsQuery()
      if (this.$route.query?.s === s) {
        return
      }
      const routeData = { name: 'file-browser', query: { ...this.$route.query, s } }
      this.$router.push(routeData)
    },
    'browserCtrl.fileTypeFilter' (n) {
      console.log('new file type filter', n)
      if ((this.$route.query?.t ?? null) !== n) {
        console.log('push file type filter into route')
        const routeData = { name: 'file-browser', query: { ...omit(this.$route.query, ['t']) } }
        if (n != null) {
          routeData.query.t = n
        }
        this.$router.push(routeData)
      }
    },
    $route (to, from) {
      console.log('route change', to, from)
      if ((to.query.t ?? null) !== this.browserCtrl.fileTypeFilter) {
        this.browserCtrl.fileTypeFilter = to.query.t ?? null
      }
      if (to.query.c && to.query.c !== this.browserCtrl.currentItem?._id) {
        console.log('Change current item!')
        this.$store?.dispatch('fileNodeTree/maybeFetchInPath', to.query.c).then(() => {
          const i = this.$store.state.fileNodeTree.itemsById[to.query.c]
          if (!i) {
            return
          }
          this.browserCtrl.goTo(i, false)
          const selectedItems = this.getSelectedItemsFromQuery()
          if (selectedItems) {
            this.browserCtrl.select(selectedItems)
          }
        })
      }
    }
  },
  methods: {
    applyFilter (f) {
      this.browserCtrl.fileTypeFilter = f
    },
    clearFilter () {
      this.browserCtrl.fileTypeFilter = null
    },
    getSelectedItemsFromQuery () {
      const s = this.$route.query?.s
      if (!s) {
        return null
      }
      const ids = s.split(',')
      const items = ids.map((id) => this.$store.state.fileNodeTree.itemsById[id]).filter((i) => i != null)
      return items
    },
    getSelectedItemsQuery () {
      return this.browserCtrl?.selectedItems?.map((i) => i._id).join(',')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/bulma-variables.scss';
.container {
  padding: 1rem;
  @include until($tablet) {
    padding: 0.25rem;
  }
}
</style>
