export default {
  data () {
    return {
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
      windowHeightMinWidth: 1024,
      navbarHeight: 70,
      secondaryNavbarHeight: 65
    }
  },
  mounted () {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onWindowHeightMixinUpdateHeight)
    })
  },
  computed: {
    windowHeightCSS () {
      if (this.windowHeightMinWidth != null && this.windowWidth < this.windowHeightMinWidth) {
        return null
      }
      return `height: ${this.windowHeight}px`
    },
    windowHeightCSSWithoutNavbar () {
      if (this.windowHeightMinWidth != null && this.windowWidth < this.windowHeightMinWidth) {
        return null
      }
      return `height: ${this.windowHeight - this.navbarHeight}px`
    },
    windowHeightCSSWithoutNavbars () {
      if (this.windowHeightMinWidth != null && this.windowWidth < this.windowHeightMinWidth) {
        return null
      }
      return `height: ${this.windowHeight - this.navbarHeight - this.secondaryNavbarHeight}px`
    }
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    onWindowHeightMixinUpdateHeight () {
      this.windowHeight = window.innerHeight
      this.windowWidth = window.innerWidth
    }
  }
}
