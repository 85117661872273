<template>
  <div class="grid-preview">
    <figure>
      <svg :viewBox="'0 0 ' + page.dimensions.width + ' ' + page.dimensions.height">
        <!-- Page -->
        <rect
          class="page"
          x="0"
          y="0"
          :width="page.dimensions.width"
          :height="page.dimensions.height"
          stroke-width="10"
          stroke="black"
          fill="transparent"
        />

        <!-- Printable area -->
        <rect
          class="printable-area"
          :x="page.printableArea.x"
          :y="page.printableArea.y"
          :width="page.printableArea.width"
          :height="page.printableArea.height"
          stroke-width="4"
          stroke="grey"
          fill="transparent"
        />

        <!-- Content area -->
        <rect
          :x="page.contentArea.x"
          :y="page.contentArea.y"
          :width="page.contentArea.width"
          :height="page.contentArea.height"
          fill="transparent"
          stroke="#53BAC0"
          stroke-width="4"
        />

        <!-- Grid elements -->
        <rect
          v-for="(frame, i) of grid.allElementFrames"
          :key="i"
          :x="gridFrame.x + frame.x"
          :y="gridFrame.y + frame.y"
          :width="frame.width"
          :height="frame.height"
          fill="#53BAC0"
          stroke="#81d7dc"
          stroke-width="4"
        />
      </svg>
    </figure>
  </div>
</template>

<script>
import { Rectangle } from '@custom-media/geometry'
export default {
  props: {
    grid: Object,
    page: Object
  },
  computed: {
    gridFrame () {
      const gridDimensions = this.grid.outerDimensions
      const contentArea = this.page.contentArea
      return Rectangle.fromProperties(
        contentArea.x + (contentArea.width - gridDimensions.width) / 2,
        contentArea.y,
        gridDimensions.width,
        gridDimensions.height
      )
    }
  }
}
</script>
