import { render, staticRenderFns } from "./DocumentsBrowser.vue?vue&type=template&id=6ae7c6f4&scoped=true"
import script from "./DocumentsBrowser.vue?vue&type=script&lang=js"
export * from "./DocumentsBrowser.vue?vue&type=script&lang=js"
import style0 from "./DocumentsBrowser.vue?vue&type=style&index=0&id=6ae7c6f4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ae7c6f4",
  null
  
)

export default component.exports