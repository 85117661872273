<template>
  <div class="user-password">
    <div class="container p-5">
      <h1 class="title">Passwort ändern</h1>

      <feathers-error vuex-store="users" vuex-action="patch" title="Fehler beim Ändern des Passworts" class="mb-4" />
      <div class="card">
        <div class="card-content">
          <div class="content">
            <b-loading :active="isPatchPending" />
            <password-change-form ref="passwordChangeForm" old-password @submit="onSubmit" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PasswordChangeForm from '@/components/PasswordChangeForm.vue'
import { mapState } from 'vuex'

export default {
  components: {
    PasswordChangeForm
  },
  data () {
    return {}
  },
  computed: {
    ...mapState('users', ['isPatchPending'])
  },
  methods: {
    async onSubmit (newPassword, oldPassword) {
      this.$store.commit('users/clearError', 'patch')
      const user = this.$store.getters['auth/user']
      await this.$store.dispatch('users/patch', [user._id, { password: newPassword, oldPassword }])
      this.$refs.passwordChangeForm.reset()
      this.$nextTick(() => {
        this.$refs.passwordChangeForm.$refs.form.reset()
      })
      this.$buefy.toast.open({
        message: 'Passwort geändert',
        type: 'is-success'
      })
    }
  }
}
</script>
