import { License } from '@custom-media/signdigital-lib/src/license'

export default {
  namespaced: true,
  state: () => {
    return {
      user: null,
      entity: null,

      team: null,
      teamRole: null,

      subscription: null,
      license: null,

      // documentEditorExitRoute: null,
      // signEditorExitRoute: null,
      // listEditorExitRoute: null,
      // listPlayerExitRoute: null,

      exitRoute: null,

      isFetchPending: false,
      isFetchComplete: false,
      errorOnFetch: null
    }
  },
  getters: {
    hasTeamLicense (state) {
      return state.license?.size > 1
    },
    isTeamOwnerWithoutLicense (state) {
      return state.teamRole === 'owner' && state.team?.ownerHasAccess === false
    },
    hasActiveSubscription (state) {
      return state.subscription?.status === 'active'
    },
    hasManualSubscription (state) {
      return state.subscription?.subscriptionType === 'manual'
    },
    billingType (state) {
      return state.user.billingType
    }
  },
  mutations: {
    init (state, { user, entity, team, teamRole, subscription }) {
      state.user = user
      state.entity = entity
      state.team = team
      state.teamRole = teamRole
      state.subscription = subscription
      if (subscription) {
        state.license = new License(subscription.license)
      }
    },
    reset (state) {
      state.user = null
      state.entity = null

      state.team = null
      state.teamRole = null

      state.subscription = null
      state.license = null

      state.isFetchPending = false
      state.isFetchComplete = false
      state.errorOnFetch = null
    },
    setFetchPending (state) {
      state.isFetchPending = true
    },
    unsetFetchPending (state) {
      state.isFetchPending = false
    },
    setErrorOnFetch (state, error) {
      state.errorOnFetch = error
    },
    unsetErrorOnFetch (state) {
      state.errorOnFetch = null
    },
    setExitRoute (state, { editor, route }) {
      state.exitRoute = { [editor]: route }
    },
    clearExitRoute (state) {
      state.exitRoute = null
    }
  },
  actions: {
    async fetch ({ rootGetters, commit, dispatch }) {
      commit('reset')
      const user = rootGetters['auth/user']

      if (user == null) {
        console.log('store.session::fetch: No user')
        return
      }

      commit('setFetchPending')
      commit('unsetErrorOnFetch')
      try {
        let team = null
        let teamRole = null

        const entity = await dispatch(`${user.role}/get`, user.entity, { root: true })

        if (user.team) {
          team = await dispatch('teams/get', user.team, { root: true })
          const isOwner = team.owner?._id === user._id
          teamRole = isOwner ? 'owner' : team.members.find((m) => m.user === user._id || m.user._id === user._id)?.role
        }

        const hasSubscription = user.subscription != null
        const isPayingUser = user.billingType === 'paying'
        const isTeamMember = user.billingType === 'member' && team != null
        let subscription = null
        if (hasSubscription && (isPayingUser || (isTeamMember && ['owner', 'manager'].includes(teamRole)))) {
          subscription = await dispatch('subscriptions/get', user.subscription, { root: true })
        }
        commit('init', { user, entity, team, teamRole, subscription })
      } catch (error) {
        console.error(error)
        commit('setErrorOnFetch', error)
      } finally {
        commit('unsetFetchPending')
      }
    }
  }
}
