<template>
  <div class="document-type-picker" :class="{ 'is-small': small, 'is-disabled': disabled }">
    <div class="create-cards columns is-multiline">
      <div class="column" :class="columnClasses">
        <create-document-card
          :disabled="disabled"
          :has-button="!selectable"
          :small="small"
          :has-description="!small"
          :selected="value === 'grid'"
          type="grid"
          @click="onDocumentTypeClicked('grid')"
        />
      </div>
      <div class="column" :class="columnClasses">
        <create-document-card
          :disabled="disabled"
          :has-button="!selectable"
          :small="small"
          :has-description="!small"
          :selected="value === 'own-sign'"
          type="own-sign"
          @click="onDocumentTypeClicked('own-sign')"
        />
      </div>
      <div class="column" :class="columnClasses">
        <create-document-card
          :disabled="disabled"
          :has-button="!selectable"
          :small="small"
          :has-description="!small"
          :selected="value === 'target-vocabulary'"
          type="target-vocabulary"
          @click="onDocumentTypeClicked('target-vocabulary')"
        />
      </div>
    </div>

    <div class="selection-description" v-if="description">
      <p><b-icon icon="info-circle" />{{ documentTypeDescription }}</p>
    </div>
  </div>
</template>

<script>
import CreateDocumentCard from '@/components/CreateDocumentCard'

export default {
  components: {
    CreateDocumentCard
  },
  props: {
    value: {
      type: String,
      default: null
    },
    selectable: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    },
    description: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    columnClasses () {
      return this.small ? '' : ['is-one-quarter-fullhd', 'is-one-quarter-desktop', 'is-half-tablet']
    },
    documentTypeDescription () {
      switch (this.value) {
        case 'own-sign':
          return 'Gegenüberstellung von eigenen Gebärden und Standard-Gebärden'
        case 'target-vocabulary':
          return 'Fortschritt-Dokumentation bei der Erlernung eines Wortschatzes'
        case 'grid':
          return 'Ein anpassbares Raster zum Ausdrucken von vielen Gebärden auf einmal'
      }
      return null
    }
  },
  methods: {
    onDocumentTypeClicked (documentType) {
      if (this.disabled) {
        return
      }
      if (this.selectable) {
        this.$emit('input', documentType)
      } else {
        this.$emit('selected', documentType)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.is-small {
  .selection-description {
    font-size: 0.9em;
  }
}
.create-cards {
  display: flex;
  .column {
    display: flex;
    .document-card {
      flex-grow: 1;
    }
  }
}

.document-type-picker.is-small::v-deep {
  .document-card {
    max-width: 180px;
    .card-content h1.title {
      font-size: 0.8rem;
    }
  }
}
</style>
